import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  createUserAndPassword,
  SignIn,
  loginInDataBase,
  updatePasswordFirebase,
  userExists,
  sendEmail,
} from "../../Actions/Actions";
import background from "../../assets/background.jpg";
import logo from "../../assets/logo.jpg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RecoverPassword from "../RecoverPassword";
import { size } from "lodash";
import { TEMPLATE } from "../../emails/Emails";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {"Copyright © "}
      <Link color="inherit" href="http://www.Pansmamarinelogistic.com.pa">
        LOGISTIC INTERNATIONAL SERVICES CORP.
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  const [showpassword, setshowpassword] = useState(false);
  const [loading, setloading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");
    let password = data.get("password");

    if (!email) {
      showToast("Plase enter an email", 3);
    } else if (!password) {
      showToast("Plase enter a password", 3);
    } else {
      const isActive = await loginInDataBase(email.trim(), password.trim());
      console.log("IS ACTIVE");
      console.log(isActive);
      if (isActive.length === 0) {
        showToast(
          "User and Password Incorrect. Please, validate and try again. If you has been trying many times, please use forgot password option or contact to Administrator.",
          3
        );
      } else {
        const response = await SignIn(navigate, email.trim(), password.trim());
        console.log("ENTRO AQUÍ AL SEGUNDO PERO NO LOGUEO");
        console.log(response);
        if (!response) {
          showToast(
            "User and Password Incorrect. Please, validate and try again. If you has been trying many times, please use forgot password option or contact to Administrator.",
            3
          );
        }
      }
    }
  };

  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };

  const handleClose = () => {
    setshowpassword(false);
  };

  const completeRequest = () => {
    setshowpassword(true);
  };

  const sendpasswordrecovery = async (event) => {
    event.preventDefault();
    setloading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get("emailrecovery").trim();

    if (!email) {
      showToast("Plase enter an email", 3);
    } else if (!validaremail(email)) {
      showToast("Please Introduce a Valid Email", 2);
    } else {
      let passwordweb = generatePasswordRand(6, "rand");
      let exits = await userExists(email);
      console.log(exits);
      if (size(exits) > 0) {
        const user = updatePasswordFirebase(
          exits[0]._id,
          passwordweb,
          email.trim()
        );

        const emailcreate = TEMPLATE.filter(
          (item) => item.id === "UPDATE_PASSWORD"
        );

        console.log("EMAIL CREATED");
        console.log(emailcreate);

        showToast(
          "We sent your access info to your email, please review. (Please also check the SPAM folder)",
          1
        );
        setshowpassword(false);
        //aquí entonces se envía un email al usuario.
      } else {
        showToast(
          "This email is not register in PMSSA Portal, please contact to administrator.",
          2
        );
      }
    }
    setloading(false);
  };

  const validaremail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      return false;
    } else {
      return true;
    }
  };

  const generatePasswordRand = (length, type) => {
    let characters = "";
    switch (type) {
      case "num":
        characters = "0123456789";
        break;
      case "alf":
        characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        break;
      case "rand":
        //FOR ↓
        break;
      default:
        characters =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        break;
    }
    let pass = "";
    let i = 0;
    for (i = 0; i < length; i++) {
      if (type === "rand") {
        pass += String.fromCharCode(
          (Math.floor(Math.random() * 100) % 94) + 33
        );
      } else {
        pass += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    return pass;
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <img src={logo} alt="logo" width="300px" height="auto" />

            <Typography component="h1" variant="h5">
              LOGISTIC INTERNATIONAL SERVICES CORP.
            </Typography>
            <Typography component="h1" variant="h5">
              SIGN IN
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={completeRequest}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
      <RecoverPassword
        open={showpassword}
        handleClose={handleClose}
        completeRequest={completeRequest}
        handleSubmit={sendpasswordrecovery}
        loading={loading}
      />
    </ThemeProvider>
  );
}
