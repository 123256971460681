import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getData, getGapPool } from "../../Actions/Actions";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import View from "@mui/icons-material/ViewAgenda";
import EditIcon from "@mui/icons-material/Edit";
import { TextField, Typography } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import EmbarcationTimeLine from "../Embarkations/EmbarcationTimeLine";
import { MobileDatePicker } from "@mui/x-date-pickers";

const columns = [
  {
    field: "signondate",
    headerName: "SIGN ON DATE",
    width: 200,
    editable: true,
    resizable: true,
  },
  {
    field: "VesselName",
    headerName: "Vessel Name",
    width: 200,
    editable: true,
  },

  {
    field: "signoffdate",
    headerName: "SIGN OFF DATE",
    width: 200,
    editable: true,
    renderCell: (params) => (
      <Typography style={{ overflowWrap: "break-word", size: 10 }}>
        {params.value}
      </Typography>
    ),
    resizable: true,
  },

  {
    field: "signoffreason_detail",
    headerName: "SIGN OFF DETAILS",
    width: 200,
    editable: true,
  },
  {
    field: "elegible_toreturn",
    headerName: "ELEGIBLE TO RETURN?",
    width: 200,
    editable: true,
  },
  {
    field: "doareturndate",
    headerName: "DAO RETURN DATE",
    width: 200,
    editable: true,
  },
  {
    field: "id",
    headerName: "Id",
    width: 100,
    hide: true,
  },
];

const Embarcations = ({ data, setdata, staff }) => {
  const [gridData, setGridData] = useState([]);

  const handleChange = (value, input) => {
    setdata({ ...staff, [input]: value });
  };
  const allowedPositions = ["AB", "OS", "WIPER", "OILER"];

  useEffect(() => {
    console.log("dev", data);
    let newData = data.map((item) => {
      if (item.signoffreason_detail === "TRANSFER VESSEL") {
        return {
          ...item,
          signoffreason_detail: `TRANSFER TO ${item.transfernewvessel}`,
        };
      }
      return { ...item };
    });
    setGridData(newData);
  }, []);

  console.log(data, staff);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}>
      {staff.position_applying &&
        allowedPositions.includes(staff.position_applying) && (
          <Grid container sx={{ pb: 3 }}>
            <Grid item xs={3}>
              <Typography variant="subtitle2">PROMOTED DAY/ DEMOTED</Typography>
            </Grid>
            <Grid item xs={3}>
              <MobileDatePicker
                label=""
                inputFormat="MM/dd/yyyy"
                onChange={(event) => handleChange(event, "promoteddemoteddate")}
                name="promoteddemoteddate"
                value={staff.promoteddemoteddate}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" fullWidth />
                )}
              />
            </Grid>
          </Grid>
        )}
      {/* DATAGRID */}
      <Box sx={{ height: "600px" }}>
        <DataGrid
          rows={gridData}
          columns={columns}
          autoPageSize={true}
          sx={{
            boxShadow: 2,
          }}
          height={"80vh"}
        />
      </Box>

      <EmbarcationTimeLine data={data} />
    </Paper>
  );
};

export default Embarcations;
