import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getGapPool, getSkills } from "../../Actions/Actions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ExportReportBtn from "../Commons/ExportReportBtn";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const columns = [
  {
    field: "id",
    headerName: "#",
    width: 20,
  },
  {
    field: "Id",
    headerName: "Acciones",
    width: 60,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/updatepersonalinfo?id=${params.value}`,
          state: { id: params.value },
        }}
        reloadDocument>
        <EditIcon style={{ fontSize: 25 }} color="primary" />
      </Link>
    ),
    sortable: false,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    width: 300,
  },
  {
    field: "category_vessel",
    headerName: "Vessel Type",
    width: 150,
  },
  {
    field: "department",
    headerName: "Department",
    width: 150,
    editable: true,
  },

  {
    field: "position_applying",
    headerName: "Position",
    width: 200,
    editable: true,
  },
  {
    field: "company",
    headerName: "Company",
    width: 170,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 180,
    editable: true,
  },
  {
    field: "place_of_birth",
    headerName: "Nationality",
    width: 180,
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: true,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 250,
    editable: true,
  },
  {
    field: "date_of_birth",
    headerName: "Date of Birth",
    width: 150,
    editable: true,
  },
  {
    field: "sex",
    headerName: "Gender",
    width: 100,
    editable: true,
  },
  {
    field: "nearly_airport",
    headerName: "Nerly Airport",
    width: 120,
    editable: true,
  },
];

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Select
          labelId="demo-simple-select-label"
          variant="standard"
          id="demo-simple-select"
          value={props.vesselType}
          sx={{ width: 200, mr: 2 }}
          onChange={(e) => props.setVesselType(e.target.value)}>
          <MenuItem value="Show All">Show All</MenuItem>
          <MenuItem value="Merchant vessel">Merchant vessel</MenuItem>
          <MenuItem value="Passenger vessel">Passenger vessel</MenuItem>
        </Select>
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </div>
  );
}
export const GapPool = (props) => {
  const [data, setdata] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(50);
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [vesselType, setVesselType] = React.useState("Show All");

  useEffect(() => {
    console.log(data);
    const loadResults = async () => {
      setloading(true);
      const resultado = await getGapPool();
      const upper = resultado.map((item) => {
        if (item.languages && item.otherlanguajes) {
          console.log(item);
        }
        return {
          ...item,
          fullname: item.fullname.toUpperCase(),
          company: item.company ? item.company.toUpperCase() : item.company,
        };
      });
      setCompleteData(upper);
      setdata(upper);
      setloading(false);
    };

    loadResults();
  }, []);

  useEffect(() => {
    getSkills();
  }, []);

  // React.useEffect(() => {
  // 	setdata(data);
  // }, [data]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const requestSearch = async (searchValue) => {
    if (searchValue === "") {
      setSearchText(searchValue);

      setloading(true);
      const resultado = await getGapPool();
      const upper = resultado.map((item) => {
        return { ...item, fullname: item.fullname.toUpperCase() };
      });
      setdata(upper);
      setloading(false);
    } else {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = completeData.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
      const upper = filteredRows.map((item) => {
        return { ...item, fullname: item.fullname.toUpperCase() };
      });
      setdata(upper);
    }
  };

  const getCsvData = () => {
    const csvData = [];
    const headers = columns.map((item) => {
      return item.headerName;
    });
    data.forEach((item) => {
      csvData.push({
        id: item.id,
        fullname: item.fullname,
        department: item.department,
        position: item.position,
        company: item.company,
        status: item.status,
        nationality: item.nationality,
        email: item.email,
        phone: item.phone,
        dateofbirth: item.date_of_birth,
        gender: item.sex,
        nearlyAirport: item.nearly_airport,
      });
    });
    return [headers, csvData];
  };

  const filterByVesselType = (fullData) => {
    console.log("filteredData1", vesselType);
    if (vesselType === "Show All") return fullData;
    if (vesselType.toUpperCase() === "Merchant vessel".toUpperCase()) {
      console.log("filteredData2");
      const filteredData = fullData.filter((item) => {
        return (
          item.category_vessel.toUpperCase() === "Merchant Vessel".toUpperCase()
        );
      });
      console.log("filteredData3", filteredData);
      return filteredData;
    }
    if (vesselType.toUpperCase() === "Passenger Vessel".toUpperCase()) {
      return fullData.filter((item) => {
        return (
          item.category_vessel.toUpperCase() ===
          "Passenger Vessel".toUpperCase()
        );
      });
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Grid item>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}>
            <Title>ALL STAFF DATABASE</Title>
            <Grid container>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{ marginBottom: 2 }}>
                <ExportReportBtn getData={getCsvData} />
                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  onClick={() => {
                    navigate("/addstaff");
                  }}
                  startIcon={<PersonAddAlt1OutlinedIcon />}>
                  SHORT NOTICE
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={filterByVesselType(data)}
              columns={columns}
              sx={{
                boxShadow: 2,
              }}
              loading={loading}
              pagination
              rowsPerPageOptions={[20, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              initialState={{
                pagination: {
                  pageSize: pageSize,
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => {
                    requestSearch(event.target.value);
                  },
                  clearSearch: () => requestSearch(""),
                  vesselType,
                  setVesselType,
                },
                pagination: { classes: null },
              }}
            />
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
};

export default GapPool;
