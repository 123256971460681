import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { numberNextLimiter } from "../../../utils/utils";
import { DOCUMUMENT_LIST } from "../../../constants";

const CocDocuments = ({ documents, COC, person, lightGrey, hideCoC }) => {
  const [vaccines, setVaccines] = useState([]);
  const [docsList, setDocsList] = useState([]);

  useEffect(() => {
    if (person.vaccination_info !== "") {
      setVaccines(JSON.parse(person.vaccination_info));
    } else {
      const emptyVaccines = [
        {
          Doze: "First Doze",
        },
        {
          Doze: "Second Doze",
        },
        {
          Doze: "Booster",
        },
      ];
      setVaccines(emptyVaccines);
    }
  }, [documents]);

  return (
    <>
      <View style={[styles.row, { marginTop: 5, height: 40 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%", height: 40 },
          ]}>
          Type of Document / ID
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%", height: 40 },
          ]}>
          Country of Issue
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%", height: 40 },
          ]}>
          No.
        </Text>
        <View
          style={[
            styles.cell,
            {
              width: "15%",
              height: 40,
              display: "flex",
              flexDirection: "column",
            },
          ]}>
          <Text style={styles.normalTableText}>Date of Issue</Text>
          <Text style={{ fontSize: 6 }}>(YYYY-MM-DD)</Text>
        </View>
        <View
          style={[
            styles.cell,
            {
              width: "15%",
              height: 40,
              display: "flex",
              flexDirection: "column",
            },
          ]}>
          <Text style={styles.normalTableText}>Issued at</Text>
          <Text style={styles.normalTableText}>(Place)</Text>
        </View>
        <View
          style={[
            styles.cell,
            {
              width: "15%",
              height: 40,
              borderRight: 0.5,
              display: "flex",
              flexDirection: "column",
            },
          ]}>
          <Text style={styles.normalTableText}>Valid Until</Text>
          <Text style={{ fontSize: 6 }}>(YYYY-MM-DD)</Text>
        </View>
      </View>
      {/* ### TABLE ROWS ### */}
      {person.type_document === "" && person.COC === "" ? (
        <>
          <View style={styles.row}>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "15%" },
              ]}></Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "20%" },
              ]}></Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "20%" },
              ]}></Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "15%" },
              ]}></Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "15%" },
              ]}></Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "15%", borderRight: 0.5 },
              ]}></Text>
          </View>
        </>
      ) : (
        <>
          {!hideCoC && (
            <>
              {COC.map((item, index) => {
                return (
                  <View style={styles.row} key={index}>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "15%" },
                      ]}>
                      {item["COC DOC."]}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "20%" },
                      ]}>
                      {item["Country of issue"]}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "20%" },
                      ]}>
                      {numberNextLimiter(item["No."], 15)}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "15%" },
                      ]}>
                      {item["Date of issue"]}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "15%" },
                      ]}>
                      {item["Issue at (place)"]}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "15%", borderRight: 0.5 },
                      ]}>
                      {item["Valid until"]}
                    </Text>
                  </View>
                );
              })}
            </>
          )}

          {documents.map((item, index) => {
            return (
              <View style={[styles.row, { flexWrap: "wrap" }]} key={index}>
                <View
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "15%" },
                  ]}>
                  <Text>{item["Type of Document"]?.toUpperCase()}</Text>
                </View>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "20%" },
                  ]}>
                  {item["Country of Issue"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "20%" },
                  ]}>
                  {numberNextLimiter(item["No."], 15)}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "15%" },
                  ]}>
                  {item["Date of Issue"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "15%" },
                  ]}>
                  {item["Issued at (Place)"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "15%", borderRight: 0.5 },
                  ]}>
                  {item["Valid Until"]}
                </Text>
              </View>
            );
          })}
        </>
      )}
      {/* ### COVID VACCINATION ROW ### */}
      <View
        style={[
          styles.row,
          { borderBottom: 0.5, borderRight: 0.5, borderLeft: 0.5 },
        ]}>
        <Text
          style={[
            styles.normalTableText,
            { width: "20%", textAlign: "center" },
          ]}>
          COVID VACCINATION
        </Text>
        <View style={{ width: "45%" }}>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              { backgroundColor: lightGrey },
            ]}></Text>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}>
            {vaccines.length > 0 ? (
              <>
                {vaccines.map((row, index) => {
                  return (
                    <View key={index} style={styles.row}>
                      <Text
                        style={[
                          styles.cell,
                          styles.normalTableText,
                          { textAlign: "center", width: "100%" },
                        ]}>
                        {row.Doze}
                      </Text>
                    </View>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </View>
        </View>
        <View style={{ width: "35%" }}>
          <Text style={[styles.cell, styles.normalTableText]}>
            Vaccination Mark
          </Text>
          {vaccines.length > 0 ? (
            <>
              {vaccines.map((row, index) => {
                return (
                  <View key={index} style={styles.row}>
                    <Text
                      style={[
                        styles.normalTableText,
                        styles.cell,
                        { width: "40%" },
                      ]}>
                      {row["Date of issue"]}
                    </Text>
                    <Text
                      style={[
                        styles.normalTableText,
                        styles.cell,
                        { textAlign: "center", width: "60%" },
                      ]}>
                      {row["Vaccination Mark"]}
                    </Text>
                  </View>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
  topSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  firstTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  icon: {
    width: 70,
    height: 90,
    borderWidth: 0.5,
    borderColor: "black",
  },
  row: {
    borderTop: 0.5,
    borderColor: "black",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderLeft: 0.5,
    borderColor: "black",
    textAlign: "center",
    minHeight: 23,
    overflow: "hidden",
  },
  normalTableText: {
    fontSize: 6,
    paddingTop: 5,
  },
  verticalCell: {
    display: "flex",
  },
});

export default CocDocuments;
