import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import CompaniesSelect from "../Commons/CompaniesSelect";
import Title from "../Commons/Title";
import _, { size, isEmpty } from "lodash";
import {
  loadPositions,
  loadDepartments,
  loadVesselType,
  addStaff,
  uploadDocumentToFirebase,
  updateDataPersonal,
} from "../../Actions/Actions";
import { contrylistdata } from "../Commons/CountryList";
import { useEffect } from "react";
import LoadingProgress from "../Commons/LoadingProgress";
import { useNavigate } from "react-router-dom";
import { async } from "@firebase/util";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import defaultUser from "../../assets/default-avatar.jpg";
import UserProfilePicture from "../Commons/UserProfilePicture";

const AddStaff = () => {
  const [data, setData] = useState({
    fullname: "",
    email: null,
    sex: "",
    date_of_birth: null,
    application_date: null,
    status: "",
    contractcommencesdate: null,
    contractendsdate: null,
  });
  const [deparmentlistbk, setdepartmentlistbk] = useState([]);
  const [vessel, vessellist] = useState([]);
  const [deparmentlist, setdepartmentlist] = useState([]);
  const [positionslist, setpositionlist] = useState([]);
  const [positionslistbk, setpositionlistbk] = useState([]);
  const [loadingprogress, setloadingprogress] = useState(false);
  const [showError, setShowError] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [previewImg, setPreviewImg] = useState("");
  const statusList = [
    "CONTRACT REVOKED",
    "GAP POOL ST/BY",
    "HIRING PROCESS",
    "MEDICAL LEAVE",
    "ON BOARD",
    "VACATION",
    "WORK PERMIT LEAVE",
  ];

  const navigate = useNavigate();

  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };

  const changeVesselType = (e) => {
    setData({ ...data, category_vessel: e.target.value });
    let departments = deparmentlistbk.filter(
      (item) => item.category.toLowerCase() === e.target.value.toLowerCase()
    );
    setdepartmentlist(departments);
  };

  const loadDataPerson = async () => {
    setloadingprogress(true);
    const positions = await loadPositions();
    const departments = await loadDepartments();
    const barquitos = await loadVesselType();
    setpositionlistbk(positions);
    setdepartmentlistbk(departments);
    setpositionlist(positions);
    setdepartmentlist(departments);
    vessellist(barquitos);
    setloadingprogress(false);
  };

  const handleChange = (e) => {
    setShowError(false);
    const { name, value } = e.target;
    setData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleDateBirth = (value) => {
    const yearDiff = moment().diff(value, "years");
    if (yearDiff < 18) {
      showToast("You must be 18 years old or above", 2);
      return;
    }
    setData({ ...data, date_of_birth: new Date(value) });
  };

  const handleCommencesDate = (value) => {
    setData({ ...data, contractcommencesdate: new Date(value) });
  };

  const handleEndsDate = (value) => {
    setData({ ...data, contractendsdate: new Date(value) });
  };

  const handleApplicationDate = (value) => {
    setData({ ...data, application_date: new Date(value) });
  };

  const validateEmail = (email) => {
    // Expresión regular para validar email
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Devuelve true si el email cumple con la expresión regular, false en caso contrario
    return regexEmail.test(email);
  };

  const handleSend = async () => {
    const yearsDiff = moment().diff(data.date_of_birth, "years");
    if (
      data.fullname === "" ||
      data.email === "" ||
      data.sex === "" ||
      data.date_of_birth === "" ||
      data.application_date === "" ||
      data.status === ""
    ) {
      showToast("Please provide the required inputs.", 3);
      setShowError(true);
      return;
    }
    if (!validateEmail(data.email)) {
      showToast("Please enter a valid email address", 3);
      return;
    }
    if (yearsDiff < 18) {
      showToast("You must be 18 years old or above", 3);
      return;
    }
    console.log("success");
    setloadingprogress(true);
    const fileUpload = await uploadDocumentToFirebase(profileImg);
    let userData = { ...data, user_selfie: fileUpload };
    const resp = await addStaff(userData);
    console.log("resp", resp);
    userData["PMSSA ID NO"] = resp.Id.toString();
    userData.Id = resp.Id.toString();
    const respuesta = await updateDataPersonal(userData);
    console.log("resp", respuesta);
    if (resp.status === "OK") {
      showToast("Staff created successfully.", 1);
      navigate(`/updatepersonalinfo?id=${resp.Id}`, { state: { id: resp.Id } });
      console.log(resp);
    } else {
      showToast("Something went wrong.", 3);
    }
    setloadingprogress(false);
  };

  useEffect(() => {
    loadDataPerson();
  }, []);

  const SelectPlaceOfBirth = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="placeofbirthselect"
          id="placeofbirthselect"
          value={data.place_of_birth}
          onChange={(e) => handleChange(e)}
          name="place_of_birth"
          fullWidth
          variant="standard">
          {contrylistdata.map((item, index) => (
            <MenuItem value={item.name.toUpperCase()} key={index}>
              {item.name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const VesselType = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="merchanselect"
          id="merchanselect"
          name="category_vessel"
          value={
            data.category_vessel === ""
              ? "Merchant vessel"
              : data.category_vessel
          }
          label="Category Vessel"
          onChange={changeVesselType}
          fullWidth
          variant="standard">
          {vessel.map((item, index) => (
            <MenuItem value={item.Name} key={index}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const changeDepartment = (e) => {
    setData({ ...data, department: e.target.value });

    let positionsl = positionslistbk.filter(
      (item) => item.department.toLowerCase() === e.target.value.toLowerCase()
    );
    setpositionlist(positionsl);
  };

  const DepartmentSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="departmentselect"
          id="departmentselect"
          name="department"
          value={_.get(data, "department", "").toUpperCase()}
          label="Department"
          onChange={changeDepartment}
          variant="standard">
          {deparmentlist.map((item, index) => (
            <MenuItem value={item.department_name.toUpperCase()} key={index}>
              {item.department_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const PositionSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="positionselect"
          id="positionselect"
          name="position_applying"
          value={_.get(data, "position_applying", "").toUpperCase()}
          label="Position Applying"
          onChange={(e) => handleChange(e)}
          variant="standard">
          {positionslist.map((item, index) => (
            <MenuItem value={item.position_name.toUpperCase()} key={index}>
              {item.position_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const handleFileInput = (e) => {
    console.log(e.target.files[0]);
    setProfileImg(e.target.files[0]);
  };

  useEffect(() => {
    if (profileImg === "" || profileImg === undefined) return;
    const imgUrl = URL.createObjectURL(profileImg);
    setPreviewImg(imgUrl);
    console.log(imgUrl);
  }, [profileImg]);

  return (
    <Box sx={{ display: "flex" }}>
      <LoadingProgress loading={loadingprogress} />
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Grid>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}>
            {/* PROFILE PICTURE */}
            <UserProfilePicture
              profileImg={profileImg}
              setProfileImg={setProfileImg}
              previewImg={previewImg}
              setPreviewImg={setPreviewImg}
              showToast={showToast}
            />
            <Grid>
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  alignItem: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}>
                <Grid item xs={2}>
                  <Typography
                    variant="subtitle2"
                    sx={showError ? { color: "red" } : {}}>
                    Full Name*:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <TextField
                    required
                    id="fullname"
                    label=""
                    name="fullname"
                    autoComplete="fullname"
                    value={data.fullname}
                    onChange={(e) => handleChange(e)}
                    variant="standard"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography
                    variant="subtitle2"
                    sx={showError ? { color: "red" } : {}}>
                    Gender*:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="sex"
                      value={_.get(data, "sex", "M")}
                      onChange={(e) => handleChange(e)}>
                      <FormControlLabel
                        value="F"
                        control={<Radio />}
                        label="F"
                      />
                      <FormControlLabel
                        value="M"
                        control={<Radio />}
                        label="M"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={2} sx={{}}>
                  <Typography
                    variant="subtitle2"
                    sx={showError ? { color: "red" } : {}}>
                    Date of Birth*:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <MobileDatePicker
                    label=""
                    inputFormat="MM/dd/yyyy"
                    value={data.date_of_birth}
                    onChange={handleDateBirth}
                    name="date_of_birth"
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="subtitle2"
                    sx={showError ? { color: "red" } : {}}>
                    Status*:
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <Select
                    labelId="placeofbirthselect"
                    id="placeofbirthselect"
                    value={data.status}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    name="status"
                    fullWidth
                    variant="standard">
                    {statusList.map((item) => (
                      <MenuItem value={item.toUpperCase()} key={item.name}>
                        {item.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2">Nationality: </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <SelectPlaceOfBirth />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography
                    variant="subtitle2"
                    sx={showError ? { color: "red" } : {}}>
                    Application Date*:
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <MobileDatePicker
                    label=""
                    inputFormat="MM/dd/yyyy"
                    value={data.application_date}
                    onChange={handleApplicationDate}
                    name="application_date"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        error={false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2">Category Vessel: </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <VesselType />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2">Company: </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <CompaniesSelect changeCompany={handleChange} data={data} />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2">Department: </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <DepartmentSelect />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography
                    variant="subtitle2"
                    sx={showError ? { color: "red" } : {}}>
                    Email*:
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <TextField
                    required
                    id="email"
                    label=""
                    name="email"
                    autoComplete="email"
                    value={data.email}
                    onChange={(e) => handleChange(e)}
                    variant="standard"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2">Position: </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <PositionSelect />
                </Grid>

                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2">Phone: </Typography>
                </Grid>

                <Grid item xs={4} sx={{ pr: 2 }}>
                  <TextField
                    required
                    id="phone"
                    label=""
                    name="phone"
                    autoComplete="phone"
                    value={data.phone}
                    onChange={(e) => handleChange(e)}
                    variant="standard"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2">Whatsapp: </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <TextField
                    required
                    id="whatsapp"
                    label=""
                    name="whatsapp"
                    autoComplete="whatsapp"
                    value={data.whatsapp}
                    onChange={(e) => handleChange(e)}
                    variant="standard"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2">Nearly Airport: </Typography>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <TextField
                    required
                    id="nearly_airtport"
                    label=""
                    name="nearly_airport"
                    autoComplete="nearly_airport"
                    value={data.nearly_airport}
                    onChange={(e) => handleChange(e)}
                    variant="standard"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Commences Date:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{ pr: 2 }}>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <MobileDatePicker
                      label=""
                      inputFormat="MM/dd/yyyy"
                      value={data.contractcommencesdate}
                      onChange={handleCommencesDate}
                      name="contractcommencesdate"
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: 100 }}
                          {...params}
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Ends Date:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{ pr: 2 }}>
                  <MobileDatePicker
                    label=""
                    inputFormat="MM/dd/yyy"
                    value={data.contractendsdate}
                    onChange={handleEndsDate}
                    name="contractendsdate"
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: 100 }}
                        {...params}
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Company Hiring Code :
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{ pr: 2 }}>
                  <TextField
                    required
                    id="hiringcompanycode"
                    label=""
                    name="hiringcompanycode"
                    autoComplete="hiringcompanycode"
                    value={data.hiringcompanycode}
                    onChange={(e) => handleChange(e)}
                    variant="standard"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              onClick={handleSend}
              sx={{ width: 150, ml: "auto", mt: 2, mb: 1 }}>
              ADD STAFF +
            </Button>
          </Paper>
        </Grid>
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default AddStaff;
