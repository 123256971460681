import {
  app,
  db,
  collection,
  addDoc,
  setDoc,
  doc,
  getDoc,
  query,
  getDocs,
  where,
  updateDoc,
  arrayUnion,
  arrayRemove,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  storage,
  deleteDoc,
} from "../Firebase.js/Firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import _, { result, size } from "lodash";
import moment from "moment";
import { onSnapshot } from "firebase/firestore";
import { ConnectedTvOutlined } from "@mui/icons-material";
import { connectStorageEmulator, uploadBytes } from "firebase/storage";

const auth = getAuth();
auth.languageCode = "en";

const test = false;
export const server = test
  ? "https://demo.myinteresa.com"
  : "https://apiv2.panamamarinelogistic.com.pa";

export const sendEmail = async (body, subject, destination) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  fetch(
    `${server}/sendemail?body=${encodeURIComponent(
      body
    )}&subject=${encodeURIComponent(subject)}&destination=${destination}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export const signOutFn = (navigate) => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      sessionStorage.removeItem("user");
      navigate("/login");
    })
    .catch((error) => {
      // An error happened.
    });
};

export const createUserAndPassword = async (email, password, displayName) => {
  let respuesta = "";

  let requestOptions = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "no-cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit

    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer",
  };

  await fetch(
    `https://us-central1-portal-panama-marine-services.cloudfunctions.net/createUserFn?email=${email}&password=${password}&displayName=${displayName}`,
    requestOptions
  )
    .then((result) => result.text())
    .then((data) => {
      console.log(data);
    })
    .catch((error) => console.log("error", error));

  return respuesta;
};

export const getCurrentUser = () => {
  return auth.currentUser;
};

export const SignIn = async (navigate, email, password) => {
  let response = false;
  await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      sessionStorage.setItem("user", JSON.stringify(user));

      navigate("/");
      response = true;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });

  return response;
};

export const requestpersonal = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/requestpersonal`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const requestpersonalstatus = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/requestpersonalstatus`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const showpersonal = async (id) => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/showpersonal`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

function compare(a, b) {
  if (a.position_applying < b.position_applying) {
    return -1;
  }
  if (a.position_applying > b.position_applying) {
    return 1;
  }
  return 0;
}

function comparestatus(a, b) {
  if (a.status < b.status) {
    return -1;
  }
  if (a.status > b.statusss) {
    return 1;
  }
  return 0;
}

export const loadVesselType = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/loadvesseltype`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const addRecord = async (document, data) => {
  const docRef = await addDoc(collection(db, document), data);
  return docRef;
};

export const getData = async (document) => {
  let result = [];
  const citiesRef = query(collection(db, document));
  const querySnapshot = await getDocs(citiesRef);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let info = doc.data();
    info.id = doc.id;
    result.push(info);
  });
  return result;
};

export const getDatabyId = async (document, id) => {
  let result = [];
  const citiesRef = query(
    collection(db, document),
    where("id", "==", Number(id))
  );
  const querySnapshot = await getDocs(citiesRef);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    result.push(doc.data());
  });
  return result;
};

export const getPositionsByUsers = async (document, id) => {
  let result = [];
  const citiesRef = query(collection(db, document), where("iduser", "==", id));
  const querySnapshot = await getDocs(citiesRef);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    if (doc.data()) {
      let registro = doc.data();
      registro.id = doc.id;
      result.push(registro);
    }
  });
  return result;
};

export const getFirebaseAttachments = async (document, id) => {
  let result = [];
  const citiesRef = query(
    collection(db, document),
    where("idpersonal", "==", id)
  );
  try {
    const querySnapshot = await getDocs(citiesRef);
    querySnapshot.forEach((doc) => {
      let registro = doc.data();
      registro.id = doc.id;
      result.push(registro);
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getFirebaseAttachmentsExternal = async (document, id) => {
  let result = [];
  const citiesRef = query(
    collection(db, document),
    where("idpersonal", "==", id),
    where("external", "==", true)
  );
  try {
    const querySnapshot = await getDocs(citiesRef);
    querySnapshot.forEach((doc) => {
      let registro = doc.data();
      registro.id = doc.id;
      result.push(registro);
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getGapPool = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/getgappool`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        item.id = item.Id;
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const getLanguages = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/getLenguages`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        item.id = item.Id;
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const getCompanies = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/getcompanies`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        item.id = item.Id;
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const loginInDataBase = async (email, password) => {
  let result = [];
  const citiesRef = query(
    collection(db, "Users"),
    where("email", "==", email.toLowerCase()),
    //where("password", "==", password),
    where("status", "==", true)
  );
  const querySnapshot = await getDocs(citiesRef);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots

    result.push(doc.data());
  });
  return result;
};

export const updatePasswordFb = async (password) => {
  const auth = getAuth();

  const user = auth.currentUser;
  const newPassword = password;

  await updatePassword(user, newPassword)
    .then(() => {})
    .catch((error) => {});
};

export const getUserByEmail = async (email) => {
  let result = [];
  const citiesRef = query(
    collection(db, "Users"),
    where("email", "==", email.toLowerCase())
  );
  const querySnapshot = await getDocs(citiesRef);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let registro = doc.data();
    registro.id = doc.id;
    result.push(registro);
  });
  return result;
};

export const userExists = async (email) => {
  let result = [];
  const userRef = query(
    collection(db, "Users"),
    where("email", "==", email.toLowerCase())
  );
  const querySnapshot = await getDocs(userRef);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots

    if (doc) {
      let record = doc.data();
      record._id = doc.id;
      result.push(record);
    }
  });
  return result;
};

export const updatePasswordFirebase = async (id, newPassword, email) => {
  const resp = await setDoc(
    doc(db, "Users", id),
    {
      password: newPassword,
    },
    { merge: true }
  );

  await sendPasswordResetEmail(auth, email)
    .then((resp) => {})
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });

  //await updatePasswordFb(newPassword);
  return resp;
};

export const updateRecord = async (document, id, data) => {
  const resp = await setDoc(doc(db, document, id), data, { merge: true });
  return resp;
};

export const getRecordById = async (document, id) => {
  let response = {};
  const docRef = doc(db, document, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    response = docSnap.data();
    response.id = docSnap.id;
  } else {
  }

  return response;
};

export const updateArray = async (document, id, data) => {
  let response = {};
  const docRef = doc(db, document, id);
  await updateDoc(docRef, {
    staff: arrayUnion(...data),
  });
};

export const updateSelectedArray = async (document, id, data) => {
  let response = {};
  const docRef = doc(db, document, id);
  await updateDoc(docRef, {
    request_details: arrayUnion(...data),
  });
};

export const removeSelectedArray = async (document, id, data) => {
  let response = {};
  const docRef = doc(db, document, id);
  await updateDoc(docRef, {
    request_details: arrayRemove("0"),
  }).catch((error) => console.log(error));

  console.log(docRef);
};

export const createNewHiring = async (id) => {
  let myHeaders = new Headers();

  let raw = JSON.stringify({
    "CHOOSE PMSSA GAP POOL STAFF": Number(id),
  });

  let requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: raw,
    redirect: "follow",

    cache: "no-cache",
    credentials: "same-origin",
    referrerPolicy: "no-referrer",
  };

  await fetch(
    `https://api.panamamarinelogistic.com.pa/createnewhiring?id=${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export const getPersonById = async (id) => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/getpersonalbyid?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        item.id = item.Id;
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const loadCompanies = async () => {
  return getCompanies();
};

export const loadDepartments = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/loaddepartments`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const loadPositions = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/loadpositions`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const updateDataPersonal = async (data) => {
  let info = {};
  let raw = JSON.stringify(data);
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
  };

  await fetch(
    "https://api.panamamarinelogistic.com.pa/updatepersonalinfo",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => (info = result))
    .catch((error) => (info.error = error));

  return info;
};

export const updateEmbarcaciones = async (data) => {
  let info = {};
  let raw = JSON.stringify(data);
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: raw,
    redirect: "follow",
  };

  await fetch(
    "https://api.panamamarinelogistic.com.pa/updateembarcaciones",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => (info = result))
    .catch((error) => (info.error = error));

  return info;
};

export const vesselTypeDetail = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/vesseltypedetail`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const typeofdocument = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/typeofdocument`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      result.forEach((item) => {
        lists.push(item);
      });
    })
    .catch((error) => console.log(error));

  return lists;
};

export const loadPositionsRequest = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/loadpositionsrequest`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const loadCertificates = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/loadcertificates`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

//https://api.panamamarinelogistic.com.pa/getembarcaciones

export const getEmbarkations = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/getembarcaciones`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const getAttachmentByProcess = async (processid) => {
  let lists = [];
  let type = "APPLICATION PROCESS";

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(
    `${server}/getattachmentbyprocess?processid=${processid}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result) {
        let downloadkey = _.get(result, "downloadKey", "");
        let fields = _.get(result, "fields", []);
        fields.forEach((item) => {
          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-06 List of positions on board",
            downloadkey,
            item["Attach F-PMSSA-06 List of positions on board"]
          );

          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-07 Interview evaluation for new applicant",
            downloadkey,
            item["Attach F-PMSSA-07 Interview evaluation for new applicant"]
          );

          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-08 Estimated cost of boarding",
            downloadkey,
            item["Attach F-PMSSA-08 Estimated cost of boarding"]
          );
          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-09 Verification of employment and personal references",
            downloadkey,
            item[
              "Attach F-PMSSA-09 Verification of employment and personal references"
            ]
          );
          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-10 Verification and validation of crew documents",
            downloadkey,
            item[
              "Attach F-PMSSA-10 Verification and validation of crew documents"
            ]
          );
          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-11 Interview Questionnaire – Job Description",
            downloadkey,
            item["Attach F-PMSSA-11 Interview Questionnaire – Job Description"]
          );

          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-12 Record of life videos on board and training seen by the crew",
            downloadkey,
            item[
              "Attach F-PMSSA-12 Record of life videos on board and training seen by the crew"
            ]
          );

          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-13 Description of the work to be done on board",
            downloadkey,
            item[
              "Attach F-PMSSA-13 Description of the work to be done on board"
            ]
          );

          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach  F-PMSSA-14 Crew Member's Statement of Understanding",
            downloadkey,
            item["Attach  F-PMSSA-14 Crew Member's Statement of Understanding"]
          );
          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach  F-PMSSA-15 Authorized Vendor Advisory",
            downloadkey,
            item["Attach  F-PMSSA-15 Authorized Vendor Advisory"]
          );
          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach COC",
            downloadkey,
            item["Attach COC"]
          );
          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach covid vaccination and others documents",
            downloadkey,
            item["Attach covid vaccination and others documents"]
          );

          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach training certification",
            downloadkey,
            item["Attach training certification"]
          );
          getAttachmentArray(
            processid,
            processid,
            lists,
            "Attach F-PMSSA-14 Crew Members Statement of Understanding",
            downloadkey,
            item["Attach F-PMSSA-14 Crew Members Statement of Understanding"]
          );

          /*************HIRING PROCESS******************/

          if (processid.toLowerCase().includes("hiring")) {
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach  F-PMSSA-16 New crew approval confirmation model",
              downloadkey,
              item["Attach  F-PMSSA-16 New crew approval confirmation model"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach  F-PMSSA-17 Model Employment Letter",
              downloadkey,
              item["Attach  F-PMSSA-17 Model Employment Letter"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach  F-PMSSA-18 Supporting Note for U.S. Embassy",
              downloadkey,
              item["Attach  F-PMSSA-18 Supporting Note for U.S. Embassy"]
            );

            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach  F-PMSSA-19 Employment contract with the Shipping Company-Shipowner",
              downloadkey,
              item[
                "Attach  F-PMSSA-19 Employment contract with the Shipping Company-Shipowner"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach  F-PMSSA-20 Designation of the life insurance beneficiary",
              downloadkey,
              item[
                "Attach  F-PMSSA-20 Designation of the life insurance beneficiary"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach  F-PMSSA-21 Proof of Repatriation – City of Origin",
              downloadkey,
              item["Attach  F-PMSSA-21 Proof of Repatriation – City of Origin"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F- PMSSA-22 No Show Policy",
              downloadkey,
              item["Attach F- PMSSA-22 No Show Policy"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-23 Drug and Alcohol Policy",
              downloadkey,
              item["Attach F-PMSSA-23 Drug and Alcohol Policy"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-24 Frequently Asked Questions about MLC 2006",
              downloadkey,
              item[
                "Attach F-PMSSA-24 Frequently Asked Questions about MLC 2006"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-25 Complaint form and procedure on board",
              downloadkey,
              item["Attach F-PMSSA-25 Complaint form and procedure on board"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-26 PEME Form",
              downloadkey,
              item["Attach F-PMSSA-26 PEME Form"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-27 Checklist of hired crew member",
              downloadkey,
              item["Attach F-PMSSA-27 Checklist of hired crew member"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-29 Verification of crew members' documents",
              downloadkey,
              item["Attach F-PMSSA-29 Verification of crew members' documents"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-28 Documents and Articles – Leaving Home",
              downloadkey,
              item["Attach F-PMSSA-28 Documents and Articles – Leaving Home"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-30 Joining his ship. The first day",
              downloadkey,
              item["Attach F-PMSSA-30 Joining his ship. The first day"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-31 Information on daily attendance inputs and output",
              downloadkey,
              item[
                "Attach F-PMSSA-31 Information on daily attendance inputs and output"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-32 Supervised training",
              downloadkey,
              item["Attach F-PMSSA-32 Supervised training"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-33 Evaluation of probationary period (90 days)",
              downloadkey,
              item[
                "Attach F-PMSSA-33 Evaluation of probationary period (90 days)"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-34 Performance evaluation deck / machinery department",
              downloadkey,
              item[
                "Attach F-PMSSA-34 Performance evaluation deck / machinery department"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-35 Performance evaluation of the crew-hotel",
              downloadkey,
              item["Attach F-PMSSA-35 Performance evaluation of the crew-hotel"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-36 Disembarkation meeting - Information for crew members to disembark",
              downloadkey,
              item[
                "Attach F-PMSSA-36 Disembarkation meeting - Information for crew members to disembark"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-37 Departure or Disembarkation procedure",
              downloadkey,
              item["Attach F-PMSSA-37 Departure or Disembarkation procedure"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-38 Declaration of termination of contract",
              downloadkey,
              item["Attach F-PMSSA-38 Declaration of termination of contract"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-40 Selection Verification and Commitment Form",
              downloadkey,
              item[
                "Attach F-PMSSA-40 Selection Verification and Commitment Form"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach F-PMSSA-42 Monthly boarding report",
              downloadkey,
              item["Attach F-PMSSA-42 Monthly boarding report"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Contract Employment",
              downloadkey,
              item["Attach Contract Employment"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach A201-ANNEX II DRUGS Statement",
              downloadkey,
              item["Attach A201-ANNEX II DRUGS Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach IDF Statement",
              downloadkey,
              item["Attach IDF Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Joining Statement",
              downloadkey,
              item["Attach Joining Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX 2.2a – SARS – COV2",
              downloadkey,
              item["Attach ANNEX 2.2a – SARS – COV2"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Written Consent",
              downloadkey,
              item["Attach Written Consent"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Rolling Updates of Safety Issues",
              downloadkey,
              item["Attach Rolling Updates of Safety Issues"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach 13-Cyrus + Malta + Portugal on Board complain Handling",
              downloadkey,
              item[
                "Attach 13-Cyrus + Malta + Portugal on Board complain Handling"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach A02 – 26 Private Medicines Declaration",
              downloadkey,
              item["Attach A02 – 26 Private Medicines Declaration"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX II – 4 Data Privacy Statement",
              downloadkey,
              item["Attach ANNEX II – 4 Data Privacy Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX IV On board complain Form",
              downloadkey,
              item["Attach ANNEX IV On board complain Form"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX V Contact Details on board complain – Handling",
              downloadkey,
              item[
                "Attach ANNEX V Contact Details on board complain – Handling"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX VI – 1 CYRUS National Repatriation",
              downloadkey,
              item["Attach ANNEX VI – 1 CYRUS National Repatriation"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX VI – 1 MALTA National Repatriation",
              downloadkey,
              item["Attach ANNEX VI – 1 MALTA National Repatriation"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach C I. 14 -Circular Letter No. – 14 while COVID – 19",
              downloadkey,
              item["Attach C I. 14 -Circular Letter No. – 14 while COVID – 19"]
            );

            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Corona Virus Guidelines WHO – Poster",
              downloadkey,
              item["Attach Corona Virus Guidelines WHO – Poster"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Currently ITS. Reederei Nord IDF CDA 2022 – 2023",
              downloadkey,
              item["Attach Currently ITS. Reederei Nord IDF CDA 2022 – 2023"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ATP I_ Itinerary",
              downloadkey,
              item["Attach ATP I_ Itinerary"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach LOG",
              downloadkey,
              item["Attach LOG"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach OKTB",
              downloadkey,
              item["Attach OKTB"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Negative RT-PCR",
              downloadkey,
              item["Attach Negative RT-PCR"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH PRE EMPLOYMENT PHYSICAL EXAM – PEME",
              downloadkey,
              item["ATTACH PRE EMPLOYMENT PHYSICAL EXAM – PEME"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Joining documents",
              downloadkey,
              item["Attach Joining documents"]
            );
          }
          if (processid.toLowerCase().includes("emb")) {
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH PRE EMPLOYMENT PHYSICAL EXAM – PEME",
              downloadkey,
              item["ATTACH PRE EMPLOYMENT PHYSICAL EXAM – PEME"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Joining documents",
              downloadkey,
              item["Attach Joining documents"]
            );

            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH DEVELOPMENT ACTIVITIES -FOLLOW IN UP",
              downloadkey,
              item["ATTACH DEVELOPMENT ACTIVITIES -FOLLOW IN UP"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH EVALUATIONS ON BOARD",
              downloadkey,
              item["ATTACH EVALUATIONS ON BOARD"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH SEA SERVICE CERTIFICATE",
              downloadkey,
              item["ATTACH SEA SERVICE CERTIFICATE"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH PROVIDED MEDICAL REPORT OR UNFIT FOR WORK",
              downloadkey,
              item["ATTACH PROVIDED MEDICAL REPORT OR UNFIT FOR WORK"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH MEDICAL FOLLOW UP REPORTS / DATES",
              downloadkey,
              item["ATTACH MEDICAL FOLLOW UP REPORTS / DATES"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH FINAL MEDICAL REPORT FIT FOR DUTY CERTIFICATE/DATE",
              downloadkey,
              item["ATTACH FINAL MEDICAL REPORT FIT FOR DUTY CERTIFICATE/DATE"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH SUBMITTED DE-BRIEFING",
              downloadkey,
              item["ATTACH SUBMITTED DE-BRIEFING"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "ATTACH UPDATED DOA'S BY SEAFARER",
              downloadkey,
              item["ATTACH UPDATED DOA'S BY SEAFARER"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Contract Employment",
              downloadkey,
              item["Attach Contract Employment"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach A201-ANNEX II DRUGS Statement",
              downloadkey,
              item["Attach A201-ANNEX II DRUGS Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach IDF Statement",
              downloadkey,
              item["Attach IDF Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Joining Statement",
              downloadkey,
              item["Attach Joining Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX 2.2a – SARS – COV2",
              downloadkey,
              item["Attach ANNEX 2.2a – SARS – COV2"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Written Consent",
              downloadkey,
              item["Attach Written Consent"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Rolling Updates of Safety Issues",
              downloadkey,
              item["Attach Rolling Updates of Safety Issues"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach 13-Cyrus + Malta + Portugal on Board complain Handling",
              downloadkey,
              item[
                "Attach 13-Cyrus + Malta + Portugal on Board complain Handling"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach A02 – 26 Private Medicines Declaration",
              downloadkey,
              item["Attach A02 – 26 Private Medicines Declaration"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX II – 4 Data Privacy Statement",
              downloadkey,
              item["Attach ANNEX II – 4 Data Privacy Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX II – 4 Data Privacy Statement",
              downloadkey,
              item["Attach ANNEX II – 4 Data Privacy Statement"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX IV On board complain Form",
              downloadkey,
              item["Attach ANNEX IV On board complain Form"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX V Contact Details on board complain – Handling",
              downloadkey,
              item[
                "Attach ANNEX V Contact Details on board complain – Handling"
              ]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX VI – 1 CYRUS National Repatriation",
              downloadkey,
              item["Attach ANNEX VI – 1 CYRUS National Repatriation"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ANNEX VI – 1 MALTA National Repatriation",
              downloadkey,
              item["Attach ANNEX VI – 1 MALTA National Repatriation"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach C I. 14 -Circular Letter No. – 14 while COVID – 19",
              downloadkey,
              item["Attach C I. 14 -Circular Letter No. – 14 while COVID – 19"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Corona Virus Guidelines WHO – Poster",
              downloadkey,
              item["Attach Corona Virus Guidelines WHO – Poster"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Currently ITS. Reederei Nord IDF CDA 2022 – 2023",
              downloadkey,
              item["Attach Currently ITS. Reederei Nord IDF CDA 2022 – 2023"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach ATP I_ Itinerary",
              downloadkey,
              item["Attach ATP I_ Itinerary"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach LOG",
              downloadkey,
              item["Attach LOG"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach OKTB",
              downloadkey,
              item["Attach OKTB"]
            );
            getAttachmentArray(
              processid,
              processid,
              lists,
              "Attach Negative RT-PCR",
              downloadkey,
              item["Attach Negative RT-PCR"]
            );
          }
        });
      }
    })
    .catch((error) => console.log("error", error));

  return lists;
};

const getAttachmentArray = async (
  reference,
  processid,
  array,
  name,
  downloadkey,
  variable
) => {
  if (variable) {
    array.push({
      id: variable.attachmentId,
      name: variable.name,
      description: name,
      type: processid,
      date: moment(variable.creationTime).format("lll"),
      downloadkey: downloadkey,
      reference: reference,
      source: "FLOKZU",
      url: "", // solo aplica para firebase
    });
  }
  return array;
};

export const uploadDocumentToFirebase = async (file) => {
  let urlcomplete = "";
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `/files/${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => reject(err),
      async () => {
        // download url
        await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          urlcomplete = url;
          resolve(url);
        });
      }
    );
  });
};

export const getEmbarcationsById = async (id) => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/getembarcacionesbyid?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        item.id = item.Id;
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

export const deleteRecord = async (document, id) => {
  try {
    await deleteDoc(doc(db, document, id));
  } catch (error) {
    console.log("Error al eliminar el documento:", error);
  }
};

export const deleteRecordByID = async (document, id) => {
  const citiesRef = query(collection(db, document), where("iduser", "==", id));
  const snapShot = await getDocs(citiesRef);
  const results = snapShot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  results.forEach(async (result) => {
    const docRef = doc(db, document, result.id);
    await deleteDoc(docRef);
  });
};

export const loadUpdateById = async (id) => {
  let result = [];
  const citiesRef = query(
    collection(db, "UpdateRequests"),
    where("pmssaid", "==", id.toString()),
    where("status", "==", "pending")
  );
  const querySnapshot = await getDocs(citiesRef);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let record = doc.data();
    record.idfirebase = doc.id;
    result.push(record);
  });
  return result;
};

export const infoUpdateRequest = async (id, data, document) => {
  const resp = await setDoc(doc(db, document, id), data, { merge: true });
};

export const addStaff = async (data) => {
  let requestOptions = {
    method: "PUT",
    body: JSON.stringify(data), // data can be `string` or {object}!
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(`${server}/addstaff`, requestOptions)
    .then((response) => response.json())
    .then((data) => (result = data.resp))
    .catch((error) => console.log("error", error));

  return result;
};

export const getSkills = async (id) => {
  let result = {};
  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(`${server}/getskills?idproceso=${id}`, requestOptions)
    .then((response) => response.json())
    .then((data) => (result = data.resp))
    .catch((error) => console.log("error", error));

  return result;
};

export const getDissaproved = async () => {
  let result = null;
  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(`${server}/getdissaproved`, requestOptions)
    .then((response) => response.json())
    .then((data) => (result = data))
    .catch((error) => console.log("error", error));

  return result;
};

export const getComplaints = async () => {
  let result = null;
  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(`${server}/getcomplaints`, requestOptions)
    .then((response) => response.json())
    .then((data) => (result = data))
    .catch((error) => console.log("error", error));

  return result;
};

export const getMitradel = async () => {
  let result = null;
  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(`${server}/getmitradel`, requestOptions)
    .then((response) => response.json())
    .then((data) => (result = data))
    .catch((error) => console.log("error", error));

  return result;
};

export const getHiringsById = async (IDPMSSA) => {
  let result = [];
  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(`${server}/gethiringsbyid?IDPMSSA=${IDPMSSA}`, requestOptions)
    .then((response) => response.json())
    .then((data) => (result = data))
    .catch((error) => console.log("error", error));

  return result;
};

export const getCountryCodes = async () => {
  let result = [];
  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await fetch(`${server}/getcountrycodes`, requestOptions)
    .then((response) => response.json())
    .then((data) => (result = data))
    .catch((error) => console.log("error", error));

  return result;
};

export const handleDownloadExcel = (filterDate, mitradelData) => {
  let localData = [...mitradelData];
  if (!filterDate.m) {
    const newData = localData.filter((item) => {
      return moment().isSame(moment(item.date), "month");
    });
  }
  var check = moment();
  const m = filterDate.m ? Number(filterDate.m) : Number(check.format("M"));
  const y = filterDate.y ? Number(filterDate.y) : Number(check.format("YYYY"));
  if (!filterDate.m) {
    const newData = localData.filter((item) => {
      return moment().isSame(moment(item.date), "month");
    });
    let raw = JSON.stringify({
      date: { m: m, y: y },
      data: newData,
    });
    let url = `${server}/getmitradelreport?data=${raw}`;
    window.open(url, "_blank");
    return;
  }
  let raw = JSON.stringify({
    date: { m: m, y: y },
    data: mitradelData,
  });
  let url = `${server}/getmitradelreport?data=${raw}`;
  window.open(url, "_blank");
};

export const getCocList = async () => {
  let lists = [];

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  await fetch(`${server}/getcocs`, requestOptions)
    .then((response) => response.json())
    .then((result) =>
      result.map((item) => {
        lists.push(item);
      })
    )
    .catch((error) => console.log(error));

  return lists;
};

/* export const fixingAb = async () => {
  let abOsList = [];
  let response = await getData("Skills");
  response.forEach(async (item) => {
    if (
      (item.position === "AB" || item.position === "OS") &&
      item.skills.length > 0
    ) {
      abOsList.push(item);
    }
  });
  console.log("list", abOsList);
   response.forEach(async (item) => {
    if (item.position === "WIPER") {
      let newItem = { ...item };
      console.log(newItem);
       newItem.skills[2] = {
        skill:
          "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
        questions: question,
      };
       updateRecord("Skills", item.id, newItem); 
    }
  }); 
}; */
