import { Box, Container, Paper, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getMitradel,
  getGapPool,
  getExcelReport,
  handleDownloadExcel,
} from "../../Actions/Actions";
import LoadingProgress from "../Commons/LoadingProgress";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import { ConnectedTvOutlined } from "@mui/icons-material";

export default function Mitradel() {
  const [mitradelData, setMitradelData] = useState([]);
  const [puredata, setpuredata] = useState([]);
  const [filterDate, setFilterDate] = useState({ m: "", y: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [monthfilter, setmonthfilter] = useState(
    moment().startOf("month").format("YYYY-MM-DD hh:mm")
  );

  const getAllData = async () => {
    setIsLoading(true);
    const response = await getMitradel();
    const alldata = await getGapPool();
    console.log("response", response);
    if (!response.length) return;
    response.forEach((item, index) => {
      let dato = alldata.find((itemd) => itemd.id === item.idpmssa);
      console.log("DATO ES");
      console.log(dato);
      if (dato) {
        let documents = dato.type_document
          ? JSON.parse(dato.type_document)
          : [];
        console.log("documents", documents);
        documents.forEach((item) => {
          console.log(item["Type of Document"].toUpperCase());
        });
        let passport = documents.filter((item) =>
          item["Type of Document"].toUpperCase().includes("PASSPORT")
        );
        console.log("PASAPORTE", passport);
        item.passport = passport[0] ? passport[0]["No."] : "";
        item.ispanamenian = dato.ispanamenian;
        item.sex = dato.sex;
        item.age = getEdad(dato.date_of_birth);
        item.educationlevel = dato.higherleveleducation;
        item.nationality = dato.place_of_birth;
        item.idreport = passport.length > 0 ? passport[0]["No."] : "";
        item.ispanamenian = dato.ispanamenian;
        item.beneficiary = dato.beneficiary;
        item.contractsigned = dato.fullname;
        item.rowid = index;
      }
    });

    //VALIDAR SI ES PANAMEÑO Y SI ESTÁ DENTRO DEL MES ACUAL.
    let y = new Date().getUTCFullYear();
    let m = new Date().getMonth();

    let filterPanamanians = response.filter((item) => {
      return item.ispanamenian === "true";
    });
    let filterByMonth = filterPanamanians.filter((item, index) => {
      return (
        moment().isSame(moment(item.date), "months") &&
        moment().isSame(moment(item.date), "years")
      );
    });

    filterByMonth.forEach((item, index) => {
      filterByMonth[index].id = index;
    });
    filterPanamanians.forEach((item, index) => {
      filterPanamanians[index].id = index;
    });

    setFilterDate({
      m: moment().month(m).format("M"),
      y: y,
    });
    setMitradelData(filterByMonth);
    console.log("puredata", filterByMonth);
    setpuredata(filterPanamanians);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  const onChangeMonths = (datechange) => {
    let m = new Date(datechange).getMonth();
    let y = new Date(datechange).getUTCFullYear();
    const fecha1 = moment(datechange, "YYYY/MM/DD");

    const localData = puredata.filter((item, i) => {
      const fecha2 = moment(item.date, "YYYY/MM/DD");
      return fecha1.isSame(fecha2, "month");
    });

    console.log("EL FILTRO ES");
    console.log(localData);
    setFilterDate({
      m: moment()
        .month(m + 1)
        .format("M"),
      y: y,
    });
    setmonthfilter(datechange);
    setMitradelData(localData);
  };

  const getFirstDay = (datevalue) => {
    let y = new Date(datevalue).getFullYear();
    let m = new Date(datevalue).getMonth();
    return new Date(y, Number(m) + 1, 1);
  };

  const getLastDay = (datevalue) => {
    let y = new Date(datevalue).getFullYear();
    let m = new Date(datevalue).getMonth();
    return new Date(y, Number(m) + 2, 0);
  };

  const ExportCSV = ({ csvData, fileName }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let copydata = [];

    csvData.map((item) => {
      delete item.ispanamenian;
      delete item.Id;
      item.id = item.idreport;
      copydata.push(item);
    });

    const exportToCSV = () => {
      const ws = XLSX.utils.json_to_sheet(copydata);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          if (mitradelData && mitradelData.length === 0) {
            toast.error(
              "Not enough data to make a report, please select another month/year."
            );
            return;
          }
          console.log("Dato de reporte");
          console.log(filterDate);
          console.log(mitradelData);
          handleDownloadExcel(filterDate, mitradelData);
          toast.success("Report generated successfully");
        }}>
        Export to Excel
      </Button>
    );
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      sortable: false,
      width: 150,
    },
    {
      field: "name",
      headerName: "Full name",
      width: 250,
      editable: false,
    },
    {
      field: "passport",
      headerName: "Passport",
      sortable: false,
      width: 200,
    },
    {
      field: "sex",
      headerName: "Sex",
      sortable: false,
      width: 50,
    },
    {
      field: "age",
      headerName: "Age",
      sortable: false,
      width: 50,
    },
    {
      field: "educationlevel",
      headerName: "Educational level",
      sortable: false,
      width: 150,
    },
    {
      field: "nationality",
      headerName: "Nationality",
      sortable: false,
      width: 150,
    },
    {
      field: "shippingcompany",
      headerName: "Name of the shipping company",
      sortable: false,
      width: 300,
    },

    {
      field: "vesselname",
      headerName: "Vessel Name",
      sortable: false,
      width: 200,
    },

    {
      field: "economicactivity",
      headerName: "Economic Activity",
      sortable: false,
      width: 180,
    },
    {
      field: "ocupation",
      headerName: "Occupation (describe)",
      sortable: false,
      width: 200,
    },
    {
      field: "contractsigned",
      headerName: "Contract Signed MLC 2006 BY:",
      sortable: false,
      width: 300,
    },
    {
      field: "agreesalary",
      headerName: "Agreed salary",
      sortable: false,
      width: 150,
    },
    {
      field: "placement",
      headerName: "Type of placement (indefinite or temporary)",
      sortable: false,
      width: 300,
    },
    {
      field: "beneficiary",
      headerName: "F-PMSSA-20 LIFE INSURANCE BENEFICIARY",
      sortable: false,
      width: 300,
    },
    {
      field: "comment",
      headerName: "Comments",
      sortable: false,
      width: 150,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ExportCSV csvData={mitradelData} fileName={"Mitradel"} />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}>
          {/* NEW CHART HERE */}
          {mitradelData && (
            <Box sx={{ p: 3 }}>
              <Typography variant="h5" color="primary">
                Mitradel report
              </Typography>
              <div style={{ marginBottom: 20 }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="month"
                  onChange={(e) => {
                    onChangeMonths(e.target.value + "-01");
                  }}
                />
              </div>
              <Box sx={{ width: "100%", height: "70vh" }}>
                <DataGrid
                  rows={mitradelData}
                  columns={columns}
                  pageSize={50}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  getRowId={(row) => row.rowid}
                />
              </Box>
            </Box>
          )}
        </Paper>
        <LoadingProgress loading={isLoading} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="light"
        />
      </Container>
    </>
  );
}
