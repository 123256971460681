import { Text, View } from "@react-pdf/renderer";
import { numberNextLimiter } from "../../../utils/utils";

const Certificates = ({
  styles,
  person,
  certificates,
  otherTraining,
  lightGrey,
}) => {
  const certificatesColums = [
    {
      name: "Description of Cert / Course",
      width: "20%",
    },
    {
      name: "Country of Issue",
      width: "20%",
    },
    {
      name: "Number",
      width: "20%",
    },
    {
      name: "Date of Issue(YYYY-MM-DD)",
      width: "20%",
    },
    {
      name: "Date of Expiry(YYYY-MM-DD)",
      width: "20%",
    },
  ];
  return (
    <>
      <View style={[styles.row, { backgroundColor: lightGrey }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "100%", borderRight: 0.5, height: 30 },
          ]}>
          STCW CERTIFICATES
        </Text>
      </View>
      <View style={[styles.row, { borderRight: 0.5 }]}>
        {certificatesColums.map((item, index) => {
          return (
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                {
                  width: item.width,
                  height: 40,
                  backgroundColor: lightGrey,
                },
              ]}
              key={index}>
              {item.name}
            </Text>
          );
        })}
      </View>
      <View>
        {person.lic_sbook_stcw_certificates === "" ? (
          <>
            <View style={[styles.row, { borderRight: 0.5 }]}>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: 6 },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: 6 },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: 6 },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: 6 },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: 6 },
                ]}></Text>
            </View>
          </>
        ) : (
          <>
            {certificates.map((item, index) => {
              return (
                <View style={[styles.row, { borderRight: 0.5 }]} key={index}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      {
                        minheight: 25,
                        width: "20%",
                        fontSize: 6,
                        textAlign: "left",
                        height: "auto",
                      },
                    ]}>
                    {`${item[
                      `Description of cert / course`
                    ]?.toUpperCase()}\n\n\n\n`}
                  </Text>

                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { minheight: 25, width: "20%", fontSize: 6 },
                    ]}>
                    {item["Country of issue"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { minheight: 25, width: "20%", fontSize: 6 },
                    ]}>
                    {numberNextLimiter(item["Number"], 15)}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { minheight: 25, width: "20%", fontSize: 6 },
                    ]}>
                    {item["Date of issue"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { minheight: 25, width: "20%", fontSize: 6 },
                    ]}>
                    {item["Date of expiry"]}
                  </Text>
                </View>
              );
            })}
          </>
        )}
      </View>
      <View style={[styles.row, { borderRight: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "100%",
              height: 30,
              backgroundColor: lightGrey,
            },
          ]}>
          OTHER TRAINING OR CERTIFICATE
        </Text>
      </View>
      <View style={[styles.row, { borderRight: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "40%",
              height: 30,
              backgroundColor: lightGrey,
            },
          ]}>
          Name of education institution / tech. institute
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "20%",
              height: 30,
              backgroundColor: lightGrey,
            },
          ]}>
          Obtained title or grade
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "20%",
              height: 30,
              backgroundColor: lightGrey,
            },
          ]}>
          Date on (YYYY-MM-DD)
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "20%",
              height: 30,
              backgroundColor: lightGrey,
            },
          ]}>
          Date off (YYYY-MM-DD)
        </Text>
      </View>
      {person.other_training === "" ? (
        <View style={[styles.row, { borderBottom: 0.5, borderRight: 0.5 }]}>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              {
                width: "40%",
                height: 30,
              },
            ]}></Text>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              {
                width: "20%",
                height: 30,
              },
            ]}></Text>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              {
                width: "20%",
                height: 30,
              },
            ]}></Text>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              {
                width: "20%",
                height: 30,
              },
            ]}></Text>
        </View>
      ) : (
        <View style={{ borderBottom: 0.5 }}>
          {otherTraining.map((item, index) => {
            return (
              <View key={index} style={[styles.row, { borderRight: 0.5 }]}>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    {
                      width: "40%",
                      height: 30,
                    },
                  ]}>
                  {item["Name of education institution / tech. institute"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    {
                      width: "20%",
                      height: 30,
                    },
                  ]}>
                  {item["Obtained title or grade"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    {
                      width: "20%",
                      height: 30,
                    },
                  ]}>
                  {item["Date on"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    {
                      width: "20%",
                      height: 30,
                    },
                  ]}>
                  {item["Date off"]}
                </Text>
              </View>
            );
          })}
        </View>
      )}
    </>
  );
};

export default Certificates;
