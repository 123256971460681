import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { TEMPLATE } from "../../emails/Emails";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {
  getPersonById,
  loadVesselType,
  loadDepartments,
  loadPositions,
  updateDataPersonal,
  vesselTypeDetail,
  typeofdocument,
  loadCertificates,
  getAttachmentByProcess,
  uploadDocumentToFirebase,
  addRecord,
  getFirebaseAttachments,
  getEmbarcationsById,
  sendEmail,
  loadUpdateById,
  updateEmbarcaciones,
  updateRecord,
  getRecordById,
  getHiringsById,
  getCountryCodes,
  getUserSelfie,
  getCocList,
  server,
  deleteRecord,
  deleteRecordByID,
} from "../../Actions/Actions";
import { stringify, v4 as uuidv4 } from "uuid";
import _, { size, isEmpty, lastIndexOf } from "lodash";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { contrylistdata } from "../Commons/CountryList";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExperienceOnBoard from "./ExperienceOnBoard";
import ExperienceOnShore from "./ExperienceOnShore";
import DocumentsTab from "./DocumentsTab";
import LicBookSTCW from "./LicBookSTCW";
import OtherTrainings from "./OtherTrainings";
import COCTable from "./CocTable";
import AddCOC from "../DataViewComponents/AddCOC";
import AddAttachments from "../DataViewComponents/AddAttachments";
import AddNewExperienceOnBoard from "../DataViewComponents/AddNewExperienceOnBoard";
import AddNewExperienceOnShore from "../DataViewComponents/AddNewExperienceOnShore";
import AddDocuments from "../DataViewComponents/AddDocuments";
import AddTrainings from "../DataViewComponents/AddTrainings";
import AddOtherTrainings from "../DataViewComponents/AddOtherTrainings";
import AttachmentTable from "../DataViewComponents/AttachmentTable";
import SkillsTable from "../DataViewComponents/SkillsTable";
import LoadingProgress from "../Commons/LoadingProgress";
import Embarcations from "./Embarcations";
import BadgeIcon from "@mui/icons-material/Badge";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CompaniesSelect from "../Commons/CompaniesSelect";
import VaccinesTab from "./Vaccines";
import AddVaccines from "../DataViewComponents/AddVaccines";
import UserProfilePicture from "../Commons/UserProfilePicture";
import {
  emptycoc,
  emptydoc,
  emptycertificate,
  emptyotherTraining,
  emptyVaccine,
  skillArray,
  otherattachment,
  emptyexperienceonshore,
  emptyexperienceonboard,
} from "../Commons/CONSTANTS";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import EmergencyContacts from "./EmergencyContacts";
import { useNavigate } from "react-router-dom";
import {
  ClosedCaptionDisabledOutlined,
  WindowSharp,
} from "@mui/icons-material";
import MarlinsTestTab from "./MarlinsTestTab";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import LenguagesTab from "./LenguagesTab";
/*****FIN DECLARACIÓN DE OBJETOS*************/

/***************INICIO********************/
export default function UpdatePersonalInfo() {
  let QUERY = window.location.search;
  const [data, setdata] = useState({});
  const [value, setValue] = React.useState(0);
  const [valuemain, setValuemain] = React.useState(0);
  const [fecha, setfecha] = useState(new Date());
  const [contry, setcontry] = useState("");
  const [vessel, vessellist] = useState([]);
  const [deparmentlist, setdepartmentlist] = useState([]);
  const [deparmentlistbk, setdepartmentlistbk] = useState([]);
  const [positionslist, setpositionlist] = useState([]);
  const [positionslistbk, setpositionlistbk] = useState([]);
  const [loading, setloading] = useState(false);
  const [showcocpanel, setshowcocpanel] = useState(false);
  const [newcoc, setnewcoc] = useState(emptycoc);
  const [cocloading, setcocloading] = useState(false);
  const [vesseltypedetails, setvesseltypedetails] = useState([]);
  const [listtypeofdocument, setlisttypeofdocument] = useState([]);
  const [showexperienceboard, setshowexperienceboard] = useState(false);
  const [newexperienceboard, setnewexperienceboard] = useState(
    emptyexperienceonboard
  );
  const [experienceboardloading, setexperienceboardloading] = useState(false);
  const [showexperienceshore, setshowexperienceshore] = useState(false);
  const [newexperienceshore, setnewexperienceshore] = useState(
    emptyexperienceonshore
  );
  const [experienceshoreloading, setexperienceshoreloading] = useState(false);
  const [showdocpanel, setshowdocpanel] = useState(false);
  const [newdoc, setnewdoc] = useState(emptydoc);
  const [docloading, setdocloading] = useState(false);
  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);
  const [certificatelist, setcertificatelist] = useState([]);
  const [showcertificates, setshowcertificates] = useState(false);
  const [showothercert, setshowothercert] = useState(false);
  const [showVaccine, setShowVaccine] = useState(false);
  const [newcertificate, setnewcertificate] = useState(emptycertificate);
  const [cocSelectOptions, setCocSelectOptions] = useState([]);
  const [newothercertificate, setnewothercertificate] =
    useState(emptyotherTraining);
  const [newvaccine, setnewvaccine] = useState(emptyVaccine);
  const [loadingcert, setloadingcert] = useState(false);
  const [attachmentlist, setatachmentlist] = useState([]);
  const [isedit, setisedit] = useState(false);
  const [backup, setbackup] = useState({});
  const [loadingprogress, setloadingprogress] = useState(false);
  const [embarcations, setembarcations] = useState([]);
  const [urlsent, seturlsent] = useState([]);
  const [isPromoted, setIsPromoted] = useState({});
  const [questions, setQuestions] = useState([]);
  const [disabledBoxes, setDisabledBoxes] = useState(false);
  const [skillRecord, setSkillRecord] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [previewImg, setPreviewImg] = useState("");
  const [countryCodes, setCountryCodes] = useState("");
  const [newattachments, setnewattachments] = useState(otherattachment);
  const [showattachments, setshowattachments] = useState(false);
  const [addNewSelfie, setAddNewSelfie] = useState(false);
  const [actualPosition, setActualPosition] = useState("");
  const [attachmentTableData, setAttachmentTableData] =
    useState(attachmentlist);
  const statusList = [
    "CONTRACT REVOKED",
    "GAP POOL ST/BY",
    "HIRING PROCESS",
    "MEDICAL LEAVE",
    "ON BOARD",
    "VACATION",
    "WORK PERMIT LEAVE",
  ];

  useEffect(() => {
    setAttachmentTableData(attachmentlist);
  }, [attachmentlist]);

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      loadDataPerson();
    };
    loadData();
  }, []);

  useEffect(() => {
    if (size(embarcations) > 0) {
      embarcations.forEach((item, i) => {
        if (item.promoteddate !== "") {
          setIsPromoted({
            status: true,
            index: i,
            promotedDate: item.promoteddate,
          });
        } else {
          setIsPromoted({
            status: false,
            promotedDate: "",
          });
        }
      });
    }
  }, [embarcations]);

  const handlePromote = async () => {
    if (isPromoted.status === true) {
      showToast("Cadet is already promoted", 2);
      return;
    }
    if (isPromoted.promotedDate === "") {
      showToast("Please enter a valid promote Date", 2);
      return;
    }
    setloadingprogress(true);
    let index = lastIndexOf(embarcations);
    let promoteEmbarcation = embarcations[index - 1];
    delete promoteEmbarcation.id;
    let newUpdate = {
      ...promoteEmbarcation,
      promoteddate: moment(isPromoted.promotedDate).format("DD/MM/YYYY"),
    };
    await updateEmbarcaciones(newUpdate);
    await loadDataPerson();
    setloadingprogress(false);
    showToast(
      "Cadet promoted successfully on the: " +
        moment(isPromoted.promotedDate).format("DD/MM/YYYY"),
      1
    );
  };

  const handlePromotedDate = (value) => {
    setIsPromoted({ ...isPromoted, promotedDate: value });
  };

  const loadDataPerson = async () => {
    let id = QUERY.replace("?id=", "");
    let allattachments = [];
    setloadingprogress(true);
    const resultado = await getPersonById(id);
    const barquitos = await loadVesselType();
    const departments = await loadDepartments();
    const positions = await loadPositions();
    const vesseldetails = await vesselTypeDetail();
    const typedocs = await typeofdocument();
    const cocList = await getCocList();
    const certificates = await loadCertificates();
    const country_codes = await getCountryCodes();
    let attachments = await getAttachmentByProcess(resultado[0].idproceso);
    const firebaseattachment = await getFirebaseAttachments("Attachment", id);
    const hiring = await getHiringsById(id);
    let hiringattachments =
      size(hiring) > 0 ? await getAttachmentByProcess(hiring[0].IDPROCESO) : [];

    hiringattachments = addAttachmentType(hiringattachments, "HIRING PROCESS");
    attachments = addAttachmentType(attachments, "APPLICATION PROCESS");

    console.log("atta_1", hiringattachments);
    console.log("atta_2", attachments);

    let embarcaciones = await getEmbarcationsById(id);

    //mostrar solo las embarcaciones con signon confirmadas.
    embarcaciones =
      size(embarcaciones) > 0 &&
      embarcaciones.filter((item) => !isEmpty(item.signondate));
    const urlsend = await loadUpdateById(id);

    //OBTENER ADJUNTOS DE LAS EMBARCACIONES.
    if (embarcaciones.length > 0) {
      await Promise.all(
        embarcaciones.map(async (item, index) => {
          let attachment = [];
          if (item.idprocesoembarque) {
            attachment = await getAttachmentByProcess(item.idprocesoembarque);

            console.log("ATTACHMENTS EMBARQUE");
            console.log(item.idprocesoembarque);
            console.log(attachment);

            attachment.forEach((item, i) => {
              attachment[i] = {
                ...item,
                attachmentType: `VESSEL ${index + 1}`,
              };
            });
          }
          allattachments = allattachments.concat(attachment);
          return attachment;
        })
      );
    }

    console.log("ALL DATA", allattachments);

    if (size(hiringattachments) > 0) {
      console.log("DATA", hiringattachments);
      allattachments = allattachments.concat(hiringattachments);
      console.log("ENTRO A HIRING PROCESS");
    }
    if (size(attachments) >= 0 && size(firebaseattachment) > 0) {
      allattachments = allattachments.concat(firebaseattachment);
      console.log("ENTRO EN FIREBASE");
      //setatachmentlist(allattachments);
    }
    if (size(attachments) > 0) {
      allattachments = allattachments.concat(attachments);
      console.log("ENTRO EN ATTACHMENT APPLICATION PROCESS");
      console.log(attachments);
    }
    setatachmentlist(allattachments);
    console.log("ALL DATA 2", allattachments);
    // console.log("CODIGOCOC");
    // console.log(cocList);

    setCocSelectOptions(cocList);
    setembarcations(embarcaciones);
    seturlsent(urlsend);
    setCountryCodes(country_codes);
    setcertificatelist(certificates);
    setlisttypeofdocument(typedocs);
    setvesseltypedetails(vesseldetails);
    filterPositions(positions, resultado[0]);
    setpositionlistbk(positions);
    setdepartmentlist(departments);
    setdepartmentlistbk(departments);
    setActualPosition(resultado[0].position_applying);
    setdata(resultado[0]);
    vessellist(barquitos);
    getSkillRecord(resultado[0]);
    setloadingprogress(false);
  };

  const filterPositions = (positions, data) => {
    let positionsl = positions.filter(
      (item) => item.department.toLowerCase() === data.department.toLowerCase()
    );
    setpositionlist(positionsl);
  };

  const addAttachmentType = (attachments, type) => {
    if (attachments.length === 0) return;
    let newAttachments = attachments.map((item) => {
      return { ...item, attachmentType: type };
    });
    return newAttachments;
  };

  const getSkillRecord = async (user) => {
    await getRecordById("Skills", user.Id.toString()).then((res) => {
      if (Object.keys(res).length !== 0) {
        setQuestions(res.skills);
        setDisabledBoxes(true);
        return;
      }
    });
  };

  // useEffect(() => {
  // 	//console.log("Staff Data", data);
  // }, [data]);

  const addToValue = (num) => {
    if (!num) return;
    let id = "";
    const intAmount = 6;
    const extraNums = intAmount - num.length;
    id = "0".repeat(extraNums) + num;
    return id;
  };

  const uploadFileServer = async (type, description, newType) => {
    if (file) {
      let url = await uploadDocumentToFirebase(file);
      const upload = {
        id: uuidv4(),
        name: file.name,
        description: description,
        type: type,
        date: moment(new Date()).format("lll"),
        downloadkey: "",
        reference: "",
        source: "FIREBASE",
        url: url,
        idpersonal: QUERY.replace("?id=", ""),
        attachmentType: newType,
      };

      await addRecord("Attachment", upload);
    }
  };

  const cleanFile = () => {
    if (file) {
      setFile("");
    }
  };

  const deleteRecords = async (data, row) => {
    let newarray = [];

    data.forEach((item) => {
      if (
        item["Type of Document"] === row[["Type of Document"]] &&
        item["Country of Issue"] === row["Country of Issue"] &&
        item["Issued at (Place)"] === row["Issued at (Place)"] &&
        item["No."] === row["No."] &&
        item["Date of Issue"] === row["Date of Issue"] &&
        item["Valid Until"] === row["Valid Until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });
    let datos = {
      Id: QUERY.replace("?id=", ""),
      type_document: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteCOC = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Country of issue"] === row[["Country of issue"]] &&
        item["Issue at (place)"] === row["Issue at (place)"] &&
        item["No."] === row["No."] &&
        item["Date of issue"] === row["Date of issue"] &&
        item["Date of Issue"] === row["Date of Issue"] &&
        item["Valid until"] === row["Valid until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      COC: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteLicBook = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Description of cert / course"] ===
          row[["Description of cert / course"]] &&
        item["Country of issue"] === row["Country of issue"] &&
        item["Number"] === row["Number"] &&
        item["Date of issue"] === row["Date of issue"] &&
        item["Date of expiry"] === row["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      lic_sbook_stcw_certificates: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteOther = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Name of education institution / tech. institute"] ===
          row["Name of education institution / tech. institute"] &&
        item["Obtained title or grade"] === row["Obtained title or grade"] &&
        item["Date on"] === row["Date on"] &&
        item["Date off"] === row["Date off"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      other_training: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteVaccine = async (data, row) => {
    let newarray = [];

    console.log(data, row);

    data.map((item) => {
      if (
        item["Doze"] === row[["Doze"]] &&
        item["Vaccination Mark"] === row["Vaccination Mark"] &&
        item["Date of issue"] === row["Date of issue"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      vaccination_info: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  /**EDIT NEW DOCUMENT*/

  const editNewDocument = (row) => {
    const emptydoc = {
      doctype: row["Type of Document"],
      contry: row["Country of Issue"],
      issueatplace: row["Issued at (Place)"],
      no: row["No."],
      issuedate: row["Date of Issue"] === "" ? null : row["Date of Issue"],
      validdate: row["Valid Until"] === "" ? null : row["Valid Until"],
    };

    if (emptydoc.issuedate === "Invalid date") {
      emptydoc.issuedate = null;
    }

    setbackup(row);
    setisedit(true);
    setnewdoc(emptydoc);
    setshowdocpanel(true);
  };

  const saveDocument = async () => {
    //ELIMINAR EL DOCUMENTO ANTERIOR DEL ARRAY.

    let newarray = [];
    let datos = JSON.parse(data.type_document);

    // debido a que no hay llave primaria se hizo de esta forma.
    datos.map((item) => {
      if (
        item["Type of Document"] === backup[["Type of Document"]] &&
        item["Country of Issue"] === backup["Country of Issue"] &&
        item["Issued at (Place)"] === backup["Issued at (Place)"] &&
        item["No."] === backup["No."] &&
        item["Date of Issue"] === backup["Date of Issue"] &&
        item["Valid Until"] === backup["Valid Until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });
    //addnew
    //newadday.push()

    if (isEmpty(newdoc.doctype)) {
      showToast("Please select a Document Type", 3);
    } else {
      setdocloading(true);

      let insertdata = {
        "Type of Document": newdoc.doctype,
        "Country of Issue": newdoc.contry,
        "No.": newdoc.no,
        "Date of Issue":
          newdoc.issuedate === null
            ? ""
            : moment(newdoc.issuedate).format("YYYY/MM/DD"),
        "Issued at (Place)": newdoc.issueatplace,
        "Valid Until":
          newdoc.validdate === null
            ? ""
            : moment(newdoc.validdate).format("YYYY/MM/DD"),
      };
      newarray.push(insertdata);

      console.log("LA DATA QUE VIENE ES");
      console.log(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        type_document: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await uploadFileServer("DOCUMENTS", newdoc.doctype);
      setdocloading(false);
      await loadDataPerson();
      setnewdoc(emptydoc);
      handleDocClose();
      setFile("");
      setbackup({});
      setisedit(false);
      setshowdocpanel(false);
      showToast("Update Sucessful", 1);
    }
  };

  const editCOC = (row) => {
    const emptyCOC = {
      "COC DOC.": row["COC DOC."],
      "Grade of license (if apply)": row["Grade of license (if apply)"],
      contry: row["Country of issue"],
      issueatplace: row["Issue at (place)"],
      no: row["No."],
      issuedate: row["Date of issue"] === "" ? null : row["Date of issue"],
      validdate: row["Valid until"] === "" ? null : row["Valid until"],
    };

    setbackup(row);
    setisedit(true);
    setnewcoc(emptyCOC);
    setshowcocpanel(true);
  };

  const saveCOC = async () => {
    let newarray = [];
    let datos = JSON.parse(data.COC);
    setcocloading(true);
    datos.map((item) => {
      if (
        item["COC DOC."] === backup["COC DOC."] &&
        item["Grade of license (if apply)"] ===
          backup["Grade of license (if apply)"] &&
        item["Country of issue"] === backup["Country of issue"] &&
        item["Issue at (place)"] === backup["Issue at (place)"] &&
        item["No."] === backup["No."] &&
        item["Date of issue"] === backup["Date of issue"] &&
        item["Valid until"] === backup["Valid until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let insertdata = {
      "COC DOC.": newcoc["COC DOC."],
      "Grade of license (if apply)": newcoc["Grade of license (if apply)"],
      "Country of issue": newcoc.contry,
      "No.": newcoc.no,
      "Date of issue":
        newcoc.issuedate === null
          ? ""
          : moment(newcoc.issuedate).format("YYYY/MM/DD"),
      "Issue at (place)": newcoc.issueatplace,
      "Valid until":
        newcoc.validdate === null
          ? ""
          : moment(newcoc.validdate).format("YYYY/MM/DD"),
    };

    newarray.push(insertdata);

    let datos1 = {
      Id: QUERY.replace("?id=", ""),
      COC: newarray,
    };
    const respuesta = await updateDataPersonal(datos1);
    console.log(respuesta);

    await uploadFileServer("COC", "COC");
    setcocloading(false);
    await loadDataPerson();
    setnewcoc(emptycoc);
    handleCOCClose();
    setbackup({});
    setisedit(false);
    showToast("COC Updated", 1);
  };

  const editexperienceonboard = (row) => {
    const emptyexperience = {
      dateon: row["Date on"] === "" ? null : row["Date on"],
      dateoff: row["Date off"] === "" ? null : row["Date off"],
      companyname: row["Company name"],
      vesselname: row["Vessel name"],
      imo: row["IMO #"],
      gthp: row["GT / HP"],
      typevessel: row["Type of vessel"],
      "Rank/position": row["Rank/position"],
    };

    setbackup(row);
    setisedit(true);
    setnewexperienceboard(emptyexperience);
    setshowexperienceboard(true);
  };
  //comentario de prueba.
  const saveExperienceOnBoard = async () => {
    let newarray = [];
    let datos = JSON.parse(data.experience_onboard);
    datos.map((item) => {
      if (
        item["Date on"] === backup["Date on"] &&
        item["Date off"] === backup["Date off"] &&
        item["Company name"] === backup["Company name"] &&
        item["Vessel name"] === backup["Vessel name"] &&
        item["IMO #"] === backup["IMO #"] &&
        item["GT / HP"] === backup["GT / HP"] &&
        item["Type of vessel"] === backup["Type of vessel"] &&
        item["Rank/position"] === backup["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newexperienceboard.companyname)) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceboardloading(true);

      const insertdata = {
        "Date on":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceboard.companyname,
        "Vessel name": newexperienceboard.vesselname,
        "IMO #": newexperienceboard.imo,
        "GT / HP": newexperienceboard.gthp,
        "Type of vessel": newexperienceboard.typevessel,
        "Rank/position": newexperienceboard["Rank/position"],
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onboard: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await loadDataPerson();
      setexperienceboardloading(false);
      setnewexperienceboard(emptyexperienceonboard);
      handleExperienceBoardClose();
      setbackup({});
      setisedit(false);
      showToast("Experience On Board was Updated Sucessful", 1);
    }
  };

  const editexperienceonshore = (row) => {
    const emptyexperience = {
      "Date on": row["Date on"] === "" ? null : row["Date on"],
      "Date off": row["Date off"] === "" ? null : row["Date off"],
      "Company name": row["Company name"],
      "Contact person telephone": row["Contact person telephone"],
      "Duties or responsibilities": row["Duties or responsibilities"],
      "Rank/position": row["Rank/position"],
      "Reason for leaving": row["Reason for leaving"],
    };

    setbackup(row);
    setisedit(true);
    setnewexperienceshore(emptyexperience);
    setshowexperienceshore(true);
  };

  const saveExperienceOnShore = async () => {
    let newarray = [];
    let datos = JSON.parse(data.experience_onshore);
    datos.map((item) => {
      if (
        item["Date on"] === backup["Date on"] &&
        item["Date off"] === backup["Date off"] &&
        item["Company name"] === backup["Company name"] &&
        item["Contact person telephone"] ===
          backup["Contact person telephone"] &&
        item["Duties or responsibilities"] ===
          backup["Duties or responsibilities"] &&
        item["Rank/position"] === backup["Rank/position"] &&
        item["Reason for leaving"] === backup["Reason for leaving"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newexperienceshore["Company name"])) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceshoreloading(true);

      const insertdata = {
        "Date on":
          newexperienceshore["Date on"] === null
            ? ""
            : moment(newexperienceshore["Date on"]).format("YYYY/MM/DD"),
        "Date off":
          newexperienceshore["Date off"] === null
            ? ""
            : moment(newexperienceshore["Date off"]).format("YYYY/MM/DD"),
        "Company name": newexperienceshore["Company name"],
        "Contact person telephone":
          newexperienceshore["Contact person telephone"],
        "Duties or responsibilities":
          newexperienceshore["Duties or responsibilities"],
        "Rank/position": newexperienceshore["Rank/position"],
        "Type of vessel": newexperienceshore["Type of vessel"],
        "Reason for leaving": newexperienceshore["Reason for leaving"],
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onshore: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      await loadDataPerson();
      setexperienceshoreloading(false);
      setnewexperienceshore(emptyexperienceonshore);
      handleExperienceshoreClose();
      setbackup({});
      setisedit(false);
      showToast("Updated Sucessful", 1);
    }
  };

  const editnewLicBook = (row) => {
    console.log("ESTA ES LA FILA");
    console.log(row);
    const emptybook = {
      description: row["Description of cert / course"],
      contry: row["Country of issue"],
      number: row["Number"],
      issuedate: row["Date of issue"] === "" ? null : row["Date of issue"],
      expireddate: row["Date of expiry"] === "" ? null : row["Date of expiry"],
    };

    setbackup(row);
    setisedit(true);
    setnewcertificate(emptybook);
    setshowcertificates(true);
  };

  const saveLicBook = async () => {
    let newarray = [];
    let datos = JSON.parse(data.lic_sbook_stcw_certificates);

    datos.map((item) => {
      if (
        item["Description of cert / course"] ===
          backup["Description of cert / course"] &&
        item["Country of issue"] === backup["Country of issue"] &&
        item["Number"] === backup["Number"] &&
        item["Date of issue"] === backup["Date of issue"] &&
        item["Date of expiry"] === backup["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newcertificate.description)) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newcertificate.description,
        "Country of issue": newcertificate.contry,
        Number: newcertificate.number,
        "Date of issue":
          newcertificate.issuedate === null
            ? ""
            : moment(newcertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newcertificate.expireddate === null
            ? ""
            : moment(newcertificate.expireddate).format("YYYY/MM/DD"),
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        lic_sbook_stcw_certificates: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await uploadFileServer("LIC BOOK STCW", newcertificate.description);

      await loadDataPerson();
      setnewcertificate(emptycertificate);
      handleCertificatesClose();
      setloadingcert(false);
      setbackup({});
      setisedit(false);
      showToast("Update Sucesfull", 1);
    }
  };

  const editothertrainings = (row) => {
    const emptybook = {
      "Name of education institution / tech. institute":
        row["Name of education institution / tech. institute"],
      "Obtained title or grade": row["Obtained title or grade"],
      "Date on": row["Date on"] === "" ? null : row["Date on"],
      "Date off": row["Date off"] === "" ? null : row["Date off"],
    };

    setbackup(row);
    setisedit(true);
    setnewothercertificate(emptybook);
    setshowothercert(true);
  };

  const editVaccines = (row) => {
    const emptyVaccination = {
      Doze: row["Doze"],
      "Vaccination Mark": row["Vaccination Mark"],
      "Date of issue":
        row["Date of issue"] === "" ? null : row["Date of issue"],
    };

    setbackup(row);
    setisedit(true);
    setnewvaccine(emptyVaccination);
    setShowVaccine(true);
  };

  const saveOtherCertificate = async () => {
    let newarray = [];
    let datos = JSON.parse(data.other_training);

    datos.map((item) => {
      if (
        item["Name of education institution / tech. institute"] ===
          backup["Name of education institution / tech. institute"] &&
        item["Obtained title or grade"] === backup["Obtained title or grade"] &&
        item["Date on"] === backup["Date on"] &&
        item["Date off"] === backup["Date off"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (
      isEmpty(
        newothercertificate["Name of education institution / tech. institute"]
      )
    ) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Name of education institution / tech. institute":
          newothercertificate[
            "Name of education institution / tech. institute"
          ],
        "Obtained title or grade":
          newothercertificate["Obtained title or grade"],
        "Date on":
          newothercertificate["Date on"] === null
            ? ""
            : moment(newothercertificate["Date on"]).format("YYYY/MM/DD"),
        "Date off":
          newothercertificate["Date off"] === null
            ? ""
            : moment(newothercertificate["Date off"]).format("YYYY/MM/DD"),
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        other_training: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      await uploadFileServer(
        "OTHER TRAININGS",
        newothercertificate["Name of education institution / tech. institute"]
      );
      console.log(respuesta);
      await loadDataPerson();
      setnewothercertificate(emptycertificate);
      handleOtherCertClose();
      setloadingcert(false);
      setbackup({});
      setisedit(false);
      showToast("Update Sucesfull", 1);
    }
  };

  const saveVaccines = async () => {
    let newarray = [];
    let datos = JSON.parse(data.vaccination_info);

    datos.map((item) => {
      if (
        item["Doze"] === backup["Doze"] &&
        item["Vaccination Mark"] === backup["Vaccination Mark"] &&
        item["Date of issue"] === backup["Date of issue"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newvaccine.Doze)) {
      showToast("Please Vaccine doze", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        Doze: newvaccine.Doze,
        "Vaccination Mark": newvaccine["Vaccination Mark"],
        "Date of issue":
          newvaccine.issuedate === null
            ? ""
            : moment(newvaccine["Date of issue"]).format("YYYY/MM/DD"),
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        vaccination_info: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await loadDataPerson();
      setnewvaccine(emptyVaccine);
      handleVaccineCertClose();
      setloadingcert(false);
      setbackup({});
      setisedit(false);
      showToast("Update Sucesfull", 1);
    }
  };

  /*EDIT NEW DOCUMENT*/
  /****************CERTIFICATES******************/
  const handleCertificatesOpen = () => {
    setnewcertificate(emptycertificate);
    setshowcertificates(true);
  };
  const handleCertificatesClose = () => {
    setshowcertificates(false);
    setisedit(false);
    cleanFile();
  };

  const deleteExperienceOnBoard = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Date on"] === row[["Date on"]] &&
        item["Date off"] === row["Date off"] &&
        item["Company name"] === row["Company name"] &&
        item["Vessel name"] === row["Vessel name"] &&
        item["IMO #"] === row["IMO #"] &&
        item["GT / HP"] === row["GT / HP"] &&
        item["Type of vessel"] === row["Type of vessel"] &&
        item["Rank/position"] === row["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      experience_onboard: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };
  const deteleExperienceOnShore = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Date on"] === row[["Date on"]] &&
        item["Date off"] === row["Date off"] &&
        item["Company name"] === row["Company name"] &&
        item["Contact person telephone"] === row["Contact person telephone"] &&
        item["Duties or responsibilities"] ===
          row["Duties or responsibilities"] &&
        item["Rank/position"] === row["Rank/position"] &&
        item["Reason for leaving"] === row["Reason for leaving"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      experience_onshore: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const handleChangeCertSelect = (e) => {
    setnewcertificate({ ...newcertificate, description: e.target.value });
  };

  const handleChangeCertIssueDate = (e) => {
    setnewcertificate({ ...newcertificate, issuedate: e });
  };

  const handleChangeCertExpiredDate = (e) => {
    setnewcertificate({ ...newcertificate, expireddate: e });
  };

  const handleCountryCert = (e) => {
    setnewcertificate({ ...newcertificate, contry: e.target.value });
  };

  const onchangeCert = (event) => {
    setnewcertificate({
      ...newcertificate,
      [event.target.name]: event.target.value,
    });
  };

  const addNewCertificate = async () => {
    if (isEmpty(newcertificate.description)) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newcertificate.description,
        "Country of issue": newcertificate.contry,
        Number: newcertificate.number,
        "Date of issue":
          newcertificate.issuedate === null
            ? ""
            : moment(newcertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newcertificate.expireddate === null
            ? ""
            : moment(newcertificate.expireddate).format("YYYY/MM/DD"),
      };

      let docdatos = isEmpty(data.lic_sbook_stcw_certificates)
        ? []
        : JSON.parse(data.lic_sbook_stcw_certificates);
      docdatos.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        lic_sbook_stcw_certificates: docdatos,
      };

      const respuesta = await updateDataPersonal(datos);
      await uploadFileServer("LIC BOOK STCW", newcertificate.description);

      await loadDataPerson();
      setnewcertificate(emptycertificate);
      handleCertificatesClose();
      showToast("Certificate Added Sucessful", 1);
      setloadingcert(false);
    }
  };

  /*****************FIN CERTIFICATES************/

  /*****************OTHER CERTIFICATES*********/
  const handleOtherCertOpen = () => {
    setnewothercertificate(emptyotherTraining);
    setshowothercert(true);
  };
  const handleOtherCertClose = () => {
    setshowothercert(false);
    setisedit(false);
    setnewothercertificate(emptyotherTraining);
    cleanFile();
  };

  const handleChangeOtherCertIssueDate = (e) => {
    setnewothercertificate({ ...newothercertificate, "Date on": e });
  };

  const handleChangeOtherCertExpiredDate = (e) => {
    setnewothercertificate({ ...newothercertificate, "Date off": e });
  };

  const handleCountryOtherCert = (e) => {
    setnewothercertificate({ ...newothercertificate, contry: e.target.value });
  };

  const onchangeOtherCert = (event) => {
    setnewothercertificate({
      ...newothercertificate,
      [event.target.name]: event.target.value,
    });
  };

  const addNewOtherCertificate = async () => {
    if (
      isEmpty(
        newothercertificate["Name of education institution / tech. institute"]
      )
    ) {
      showToast("Please add a Certificate description", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Name of education institution / tech. institute":
          newothercertificate[
            "Name of education institution / tech. institute"
          ],
        "Obtained title or grade":
          newothercertificate["Obtained title or grade"],
        "Date on":
          newothercertificate["Date on"] === null
            ? ""
            : moment(newothercertificate["Date on"]).format("YYYY/MM/DD"),
        "Date off":
          newothercertificate["Date off"] === null
            ? ""
            : moment(newothercertificate["Date off"]).format("YYYY/MM/DD"),
      };

      let docdatos = isEmpty(data.other_training)
        ? []
        : JSON.parse(data.other_training);
      docdatos.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        other_training: docdatos,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await uploadFileServer(
        "OTHER TRAININGS",
        newothercertificate["Name of education institution / tech. institute"]
      );
      await loadDataPerson();
      setnewothercertificate(emptycertificate);
      handleOtherCertClose();
      setloadingcert(false);
      showToast("Certificate Added Sucessful", 1);
    }
  };

  /**++++++++++++++++FIN OTHER CERTIFICATES****+*/

  /***************** VACCINES *********/
  const handleVaccineCertOpen = () => {
    console.log("this one", emptyVaccine);
    setnewvaccine(emptyVaccine);
    setShowVaccine(true);
  };
  const handleVaccineCertClose = () => {
    setShowVaccine(false);
    setisedit(false);
    cleanFile();
  };

  const handleChangeVaccineIssueDate = (e) => {
    setnewvaccine({ ...newvaccine, "Date of issue": e });
  };

  const handleChangeVaccineDoze = (e) => {
    setnewvaccine({ ...newvaccine, Doze: e.target.value });
  };

  const handleVaccineMark = (e) => {
    setnewvaccine({ ...newvaccine, "Vaccination Mark": e.target.value });
  };

  const onchangeVaccine = (event) => {
    setnewothercertificate({
      ...newothercertificate,
      [event.target.name]: event.target.value,
    });
  };

  const addNewVaccine = async () => {
    if (isEmpty(newvaccine.Doze)) {
      showToast("Please add a Vaccine doze", 3);
    } else {
      let insertdata = {
        Doze: newvaccine.Doze,
        "Vaccination Mark": newvaccine["Vaccination Mark"],
        "Date of issue":
          newvaccine["Date of issue"] === null
            ? ""
            : moment(newvaccine["Date of issue"]).format("YYYY/MM/DD"),
      };

      let vaccinationdatos = isEmpty(data.vaccination_info)
        ? []
        : JSON.parse(data.vaccination_info);
      vaccinationdatos.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        vaccination_info: vaccinationdatos,
      };

      const respuesta = await updateDataPersonal(datos);
      await loadDataPerson();
      // TO DO FIX VACCINES MERGIN WITH OTHERCERTIFICATES
      setnewothercertificate(emptycertificate);
      handleVaccineCertClose();
      setisedit(false);
      setloadingcert(false);
      showToast("Vaccine Added Sucessful", 1);
    }
  };

  /****************DOCUMENTS*********************/
  const handleDocsOpen = () => {
    setnewdoc(emptydoc);
    setshowdocpanel(true);
  };
  const handleDocClose = () => {
    setshowdocpanel(false);
    cleanFile();
  };

  const handleChangeDocIssueDate = (e) => {
    setnewdoc({ ...newdoc, issuedate: e });
  };

  const handleChangeDocValidDate = (e) => {
    setnewdoc({ ...newdoc, validdate: e });
  };

  const handleChangeContrySelect = (e) => {
    setnewdoc({ ...newdoc, contry: e.target.value });
  };

  const handleChangeIssueDate = (e) => {
    setnewcoc({ ...newcoc, issuedate: e });
  };

  const handleChangeValidDate = (e) => {
    setnewcoc({ ...newcoc, validdate: e });
  };
  const handleDocChangeSelect = (e) => {
    setnewdoc({ ...newdoc, doctype: e.target.value });
  };

  const handleChangeSelect = (e) => {
    console.log(newcoc);
    setnewcoc({ ...newcoc, [e.target.name]: e.target.value });
  };

  const addNewDoc = async () => {
    if (isEmpty(newdoc.doctype)) {
      showToast("Please select a Document Type", 3);
    } else {
      setdocloading(true);
      setloadingprogress(true);

      let insertdata = {
        "Type of Document": newdoc.doctype,
        "Country of Issue": newdoc.contry,
        "No.": newdoc.no,
        "Date of Issue":
          newdoc.issuedate === null
            ? ""
            : moment(newdoc.issuedate).format("YYYY/MM/DD"),
        "Issued at (Place)": newdoc.issueatplace,
        "Valid Until":
          newdoc.validdate === null
            ? ""
            : moment(newdoc.validdate).format("YYYY/MM/DD"),
      };
      let docdatos = isEmpty(data.type_document)
        ? []
        : JSON.parse(data.type_document);

      docdatos.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        type_document: docdatos,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log("RESPUESTA ADD");
      console.log(respuesta);
      await uploadFileServer("DOCUMENTS", newdoc.doctype);
      await loadDataPerson();
      handleDocClose();
      setdocloading(false);
      setnewdoc(emptydoc);
      setloadingprogress(false);
      showToast("Document Added Sucessful", 1);
    }
  };

  /***************END DOCUMENTS*****************/

  /****************EXPERIENCE ON BOARD***********/
  const onchangeExpBoard = (event) => {
    setnewexperienceboard({
      ...newexperienceboard,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeDateon = (e) => {
    console.log(e);
    setnewexperienceboard({ ...newexperienceboard, dateon: e });
  };

  const handleChangeDateoff = (e) => {
    setnewexperienceboard({ ...newexperienceboard, dateoff: e });
  };
  const handleChangeSelectExperience = (e) => {
    setnewexperienceboard({
      ...newexperienceboard,
      typevessel: e.target.value,
    });
  };
  const handleExperienceBoardClose = () => {
    console.log("newexperienceboard", newexperienceboard);
    setshowexperienceboard(false);
    cleanFile();
  };

  const handleExperienceBoardOpen = () => {
    setnewexperienceboard(emptyexperienceonboard);
    setshowexperienceboard(true);
  };

  const addNewExpOnBoard = async () => {
    if (isEmpty(newexperienceboard.companyname)) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceboardloading(true);

      const insertdata = {
        "Date on":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceboard.companyname,
        "Vessel name": newexperienceboard.vesselname,
        "IMO #": newexperienceboard.imo,
        "GT / HP": newexperienceboard.gthp,
        "Type of vessel": newexperienceboard.typevessel,
        "Rank/position": newexperienceboard["Rank/position"],
      };
      let experienceonboard = isEmpty(data.experience_onboard)
        ? []
        : JSON.parse(data.experience_onboard);
      experienceonboard.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onboard: experienceonboard,
      };

      const respuesta = await updateDataPersonal(datos);
      await loadDataPerson();
      setexperienceboardloading(false);
      setnewexperienceboard(emptyexperienceonboard);
      handleExperienceBoardClose();
      showToast("Experience On Board was Added Sucessful", 1);
    }
  };

  /*************FIN EXPERIENCE ON BOARD*****************/

  /****************EXPERIENCE ON SHORE******************/

  /******************FIN EXPERIENCE ON SHORE*********/

  const onchangeExpShore = (event) => {
    setnewexperienceshore({
      ...newexperienceshore,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeDateonShore = (e) => {
    setnewexperienceshore({ ...newexperienceshore, "Date on": e });
  };

  const handleChangeDateoffShore = (e) => {
    setnewexperienceshore({ ...newexperienceshore, "Date off": e });
  };

  const handleExperienceshoreClose = () => {
    setshowexperienceshore(false);
    setnewexperienceshore(emptyexperienceonshore);
    setisedit(false);
    cleanFile();
  };

  const handleExperienceshoreOpen = () => {
    setnewexperienceshore(emptyexperienceonshore);
    setshowexperienceshore(true);
  };

  const addNewExpOnshore = async () => {
    if (isEmpty(newexperienceshore["Company name"])) {
      showToast("Please add a Company Name", 3);
    } else {
      setexperienceshoreloading(true);

      const insertdata = {
        "Date on":
          newexperienceshore["Date on"] === null
            ? ""
            : moment(newexperienceshore.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceshore["Date off"] === null
            ? ""
            : moment(newexperienceshore["Date off"]).format("YYYY/MM/DD"),
        "Company name": newexperienceshore["Company name"],
        "Contact person telephone":
          newexperienceshore["Contact person telephone"],
        "Duties or responsibilities":
          newexperienceshore["Duties or responsibilities"],
        "Rank/position": newexperienceshore["Rank/position"],
        "Reason for leaving": newexperienceshore["Reason for leaving"],
      };
      let experienceonshore = isEmpty(data.experience_onshore)
        ? []
        : JSON.parse(data.experience_onshore);
      experienceonshore.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onshore: experienceonshore,
      };

      const respuesta = await updateDataPersonal(datos);
      await loadDataPerson();
      setexperienceshoreloading(false);
      setnewexperienceshore(emptyexperienceonshore);
      handleExperienceshoreClose();
      showToast("Experience On Shore was Added Sucessful", 1);
    }
  };

  /*******************COC******************************/

  const handleCOCClose = () => {
    setshowcocpanel(false);
    cleanFile();
  };

  const handleCOCOpen = () => {
    setnewcoc(emptycoc);
    setshowcocpanel(true);
  };

  const onchangeCOC = (event) => {
    setnewcoc({ ...newcoc, [event.target.name]: event.target.value });
  };

  const onchangeDoc = (event) => {
    setnewdoc({
      ...newdoc,
      [event.target.name]: event.target.value,
    });
  };

  const addNewCoc = async () => {
    setcocloading(true);

    let insertdata = {
      "COC DOC.": newcoc["COC DOC."],
      "Grade of license (if apply)": newcoc["Grade of license (if apply)"],
      "Country of issue": newcoc.contry,
      "No.": newcoc.no,
      "Date of issue":
        newcoc.issuedate === null
          ? ""
          : moment(newcoc.issuedate).format("YYYY/MM/DD"),
      "Issue at (place)": newcoc.issueatplace,
      "Valid until":
        newcoc.validdate === null
          ? ""
          : moment(newcoc.validdate).format("YYYY/MM/DD"),
    };
    let cocdatos = isEmpty(data.COC) ? [] : JSON.parse(data.COC);
    cocdatos.push(insertdata);

    let datos = {
      Id: QUERY.replace("?id=", ""),
      COC: cocdatos,
    };
    const respuesta = await updateDataPersonal(datos);
    console.log(respuesta);
    await uploadFileServer("COC", "COC");
    await loadDataPerson();
    setcocloading(false);
    setnewcoc(emptycoc);
    handleCOCClose();
    showToast("COC Document Added Sucessful", 1);
  };

  /*****************FIN COC***************************/

  /*****************USER DATA*************************/

  const handlePlaceOfBirth = (e) => {
    setdata({ ...data, place_of_birth: e.target.value });
  };

  const changeVesselType = (e) => {
    setdata({ ...data, category_vessel: e.target.value });
    let departments = deparmentlistbk.filter(
      (item) => item.category.toLowerCase() === e.target.value.toLowerCase()
    );
    setdepartmentlist(departments);
  };

  const changeCompany = (e) => {
    setdata({ ...data, company: e.target.value });
  };

  const changeDepartment = (e) => {
    setdata({ ...data, department: e.target.value });

    let positionsl = positionslistbk.filter(
      (item) => item.department.toLowerCase() === e.target.value.toLowerCase()
    );
    setpositionlist(positionsl);
  };

  const changePositions = (e) => {
    setdata({ ...data, position_applying: e.target.value });
  };

  const changeEducation = (e) => {
    setdata({ ...data, higherleveleducation: e.target.value });
  };

  const changeCivilStatus = (e) => {
    setdata({ ...data, civilstatus: e.target.value });
  };

  const handleChange = (event) => {
    setdata({ ...data, [event.target.name]: event.target.value });
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTabsmain = (event, newValue) => {
    setValuemain(newValue);
  };

  const handleDateBirth = (value) => {
    setdata({ ...data, date_of_birth: new Date(value) });
  };

  const handleApplicationDate = (value) => {
    setdata({ ...data, application_date: new Date(value) });
  };

  const handleCommencesDate = (value) => {
    setdata({ ...data, contractcommencesdate: new Date(value) });
  };

  const handleEndsDate = (value) => {
    setdata({ ...data, contractendsdate: new Date(value) });
  };

  const updateData = async () => {
    setloading(true);
    let userData = { ...data };
    if (profileImg) {
      const fileUpload = await uploadDocumentToFirebase(profileImg);
      userData.user_selfie = fileUpload;
    }

    delete userData.id;
    userData.date_of_birth = moment(userData.date_of_birth).format(
      "YYYY/MM/DD"
    );
    const respuesta = await updateDataPersonal(userData);

    await loadDataPerson();
    setloading(false);
    showToast("Record has been updated successful!", 1);
    if (
      skillArray.includes(data.position_applying) &&
      data.position_applying !== actualPosition
    ) {
      await deleteRecord("Skills", data.Id);
      window.location.reload();
    } else {
      let updaterequest = {
        skills: questions,
        position: data.position_applying,
        pmssaid: data.Id,
      };
      const resultado = await updateRecord("Skills", data.Id, updaterequest);
      console.log("result:", resultado);
    }
  };

  /**************FIN USER DATA************************************/

  /****************TOOLS*****************************************/
  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };
  const handleUpload = async () => {
    if (!file) {
      alert("Please upload an image first!");
    } else {
      await uploadDocumentToFirebase(file);
    }
  };

  function handleFileChange(event) {
    setFile(event.target.files[0]);
  }

  /*******************FIN TOOLS**********************/

  const VesselType = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="merchanselect"
          id="merchanselect"
          value={
            data.category_vessel === ""
              ? "Merchant vessel"
              : data.category_vessel
          }
          label="Category Vessel"
          onChange={changeVesselType}
          fullWidth
          variant="standard">
          {vessel.map((item) => (
            <MenuItem value={item.Name} key={item.Id}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  // useEffect(() => {
  // 	console.log(data);
  // }, [data]);

  const sendUpdateInfo = async () => {
    //1. Alertar de que se generará un correo y un link.
    setloadingprogress(true);
    let id = QUERY.replace("?id=", "");
    let fullname = data.fullname;
    const updaterequest = {
      createDate: new Date(),
      data: data,
      status: "pending",
      pmssaid: data.id,
      fullname: fullname,
    };

    if (size(urlsent) === 0) {
      const resultado = await addRecord("UpdateRequests", updaterequest);
      console.log(resultado.id);

      const baseUrl = "https://portal.panamamarinelogistic.com.pa";

      const emailcreate = TEMPLATE.filter((item) => item.id === "UPDATE_INFO");
      let body = emailcreate[0].body.replace("$fullname", fullname);
      body = body.replace("$link", `${baseUrl}/updateinfo?id=${resultado.id}`);

      console.log("EMAIL SEND TO: ", data.email);
      sendEmail(body, emailcreate[0].subject, `${data.email}`);
      loadDataPerson();
      showToast("A LINK WAS SENT TO " + fullname, 1);
    } else {
      showToast(
        fullname +
          " ALREADY HAS A PENDING REQUEST SENT , PLEASE COPY LINK IF  YOU WANT SENT AGAIN",
        1
      );
    }
    setloadingprogress(false);
  };

  /****************ADD ATTACHMENTS*******************************/
  const handleOpenAttachments = () => {
    setshowattachments(true);
  };

  const handleCloseAttachments = () => {
    setshowattachments(false);
  };

  const onchangeAttachments = (event) => {
    setnewattachments({
      ...newattachments,
      [event.target.name]: event.target.value,
    });
  };

  const addNewAttachments = async () => {
    let idLocal = QUERY.replace("?id=", "");
    if (!newattachments.description === "") {
      showToast("Please enter a description", 2);
    } else if (!file) {
      showToast("Please Select a File", 2);
    } else {
      //ingresar documento
      setloadingcert(true);
      await uploadFileServer(
        "DOCUMENTS",
        newattachments.description,
        newattachments.attachmentType
      );
      const attachResponse = await getFirebaseAttachments(
        "Attachment",
        idLocal
      );
      let newAddition = attachResponse.filter((item) => {
        return (
          item.attachmentType === newattachments.attachmentType &&
          item.description === newattachments.description
        );
      });
      setAttachmentTableData([...attachmentTableData, newAddition[0]]);
      console.log("res", attachResponse);
      handleCloseAttachments();
    }
  };

  /*******************FIN***********************************/

  const DepartmentSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="departmentselect"
          id="departmentselect"
          value={_.get(data, "department", "").toUpperCase()}
          label="Department"
          onChange={changeDepartment}
          variant="standard">
          {deparmentlist.map((item) => (
            <MenuItem value={item.department_name.toUpperCase()} key={item.Id}>
              {item.department_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const PositionSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="positionselect"
          id="positionselect"
          value={_.get(data, "position_applying", "").toUpperCase()}
          label="Position Applying"
          onChange={changePositions}
          variant="standard">
          {positionslist.map((item) => (
            <MenuItem value={item.position_name.toUpperCase()} key={item.Id}>
              {item.position_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const SelectPlaceOfBirth = ({ place_of_birth }) => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="placeofbirthselect"
          id="placeofbirthselect"
          value={_.get(data, "place_of_birth", "").toUpperCase()}
          onChange={handlePlaceOfBirth}
          name="place_of_birth"
          fullWidth
          variant="standard">
          {contrylistdata.map((item) => (
            <MenuItem value={item.name.toUpperCase()} key={item.name}>
              {item.name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const EducationLevelSelect = ({ education }) => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="positionselect"
          id="positionselect"
          value={_.get(data, "higherleveleducation", "").toUpperCase()}
          label="Position Applying"
          onChange={changeEducation}
          variant="standard">
          <MenuItem value="TECHNIQUE">TECHNIQUE</MenuItem>
          <MenuItem value="HIGH SCHOOL">HIGH SCHOOL</MenuItem>
          <MenuItem value="UNIVERSITY">UNIVERSITY</MenuItem>
          <MenuItem value="ELEMENTARY">ELEMENTARY</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const CivilStatusSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="civilstatus"
          id="civilstatus"
          value={_.get(data, "civilstatus", "").toUpperCase()}
          label="Position Applying"
          onChange={changeCivilStatus}
          variant="standard">
          <MenuItem value="DIVORCED">Divorced</MenuItem>
          <MenuItem value="MARRIED">Married</MenuItem>
          <MenuItem value="SEPARATED">Separated</MenuItem>
          <MenuItem value="SINGLE">Single</MenuItem>
          <MenuItem value="WIDOWED">Widowed</MenuItem>
          <MenuItem value="FREE UNION">Free union</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const changeCountryCode = (e) => {
    const value = e.target.value;
    const countryObj = countryCodes.filter((item) => {
      return item.country_code_name === value;
    });
    setdata({ ...data, countrycode: countryObj[0].Id });
  };

  const CountryCodeSelect = () => {
    if (!countryCodes) return;
    const countryIndex = Number(data.countrycode) - 1;
    const value = countryCodes[countryIndex];
    return (
      <FormControl fullWidth>
        <Select
          value={value ? value.country_code_name : ""}
          label="Position Applying"
          onChange={(e) => changeCountryCode(e)}
          variant="standard">
          {countryCodes.map((item, index) => {
            return (
              <MenuItem key={index} value={item.country_code_name}>
                {item.country_code_name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const copyLink = async () => {
    console.log(urlsent);
    await navigator.clipboard.writeText(
      `https://portal.panamamarinelogistic.com.pa/updateinfo?id=${urlsent[0].idfirebase}`
    );
    showToast("Link was copy in clipboard", 1);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Grid>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "row",
            }}>
            <Box
              sx={{
                display: "flex",
                displayDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                paddingRight: 5,
                paddingLeft: 5,
              }}>
              <ArrowBackIosNewRoundedIcon
                onClick={() => navigate("/staffdatabase")}
              />
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={updateData}
                startIcon={<SaveAsRoundedIcon />}
                size={"small"}>
                {!loading ? (
                  "UPDATE INFO"
                ) : (
                  <CircularProgress color="inherit" />
                )}
              </Button>
              <Button
                variant="contained"
                sx={{ mr: 5, width: 200 }}
                onClick={sendUpdateInfo}
                size={"small"}
                startIcon={<MarkAsUnreadIcon />}>
                Send Update Request
              </Button>
              {size(urlsent) > 0 && (
                <Button
                  variant="contained"
                  sx={{ mr: 5, textAlign: "right" }}
                  onClick={copyLink}
                  size={"small"}
                  startIcon={<ContentPasteIcon />}>
                  COPY LINK
                </Button>
              )}
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                startIcon={<BadgeIcon />}
                size={"medium"}
                color="success">
                Status
                {" " + data.status}
              </Button>
              {embarcations.length >= 3 && (
                <>
                  {data.position_applying.includes("CADET") ||
                  data.position_applying.includes("OFFICER") ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <Button
                        variant="outlined"
                        sx={{ mr: 5 }}
                        onClick={handlePromote}
                        size={"medium"}>
                        {isPromoted.status
                          ? "PROMOTED DATE: " + isPromoted.promotedDate
                          : "PROMOTE CADET"}
                      </Button>
                      {!isPromoted.status && (
                        <MobileDatePicker
                          label="Promote date"
                          inputFormat="MM/dd/yyyy"
                          value={isPromoted.promotedDate}
                          onChange={handlePromotedDate}
                          name="date_of_birth"
                          renderInput={(params) => (
                            <TextField
                              sx={{ mb: 2 }}
                              {...params}
                              variant="standard"
                            />
                          )}
                        />
                      )}
                    </Box>
                  ) : null}
                </>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}>
            <Tabs
              value={valuemain}
              onChange={handleChangeTabsmain}
              aria-label="basic tabs"
              variant="standard">
              <Tab label="PERSONAL DATA" {...a11yProps(0)} />
              <Tab label="EMBARKATIONS" {...a11yProps(1)} />
              <Tab label="ATTACHMENTS" {...a11yProps(2)} />
              <Tab label="EMERGENCY CONTACTS" {...a11yProps(3)} />
              <Tab label="MARLINS / Lenguage - Test" {...a11yProps(4)} />
              {skillArray.includes(data.position_applying) &&
              data.idproceso === "" ? (
                <Tab label="Skills" {...a11yProps(5)} />
              ) : null}
            </Tabs>

            <TabPanel value={valuemain} index={0}>
              <Grid>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItem: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}>
                  {/* PROFILE PICTURE */}
                  <UserProfilePicture
                    profileImg={data.user_selfie}
                    setProfileImg={setProfileImg}
                    previewImg={previewImg}
                    setPreviewImg={setPreviewImg}
                  />
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Full Name: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="fullname"
                      label=""
                      name="fullname"
                      autoComplete="fullname"
                      value={data.fullname?.toUpperCase()}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Gender: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="sex"
                        value={_.get(data, "sex", "M")}
                        onChange={handleChange}>
                        <FormControlLabel
                          value="F"
                          control={<Radio />}
                          label="F"
                        />
                        <FormControlLabel
                          value="M"
                          control={<Radio />}
                          label="M"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Date of Birth: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <MobileDatePicker
                      label=""
                      inputFormat="MM/dd/yyyy"
                      value={data.date_of_birth}
                      onChange={handleDateBirth}
                      name="date_of_birth"
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Nationality: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <SelectPlaceOfBirth place_of_birth={data.place_of_birth} />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Application Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">
                      {/* {data.application_date} */}

                      <MobileDatePicker
                        label=""
                        inputFormat="MM/dd/yyyy"
                        value={data.application_date}
                        onChange={handleApplicationDate}
                        name="application_date"
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" fullWidth />
                        )}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Is Panamenian: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="ispanamenian"
                        value={_.get(data, "ispanamenian", "false")}
                        onChange={handleChange}>
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Status:</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <Select
                      labelId="placeofbirthselect"
                      id="placeofbirthselect"
                      value={data.status}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="status"
                      fullWidth
                      variant="standard">
                      {statusList.map((item, index) => (
                        <MenuItem value={item.toUpperCase()} key={index}>
                          {item.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Category Vessel:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <VesselType />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Company: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <CompaniesSelect
                      changeCompany={changeCompany}
                      data={data}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Department: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <DepartmentSelect />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Email: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="email"
                      label=""
                      name="email"
                      autoComplete="email"
                      value={data.email}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Position: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <PositionSelect />
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Education level:
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <EducationLevelSelect
                      education={data.higherleveleducation}
                    />
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Phone: </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      pr: 2,
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                    }}>
                    <Box sx={{ width: "40%" }}>
                      <CountryCodeSelect />
                    </Box>
                    <TextField
                      required
                      id="phone"
                      label=""
                      name="phone"
                      autoComplete="phone"
                      value={data.phone}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                      sx={{ width: "60%" }}
                    />
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Whatsapp: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="whatsapp"
                      label=""
                      name="whatsapp"
                      autoComplete="whatsapp"
                      value={data.whatsapp}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                    comment
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Comment: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="comment"
                      label=""
                      name="comment"
                      autoComplete="comment"
                      value={data.comment}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Civil Status:</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <CivilStatusSelect />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Complete Address:
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="customeraddress"
                      label=""
                      name="customeraddress"
                      autoComplete="whatsapp"
                      value={data.customeraddress}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Nearly Airport:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="nearly_airtport"
                      label=""
                      name="nearly_airport"
                      autoComplete="nearly_airport"
                      value={data.nearly_airport}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">PMSSA #</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <Title>
                      {addToValue(
                        data["PMSSA ID NO"] ? data["PMSSA ID NO"] : data.Id
                      )}
                    </Title>
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Commences Date:</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <MobileDatePicker
                      label=""
                      inputFormat="MM/dd/yyyy"
                      onChange={handleCommencesDate}
                      value={data.contractcommencesdate}
                      name="contractcommencesdate"
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Ends Date: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <MobileDatePicker
                      label=""
                      inputFormat="MM/dd/yyyy"
                      onChange={handleEndsDate}
                      value={data.contractendsdate}
                      name="contractendsdate"
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Company Hiring Code :
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <TextField
                      required
                      id="hiringcompanycode"
                      label=""
                      name="hiringcompanycode"
                      autoComplete="hiringcompanycode"
                      value={data.hiringcompanycode}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="basic tabs"
                    variant="standard">
                    <Tab label="DOCUMENTS" {...a11yProps(0)} />
                    <Tab label="COC" {...a11yProps(1)} />
                    <Tab label="EXPERIENCE ON BOARD" {...a11yProps(2)} />
                    <Tab label="EXPERIENCE ON SHORE" {...a11yProps(3)} />
                    <Tab label="TRAINING AND CERTIFICATION" {...a11yProps(4)} />
                    <Tab label="OTHER TRAININGS" {...a11yProps(5)} />
                    <Tab label="VACCINES" {...a11yProps(6)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <DocumentsTab
                    data={data.type_document}
                    addNew={handleDocsOpen}
                    deleterecord={deleteRecords}
                    edit={editNewDocument}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <COCTable
                    data={data.COC}
                    addNew={handleCOCOpen}
                    deleterecord={deleteCOC}
                    edit={editCOC}
                    position={data.position_applying}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ExperienceOnBoard
                    data={data.experience_onboard}
                    addNew={handleExperienceBoardOpen}
                    deleterecord={deleteExperienceOnBoard}
                    edit={editexperienceonboard}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <ExperienceOnShore
                    data={data.experience_onshore}
                    addNew={handleExperienceshoreOpen}
                    deleterecord={deteleExperienceOnShore}
                    edit={editexperienceonshore}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <LicBookSTCW
                    data={data.lic_sbook_stcw_certificates}
                    addNew={handleCertificatesOpen}
                    deleterecord={deleteLicBook}
                    edit={editnewLicBook}
                  />
                </TabPanel>

                <TabPanel value={value} index={5}>
                  <OtherTrainings
                    data={data.other_training}
                    addNew={handleOtherCertOpen}
                    deleterecord={deleteOther}
                    edit={editothertrainings}
                  />
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <VaccinesTab
                    data={data.vaccination_info}
                    addNew={handleVaccineCertOpen}
                    deleterecord={deleteVaccine}
                    edit={editVaccines}
                  />
                </TabPanel>
              </Box>
            </TabPanel>
            <TabPanel value={valuemain} index={1}>
              {size(embarcations) > 0 ? (
                <Embarcations
                  data={embarcations}
                  staff={data}
                  setdata={setdata}
                />
              ) : (
                <>
                  <DirectionsBoatFilledIcon />
                  <Typography sx={{ fontWeight: "Bold" }}>
                    THIS SEAFEAR DOES NOT HAVE EMBARKATION INFO YET
                  </Typography>
                </>
              )}
            </TabPanel>
            <TabPanel value={valuemain} index={2}>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{ marginBottom: 2 }}>
                <Button variant="contained" onClick={handleOpenAttachments}>
                  ADD NEW ATTACHMENT +
                </Button>
              </Grid>

              <AttachmentTable
                data={attachmentTableData}
                marine={data}
                datos={attachmentTableData}
                setdatos={setAttachmentTableData}
                embarcations={size(embarcations) > 0 ? embarcations : []}
              />
            </TabPanel>
            <TabPanel value={valuemain} index={3}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "Bold" }}>
                    F-PMSSA-20 DESIGNATION OF THE LIFE INSURANCE BENEFICIARY
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="beneficiary"
                    label="LIFE INSURANCE BENEFICIARY"
                    name="beneficiary"
                    autoComplete="beneficiary"
                    value={data.beneficiary}
                    onChange={handleChange}
                    variant="standard"
                    fullWidth
                    placeholder="LIFE INSURANCE BENEFICIARY"
                  />
                </Grid>
              </Grid>
              <EmergencyContacts data={data} setdata={setdata} />
            </TabPanel>
            <TabPanel value={valuemain} index={4}>
              <LenguagesTab data={data} setData={setdata} />
              <MarlinsTestTab
                data={data}
                setdata={setdata}
                showToast={showToast}
              />
            </TabPanel>
            <TabPanel value={valuemain} index={5}>
              <SkillsTable
                data={data}
                position={data.position_applying}
                questions={questions}
                setQuestions={setQuestions}
                skillRecord={skillRecord}
                disabledBoxes={disabledBoxes}
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Container>

      <AddCOC
        open={showcocpanel}
        handleOpen={handleCOCOpen}
        handleClose={handleCOCClose}
        contrylistdata={contrylistdata}
        data={newcoc}
        onChange={onchangeCOC}
        handleChangeSelect={handleChangeSelect}
        contry={contry}
        handleChangeIssueDate={handleChangeIssueDate}
        handleChangeValidDate={handleChangeValidDate}
        addnew={addNewCoc}
        cocSelectOptions={cocSelectOptions}
        loading={cocloading}
        setloading={setcocloading}
        handleUpload={handleUpload}
        isedit={isedit}
        edit={saveCOC}
        position={data.position_applying}
      />
      <AddNewExperienceOnBoard
        open={showexperienceboard}
        handleOpen={handleExperienceBoardOpen}
        handleClose={handleExperienceBoardClose}
        vesseltypedetails={vesseltypedetails}
        data={newexperienceboard}
        onChange={onchangeExpBoard}
        handleChangeSelect={handleChangeSelectExperience}
        handleChangeDateoff={handleChangeDateoff}
        handleChangeDateon={handleChangeDateon}
        addnew={addNewExpOnBoard}
        loading={experienceboardloading}
        isedit={isedit}
        edit={saveExperienceOnBoard}
      />

      <AddNewExperienceOnShore
        open={showexperienceshore}
        handleOpen={handleExperienceshoreOpen}
        handleClose={handleExperienceshoreClose}
        vesseltypedetails={vesseltypedetails}
        data={newexperienceshore}
        onChange={onchangeExpShore}
        handleChangeDateoff={handleChangeDateoffShore}
        handleChangeDateon={handleChangeDateonShore}
        addnew={addNewExpOnshore}
        loading={experienceshoreloading}
        isedit={isedit}
        edit={saveExperienceOnShore}
      />

      <AddDocuments
        open={showdocpanel}
        handleOpen={handleDocsOpen}
        handleClose={handleDocClose}
        listtypeofdocument={listtypeofdocument}
        contrylistdata={contrylistdata}
        data={newdoc}
        onChange={onchangeDoc}
        handleChangeSelect={handleChangeContrySelect}
        handleChangeDoctypeSelect={handleDocChangeSelect}
        handleChangeIssueDate={handleChangeDocIssueDate}
        handleChangeValidDate={handleChangeDocValidDate}
        addnew={addNewDoc}
        loading={docloading}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        percent={percent}
        isedit={isedit}
        edit={saveDocument}
      />

      <AddTrainings
        open={showcertificates}
        handleOpen={handleCertificatesOpen}
        handleClose={handleCertificatesClose}
        list={certificatelist}
        data={newcertificate}
        handleChangeCertSelect={handleChangeCertSelect}
        handleChangeSelect={handleCountryCert}
        contrylistdata={contrylistdata}
        onChange={onchangeCert}
        handleChangeCertIssueDate={handleChangeCertIssueDate}
        handleChangeCertExpiredDate={handleChangeCertExpiredDate}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        percent={percent}
        loading={loadingcert}
        addnew={addNewCertificate}
        isedit={isedit}
        edit={saveLicBook}
      />

      <AddOtherTrainings
        open={showothercert}
        handleOpen={handleOtherCertOpen}
        handleClose={handleOtherCertClose}
        data={newothercertificate}
        handleChangeSelect={handleCountryOtherCert}
        contrylistdata={contrylistdata}
        onChange={onchangeOtherCert}
        handleChangeCertIssueDate={handleChangeOtherCertIssueDate}
        handleChangeCertExpiredDate={handleChangeOtherCertExpiredDate}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        percent={percent}
        loading={loadingcert}
        addnew={addNewOtherCertificate}
        isedit={isedit}
        edit={saveOtherCertificate}
      />
      <AddVaccines
        open={showVaccine}
        handleOpen={handleVaccineCertOpen}
        handleClose={handleVaccineCertClose}
        data={newvaccine}
        handleChangeVaccineDoze={handleChangeVaccineDoze}
        handleVaccineMark={handleVaccineMark}
        handleChangeCertIssueDate={handleChangeVaccineIssueDate}
        loading={loadingcert}
        addnew={addNewVaccine}
        isedit={isedit}
        edit={saveVaccines}
      />

      <AddAttachments
        open={showattachments}
        handleClose={handleCloseAttachments}
        data={newattachments}
        onChange={onchangeAttachments}
        addnew={addNewAttachments}
        loading={loadingcert}
        handleFileChange={handleFileChange}
        isedit={false}
        attachmentlist={attachmentlist}
        marine={data}
        embarcations={embarcations}
      />

      <LoadingProgress loading={loadingprogress} />

      <ToastContainer />
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
