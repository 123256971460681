import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import SettingsIcon from "@mui/icons-material/Settings";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";

export const menu = [
  {
    icon: <DonutSmallIcon />,
    title: "Reports",
    items: [
      {
        title: "Crew Status",
        to: "/crewstatus",
      },
      {
        title: "Approved & Dissapproved",
        to: "/approveddissaproved",
      },
      {
        title: "Seaferers",
        to: "/seafers",
      },
      {
        title: "Company",
        to: "/companyreports",
      },
      {
        title: "Complaints",
        to: "/complaints",
      },
      {
        title: "Mitradel",
        to: "/mitradel",
      },
      {
        title: "Pending Update",
        to: "/pendingupdatereport",
      },
    ],
  },
];

export const settingsmenu = [
  {
    icon: <SettingsIcon />,
    title: "Settings",
    items: [
      {
        title: "Users",
        to: "/users",
      },
      {
        title: "Companies",
        to: "/companies",
      },
    ],
  },
];

export const embarkationmenu = [
  {
    icon: <DirectionsBoatIcon />,
    title: "Embarkations",
    items: [
      {
        title: "List",
        to: "/embarkationlist",
      },
      {
        title: "Graph",
        to: "/embarkationreport",
      },
    ],
  },
];
