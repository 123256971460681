import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Chart from "../Dashboard/Chart";
import ChartStatus from "../Dashboard/ChartStatus";
import Deposits from "../Dashboard/Deposits";

import {
  loadPositionsRequest,
  requestpersonalstatus,
  getGapPool,
} from "../../Actions/Actions";
import _, { size } from "lodash";
import CircularPanel from "../Dashboard/CircularPanel";
import CircularPanelV2 from "../Dashboard/CircularPanelV2";
import LoadingProgress from "../Commons/LoadingProgress";

const mdTheme = createTheme();

export default function Graph() {
  const [open, setOpen] = React.useState(true);
  const [listado, setlistado] = useState([]);
  const [datachart, setdatachart] = useState([]);
  const [loading, setloading] = useState(false);

  const [total, settotal] = useState(0);

  useEffect(() => {
    const loadResults = async () => {
      await initialLoading();
    };
    loadResults();
  }, []);

  const initialLoading = async () => {
    setloading(true);
    const graphstatus = await requestpersonalstatus();
    const positions = await loadPositionsRequest();
    const resultado = await getGapPool();
    let dararesult = [];
    resultado.map((item) => {
      let dato = {
        ...item,
        company:
          item.company === ""
            ? "NO COMPANY ASSIGN"
            : item.company.trim().toUpperCase(),
      };
      dararesult.push(dato);
    });

    console.log("RESULTADO");

    let datagraph = resultado.sort(comparecompany);
    let groupcompany = groupBy(dararesult, "company");
    let resultadofinal = [];
    let claves = Object.keys(groupcompany); // claves = ["nombre", "color", "macho", "edad"]
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      resultadofinal.push({ id: clave, total: size(groupcompany[clave]) });
    }

    console.log("RESULTADO FINALFINAL");
    console.log(resultadofinal);

    settotal(size(resultadofinal));
    setdatachart(resultadofinal);
    setlistado(positions);
    setloading(false);
  };

  let groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  function comparecompany(a, b) {
    if (a.company.trim().toUpperCase() < b.company.trim().toUpperCase()) {
      return -1;
    }
    if (a.company.trim().toUpperCase() > b.company.trim().toUpperCase()) {
      return 1;
    }
    return 0;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
        }}>
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
          <Grid item sx={{ mb: 2 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <Deposits total={total} title="COMPANIES" />
                </Paper>
              </Grid>
              {/* Recent Orders */}
            </Paper>
          </Grid>
          <Grid container spacing={3}>
            {/* Chart */}

            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}>
                {size(datachart) > 0 && (
                  <CircularPanelV2
                    data={datachart}
                    title="Companies vs number of Sailors"
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <LoadingProgress loading={loading} />
    </Box>
  );
}
