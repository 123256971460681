import { StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";

const BasicInfo = ({ names, person }) => {
  const [leng, setLeng] = useState([]);

  useEffect(() => {
    if (person.languages !== "") {
      setLeng(JSON.parse(person.languages));
    }
  }, []);

  return (
    <View style={{ width: "100%" }}>
      <View style={[styles.row, { marginTop: 5 }]}>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          First Name:
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "30%" }]}>
          {names[0]}
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "50%", borderRight: 0.5 },
          ]}>
          Complete home Address:
        </Text>
      </View>
      <View style={[styles.row]}>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          Middle Name:
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "30%" }]}>
          {names[1]}
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "50%", borderRight: 0.5 },
          ]}>
          {person.customeraddress}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          Surnames:
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "30%" }]}>
          {names[2] === undefined ? "" : names[2]}{" "}
          {names[3] === undefined ? "" : names[3]}
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          Phone / Cell:
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "30%", borderRight: 0.5, borderColor: "black" },
          ]}>
          +{person.countrycode} {person.phone}
        </Text>
      </View>
      <View style={styles.row}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%", height: 40 },
          ]}>
          Date of Birth:
        </Text>
        <View style={[styles.cell, { width: "30%", height: 40 }]}>
          <Text style={[styles.normalTableText, { borderBottom: 0.5 }]}>
            (YYYY/MM/DD)
          </Text>
          <Text style={styles.normalTableText}>{person.date_of_birth}</Text>
        </View>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%", height: 40 },
          ]}>
          Nearly Airport:
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "30%", borderRight: 0.5, height: 40 },
          ]}>
          {person.nearly_airport}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.normalTableText, { width: "15%" }]}>
          Nationality:
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "30%" }]}>
          {person.place_of_birth}
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "15%" }]}>
          E-mail:
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "40%", borderRight: 0.5 },
          ]}>
          {person.email}
        </Text>
      </View>
      <View style={styles.row}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%", height: 45 },
          ]}>
          Civil Status:
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%", height: 45 },
          ]}>
          {person.civilstatus}
        </Text>
        <View
          style={[
            styles.verticalCell,
            styles.cell,
            { width: "10%", height: 45 },
          ]}>
          <Text style={[styles.normalTableText, { borderBottom: 0.5 }]}>
            Sex
          </Text>
          <Text style={[styles.normalTableText]}>{person.sex}</Text>
        </View>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%", height: 45 },
          ]}>
          Whatsapp
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "30%", borderRight: 0.5, height: 45 },
          ]}>
          {person.whatsapp}
        </Text>
      </View>
      {/* LENGUAGES */}
      <View style={[styles.row, { borderBottom: 0.5 }]}>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          Lenguages:
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "10%" }]}>
          {leng[0] ? leng[0].Languages : ""}
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "5%" }]}>
          {leng[0] ? leng[0]["%"] : ""}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "10%" }]}>
          {leng[1] ? leng[1].Languages : ""}
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "5%" }]}>
          {leng[1] ? leng[1]["%"] : ""}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          Others:
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "15%" }]}>
          {person.otherlanguajes}
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%", borderRight: 0.5 },
          ]}>
          {person.percentagelanguajes}%
        </Text>
      </View>
      <View style={styles.row}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "100%", borderRight: 0.5 },
          ]}>
          MARLINS / Language -Test
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%" }]}>
          Total %
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%" }]}>
          Listening %
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%" }]}>
          Grammar %
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%" }]}>
          Vocabulary %
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "28%", borderRight: 0.5 },
          ]}>
          Issue Date
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%" }]}>
          {person.mt_total}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%" }]}>
          {person.mt_listening}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%" }]}>
          {person.mt_grammar}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%" }]}>
          {person.mt_vocabulary}%
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "28%", borderRight: 0.5 },
          ]}>
          {moment(person.mt_issuedate).format("L")}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          Time and Numbers %
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          Reading %
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "60%", borderRight: 0.5 },
          ]}>
          Place of Issue
        </Text>
      </View>
      <View style={[styles.row, { borderBottom: 0.5 }]}>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          {person.mt_timeandnumber}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
          {person.mt_reading}%
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "60%", borderRight: 0.5 },
          ]}>
          {person.mt_placeofissue}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
  topSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  firstTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  icon: {
    width: 70,
    height: 90,
    borderWidth: 0.5,
    borderColor: "black",
  },
  row: {
    borderTop: 0.5,
    borderColor: "black",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    displau: "flex",
    borderLeft: 0.5,
    borderColor: "black",
    textAlign: "center",
    minHeight: 23,
  },
  normalTableText: {
    fontSize: 10,
    paddingTop: 5,
  },
  verticalCell: {
    display: "flex",
  },
});

export default BasicInfo;
