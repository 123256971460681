import { Box, Button, TableCell } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import AddEmergencyContacts from "../DataViewComponents/AddEmergencyContacts";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const EmergencyContacts = ({ data, setdata }) => {
  const [rowData, setRowData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState({ id: undefined, isEdit: false });
  const [newContact, setNewContact] = useState({
    Name: "",
    "Middle name": "",
    Surnames: "",
    Relationship: "",
    Address: "",
    Phone: "",
  });

  useEffect(() => {
    console.log(data.emergencycontact);
    if (data.emergencycontact !== "") {
      const contacts = JSON.parse(data.emergencycontact);
      contacts.forEach((item, index) => {
        return (item.id = index);
      });
      console.log("contacst", contacts);
      setRowData(contacts);
    }
  }, [data]);

  const handleModalOpen = () => {
    setNewContact({
      Name: "",
      "Middle name": "",
      Surnames: "",
      Relationship: "",
      Address: "",
      Phone: "",
    });
    setShowModal(true);
  };

  const handleModalClose = () => {
    setIsEdit({ id: undefined, isEdit: false });
    setShowModal(false);
  };

  const handleEditContact = () => {
    let newData = [...rowData];
    newData[isEdit.id] = newContact;
    let newContacts = JSON.stringify(newData);
    setdata({ ...data, emergencycontact: newContacts });
    handleModalClose();
  };

  const handleChangeData = (newContact) => {
    let newData = [...rowData];
    newData.push(newContact);
    let newContacts = JSON.stringify(newData);
    setdata({ ...data, emergencycontact: newContacts });
    setNewContact({
      Name: "",
      "Middle name": "",
      Surnames: "",
      Address: "",
      Relationship: "",
      Phone: "",
    });
    handleModalClose();
  };

  const handleDeleteContact = (id) => {
    let dataList = [...rowData];
    dataList.splice(id, 1);
    let newContacts = JSON.stringify(dataList);
    setdata({ ...data, emergencycontact: newContacts });
  };

  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      width: 70,
      renderCell: (params) => (
        <TableCell>
          <ModeEditIcon
            variant="contained"
            color="primary"
            onClick={() => {
              let contact = rowData[params.row.id];
              setShowModal(true);
              setNewContact(contact);
              setIsEdit({ id: params.row.id, isEdit: true });
              console.log(params.row.id);
            }}
          />
        </TableCell>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 70,
      renderCell: (params) => (
        <TableCell>
          <DeleteIcon
            variant="contained"
            color="primary"
            onClick={() => {
              let resp = window.confirm("Do you want delete this contact?");
              if (resp) {
                handleDeleteContact(params.row.id);
              }
            }}
          />
        </TableCell>
      ),
    },
    {
      field: "Name",
      headerName: "First name",
      width: 150,
      editable: false,
    },
    {
      field: "Middle name",
      headerName: "Middle name",
      sortable: false,
      width: 150,
    },
    {
      field: "Surnames",
      headerName: "Surnames",
      sortable: false,
      width: 150,
    },
    {
      field: "Address",
      headerName: "Address",
      sortable: false,
      width: 200,
    },
    {
      field: "Relationship",
      headerName: "Relationship",
      sortable: false,
      width: 150,
    },
    {
      field: "Phone",
      headerName: "Phone",
      sortable: false,
      width: 150,
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          variant="contained"
          onClick={handleModalOpen}
          sx={{ marginLeft: "auto" }}>
          ADD +
        </Button>
      </Box>
      <Box sx={{ height: "100vh", width: "100%", marginTop: 3 }}>
        <DataGrid rows={rowData} columns={columns} />
      </Box>

      <AddEmergencyContacts
        open={showModal}
        handleOpen={handleModalOpen}
        handleClose={handleModalClose}
        handleChangeData={handleChangeData}
        isEdit={isEdit}
        newContact={newContact}
        setNewContact={setNewContact}
        edit={handleEditContact}
      />
    </>
  );
};

export default EmergencyContacts;
