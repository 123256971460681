import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { getData, getGapPool } from "../../Actions/Actions";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { escapeRegExp, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Button, Checkbox, TextField, Typography } from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { createStyles, makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import * as XLSX from "xlsx";

export const ExpiredDocuments = (props) => {
	const [checked, setChecked] = React.useState(false);
	const [data, setdata] = useState([]);
	const [backData, setBackData] = useState([]);
	const [time, settime] = React.useState(12);
	const [alldocuments, setalldocuments] = useState([]);
	const [loading, setloading] = useState(false);
	const [searchText, setSearchText] = useState("");

	const handleSliderChange = (event, newValue) => {
		settime(newValue);
		let total = alldocuments.filter(
			(item) => item.months <= time && item.status !== "CONTRACT REVOKED"
		);
		if (!checked) {
			let newTotal = alldocuments.filter((item) => item.months > 0);
			total.sort((a, b) => a.months - b.months);
			setdata(newTotal);
			return;
		}
		total.sort((a, b) => a.months - b.months);
		setdata(total);
	};

	const columns = [
		{
			field: "pmssa",
			headerName: "#",
			width: 20,
		},
		{
			field: "fullname",
			headerName: "Full Name",
			width: 300,
		},

		{
			field: "doctype",
			headerName: "Doc Type",
			width: 180,
		},
		{
			field: "description",
			headerName: "Description",
			width: 250,
		},
		// {
		//   field: "contryissue",
		//   headerName: "Country Issue",
		//   width: 150,
		// },
		// {
		//   field: "issueatplace",
		//   headerName: "Issue At Place",
		//   width: 170,
		// },

		// {
		//   field: "number",
		//   headerName: "Number",
		//   width: 100,
		// },

		// {
		//   field: "dateofissue",
		//   headerName: "Date of Issue",
		//   width: 110,
		// },
		{
			field: "expirydate",
			headerName: "Expiry Date",
			width: 130,
		},
		{
			field: "months",
			headerName: "Status",
			width: 150,
			renderCell: (params) => <DrawerStatus params={params} />,
			sortable: true,
		},
		{
			field: "status",
			headerName: "Status",
			width: 150,
		},
		{
			field: "id",
			headerName: "Acciones",
			width: 120,
			renderCell: (params) => (
				<Link
					to={{
						pathname: `/updatepersonalinfo?id=${params.row.pmssa}`,
						state: { id: params.row.pmssa },
					}}
					reloadDocument>
					<EditIcon style={{ fontSize: 25 }} color="primary" />
				</Link>
			),
			sortable: false,
		},
	];

	const handleDownloadXlsx = () => {
		let excelData = data.map((item) => {
			delete item.id;
			delete item.grade;
			return item;
		});
		const worksheet = XLSX.utils.json_to_sheet(excelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
		XLSX.utils.sheet_add_aoa(
			worksheet,
			[
				[
					"PMSSA",
					"Status",
					"Full name",
					"Document type",
					"Description",
					"Country of issue",
					"Issue at place",
					"Number",
					"Date of issue",
					"Expiry date",
					"Months",
				],
			],
			{
				origin: "A1",
			}
		);
		const max_width = data.reduce((w, r) => Math.max(w, r.fullname.length), 10);
		worksheet["!cols"] = [{ wch: max_width }];
		XLSX.writeFile(workbook, "Expiring documents.xlsx");
	};

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	useEffect(() => {
		if (!checked) {
			let total = alldocuments.filter(
				(item) =>
					item.months <= time &&
					item.status !== "CONTRACT REVOKED" &&
					item.months > 0
			);
			total.sort((a, b) => a.months - b.months);
			setdata(total);
			return;
		}
		let total = alldocuments.filter(
			(item) => item.months <= time && item.status !== "CONTRACT REVOKED"
		);
		total.sort((a, b) => a.months - b.months);
		setdata(total);
	}, [checked]);

	const DrawerStatus = ({ params }) => {
		if (Number(params.row.months) < 0) {
			return (
				<>
					<NewReleasesIcon sx={{ color: "red" }} />
					<Typography>Expired</Typography>
				</>
			);
		} else if (params.row.months === null) {
			return (
				<>
					<MoreHorizIcon sx={{ color: "gray" }} />
					<Typography>Empty</Typography>
				</>
			);
		} else if (Number(params.row.months) <= time) {
			return (
				<>
					<VisibilityIcon sx={{ color: "orange" }} />
					<Typography> {params.row.months} M to expire</Typography>
				</>
			);
		} else {
			return (
				<>
					<CheckCircleIcon sx={{ color: "green" }} />
					<Typography> {params.row.months} M to expire</Typography>
				</>
			);
		}
	};

	useEffect(() => {
		const loadResults = async () => {
			setloading(true);
			let documentlist = [];
			const resultado = await getGapPool();
			console.log("res", resultado);
			resultado.map((item) => {
				addDocs(item, documentlist);
				addCOC(item, documentlist);
				addtrainings(item, documentlist);
				//	addothertrainings(item, documentlist);
			});

			let total = documentlist.filter(
				(item) =>
					item.months <= time &&
					item.months > 0 &&
					item.status !== "CONTRACT REVOKED"
			);

			total.sort((a, b) => a.months - b.months);

			console.log("newTotal", total);

			documentlist.sort(compare);
			setalldocuments(documentlist);
			setBackData(total);
			setdata(total);
			setloading(false);
		};

		loadResults();
	}, []);

	function compare(a, b) {
		if (a.months < b.months) {
			return -1;
		}
		if (a.months > b.months) {
			return 1;
		}
		return 0;
	}

	const addDocs = async (item, documentlist) => {
		if (!isEmpty(item.type_document)) {
			let typedocuments = JSON.parse(item.type_document);
			typedocuments.map((documents) => {
				let dato = {
					id: uuidv4(),
					pmssa: item.Id,
					status: item.status,
					fullname: item.fullname,
					doctype: "PERSONAL DOCUMENT",
					description: documents["Type of Document"],
					contryissue: documents["Country of Issue"],
					issueatplace: documents["Issue at (place)"],
					number: documents["No."],
					dateofissue: documents["Date of Issue"],
					expirydate: documents["Valid Until"],
					months: !isEmpty(documents["Valid Until"])
						? getMonthDifference(new Date(), new Date(documents["Valid Until"]))
						: null,
				};
				documentlist.push(dato);
			});
		}
	};
	const addCOC = async (item, documentlist) => {
		if (!isEmpty(item.COC)) {
			let typedocuments = JSON.parse(item.COC);
			typedocuments.map((documents) => {
				let dato = {
					id: uuidv4(),
					pmssa: item.Id,
					fullname: item.fullname,
					status: item.status,
					doctype: "COC",
					description: "COC",
					contryissue: documents["Country of issue"],
					issueatplace: documents["Issue at (place)"],
					number: documents["No."],
					dateofissue: documents["Date of issue"],
					expirydate: documents["Valid until"],
					months: !isEmpty(documents["Valid until"])
						? getMonthDifference(new Date(), new Date(documents["Valid until"]))
						: null,
				};
				documentlist.push(dato);
			});
		}
	};

	const addtrainings = async (item, documentlist) => {
		if (!isEmpty(item.lic_sbook_stcw_certificates)) {
			let typedocuments = JSON.parse(item.lic_sbook_stcw_certificates);
			typedocuments.map((documents) => {
				let dato = {
					id: uuidv4(),
					pmssa: item.Id,
					fullname: item.fullname,
					status: item.status,
					doctype: "TRAINING AND CERTIFICATION",
					description: documents["Description of cert / course"],
					contryissue: documents["Country of issue"],
					issueatplace: documents["Country of issue"],
					number: documents["Number"],
					dateofissue: documents["Date of issue"],
					expirydate: documents["Date of expiry"],
					months: !isEmpty(documents["Date of expiry"])
						? getMonthDifference(
								new Date(),
								new Date(documents["Date of expiry"])
						  )
						: null,
				};
				documentlist.push(dato);
			});
		}
	};
	const addothertrainings = async (item, documentlist) => {
		if (!isEmpty(item.other_training)) {
			let typedocuments = JSON.parse(item.other_training);
			typedocuments.map((documents) => {
				let dato = {
					id: uuidv4(),
					pmssa: item.Id,
					fullname: item.fullname,
					status: item.status,
					doctype: "OTHER TRAININGS",
					description:
						documents["Name of education institution / tech. institute"],
					grade: documents["Obtained title or grade"],
					dateofissue: documents["Date on"],
					expirydate: documents["Date off"],
					months: !isEmpty(documents["Date of expiry"])
						? getMonthDifference(
								new Date(),
								new Date(documents["Date of expiry"])
						  )
						: null,
				};
				documentlist.push(dato);
			});
		}
	};

	function getMonthDifference(startDate, endDate) {
		return (
			endDate.getMonth() -
			startDate.getMonth() +
			12 * (endDate.getFullYear() - startDate.getFullYear())
		);
	}

	const marks = [
		{
			value: 6,
			label: "6 M",
		},
		{
			value: 12,
			label: "12 M",
		},
		{
			value: 18,
			label: "18 M",
		},
		{
			value: 24,
			label: "24 M",
		},
	];

	function valuetext(value) {
		return `${value} M`;
	}

	const requestSearch = async (searchValue) => {
		if (searchValue === "") {
			let total = [...backData];
			if (!checked) {
				let newTotal = alldocuments.filter((item) => item.months > 0);
				total.sort((a, b) => a.months - b.months);
				setdata(newTotal);
				setSearchText(searchValue);
				return;
			}
			setSearchText(searchValue);
			setdata(backData);
		} else {
			let total = [...backData];
			if (!checked) {
				let newTotal = alldocuments.filter((item) => item.months > 0);
				total.sort((a, b) => a.months - b.months);
				const filteredRows = newTotal.filter((item) => {
					return item.fullname
						.toUpperCase()
						.includes(searchValue.toUpperCase());
				});
				setdata(filteredRows);
				setSearchText(searchValue);
				return;
			}
			setSearchText(searchValue);
			const filteredRows = backData.filter((item) => {
				return item.fullname.toUpperCase().includes(searchValue.toUpperCase());
			});
			setdata(filteredRows);
		}
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
				<Grid item xs={12} md={8} lg={9}>
					<Paper
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							height: "100vh",
						}}>
						<Title>Expired Documents</Title>
						<Box sx={{ width: 300 }}>
							<Slider
								aria-label="Time"
								defaultValue={6}
								getAriaValueText={valuetext}
								step={1}
								marks={marks}
								valueLabelDisplay="on"
								onChange={handleSliderChange}
								onChangeCommitted={handleSliderChange}
								value={typeof time === "number" ? time : 0}
								max={24}
							/>
						</Box>
						<DataGrid
							rows={data}
							columns={columns}
							autoPageSize={true}
							sx={{
								boxShadow: 2,
								display: "flex",
							}}
							components={{
								Toolbar: CustomToolbar,
							}}
							componentsProps={{
								toolbar: {
									value: searchText,
									onChange: (event) => {
										requestSearch(event.target.value);
									},
									clearSearch: () => requestSearch(""),
									handleDownloadXlsx,
									checked,
									handleChange,
								},
								pagination: { classes: null },
							}}
							loading={loading}
						/>
					</Paper>
				</Grid>
			</Container>
		</Box>
	);
};

function CustomToolbar(props) {
	const classes = useStyles();
	return (
		<GridToolbarContainer>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					ml: 2,
				}}>
				<Typography variant="body1" color="initial">
					Show expired documents:
				</Typography>
				<Checkbox
					checked={props.checked}
					onChange={props.handleChange}
					inputProps={{ "aria-label": "controlled" }}
				/>
			</Box>
			<Button
				sx={{ marginLeft: "auto" }}
				onClick={() => props.handleDownloadXlsx()}>
				Download as xlsx
			</Button>
			<TextField
				variant="standard"
				value={props.value}
				onChange={props.onChange}
				placeholder="Search…"
				className={classes.textField}
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: props.value ? "visible" : "hidden" }}
							onClick={props.clearSearch}>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</GridToolbarContainer>
	);
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				padding: theme.spacing(0.5, 0.5, 0),
				justifyContent: "space-between",
				display: "flex",
				alignItems: "flex-start",
				flexWrap: "wrap",
			},
			textField: {
				[theme.breakpoints.down("xs")]: {
					width: "100%",
				},
				margin: theme.spacing(1, 0.5, 1.5),
				"& .MuiSvgIcon-root": {
					marginRight: theme.spacing(0.5),
				},
				"& .MuiInput-underline:before": {
					borderBottom: `1px solid ${theme.palette.divider}`,
				},
			},
		}),
	{ defaultTheme }
);

export default ExpiredDocuments;
