import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PersonIcon from "@mui/icons-material/Person";
import Chart from "./Chart";
import ChartStatus from "./ChartStatus";
import Deposits from "./Deposits";

import {
  loadPositionsRequest,
  requestpersonal,
  loadVesselType,
  loadDepartments,
  requestpersonalstatus,
  getGapPool,
} from "../../Actions/Actions";
import _, { isEmpty, set, size } from "lodash";
import CircularPanel from "./CircularPanel";
import CircularPanelComplete from "./CircularPanelComplete";
import LoadingProgress from "../Commons/LoadingProgress";
import moment from "moment";
import Typography from "@mui/material/Typography";
import Title from "../Commons/Title";
import { DataGrid } from "@mui/x-data-grid";
import ExpiringDocsModal from "./ExpiringDocsModal";
import ExportReportBtn from "../Commons/ExportReportBtn";

const mdTheme = createTheme();

export default function Graph() {
  const [gapPoolData, setGapPoolData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [listado, setlistado] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [expiredList, setExpiredList] = useState([]);
  const [datachart, setdatachart] = useState([]);
  const [datachartstatus, setdatachartstatus] = useState([]);
  const [loading, setloading] = useState(false);
  const [vessels, setVessels] = useState({});

  const [total, settotal] = useState(0);

  useEffect(() => {
    const loadResults = async () => {
      await initialLoading();
    };
    loadResults();
  }, []);

  useEffect(() => {
    const loadResults = async () => {
      let expiredList = [];
      await getGapPool().then((res) => {
        res.forEach((staff) => {
          let expiredCount = 0;
          const docCount = expiredDocuments(staff.type_document, "Valid Until");
          const cocCount = expiredDocuments(staff.COC, "Valid Until");
          // const otherTrainingCount = expiredDocuments(
          //   staff.other_training,
          //   "Date off"
          // );
          const licSbookCount = expiredDocuments(
            staff.lic_sbook_stcw_certificates,
            "Date of expiry"
          );
          expiredCount = licSbookCount.count + cocCount.count + docCount.count;
          // otherTrainingCount.count;
          if (expiredCount === 0) return;
          expiredList.push({
            fullname: staff.fullname,
            expiredDocuments: expiredCount,
            id: staff.id,
            docs: [
              ...docCount.docs,
              ...cocCount.docs,
              //	...otherTrainingCount.docs,
              //	...otherTrainingCount.docs,
              ...licSbookCount.docs,
            ],
          });
        });

        setExpiredList(expiredList);
      });
    };
    loadResults();
  }, []);

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const expiredDocuments = (documents, checkValue) => {
    if (documents === "") return { count: 0, docs: [] };
    let docs = [];
    let count = 0;
    let docList = JSON.parse(documents);
    docList.forEach((doc) => {
      if (doc[checkValue] === "") return;
      let diff = getMonthDifference(new Date(), new Date(doc[checkValue]));
      if (diff <= 12) {
        docs.push(doc);
        count += 1;
      }
    });
    return { count, docs };
  };

  const initialLoading = async () => {
    setloading(true);
    const resultado = await requestpersonal();
    const graphstatus = await requestpersonalstatus();
    const positions = await loadPositionsRequest();
    const gapool = await getGapPool();
    let chart = [];
    let chartstatus = [];
    let set_total = 0;

    if (size(resultado) > 0) {
      resultado.map((item) => {
        let data = {
          id: item.department.toUpperCase() + "_" + item.position.toUpperCase(),
          total: item.total,
        };
        set_total = set_total + Number(item.total);
        chart.push(data);
      });
    }
    setGapPoolData(gapool);
    handleVessels(gapool);
    settotal(set_total);
    setdatachart(chart);
    setlistado(positions);
    setdatachartstatus(graphstatus);
    setloading(false);
  };

  const handleVessels = (gapoolData) => {
    const merchant = [];
    const passenger = [];
    let empty = 0;
    gapoolData.forEach((item) => {
      if (!item.category_vessel) empty++;
      if (item.category_vessel === "Merchant vessel") merchant.push(item);
      if (item.category_vessel === "Passenger vessel") passenger.push(item);
    });
    const uniqueMerchants = {};
    const uniquePassenger = {};
    merchant.forEach((item) => {
      const combination =
        item.department?.toUpperCase() +
        "_" +
        item.position_applying?.toUpperCase();
      uniqueMerchants[combination] = (uniqueMerchants[combination] || 0) + 1;
    });
    passenger.forEach((item) => {
      const combination =
        item.department?.toUpperCase() +
        "_" +
        item.position_applying?.toUpperCase();
      uniquePassenger[combination] = (uniquePassenger[combination] || 0) + 1;
    });
    setVessels({
      merchantData: getArrayData(uniqueMerchants),
      passengerData: getArrayData(uniquePassenger),
      emptyData: empty,
    });
  };

  const getArrayData = (uniqueCombinations) => {
    const combinationsArray = Object.keys(uniqueCombinations).map(
      (combination) => ({
        id: combination,
        total: uniqueCombinations[combination],
      })
    );
    return combinationsArray;
  };

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 20,
    },
    {
      field: "fullname",
      headerName: "Full name",
      width: 300,
    },
    {
      field: "expiredDocuments",
      headerName: "Expiring documents",
      width: 150,
    },
    {
      field: "details",
      headerName: "More details",
      width: 100,
      renderCell: (params) => (
        <PersonIcon
          style={{ fontSize: 25 }}
          color="primary"
          onClick={() => {
            setModalData(params.row);
            setOpenModal(true);
          }}
        />
      ),
      sortable: false,
    },
  ];

  const handleClose = () => {
    setOpenModal(false);
  };

  const getCsvData = () => {
    const csvData = [];
    const headers = ["#", "Full name", "Expiring documents"];
    expiredList.forEach((item) => {
      csvData.push({
        id: item.id,
        fullName: item.fullname,
        documents: item.expiredDocuments,
      });
    });
    return [headers, csvData];
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
        }}>
        <Toolbar />
        <ExpiringDocsModal
          open={openModal}
          handleClose={handleClose}
          data={modalData}
        />
        <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
          <Grid item sx={{ mb: 2 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <Deposits total={total} />
                </Paper>
              </Grid>
              {/* Recent Orders */}
            </Paper>
          </Grid>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              mb: 2,
            }}>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={6} md={4} lg={4}>
                <Title>Merchant Vessel</Title>
                <Typography component="p" variant="h4">
                  #
                  {gapPoolData.reduce((accumulator, obj) => {
                    if (obj.category_vessel === "Merchant vessel") {
                      return accumulator + 1;
                    }
                    return accumulator;
                  }, 0)}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <Title>Passenger Vessel</Title>
                <Typography component="p" variant="h4">
                  #
                  {gapPoolData.reduce((accumulator, obj) => {
                    if (obj.category_vessel === "Passenger vessel") {
                      return accumulator + 1;
                    }
                    return accumulator;
                  }, 0)}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <Title>Undefined Vessels</Title>
                <Typography component="p" variant="h4">
                  #
                  {gapPoolData.reduce((accumulator, obj) => {
                    if (obj.category_vessel === "") {
                      return accumulator + 1;
                    }
                    return accumulator;
                  }, 0)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 2.5,
                }}>
                <Title>DOCUMENTS EXPIRING IN 12 MONTHS</Title>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography component="p" variant="h4">
                    #
                    {expiredList.reduce((total, item) => {
                      return total + item.expiredDocuments;
                    }, 0)}
                  </Typography>
                  <ExportReportBtn getData={getCsvData} />
                </Box>
                <Box sx={{ height: "50vh", marginTop: 2 }}>
                  <DataGrid
                    rows={expiredList}
                    columns={columns}
                    pageSize={20}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}>
                <CircularPanel data={datachartstatus} title="STAFF BY STATUS" />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}>
                <CircularPanelComplete
                  data={datachart}
                  title="STAFF BY DEPARTMENT AND POSITIONS"
                  vessels={vessels}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <LoadingProgress loading={loading} />
    </Box>
  );
}
