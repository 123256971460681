import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getEmbarkations, loadCompanies } from "../../Actions/Actions";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
} from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import {
	Button,
	TextField,
	Typography,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { isEmpty, size } from "lodash";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
const columns = [
	{
		field: "company",
		headerName: "Company",
		width: 150,
		editable: true,
	},
	{
		field: "fullname",
		headerName: "Name",
		width: 340,
		editable: true,
	},
	{
		field: "status",
		headerName: "STATUS",
		renderCell: (params) => <DrawOnBoard params={params} />,

		width: 150,
		editable: true,
	},
	{
		field: "VesselName",
		headerName: "Vessel Name",
		width: 170,
		editable: true,
	},
	{
		field: "signondate",
		headerName: "SIGN ON DATE",
		width: 110,
		editable: true,
		resizable: true,
	},

	// {
	// 	field: "transfernewvessel",
	// 	headerName: "New Vessel",
	// 	width: 150,
	// 	editable: true,
	// },
	{
		field: "signoffdate",
		headerName: "SIGN OFF DATE",
		width: 120,
		editable: true,
		renderCell: (params) => (
			<Typography style={{ overflowWrap: "break-word", size: 10 }}>
				{params.value}
			</Typography>
		),
		resizable: true,
	},

	{
		field: "doareturndate",
		headerName: "DAO RETURN DATE",
		width: 160,
	},
	{
		field: "signoffreason_detail",
		headerName: "SIGN OFF DETAILS",
		width: 150,
		editable: true,
	},
	{
		field: "elegible_toreturn",
		headerName: "ELEGIBLE TO RETURN?",
		renderCell: (params) => <DraweElegible params={params} />,
		width: 200,
		editable: true,
	},

	{
		field: "id",
		headerName: "Id",
		width: 100,
		hide: true,
	},
];

const DraweElegible = ({ params }) => {
	let value = params.row.elegible_toreturn;
	if (value) {
		if (value === "true") {
			return (
				<>
					<CheckCircleIcon sx={{ color: "green" }} />
				</>
			);
		} else {
			return (
				<>
					<ReportOffIcon sx={{ color: "red" }} />
				</>
			);
		}
	} else {
		return <Typography>{value}</Typography>;
	}
};

const DrawOnBoard = ({ params }) => {
	let value = params.row.status;
	if (value === "COMPLETED") {
		return (
			<>
				<Typography sx={{ fontWeight: "bold", color: "green" }}>
					COMPLETED
				</Typography>
			</>
		);
	} else {
		return (
			<>
				<DirectionsBoatIcon />
				<Typography sx={{ fontWeight: "bold", color: "primary" }}>
					ON BOARD
				</Typography>
			</>
		);
	}
};

const EmbarcationList = () => {
	const [data, setdata] = useState([]);
	const [backData, setBackData] = useState([]);
	const [searchText, setSearchText] = React.useState("");
	const [loading, setloading] = useState(false);
	const [companyfilter, setcompanyfilter] = useState("");
	const [puredata, setpuredata] = useState([]);
	const [companylist, setcompanylist] = React.useState([]);
	const [signonfilter, setsignonfilter] = useState("");
	const [signofffilter, setsignofffilter] = useState("");
	const userdata = JSON.parse(sessionStorage.getItem("userdata"));
	let { rol, company } = userdata;

	const requestSearch = async (searchValue) => {
		if (searchValue === "") {
			setSearchText(searchValue);
			setdata(backData);
		} else {
			setSearchText(searchValue);
			const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
			const filteredRows = backData.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field].toString());
				});
			});
			const upper = filteredRows.map((item) => {
				return { ...item, fullname: item.fullname.toUpperCase() };
			});
			setdata(upper);
		}
	};

	const handleDownloadXlsx = () => {
		console.log("data", data);
		let newData = data.map((item) => {
			return {
				company: item.company,
				fullname: item.fullname,
				status: item.status,
				VesselName: item.VesselName,
				signondate: item.signondate,
				signoffdate: item.signoffdate,
				doareturndate: item.doareturndate,
				signoffreason_detail: item.signoffreason_detail,
				elegible_toreturn: item.elegible_toreturn,
			};
		});
		const worksheet = XLSX.utils.json_to_sheet(newData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Embarkations");
		XLSX.utils.sheet_add_aoa(
			worksheet,
			[
				[
					"Company",
					"Full name",
					"Status",
					"Vessel name",
					"Sign on date",
					"Sign off date",
					"Dao return date",
					"Sign off details",
					"Elegible to return",
				],
			],
			{
				origin: "A1",
			}
		);
		const max_width = data.reduce((w, r) => Math.max(w, r.fullname.length), 10);
		worksheet["!cols"] = [{ wch: max_width }];
		XLSX.writeFile(workbook, "Embarkation list.xlsx");
	};

	useEffect(() => {
		const loadData = async () => {
			let embarcationlist = await getEmbarkations();
			let companies = await loadCompanies();
			console.log("EMBARCACIONES", embarcationlist);
			embarcationlist = embarcationlist.filter(
				(item) => !isEmpty(item.signondate)
			);
			embarcationlist.map((item) => {
				item.id = item.Id;
				item.fullname = item.fullname
					? item.fullname.toUpperCase()
					: item.fullname;

				item.company = item.company
					? item.company.trim().toUpperCase()
					: item.company;
				item.signoffdatevalue = item.signoffdate;
				item.signondate =
					item.signondate && moment(item.signondate).format("ll");
				item.signoffdate =
					item.signoffdate && moment(item.signoffdate).format("ll");

				item.VesselName = item.VesselName
					? item.VesselName.toUpperCase()
					: item.VesselName;
				item.status = item.signoffdate ? "COMPLETED" : "ON BOARD";
			});

			if (embarcationlist) {
				embarcationlist.sort(comparestatus);
			}

			if (rol === "AUDIT") {
				console.log("COMPANY DE EMBARCACIONES");
				console.log(company);
				embarcationlist = embarcationlist.filter(
					(item) =>
						item.company.trim().toUpperCase() === company.trim().toUpperCase()
				);

				console.log(embarcationlist);
				companies = companies.filter(
					(item) =>
						item.companyname.toUpperCase() === company.trim().toUpperCase()
				);
			}
			embarcationlist = embarcationlist.sort(compare);
			setcompanylist(companies);
			setBackData(embarcationlist);
			setpuredata(embarcationlist);
			setdata(embarcationlist);
		};
		loadData();
	}, []);

	function compare(a, b) {
		if (a.signoffdatevalue > b.signoffdatevalue) {
			return -1;
		}
		if (a.signoffdatevalue < b.signoffdatevalue) {
			return 1;
		}
		return 0;
	}

	function dateDiff(date1, date2) {
		let diff = (date2.getTime() - date1.getTime()) / 1000;
		diff /= 60 * 60 * 24;
		let days = Math.abs(Math.round(diff));
		let months = Math.floor(days / 30);
		days = days - months * 30;
		return `${months}M  ${days}D`;
	}

	function comparestatus(a, b) {
		if (a.signondate < b.signondate) {
			return -1;
		}
		if (a.signondate > b.signondate) {
			return 1;
		}
		return 0;
	}
	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
	}));

	const getFirstDay = (datevalue) => {
		let y = new Date(datevalue).getFullYear();
		let m = new Date(datevalue).getMonth();
		return new Date(y, Number(m) + 1, 1);
	};

	const getLastDay = (datevalue) => {
		let y = new Date(datevalue).getFullYear();
		let m = new Date(datevalue).getMonth();
		return new Date(y, Number(m) + 2, 0);
	};

	const onChangeMonths = (datetype, datechange) => {
		console.log("CAMBIO DE FECHA");
		console.log(datechange);
		let y = new Date(datechange).getFullYear();
		let m = new Date(datechange).getMonth();

		let firstday = getFirstDay(datechange);
		let lastday = getLastDay(datechange);
		let data = [];
		if (datetype === "SIGNON") {
			setsignofffilter("");
			data = puredata.filter(
				(item) =>
					new Date(item.signondate) >= firstday &&
					new Date(item.signondate) <= lastday
			);
		} else {
			data = puredata.filter(
				(item) =>
					new Date(item.signoffdate) >= firstday &&
					new Date(item.signoffdate) <= lastday
			);
		}

		//	setmonthfilter(datechange);
		setdata(data);
	};

	const handleFilterChange = (event) => {
		const { name, value } = event.target;
		let datafilter = [];
		if (!isEmpty(signonfilter) || isEmpty(signofffilter)) {
			datafilter = data.filter(
				(item) => item.company.toUpperCase() === value.toUpperCase()
			);
		} else {
			datafilter = puredata.filter(
				(item) => item.company.toUpperCase() === value.toUpperCase()
			);
		}
		setcompanyfilter(value);
		setdata(datafilter);
		//setre({ ...filters, [name]: value });
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
				<Grid item xs={12} md={8} lg={9}>
					<Paper
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
							height: "100vh",
						}}>
						<Grid container spacing={1} sx={{ mb: 2 }}>
							<Grid item xs={6}>
								<Title sx={{ fontWeight: "bold" }}>
									EMBARKATION LIST REPORT
								</Title>
							</Grid>
							<Grid item xs={6}>
								<Item>
									<InputLabel id="demo-simple-select-label">Total:</InputLabel>

									<Typography sx={{ fontWeight: "Bold" }}>
										{size(data)} Records{" "}
									</Typography>
								</Item>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={{ mb: 2 }} justifyContent="center">
							<Grid item xs={3}>
								<InputLabel id="demo-simple-select-label">
									Filter by Sign On Month:
								</InputLabel>
								<TextField
									id="outlined-basic"
									variant="outlined"
									type="month"
									onChange={(e) => {
										onChangeMonths("SIGNON", e.target.value + "-01");
										setsignonfilter(e.target.value);
									}}
									size={"small"}
									value={signonfilter}
								/>
							</Grid>
							<Grid item xs={3}>
								<InputLabel id="demo-simple-select-label">
									Filter by Sign off Month:
								</InputLabel>
								<TextField
									id="outlined-basic"
									variant="outlined"
									type="month"
									onChange={(e) => {
										onChangeMonths("SIGNOFF", e.target.value + "-01");
										setsignofffilter(e.target.value);
									}}
									size={"small"}
									value={signofffilter}
								/>
							</Grid>
							<Grid item xs={3}>
								<InputLabel id="demo-simple-select-label">
									Filter by Company:
								</InputLabel>
								<Select
									fullWidth
									name="company"
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Companies:"
									value={companyfilter}
									size={"small"}
									onChange={handleFilterChange}>
									{companylist.map((item, index) => {
										return (
											<MenuItem key={item.companyname} value={item.companyname}>
												{item.companyname}
											</MenuItem>
										);
									})}
								</Select>
							</Grid>
							<Grid item xs={3}>
								<InputLabel id="demo-simple-select-label">
									Clean Filters
								</InputLabel>

								<Button
									variant="outlined"
									startIcon={<FilterAltOffIcon fontSize="large" />}
									sx={{ ml: "auto", cursor: "pointer" }}
									onClick={() => {
										setdata(puredata);
										setcompanyfilter("");
										setsignonfilter("");
										setsignofffilter("");
									}}>
									CLEAN FILTER
								</Button>
							</Grid>
						</Grid>
						<DataGrid
							rows={data}
							columns={columns}
							autoPageSize={true}
							sx={{
								boxShadow: 2,
							}}
							height={"100vh"}
							components={{
								Toolbar: CustomToolbar,
							}}
							componentsProps={{
								toolbar: {
									value: searchText,
									onChange: (event) => {
										requestSearch(event.target.value);
									},
									clearSearch: () => requestSearch(""),
									handleDownloadXlsx,
								},
								pagination: { classes: null },
							}}
						/>
					</Paper>
				</Grid>
			</Container>
		</Box>
	);
};

const defaultTheme = createTheme();
const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				padding: theme.spacing(0.5, 0.5, 0),
				justifyContent: "space-between",
				display: "flex",
				alignItems: "flex-start",
				flexWrap: "wrap",
			},
			textField: {
				[theme.breakpoints.down("xs")]: {
					width: "100%",
				},
				margin: theme.spacing(1, 0.5, 1.5),
				"& .MuiSvgIcon-root": {
					marginRight: theme.spacing(0.5),
				},
				"& .MuiInput-underline:before": {
					borderBottom: `1px solid ${theme.palette.divider}`,
				},
			},
		}),
	{ defaultTheme }
);

function CustomToolbar(props) {
	const classes = useStyles();
	return (
		<GridToolbarContainer>
			<Button
				sx={{ marginLeft: "auto" }}
				onClick={() => props.handleDownloadXlsx()}>
				Download as xlsx
			</Button>
			<TextField
				variant="standard"
				value={props.value}
				onChange={props.onChange}
				placeholder="Search…"
				className={classes.textField}
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: props.value ? "visible" : "hidden" }}
							onClick={props.clearSearch}>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</GridToolbarContainer>
	);
}

export default EmbarcationList;
