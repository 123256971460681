import React, { Component } from "react";
import Chart from "react-apexcharts";
import Title from "../Commons/Title";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function CircularPanel({ data, title, vessels }) {
  let labels = [];
  let series = [];

  data.forEach((item) => {
    labels.push(item.id);
    series.push(item.total);
  });

  const datos = {
    options: {
      labels: labels,
    },
    series: series,
  };

  const barstate = {
    options: {
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        categories: labels,
      },
    },
    series: [
      {
        data: series,
      },
    ],
  };

  return (
    <Grid container>
      <Title>{title}</Title>

      <Grid item sx={{ mt: 2, alignItems: "center" }}>
        <div className="bar">
          <Chart
            options={barstate.options}
            series={barstate.series}
            type="bar"
            width="850"
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <Title>Merchant Vessels</Title>
        <Chart
          options={{
            dataLabels: {
              enabled: true,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            xaxis: {
              categories: vessels.merchantData?.map((item) => {
                return item.id;
              }),
            },
          }}
          series={[
            {
              data: vessels.merchantData?.map((item) => {
                return item.total;
              }),
            },
          ]}
          type="bar"
        />
      </Grid>
      <Grid item xs={6}>
        <Title>Passenger Vessels</Title>
        <Chart
          options={{
            dataLabels: {
              enabled: true,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            xaxis: {
              categories: vessels.passengerData?.map((item) => {
                return item.id;
              }),
            },
          }}
          series={[
            {
              data: vessels.passengerData?.map((item) => {
                return item.total;
              }),
            },
          ]}
          type="bar"
        />
      </Grid>
      <Title>
        There are {vessels.emptyData} users without a category vessel
      </Title>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <div className="donut">
          <Chart
            options={datos.options}
            series={datos.series}
            type="donut"
            width="700"
          />
        </div>
      </Grid>
    </Grid>
  );
}
