import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { TEMPLATE } from "../../emails/Emails";
import {
  getPersonById,
  loadVesselType,
  loadDepartments,
  loadPositions,
  updateDataPersonal,
  vesselTypeDetail,
  typeofdocument,
  loadCertificates,
  getAttachmentByProcess,
  uploadDocumentToFirebase,
  addRecord,
  getFirebaseAttachments,
  getEmbarcationsById,
  sendEmail,
  loadUpdateById,
} from "../../Actions/Actions";
import { v4 as uuidv4 } from "uuid";

import _, { size, isEmpty } from "lodash";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { contrylistdata } from "../Commons/CountryList";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddCOC from "../DataViewComponents/AddCOC";
import AddDocuments from "../DataViewComponents/AddDocuments";
import AddTrainings from "../DataViewComponents/AddTrainings";
import AddOtherTrainings from "../DataViewComponents/AddOtherTrainings";
import { storage } from "../../Firebase.js/Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import AttachmentTable from "../DataViewComponents/AttachmentTable";
import LoadingProgress from "../Commons/LoadingProgress";
import BadgeIcon from "@mui/icons-material/Badge";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CompaniesSelect from "../Commons/CompaniesSelect";
import AddNewExperienceOnBoard from "../DataViewComponents/AddNewExperienceOnBoard";
import AddNewExperienceOnShore from "../DataViewComponents/AddNewExperienceOnShore";
import ExperienceOnBoard from "../DBComponets/ExperienceOnBoard";
import ExperienceOnShore from "../DBComponets/ExperienceOnShore";
import Embarcations from "../DBComponets/Embarcations";
import OtherTrainings from "../DBComponets/OtherTrainings";
import LicBookSTCW from "../DBComponets/LicBookSTCW";
import DocumentsTab from "../DBComponets/DocumentsTab";
import COCTable from "../DBComponets/CocTable";

const mdTheme = createTheme();
/***********DECLARACIÓN DE OBJETOS**************/
const emptycoc = {
  contry: "PANAMA",
  issueatplace: "",
  no: "",
  issuedate: null,
  validdate: null,
};

const emptydoc = {
  doctype: "",
  contry: "",
  issueatplace: "",
  no: "",
  issuedate: null,
  validdate: null,
};

const emptyexperience = {
  dateon: null,
  dateoff: null,
  companyname: "",
  vesselname: "",
  imo: "",
  gthp: "",
  typevessel: "",
  "Rank/position": "",
};

const emptycertificate = {
  description: "",
  contry: "",
  number: "",
  issuedate: null,
  expireddate: null,
};
/*****FIN DECLARACIÓN DE OBJETOS*************/

/***************INICIO********************/
export default function PendingUpdate() {
  let QUERY = window.location.search;
  const [data, setdata] = useState({});
  const [value, setValue] = React.useState(0);
  const [valuemain, setValuemain] = React.useState(0);
  const [fecha, setfecha] = useState(new Date());
  const [contry, setcontry] = useState("");
  const [vessel, vessellist] = useState([]);
  const [deparmentlist, setdepartmentlist] = useState([]);
  const [positionslist, setpositionlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [showcocpanel, setshowcocpanel] = useState(false);
  const [newcoc, setnewcoc] = useState(emptycoc);
  const [cocloading, setcocloading] = useState(false);
  const [vesseltypedetails, setvesseltypedetails] = useState([]);
  const [listtypeofdocument, setlisttypeofdocument] = useState([]);
  const [showexperienceboard, setshowexperienceboard] = useState(false);
  const [newexperienceboard, setnewexperienceboard] = useState(emptyexperience);
  const [experienceboardloading, setexperienceboardloading] = useState(false);
  const [showexperienceshore, setshowexperienceshore] = useState(false);
  const [newexperienceshore, setnewexperienceshore] = useState(emptyexperience);
  const [experienceshoreloading, setexperienceshoreloading] = useState(false);
  const [showdocpanel, setshowdocpanel] = useState(false);
  const [newdoc, setnewdoc] = useState(emptydoc);
  const [docloading, setdocloading] = useState(false);
  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);
  const [certificatelist, setcertificatelist] = useState([]);
  const [showcertificates, setshowcertificates] = useState(false);
  const [showothercert, setshowothercert] = useState(false);
  const [newcertificate, setnewcertificate] = useState(emptycertificate);
  const [newothercertificate, setnewothercertificate] =
    useState(emptycertificate);
  const [loadingcert, setloadingcert] = useState(false);
  const [attachmentlist, setatachmentlist] = useState([]);
  const [isedit, setisedit] = useState(false);
  const [backup, setbackup] = useState({});
  const [loadingprogress, setloadingprogress] = useState(false);
  const [embarcations, setembarcations] = useState([]);
  const [urlsent, seturlsent] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      loadDataPerson();
    };
    loadData();
  }, []);

  const loadDataPerson = async () => {
    let id = QUERY.replace("?id=", "");
    setloadingprogress(true);
    const resultado = await getPersonById(id);
    const barquitos = await loadVesselType();
    const departments = await loadDepartments();
    const positions = await loadPositions();
    const vesseldetails = await vesselTypeDetail();
    const typedocs = await typeofdocument();
    const certificates = await loadCertificates();
    const attachments = await getAttachmentByProcess(resultado[0].idproceso);
    const firebaseattachment = await getFirebaseAttachments("Attachment", id);
    const embarcaciones = await getEmbarcationsById(id);
    const urlsend = await loadUpdateById(id);

    setembarcations(embarcaciones);
    if (size(attachments) > 0 && size(firebaseattachment) > 0) {
      let all = attachments.concat(firebaseattachment);
      setatachmentlist(all);
    } else {
      setatachmentlist(attachments);
    }
    // console.log("URL SENT");
    // console.log(urlsend);
    seturlsent(urlsend);
    setcertificatelist(certificates);
    setlisttypeofdocument(typedocs);
    setvesseltypedetails(vesseldetails);
    setpositionlist(positions);
    setdepartmentlist(departments);
    setdata(resultado[0]);
    vessellist(barquitos);
    setloadingprogress(false);
  };

  const uploadFileServer = async (type, description) => {
    if (file) {
      let url = await uploadDocumentToFirebase(file);
      const upload = {
        id: uuidv4(),
        name: file.name,
        description: description,
        type: type,
        date: moment(new Date()).format("lll"),
        downloadkey: "",
        reference: "",
        source: "FIREBASE",
        url: url,
        idpersonal: QUERY.replace("?id=", ""),
      };

      await addRecord("Attachment", upload);
    }
  };

  const cleanFile = () => {
    if (file) {
      setFile("");
    }
  };

  const deleteRecord = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Type of Document"] === row[["Type of Document"]] &&
        item["Country of Issue"] === row["Country of Issue"] &&
        item["Issued at (Place)"] === row["Issued at (Place)"] &&
        item["No."] === row["No."] &&
        item["Date of Issue"] === row["Date of Issue"] &&
        item["Valid Until"] === row["Valid Until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });
    let datos = {
      Id: QUERY.replace("?id=", ""),
      type_document: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteCOC = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Country of issue"] === row[["Country of issue"]] &&
        item["Issue at (place)"] === row["Issue at (place)"] &&
        item["No."] === row["No."] &&
        item["Date of issue"] === row["Date of issue"] &&
        item["Date of Issue"] === row["Date of Issue"] &&
        item["Valid until"] === row["Valid until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      COC: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteLicBook = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Description of cert / course"] ===
          row[["Description of cert / course"]] &&
        item["Country of issue"] === row["Country of issue"] &&
        item["Number"] === row["Number"] &&
        item["Date of issue"] === row["Date of issue"] &&
        item["Date of expiry"] === row["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      lic_sbook_stcw_certificates: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteOther = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Description of cert / course"] ===
          row[["Description of cert / course"]] &&
        item["Country of issue"] === row["Country of issue"] &&
        item["Number"] === row["Number"] &&
        item["Date of issue"] === row["Date of issue"] &&
        item["Date of expiry"] === row["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      other_training: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  /**EDIT NEW DOCUMENT*/

  const editNewDocument = (row) => {
    const emptydoc = {
      doctype: row["Type of Document"],
      contry: row["Country of Issue"],
      issueatplace: row["Issued at (Place)"],
      no: row["No."],
      issuedate: row["Date of Issue"] === "" ? null : row["Date of Issue"],
      validdate: row["Valid Until"] === "" ? null : row["Valid Until"],
    };

    if (emptydoc.issuedate === "Invalid date") {
      emptydoc.issuedate = null;
    }

    setbackup(row);
    setisedit(true);
    setnewdoc(emptydoc);
    setshowdocpanel(true);
  };

  const saveDocument = async () => {
    //ELIMINAR EL DOCUMENTO ANTERIOR DEL ARRAY.

    let newarray = [];
    let datos = JSON.parse(data.type_document);

    // debido a que no hay llave primaria se hizo de esta forma.
    datos.map((item) => {
      if (
        item["Type of Document"] === backup[["Type of Document"]] &&
        item["Country of Issue"] === backup["Country of Issue"] &&
        item["Issued at (Place)"] === backup["Issued at (Place)"] &&
        item["No."] === backup["No."] &&
        item["Date of Issue"] === backup["Date of Issue"] &&
        item["Valid Until"] === backup["Valid Until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });
    //addnew
    //newadday.push()

    if (isEmpty(newdoc.doctype)) {
      showToast("Please select a Document Type", 3);
    } else {
      setdocloading(true);

      let insertdata = {
        "Type of Document": newdoc.doctype,
        "Country of Issue": newdoc.contry,
        "No.": newdoc.no,
        "Date of Issue":
          newdoc.issuedate === null
            ? ""
            : moment(newdoc.issuedate).format("YYYY/MM/DD"),
        "Issued at (Place)": newdoc.issueatplace,
        "Valid Until":
          newdoc.validdate === null
            ? ""
            : moment(newdoc.validdate).format("YYYY/MM/DD"),
      };
      newarray.push(insertdata);

      console.log("LA DATA QUE VIENE ES");
      console.log(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        type_document: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await uploadFileServer("DOCUMENTS", newdoc.doctype);
      setdocloading(false);
      await loadDataPerson();
      setnewdoc(emptydoc);
      handleDocClose();
      setFile("");
      setbackup({});
      setisedit(false);
      setshowdocpanel(false);
      showToast("Update Sucessful", 1);
    }
  };

  const editCOC = (row) => {
    const emptyCOC = {
      contry: row["Country of issue"],
      issueatplace: row["Issue at (place)"],
      no: row["No."],
      issuedate: row["Date of issue"] === "" ? null : row["Date of issue"],
      validdate: row["Valid until"] === "" ? null : row["Valid until"],
    };

    setbackup(row);
    setisedit(true);
    setnewcoc(emptyCOC);
    setshowcocpanel(true);
  };

  const saveCOC = async () => {
    let newarray = [];
    let datos = JSON.parse(data.COC);
    setcocloading(true);
    datos.map((item) => {
      if (
        item["Country of issue"] === backup["Country of issue"] &&
        item["Issue at (place)"] === backup["Issue at (place)"] &&
        item["No."] === backup["No."] &&
        item["Date of issue"] === backup["Date of issue"] &&
        item["Valid until"] === backup["Valid until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let insertdata = {
      "Country of issue": newcoc.contry,
      "No.": newcoc.no,
      "Date of issue":
        newcoc.issuedate === null
          ? ""
          : moment(newcoc.issuedate).format("YYYY/MM/DD"),
      "Issue at (place)": newcoc.issueatplace,
      "Valid until":
        newcoc.validdate === null
          ? ""
          : moment(newcoc.validdate).format("YYYY/MM/DD"),
    };

    newarray.push(insertdata);

    let datos1 = {
      Id: QUERY.replace("?id=", ""),
      COC: newarray,
    };
    const respuesta = await updateDataPersonal(datos1);
    console.log(respuesta);

    await uploadFileServer("COC", "COC");
    setcocloading(false);
    await loadDataPerson();
    setnewcoc(emptycoc);
    handleCOCClose();
    setbackup({});
    setisedit(false);
    showToast("COC Updated", 1);
  };

  const editexperienceonboard = (row) => {
    const emptyexperience = {
      dateon: row["Date on"] === "" ? null : row["Date on"],
      dateoff: row["Date off"] === "" ? null : row["Date off"],
      companyname: row["Company name"],
      vesselname: row["Vessel name"],
      imo: row["IMO #"],
      gthp: row["GT / HP"],
      typevessel: row["Type of vessel"],
      "Rank/position": row["Rank/position"],
    };

    setbackup(row);
    setisedit(true);
    setnewexperienceboard(emptyexperience);
    setshowexperienceboard(true);
  };
  //comentario de prueba.
  const saveExperienceOnBoard = async () => {
    let newarray = [];
    let datos = JSON.parse(data.experience_onboard);
    datos.map((item) => {
      if (
        item["Date on"] === backup["Date on"] &&
        item["Date off"] === backup["Date off"] &&
        item["Company name"] === backup["Company name"] &&
        item["Vessel name"] === backup["Vessel name"] &&
        item["IMO #"] === backup["IMO #"] &&
        item["GT / HP"] === backup["GT / HP"] &&
        item["Type of vessel"] === backup["Type of vessel"] &&
        item["Rank/position"] === backup["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newexperienceboard.companyname)) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceboardloading(true);

      const insertdata = {
        "Date on":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceboard.companyname,
        "Vessel name": newexperienceboard.vesselname,
        "IMO #": newexperienceboard.imo,
        "GT / HP": newexperienceboard.gthp,
        "Type of vessel": newexperienceboard.typevessel,
        "Rank/position": newexperienceboard["Rank/position"],
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onboard: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await loadDataPerson();
      setexperienceboardloading(false);
      setnewexperienceboard(emptyexperience);
      handleExperienceBoardClose();
      setbackup({});
      setisedit(false);
      showToast("Experience On Board was Updated Sucessful", 1);
    }
  };

  const editexperienceonshore = (row) => {
    const emptyexperience = {
      dateon: row["Date on"] === "" ? null : row["Date on"],
      dateoff: row["Date off"] === "" ? null : row["Date off"],
      companyname: row["Company name"],
      vesselname: row["Vessel name"],
      imo: row["IMO #"],
      gthp: row["GT / HP"],
      typevessel: row["Type of vessel"],
      "Rank/position": row["Rank/position"],
    };

    setbackup(row);
    setisedit(true);
    setnewexperienceshore(emptyexperience);
    setshowexperienceshore(true);
  };

  const saveExperienceOnShore = async () => {
    let newarray = [];
    let datos = JSON.parse(data.experience_onshore);
    datos.map((item) => {
      if (
        item["Date on"] === backup["Date on"] &&
        item["Date off"] === backup["Date off"] &&
        item["Company name"] === backup["Company name"] &&
        item["Vessel name"] === backup["Vessel name"] &&
        item["IMO #"] === backup["IMO #"] &&
        item["GT / HP"] === backup["GT / HP"] &&
        item["Type of vessel"] === backup["Type of vessel"] &&
        item["Rank/position"] === backup["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newexperienceshore.companyname)) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceshoreloading(true);

      const insertdata = {
        "Date on":
          newexperienceshore.dateon === null
            ? ""
            : moment(newexperienceshore.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceshore.dateon === null
            ? ""
            : moment(newexperienceshore.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceshore.companyname,
        "Vessel name": newexperienceshore.vesselname,
        "IMO #": newexperienceshore.imo,
        "GT / HP": newexperienceshore.gthp,
        "Type of vessel": newexperienceshore.typevessel,
        "Rank/position": newexperienceshore["Rank/position"],
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onshore: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      await loadDataPerson();
      setexperienceshoreloading(false);
      setnewexperienceshore(emptyexperience);
      handleExperienceshoreClose();
      setbackup({});
      setisedit(false);
      showToast("Updated Sucessful", 1);
    }
  };

  const editnewLicBook = (row) => {
    console.log("ESTA ES LA FILA");
    console.log(row);
    const emptybook = {
      description: row["Description of cert / course"],
      contry: row["Country of issue"],
      number: row["Number"],
      issuedate: row["Date of issue"] === "" ? null : row["Date of issue"],
      expireddate: row["Date of expiry"] === "" ? null : row["Date of expiry"],
    };

    setbackup(row);
    setisedit(true);
    setnewcertificate(emptybook);
    setshowcertificates(true);
  };

  const saveLicBook = async () => {
    let newarray = [];
    let datos = JSON.parse(data.lic_sbook_stcw_certificates);

    datos.map((item) => {
      if (
        item["Description of cert / course"] ===
          backup["Description of cert / course"] &&
        item["Country of issue"] === backup["Country of issue"] &&
        item["Number"] === backup["Number"] &&
        item["Date of issue"] === backup["Date of issue"] &&
        item["Date of expiry"] === backup["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newcertificate.description)) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newcertificate.description,
        "Country of issue": newcertificate.contry,
        Number: newcertificate.number,
        "Date of issue":
          newcertificate.issuedate === null
            ? ""
            : moment(newcertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newcertificate.expireddate === null
            ? ""
            : moment(newcertificate.expireddate).format("YYYY/MM/DD"),
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        lic_sbook_stcw_certificates: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await uploadFileServer("LIC BOOK STCW", newcertificate.description);

      await loadDataPerson();
      setnewcertificate(emptycertificate);
      handleCertificatesClose();
      setloadingcert(false);
      setbackup({});
      setisedit(false);
      showToast("Update Sucesfull", 1);
    }
  };

  const editothertrainings = (row) => {
    const emptybook = {
      description: row["Description of cert / course"],
      contry: row["Country of issue"],
      number: row["Number"],
      issuedate: row["Date of issue"] === "" ? null : row["Date of issue"],
      expireddate: row["Date of expiry"] === "" ? null : row["Date of expiry"],
    };

    setbackup(row);
    setisedit(true);
    setnewothercertificate(emptybook);
    setshowothercert(true);
  };

  const saveOtherCertificate = async () => {
    let newarray = [];
    let datos = JSON.parse(data.other_training);

    datos.map((item) => {
      if (
        item["Description of cert / course"] ===
          backup["Description of cert / course"] &&
        item["Country of issue"] === backup["Country of issue"] &&
        item["Number"] === backup["Number"] &&
        item["Date of issue"] === backup["Date of issue"] &&
        item["Date of expiry"] === backup["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newothercertificate.description)) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newothercertificate.description,
        "Country of issue": newothercertificate.contry,
        Number: newothercertificate.number,
        "Date of issue":
          newothercertificate.issuedate === null
            ? ""
            : moment(newothercertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newothercertificate.expireddate === null
            ? ""
            : moment(newothercertificate.expireddate).format("YYYY/MM/DD"),
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        other_training: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      await uploadFileServer(
        "OTHER TRAININGS",
        newothercertificate.description
      );
      console.log(respuesta);
      await loadDataPerson();
      setnewothercertificate(emptycertificate);
      handleOtherCertClose();
      setloadingcert(false);
      setbackup({});
      setisedit(false);
      showToast("Update Sucesfull", 1);
    }
  };

  /*EDIT NEW DOCUMENT*/
  /****************CERTIFICATES******************/
  const handleCertificatesOpen = () => setshowcertificates(true);
  const handleCertificatesClose = () => {
    setshowcertificates(false);
    cleanFile();
  };

  const deleteExperienceOnBoard = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Date on"] === row[["Date on"]] &&
        item["Date off"] === row["Date off"] &&
        item["Company name"] === row["Company name"] &&
        item["Vessel name"] === row["Vessel name"] &&
        item["IMO #"] === row["IMO #"] &&
        item["GT / HP"] === row["GT / HP"] &&
        item["Type of vessel"] === row["Type of vessel"] &&
        item["Rank/position"] === row["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      experience_onboard: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };
  const deteleExperienceOnShore = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Date on"] === row[["Date on"]] &&
        item["Date off"] === row["Date off"] &&
        item["Company name"] === row["Company name"] &&
        item["Vessel name"] === row["Vessel name"] &&
        item["IMO #"] === row["IMO #"] &&
        item["GT / HP"] === row["GT / HP"] &&
        item["Type of vessel"] === row["Type of vessel"] &&
        item["Rank/position"] === row["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      experience_onshore: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const handleChangeCertSelect = (e) => {
    setnewcertificate({ ...newcertificate, description: e.target.value });
  };

  const handleChangeCertIssueDate = (e) => {
    setnewcertificate({ ...newcertificate, issuedate: e });
  };

  const handleChangeCertExpiredDate = (e) => {
    setnewcertificate({ ...newcertificate, expireddate: e });
  };

  const handleCountryCert = (e) => {
    setnewcertificate({ ...newcertificate, contry: e.target.value });
  };

  const onchangeCert = (event) => {
    setnewcertificate({
      ...newcertificate,
      [event.target.name]: event.target.value,
    });
  };

  const addNewCertificate = async () => {
    if (isEmpty(newcertificate.description)) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newcertificate.description,
        "Country of issue": newcertificate.contry,
        Number: newcertificate.number,
        "Date of issue":
          newcertificate.issuedate === null
            ? ""
            : moment(newcertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newcertificate.expireddate === null
            ? ""
            : moment(newcertificate.expireddate).format("YYYY/MM/DD"),
      };

      let docdatos = isEmpty(data.lic_sbook_stcw_certificates)
        ? []
        : JSON.parse(data.lic_sbook_stcw_certificates);
      docdatos.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        lic_sbook_stcw_certificates: docdatos,
      };

      const respuesta = await updateDataPersonal(datos);
      await uploadFileServer("LIC BOOK STCW", newcertificate.description);

      await loadDataPerson();
      setnewcertificate(emptycertificate);
      handleCertificatesClose();
      showToast("Certificate Added Sucessful", 1);
      setloadingcert(false);
    }
  };

  /*****************FIN CERTIFICATES************/

  /*****************OTHER CERTIFICATES*********/
  const handleOtherCertOpen = () => setshowothercert(true);
  const handleOtherCertClose = () => {
    setshowothercert(false);
    cleanFile();
  };

  const handleChangeOtherCertIssueDate = (e) => {
    setnewothercertificate({ ...newothercertificate, issuedate: e });
  };

  const handleChangeOtherCertExpiredDate = (e) => {
    setnewothercertificate({ ...newothercertificate, expireddate: e });
  };

  const handleCountryOtherCert = (e) => {
    setnewothercertificate({ ...newothercertificate, contry: e.target.value });
  };

  const onchangeOtherCert = (event) => {
    setnewothercertificate({
      ...newothercertificate,
      [event.target.name]: event.target.value,
    });
  };

  const addNewOtherCertificate = async () => {
    if (isEmpty(newothercertificate.description)) {
      showToast("Please add a Certificate description", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newothercertificate.description,
        "Country of issue": newothercertificate.contry,
        Number: newothercertificate.number,
        "Date of issue":
          newothercertificate.issuedate === null
            ? ""
            : moment(newothercertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newothercertificate.expireddate === null
            ? ""
            : moment(newothercertificate.expireddate).format("YYYY/MM/DD"),
      };

      let docdatos = isEmpty(data.other_training)
        ? []
        : JSON.parse(data.other_training);
      docdatos.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        other_training: docdatos,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await uploadFileServer(
        "OTHER TRAININGS",
        newothercertificate.description
      );
      await loadDataPerson();
      setnewothercertificate(emptycertificate);
      handleOtherCertClose();
      setloadingcert(false);
      showToast("Certificate Added Sucessful", 1);
    }
  };

  /**++++++++++++++++FIN OTHER CERTIFICATES****+*/

  /****************DOCUMENTS*********************/
  const handleDocsOpen = () => {
    setshowdocpanel(true);
  };
  const handleDocClose = () => {
    setshowdocpanel(false);
    cleanFile();
  };

  const handleChangeDocIssueDate = (e) => {
    setnewdoc({ ...newdoc, issuedate: e });
  };

  const handleChangeDocValidDate = (e) => {
    setnewdoc({ ...newdoc, validdate: e });
  };

  const handleChangeContrySelect = (e) => {
    setnewdoc({ ...newdoc, contry: e.target.value });
  };

  const handleChangeIssueDate = (e) => {
    setnewcoc({ ...newcoc, issuedate: e });
  };

  const handleChangeValidDate = (e) => {
    setnewcoc({ ...newcoc, validdate: e });
  };
  const handleDocChangeSelect = (e) => {
    setnewdoc({ ...newdoc, doctype: e.target.value });
  };

  const handleChangeSelect = (e) => {
    setnewcoc({ ...newcoc, contry: e.target.value });
  };

  const addNewDoc = async () => {
    if (isEmpty(newdoc.doctype)) {
      showToast("Please select a Document Type", 3);
    } else {
      setdocloading(true);
      setloadingprogress(true);

      let insertdata = {
        "Type of Document": newdoc.doctype,
        "Country of Issue": newdoc.contry,
        "No.": newdoc.no,
        "Date of Issue":
          newdoc.issuedate === null
            ? ""
            : moment(newdoc.issuedate).format("YYYY/MM/DD"),
        "Issued at (Place)": newdoc.issueatplace,
        "Valid Until":
          newdoc.validdate === null
            ? ""
            : moment(newdoc.validdate).format("YYYY/MM/DD"),
      };
      let docdatos = isEmpty(data.type_document)
        ? []
        : JSON.parse(data.type_document);
      docdatos.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        type_document: docdatos,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log("RESPUESTA ADD");
      console.log(respuesta);
      await uploadFileServer("DOCUMENTS", newdoc.doctype);
      await loadDataPerson();
      handleDocClose();
      setdocloading(false);
      setnewdoc(emptydoc);
      setloadingprogress(false);
      showToast("Document Added Sucessful", 1);
    }
  };

  /***************END DOCUMENTS*****************/

  /****************EXPERIENCE ON BOARD***********/
  const onchangeExpBoard = (event) => {
    setnewexperienceboard({
      ...newexperienceboard,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeDateon = (e) => {
    setnewexperienceboard({ ...newexperienceboard, dateon: e });
  };

  const handleChangeDateoff = (e) => {
    setnewexperienceboard({ ...newexperienceboard, dateoff: e });
  };
  const handleChangeSelectExperience = (e) => {
    setnewexperienceboard({
      ...newexperienceboard,
      typevessel: e.target.value,
    });
  };
  const handleExperienceBoardClose = () => {
    setshowexperienceboard(false);
    cleanFile();
  };

  const handleExperienceBoardOpen = () => {
    setshowexperienceboard(true);
  };

  const addNewExpOnBoard = async () => {
    if (isEmpty(newexperienceboard.companyname)) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceboardloading(true);

      const insertdata = {
        "Date on":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceboard.companyname,
        "Vessel name": newexperienceboard.vesselname,
        "IMO #": newexperienceboard.imo,
        "GT / HP": newexperienceboard.gthp,
        "Type of vessel": newexperienceboard.typevessel,
        "Rank/position": newexperienceboard["Rank/position"],
      };
      let experienceonboard = isEmpty(data.experience_onboard)
        ? []
        : JSON.parse(data.experience_onboard);
      experienceonboard.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onboard: experienceonboard,
      };

      const respuesta = await updateDataPersonal(datos);
      await loadDataPerson();
      setexperienceboardloading(false);
      setnewexperienceboard(emptyexperience);
      handleExperienceBoardClose();
      showToast("Experience On Board was Added Sucessful", 1);
    }
  };

  /*************FIN EXPERIENCE ON BOARD*****************/

  /****************EXPERIENCE ON SHORE******************/

  /******************FIN EXPERIENCE ON SHORE*********/

  const onchangeExpShore = (event) => {
    setnewexperienceshore({
      ...newexperienceshore,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeDateonShore = (e) => {
    setnewexperienceshore({ ...newexperienceshore, dateon: e });
  };

  const handleChangeDateoffShore = (e) => {
    setnewexperienceshore({ ...newexperienceshore, dateoff: e });
  };
  const handleChangeSelectShore = (e) => {
    setnewexperienceshore({
      ...newexperienceshore,
      typevessel: e.target.value,
    });
  };
  const handleExperienceshoreClose = () => {
    setshowexperienceshore(false);
    cleanFile();
  };

  const handleExperienceshoreOpen = () => {
    setshowexperienceshore(true);
  };

  const addNewExpOnshore = async () => {
    if (isEmpty(newexperienceshore.companyname)) {
      showToast("Please add a Company Name", 3);
    } else {
      setexperienceshoreloading(true);

      const insertdata = {
        "Date on":
          newexperienceshore.dateon === null
            ? ""
            : moment(newexperienceshore.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceshore.dateon === null
            ? ""
            : moment(newexperienceshore.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceshore.companyname,
        "Vessel name": newexperienceshore.vesselname,
        "IMO #": newexperienceshore.imo,
        "GT / HP": newexperienceshore.gthp,
        "Type of vessel": newexperienceshore.typevessel,
        "Rank/position": newexperienceshore["Rank/position"],
      };
      let experienceonshore = isEmpty(data.experience_onshore)
        ? []
        : JSON.parse(data.experience_onshore);
      experienceonshore.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onshore: experienceonshore,
      };

      const respuesta = await updateDataPersonal(datos);
      await loadDataPerson();
      setexperienceshoreloading(false);
      setnewexperienceshore(emptyexperience);
      handleExperienceshoreClose();
      showToast("Experience On Shore was Added Sucessful", 1);
    }
  };

  /*******************COC******************************/

  const handleCOCClose = () => {
    setshowcocpanel(false);
    cleanFile();
  };

  const handleCOCOpen = () => {
    setshowcocpanel(true);
  };

  const onchangeCOC = (event) => {
    setnewcoc({ ...newcoc, [event.target.name]: event.target.value });
  };

  const onchangeDoc = (event) => {
    setnewdoc({
      ...newdoc,
      [event.target.name]: event.target.value,
    });
  };

  const addNewCoc = async () => {
    setcocloading(true);

    let insertdata = {
      "Country of issue": newcoc.contry,
      "No.": newcoc.no,
      "Date of issue":
        newcoc.issuedate === null
          ? ""
          : moment(newcoc.issuedate).format("YYYY/MM/DD"),
      "Issue at (place)": newcoc.issueatplace,
      "Valid until":
        newcoc.validdate === null
          ? ""
          : moment(newcoc.validdate).format("YYYY/MM/DD"),
    };
    let cocdatos = isEmpty(data.COC) ? [] : JSON.parse(data.COC);
    cocdatos.push(insertdata);

    let datos = {
      Id: QUERY.replace("?id=", ""),
      COC: cocdatos,
    };
    const respuesta = await updateDataPersonal(datos);
    console.log(respuesta);
    await uploadFileServer("COC", "COC");
    await loadDataPerson();
    setcocloading(false);
    setnewcoc(emptycoc);
    handleCOCClose();
    showToast("COC Document Added Sucessful", 1);
  };

  /*****************FIN COC***************************/

  /*****************USER DATA*************************/

  const handlePlaceOfBirth = (e) => {
    setdata({ ...data, place_of_birth: e.target.value });
  };

  const changeVesselType = (e) => {
    setdata({ ...data, category_vessel: e.target.value });
  };

  const changeCompany = (e) => {
    setdata({ ...data, company: e.target.value });
  };

  const changeDepartment = (e) => {
    setdata({ ...data, department: e.target.value });
  };

  const changePositions = (e) => {
    setdata({ ...data, position_applying: e.target.value });
  };

  const handleChange = (event) => {
    setdata({ ...data, [event.target.name]: event.target.value });
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTabsmain = (event, newValue) => {
    setValuemain(newValue);
  };

  const handleDateBirth = (value) => {
    setdata({ ...data, date_of_birth: new Date(value) });
  };

  const handleCommencesDate = (value) => {
    setdata({ ...data, contractcommencesdate: new Date(value) });
  };

  const handleEndsDate = (value) => {
    setdata({ ...data, contractendsdate: new Date(value) });
  };

  const updateData = async () => {
    setloading(true);
    let datos = data;
    delete datos.id;
    datos.date_of_birth = moment(datos.date_of_birth).format("YYYY/MM/DD");
    const respuesta = await updateDataPersonal(datos);
    showToast("Record has been updated successful!", 1);
    await loadDataPerson();
    setloading(false);
  };

  /**************FIN USER DATA************************************/

  /****************TOOLS*****************************************/
  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };
  const handleUpload = async () => {
    if (!file) {
      alert("Please upload an image first!");
    } else {
      await uploadDocumentToFirebase(file);
    }

    // const storageRef = ref(storage, `/files/${file.name}`);

    // // progress can be paused and resumed. It also exposes progress updates.
    // // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);

    // uploadTask.on(
    //   "state_changed",
    //   (snapshot) => {
    //     const percent = Math.round(
    //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //     );

    //     // update progress
    //     setPercent(percent);
    //   },
    //   (err) => console.log(err),
    //   () => {
    //     // download url
    //     getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //       console.log("DATOS DEL ARCHIVO");
    //       console.log(url);
    //     });
    //   }
    // );
  };

  function handleFileChange(event) {
    setFile(event.target.files[0]);
  }

  /*******************FIN TOOLS**********************/

  const SelectPlaceOfBirth = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="placeofbirthselect"
          id="placeofbirthselect"
          value={data.place_of_birth}
          onChange={handlePlaceOfBirth}
          name="place_of_birth"
          fullWidth
          variant="standard">
          {contrylistdata.map((item) => (
            <MenuItem value={item.name.toUpperCase()} key={item.name}>
              {item.name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const VesselType = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="merchanselect"
          id="merchanselect"
          value={
            data.category_vessel === ""
              ? "Merchant vessel"
              : data.category_vessel
          }
          label="Category Vessel"
          onChange={changeVesselType}
          fullWidth
          variant="standard">
          {vessel.map((item) => (
            <MenuItem value={item.Name} key={item.Id}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const sendUpdateInfo = async () => {
    //1. Alertar de que se generará un correo y un link.
    setloadingprogress(true);
    let id = QUERY.replace("?id=", "");
    let fullname = data.fullname;
    const updaterequest = {
      createDate: new Date(),
      data: data,
      status: "pending",
      pmssaid: data.id,
      fullname: fullname,
    };

    if (size(urlsent) === 0) {
      const resultado = await addRecord("UpdateRequests", updaterequest);
      console.log(resultado.id);

      const emailcreate = TEMPLATE.filter((item) => item.id === "UPDATE_INFO");
      let body = emailcreate[0].body.replace("$name$", fullname);
      body = body.replace(
        "$link$",
        `http://localhost:3000/updateinfo?id=${resultado.id}`
      );

      sendEmail(body, emailcreate[0].subject, data.email);
      loadDataPerson();
      showToast("A LINK WAS SENT TO " + fullname, 1);
    } else {
      showToast(
        fullname +
          " ALREADY HAS A PENDING REQUEST SENT , PLEASE COPY LINK IF  YOU WANT SENT AGAIN",
        1
      );
    }
    setloadingprogress(false);
  };

  const copyLink = async () => {
    console.log(urlsent);
    await navigator.clipboard.writeText(
      `http://localhost:3000/updateinfo?id=${urlsent[0].idfirebase}`
    );
    showToast("Link was copy in clipboard", 1);
  };

  const DepartmentSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="departmentselect"
          id="departmentselect"
          value={_.get(data, "department", "").toUpperCase()}
          label="Department"
          onChange={changeDepartment}
          variant="standard">
          {deparmentlist.map((item) => (
            <MenuItem value={item.department_name.toUpperCase()} key={item.Id}>
              {item.department_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const PositionSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="positionselect"
          id="positionselect"
          value={_.get(data, "position_applying", "").toUpperCase()}
          label="Position Applying"
          onChange={changePositions}
          variant="standard">
          {positionslist.map((item) => (
            <MenuItem value={item.position_name.toUpperCase()} key={item.Id}>
              {item.position_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Grid>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "row",
            }}>
            <Grid
              justifyContent={"center"}
              alignItems={"center"}
              container
              spacing={1}>
              <Grid item xs={2} sx={{}}>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={updateData}
                  startIcon={<SaveAsRoundedIcon />}
                  size={"medium"}>
                  {!loading ? (
                    "UPDATE INFO"
                  ) : (
                    <CircularProgress color="inherit" />
                  )}
                </Button>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Button
                  variant="outlined"
                  sx={{ mr: 5 }}
                  onClick={sendUpdateInfo}
                  size={"medium"}>
                  Send Update Info
                </Button>
              </Grid>
              <Grid item xs={4} sx={{}}>
                {size(urlsent) > 0 && (
                  <Button
                    variant="contained"
                    sx={{ mr: 5 }}
                    onClick={copyLink}
                    size={"large"}
                    startIcon={<ContentPasteIcon />}>
                    COPY LINK
                  </Button>
                )}
              </Grid>

              <Grid
                item
                xs={2}
                sx={{
                  textAlign: "left",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  startIcon={<BadgeIcon />}
                  size={"medium"}
                  color="success">
                  {data.status}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}>
            <Tabs
              value={valuemain}
              onChange={handleChangeTabsmain}
              aria-label="basic tabs"
              variant="standard">
              <Tab label="PERSONAL DATA" {...a11yProps(0)} />
              <Tab label="EMBARKATIONS" {...a11yProps(1)} />
              <Tab label="ATTACHMENTS" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={valuemain} index={0}>
              <Grid>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItem: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Full Name: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="fullname"
                      label=""
                      name="fullname"
                      autoComplete="fullname"
                      value={data.fullname}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Gender: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="sex"
                        value={_.get(data, "sex", "M")}
                        onChange={handleChange}>
                        <FormControlLabel
                          value="F"
                          control={<Radio />}
                          label="F"
                        />
                        <FormControlLabel
                          value="M"
                          control={<Radio />}
                          label="M"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Date of Birth: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <MobileDatePicker
                      label=""
                      inputFormat="MM/dd/yyyy"
                      value={data.date_of_birth}
                      onChange={handleDateBirth}
                      name="date_of_birth"
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">Nationality:</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <SelectPlaceOfBirth />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Application Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">
                      {data.application_date}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Category Vessel:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <VesselType />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Company: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <CompaniesSelect
                      changeCompany={changeCompany}
                      data={data}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Department: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <DepartmentSelect />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Email: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="email"
                      label=""
                      name="email"
                      autoComplete="email"
                      value={data.email}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Position: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <PositionSelect />
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Phone: </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="phone"
                      label=""
                      name="phone"
                      autoComplete="phone"
                      value={data.phone}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Whatsapp: </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="whatsapp"
                      label=""
                      name="whatsapp"
                      autoComplete="whatsapp"
                      value={data.whatsapp}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Nearly Airport:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2 }}>
                    <TextField
                      required
                      id="nearly_airtport"
                      label=""
                      name="nearly_airport"
                      autoComplete="nearly_airport"
                      value={data.nearly_airport}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">PMSSA #</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <Title> {data.id}</Title>
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Commences Date:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ pr: 2 }}>
                    <MobileDatePicker
                      label=""
                      inputFormat="MM/dd/yyyy"
                      onChange={handleCommencesDate}
                      value={data.contractcommencesdate}
                      name="contractcommencesdate"
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">Ends Date: </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ pr: 2 }}>
                    <MobileDatePicker
                      label=""
                      inputFormat="MM/dd/yyyy"
                      onChange={handleEndsDate}
                      value={data.contractendsdate}
                      name="contractendsdate"
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <Typography variant="subtitle2">
                      Company Hiring Code :
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{}}>
                    <TextField
                      required
                      id="hiringcompanycode"
                      label=""
                      name="hiringcompanycode"
                      autoComplete="hiringcompanycode"
                      value={data.hiringcompanycode}
                      onChange={handleChange}
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="basic tabs"
                    variant="standard">
                    <Tab label="DOCUMENTS" {...a11yProps(0)} />
                    <Tab label="COC" {...a11yProps(1)} />
                    <Tab label="EXPERIENCE ON BOARD" {...a11yProps(2)} />
                    <Tab label="EXPERIENCE ON SHORE" {...a11yProps(3)} />
                    <Tab label="LIC BOOK STCW" {...a11yProps(4)} />
                    <Tab label="OTHER TRAININGS" {...a11yProps(5)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <DocumentsTab
                    data={data.type_document}
                    addNew={handleDocsOpen}
                    deleterecord={deleteRecord}
                    edit={editNewDocument}
                    isview={1}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <COCTable
                    data={data.COC}
                    addNew={handleCOCOpen}
                    deleterecord={deleteCOC}
                    edit={editCOC}
                    isview={1}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ExperienceOnBoard
                    data={data.experience_onboard}
                    addNew={handleExperienceBoardOpen}
                    deleterecord={deleteExperienceOnBoard}
                    edit={editexperienceonboard}
                    isview={1}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <ExperienceOnShore
                    data={data.experience_onshore}
                    addNew={handleExperienceshoreOpen}
                    deleterecord={deteleExperienceOnShore}
                    edit={editexperienceonshore}
                    isview={1}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <LicBookSTCW
                    data={data.lic_sbook_stcw_certificates}
                    addNew={handleCertificatesOpen}
                    deleterecord={deleteLicBook}
                    edit={editnewLicBook}
                    isview={1}
                  />
                </TabPanel>

                <TabPanel value={value} index={5}>
                  <OtherTrainings
                    data={data.other_training}
                    addNew={handleOtherCertOpen}
                    deleterecord={deleteOther}
                    edit={editothertrainings}
                    isview={1}
                  />
                </TabPanel>
              </Box>
            </TabPanel>
            <TabPanel value={valuemain} index={1}>
              <Embarcations data={embarcations} />
            </TabPanel>
            <TabPanel value={valuemain} index={2}>
              <AttachmentTable data={attachmentlist} />
            </TabPanel>
          </Paper>
        </Grid>
      </Container>

      <AddCOC
        open={showcocpanel}
        handleOpen={handleCOCOpen}
        handleClose={handleCOCClose}
        contrylistdata={contrylistdata}
        data={newcoc}
        onChange={onchangeCOC}
        handleChangeSelect={handleChangeSelect}
        contry={contry}
        handleChangeIssueDate={handleChangeIssueDate}
        handleChangeValidDate={handleChangeValidDate}
        addnew={addNewCoc}
        loading={cocloading}
        setloading={setcocloading}
        handleUpload={handleUpload}
        isedit={isedit}
        edit={saveCOC}
      />
      <AddNewExperienceOnBoard
        open={showexperienceboard}
        handleOpen={handleExperienceBoardOpen}
        handleClose={handleExperienceBoardClose}
        vesseltypedetails={vesseltypedetails}
        data={newexperienceboard}
        onChange={onchangeExpBoard}
        handleChangeSelect={handleChangeSelectExperience}
        handleChangeDateoff={handleChangeDateoff}
        handleChangeDateon={handleChangeDateon}
        addnew={addNewExpOnBoard}
        loading={experienceboardloading}
        isedit={isedit}
        edit={saveExperienceOnBoard}
      />

      <AddNewExperienceOnShore
        open={showexperienceshore}
        handleOpen={handleExperienceshoreOpen}
        handleClose={handleExperienceshoreClose}
        vesseltypedetails={vesseltypedetails}
        data={newexperienceshore}
        onChange={onchangeExpShore}
        handleChangeSelect={handleChangeSelectShore}
        handleChangeDateoff={handleChangeDateoffShore}
        handleChangeDateon={handleChangeDateonShore}
        addnew={addNewExpOnshore}
        loading={experienceshoreloading}
        isedit={isedit}
        edit={saveExperienceOnShore}
      />

      <AddDocuments
        open={showdocpanel}
        handleOpen={handleDocsOpen}
        handleClose={handleDocClose}
        listtypeofdocument={listtypeofdocument}
        contrylistdata={contrylistdata}
        data={newdoc}
        onChange={onchangeDoc}
        handleChangeSelect={handleChangeContrySelect}
        handleChangeDoctypeSelect={handleDocChangeSelect}
        handleChangeIssueDate={handleChangeDocIssueDate}
        handleChangeValidDate={handleChangeDocValidDate}
        addnew={addNewDoc}
        loading={docloading}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        percent={percent}
        isedit={isedit}
        edit={saveDocument}
      />

      <AddTrainings
        open={showcertificates}
        handleOpen={handleCertificatesOpen}
        handleClose={handleCertificatesClose}
        list={certificatelist}
        data={newcertificate}
        handleChangeCertSelect={handleChangeCertSelect}
        handleChangeSelect={handleCountryCert}
        contrylistdata={contrylistdata}
        onChange={onchangeCert}
        handleChangeCertIssueDate={handleChangeCertIssueDate}
        handleChangeCertExpiredDate={handleChangeCertExpiredDate}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        percent={percent}
        loading={loadingcert}
        addnew={addNewCertificate}
        isedit={isedit}
        edit={saveLicBook}
      />

      <AddOtherTrainings
        open={showothercert}
        handleOpen={handleOtherCertOpen}
        handleClose={handleOtherCertClose}
        data={newothercertificate}
        handleChangeSelect={handleCountryOtherCert}
        contrylistdata={contrylistdata}
        onChange={onchangeOtherCert}
        handleChangeCertIssueDate={handleChangeOtherCertIssueDate}
        handleChangeCertExpiredDate={handleChangeOtherCertExpiredDate}
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        percent={percent}
        loading={loadingcert}
        addnew={addNewOtherCertificate}
        isedit={isedit}
        edit={saveOtherCertificate}
      />

      <LoadingProgress loading={loadingprogress} />

      <ToastContainer />
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
