import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
const theme = createTheme();

export default function AddVaccines({
  open,
  handleClose,
  data,
  handleChangeCertIssueDate,
  handleChangeVaccineDoze,
  handleVaccineMark,
  loading,
  handleUpload,
  handleFileChange,
  addnew,
  isedit,
  edit,
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Container component="main" maxWidth="md" style={{ marginTop: 20 }}>
            <Paper
              sx={{
                width: 0.5,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                paddingBottom: 5,
              }}>
              <Grid>
                <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
                  <CloseOutlinedIcon onClick={() => handleClose()} />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  <Title>{data.id}</Title>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  {isedit ? <Title>UPDATE</Title> : <Title>ADD VACCINES</Title>}
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItem: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                    pr: 5,
                    pl: 5,
                  }}>
                  <FormControl variant="standard" fullWidth>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Grid sx={{}}>
                        <Typography variant="subtitle2">Doze</Typography>
                      </Grid>
                      <Grid sx={{ width: "100%", paddingLeft: 4 }}>
                        <Select
                          labelId="doctype"
                          id="doctype"
                          value={data.Doze}
                          onChange={(e) => {
                            handleChangeVaccineDoze(e);
                          }}
                          fullWidth>
                          <MenuItem value={"Booster"}>Booster</MenuItem>
                          <MenuItem value={"First Doze"}>First Doze</MenuItem>
                          <MenuItem value={"Second Doze"}>Second Doze</MenuItem>
                          <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                      </Grid>
                    </Box>
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <Box sx={{ display: "flex", alignItems: "center", py: 3 }}>
                      <Grid sx={{}}>
                        <Typography variant="subtitle2">
                          Vaccination Mark
                        </Typography>
                      </Grid>
                      <Grid sx={{ width: "80%", paddingLeft: 4 }}>
                        <Select
                          labelId="doctype"
                          id="doctype"
                          value={data["Vaccination Mark"]}
                          onChange={(e) => handleVaccineMark(e)}
                          fullWidth>
                          <MenuItem value={"Astrazeneca"}>Astrazeneca</MenuItem>
                          <MenuItem value={"Moderna"}>Moderna</MenuItem>
                          <MenuItem value={"Pfizer"}>Pfizer</MenuItem>
                          <MenuItem value={"Sinovac"}>Sinovac</MenuItem>
                          <MenuItem value={"Janssen "}>Janssen </MenuItem>
                        </Select>
                      </Grid>
                    </Box>
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Grid sx={{}}>
                        <Typography variant="subtitle2">
                          Date of issue
                        </Typography>
                      </Grid>
                      <Grid sx={{ width: "80%", paddingLeft: 4 }}>
                        <MobileDatePicker
                          name="Date of issue"
                          inputFormat="dd/MM/yyyy"
                          value={data["Date of issue"]}
                          onChange={(e) => handleChangeCertIssueDate(e)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              error={false}
                            />
                          )}
                        />
                      </Grid>
                    </Box>
                  </FormControl>

                  <Grid item xs={6} sx={{ mt: 5, textAlign: "center" }}>
                    {isedit ? (
                      <Button variant="outlined" sx={{ mr: 5 }} onClick={edit}>
                        {!loading ? (
                          "UPDATE"
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{ mr: 5 }}
                        onClick={addnew}>
                        {!loading ? (
                          "ADD NEW VACCINE"
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
    </Modal>
  );
}
