import { Text, View } from "@react-pdf/renderer";
import { numberNextLimiter } from "../../../utils/utils";

const ExpOnBoard = ({ styles, person, expOnBoard }) => {
  const experiencesColumn = [
    {
      name: "Date on",
      width: "10%",
    },
    {
      name: "Date Off",
      width: "10%",
    },
    {
      name: "Company Name /ship-owner",
      width: "15%",
    },
    {
      name: "Vessel Name ",
      width: "15%",
    },
    {
      name: "IMO # ",
      width: "10%",
    },
    {
      name: "GT / HP",
      width: "10%",
    },
    {
      name: "Type of Vessel",
      width: "15%",
    },
    {
      name: " Rank/position",
      width: "15%",
    },
  ];
  return (
    <View>
      <View style={styles.firstTextContainer}>
        <Text style={{ fontSize: 10 }}>
          2. Professional Experience Onboard:
        </Text>
      </View>
      <View style={{ borderBottom: 0.5 }}>
        <View style={[styles.row, { borderRight: 0.5 }]}>
          {experiencesColumn.map((item, index) => {
            return (
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: item.width },
                ]}
                key={index}>
                {item.name}
              </Text>
            );
          })}
        </View>
        {person.experience_onboard === "" ? (
          <>
            <View style={styles.row}>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", borderRight: 0.5 },
                ]}></Text>
            </View>
          </>
        ) : (
          <>
            {expOnBoard.map((item, index) => {
              return (
                <View style={styles.row} key={index}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["Date on"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["Date off"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%" },
                    ]}>
                    {item["Company name"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%" },
                    ]}>
                    {item["Vessel name"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {numberNextLimiter(item["IMO #"], 10)}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["GT / HP"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%" },
                    ]}>
                    {item["Type of vessel"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%", borderRight: 0.5 },
                    ]}>
                    {item["Rank/position"]}
                  </Text>
                </View>
              );
            })}
          </>
        )}
      </View>
    </View>
  );
};

export default ExpOnBoard;
