import React, { useState, useEffect, useContext } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getData } from "../../Actions/Actions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../Commons/ErrorBoundary";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GlobalContext } from "../../context/GlobalContext";
import MonthSelector from "../Commons/MonthSelector";
import ExportReportBtn from "../Commons/ExportReportBtn";

const columns = [
  {
    field: "id",
    headerName: "Acciones",
    width: 100,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/pendingupdate?id=${params.value}`,
          state: { id: params.value },
        }}
        reloadDocument>
        <VisibilityIcon style={{ fontSize: 25 }} color="primary" />
      </Link>
    ),
    sortable: false,
  },

  {
    field: "pmssaid",
    headerName: "PMSSA ID",
    width: 80,
    editable: true,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    width: 300,
    editable: true,
  },
  {
    field: "createDate",
    headerName: "createDate",
    width: 250,
    renderCell: (params) => (
      <Typography>
        {" "}
        {moment(params.row.createDate.toDate()).format("LL")}
      </Typography>
    ),
  },

  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: true,
    renderCell: (params) => <StatusComponent params={params} />,
  },
];

const getStatusStyle = (status) => {
  let baseStyle = {
    width: 15,
    height: 15,
    mr: 1,
    borderRadius: "50%",
  };
  if (status === "Updated") baseStyle.backgroundColor = "green";
  if (status === "pending") baseStyle.backgroundColor = "orange";
  if (status === "Finished") baseStyle.backgroundColor = "gray";
  return baseStyle;
};

const StatusComponent = ({ params }) => {
  let value = params.row.status;
  return (
    <>
      <Box sx={getStatusStyle(value)} />
      <Typography variant="body1" color="initial">
        {value}
      </Typography>
    </>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export function PendingUpdateReport() {
  const { updateInfo } = useContext(GlobalContext);
  const [filteredData, setFilteredData] = useState([]);
  const [filterDate, setFilterDate] = useState({ m: "", y: "" });
  const [monthfilter, setmonthfilter] = useState(
    moment().startOf("month").format("YYYY-MM-DD hh:mm")
  );

  useEffect(() => {
    setFilteredData(updateInfo);
  }, []);

  const onChangeMonths = (datechange) => {
    if (datechange === "-01") {
      setFilteredData(updateInfo);
      return;
    }
    let m = new Date(datechange).getMonth();
    let y = new Date(datechange).getUTCFullYear();

    console.log("DATE: ", y, m);

    let firstday = getFirstDay(datechange);
    let lastday = getLastDay(datechange);

    console.log(updateInfo);

    let dataFinal = updateInfo.filter((item) => {
      const selectedDate = new Date(item.createDate.toDate());
      console.log("selectedDate", selectedDate);
      return (
        new Date(item.createDate.toDate()) >= firstday &&
        new Date(item.createDate.toDate()) <= lastday
      );
    });

    console.log("filteredArray", dataFinal);

    setFilterDate({
      m: moment()
        .month(m + 1)
        .format("M"),
      y: y,
    });
    setmonthfilter(datechange);
    setFilteredData(dataFinal);
  };

  const getFirstDay = (datevalue) => {
    let y = new Date(datevalue).getFullYear();
    let m = new Date(datevalue).getMonth();
    return new Date(y, Number(m) + 1, 1);
  };

  const getLastDay = (datevalue) => {
    let y = new Date(datevalue).getFullYear();
    let m = new Date(datevalue).getMonth();
    return new Date(y, Number(m) + 2, 0);
  };

  const getCsvData = () => {
    console.log(filteredData[0]);
    const csvData = [];
    const headers = ["PMSSA ID", "Full name", "Create date", "Status"];
    filteredData.forEach((item) => {
      csvData.push({
        pmssaid: item.pmssaid,
        fullname: item.fullname,
        createDate: moment(item.createDate.toDate()).format("LL"),
        status: item.status,
      });
    });
    return [headers, csvData];
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              height: "80vh",
            }}>
            <Title>Pending update report</Title>

            <Box
              sx={{
                display: "flex",
                my: 1.45,
                justifyContent: "space-between",
              }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="month"
                onChange={(e) => {
                  onChangeMonths(e.target.value + "-01");
                }}
              />
              <ExportReportBtn getData={getCsvData} />
            </Box>
            <ErrorBoundary>
              <DataGrid
                rows={filteredData}
                columns={columns}
                autoPageSize={false}
                pageSize={20}
                sx={{
                  boxShadow: 2,
                }}
              />
            </ErrorBoundary>
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
}
