import {
	Box,
	Button,
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getDissaproved } from "../../Actions/Actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import { size } from "lodash";
import { createTheme } from "@mui/material/styles";
import ExportReportBtn from "../Commons/ExportReportBtn";
import { STAGES } from "../../constants";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const defaultTheme = createTheme();

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				padding: theme.spacing(0.5, 0.5, 0),
				display: "flex",
				alignItems: "center",
			},
			textField: {
				[theme.breakpoints.down("xs")]: {
					width: "100%",
				},
				margin: theme.spacing(1, 0.5, 1.5),
				"& .MuiSvgIcon-root": {
					marginRight: theme.spacing(0.5),
				},
				"& .MuiInput-underline:before": {
					borderBottom: `1px solid ${theme.palette.divider}`,
				},
			},
		}),
	{ defaultTheme }
);

function QuickSearchToolbar(props) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div>
				<GridToolbarFilterButton />
			</div>
			<TextField
				id="outlined-basic"
				variant="outlined"
				type="month"
				onChange={(e) => {
					props.onChangeMonths(e.target.value + "-01");
				}}
				value={props.monthFilter}
				sx={{ ml: "auto" }}
			/>
			<FormControl sx={{ width: 200, mx: 2 }}>
				<InputLabel id="demo-simple-select-label">Stage</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					label="Stage"
					value={props.selectedStage}
					onChange={(e) => {
						props.selectOnChange(e);
					}}>
					<MenuItem value="All">All</MenuItem>
					{STAGES.map((item, index) => {
						return (
							<MenuItem key={index} value={item}>
								{item.toUpperCase()}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<TextField
				variant="standard"
				value={props.value}
				onChange={props.onChange}
				placeholder="Search…"
				className={classes.textField}
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: props.value ? "visible" : "hidden" }}
							onClick={props.clearSearch}>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
			<Button
				startIcon={<FilterAltOffIcon />}
				sx={{ ml: 1 }}
				onClick={props.handleClearFilters}>
				Clear filters
			</Button>
		</div>
	);
}

function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export default function ApprovedDissaproved() {
	const [data, setData] = useState([]);
	const [backUpData, setBackUpData] = useState([]);
	const [totalapproved, settotalapproved] = useState(0);
	const [totaldisapproved, settotaldisapproved] = useState(0);
	const [chartdata, setchartdata] = useState([]);
	const [pageSize, setPageSize] = React.useState(50);
	const [searchText, setSearchText] = React.useState("");
	const [rows, setRows] = React.useState([]);
	const [loading, setloading] = useState(false);
	const [selectedStage, setSelectedStage] = useState("All");
	const [monthfilter, setmonthfilter] = useState(
		moment().startOf("month").format("YYYY-MM-DD hh:mm")
	);
	const [filterDate, setFilterDate] = useState({ m: "", y: "" });

	useEffect(() => {
		const loadDissaprovedList = async () => {
			await loadAll();
		};

		loadDissaprovedList();
	}, []);

	const loadAll = async () => {
		const response = await getDissaproved();

		let approvedarray = [];
		let dissaprovedarray = [];
		let datearray = [];
		response.sort(compare);
		response.forEach((item) => {
			item.id = Number(item.Id);
			item.datestring = item.date;
			item.fullname = item.fullname?.toUpperCase();
			item.stage = item.stage ? item.stage.trim().toUpperCase() : item.stage;
		});

		console.log(groupBy(response, "status"));
		setBackUpData(response);
		setData(response);
	};

	let groupBy = function (xs, key) {
		return xs.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	function compare(a, b) {
		if (a.date < b.date) {
			return -1;
		}
		if (a.date > b.date) {
			return 1;
		}
		return 0;
	}

	const requestSearch = async (searchValue) => {
		let localData = [...backUpData];
		if (!searchValue) {
			setSearchText("");
			return localData;
		}
		const filteredArray = localData.filter((item) => {
			return selectedStage !== "All"
				? selectedStage.toUpperCase() === item.stage
				: item;
		});
		if (searchValue === "") {
			setSearchText(searchValue);
			return backUpData;
		} else {
			setSearchText(searchValue);
			const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
			const filteredRows = filteredArray.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field].toString());
				});
			});
			return filteredRows;
		}
	};

	useEffect(() => {
		if (size(data) > 0) {
			settotalapproved(
				size(data.filter((item) => item.status.trim() === "APPROVED"))
			);
			settotaldisapproved(
				size(data.filter((item) => item.status.trim() === "DISAPPROVED"))
			);
		}
	}, [data]);

	const columns = [
		{
			field: "stage",
			headerName: "Stage",
			width: 500,
		},
		{
			field: "status",
			headerName: "Status",
			width: 200,
			editable: false,
			renderCell: (params) => <DrawerStatus params={params} />,
		},
		{
			field: "datestring",
			headerName: "Desition Date",
			renderCell: (params) => (
				<Typography> {moment(params.row.datestring).format("ll")}</Typography>
			),

			width: 200,
		},
		{
			field: "fullname",
			headerName: "Full name",
			width: 300,
			editable: false,
		},
		{
			field: "reason",
			headerName: "Reason",

			width: 1000,
		},
		{
			field: "company",
			headerName: "Company",
			width: 200,
		},

		{
			field: "department",
			headerName: "Departament",
			width: 200,
		},
		{
			field: "position",
			headerName: "Position",
			width: 200,
		},
		{
			field: "idproceso",
			headerName: "Flokzu ID",
			width: 150,
		},
	];

	const DrawerStatus = ({ params }) => {
		let value = params.row.status;
		if (value) {
			if (value === "APPROVED") {
				return (
					<>
						<CheckCircleIcon sx={{ color: "green" }} />
						<Typography>{value}</Typography>
					</>
				);
			} else {
				return (
					<>
						<ReportOffIcon sx={{ color: "red" }} />
						<Typography>{value}</Typography>
					</>
				);
			}
		} else {
			return <Typography>{value}</Typography>;
		}
	};

	const getCsvData = () => {
		console.log(data);
		const csvData = [];
		const headers = columns.map((item) => {
			return item.headerName;
		});
		data.forEach((item) => {
			csvData.push({
				stage: item.stage,
				status: item.status,
				datestring: item.date,
				fullname: item.fullname,
				reason: item.reason,
				company: item.company,
				department: item.department,
				position: item.position,
				idproceso: item.idproceso,
			});
		});
		return [headers, csvData];
	};

	const handleFilter = async (textFilter, stageFilter, monthFilter) => {
		console.log("filters", monthFilter);
		console.log(stageFilter);
		const textFilteredData = await requestSearch(textFilter);
		const stageFilteredData = textFilteredData.filter((item) => {
			return stageFilter
				? stageFilter === "All"
					? item
					: item.stage.toUpperCase() === stageFilter.toUpperCase()
				: item;
		});
		const monthFilteredData = onChangeMonths(monthFilter, stageFilteredData);
		setData(monthFilteredData);
	};

	const onChangeMonths = (datechange, dataToBeFiltered) => {
		if (!datechange) return dataToBeFiltered;
		let m = new Date(datechange).getMonth();
		let y = new Date(datechange).getUTCFullYear();

		let firstday = getFirstDay(datechange);
		let lastday = getLastDay(datechange);

		let data = dataToBeFiltered.filter(
			(item) =>
				new Date(item.date) >= firstday && new Date(item.date) <= lastday
		);
		setFilterDate({
			m: moment()
				.month(m + 1)
				.format("M"),
			y: y,
		});
		setmonthfilter(datechange);
		return data;
	};

	const getFirstDay = (datevalue) => {
		let y = new Date(datevalue).getFullYear();
		let m = new Date(datevalue).getMonth();
		return new Date(y, Number(m) + 1, 1);
	};

	const getLastDay = (datevalue) => {
		let y = new Date(datevalue).getFullYear();
		let m = new Date(datevalue).getMonth();
		return new Date(y, Number(m) + 2, 0);
	};

	return (
		<>
			<Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
				<Paper
					sx={{
						p: 3,
						display: "flex",
						flexDirection: "column",
					}}>
					<Typography variant="h5" color="initial">
						Approved & dissapproved
					</Typography>

					<Grid container>
						<Grid
							item
							xs={4}
							sx={{
								textAlign: "center",
							}}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									flexWrap: "wrap",
								}}>
								<CheckCircleIcon sx={{ color: "green" }} />
								Total Approved: {totalapproved}
							</div>
						</Grid>
						<Grid
							item
							xs={4}
							sx={{ textAlign: "center", verticalAlign: "top" }}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									flexWrap: "wrap",
								}}>
								<ReportOffIcon sx={{ color: "red" }} />
								Total Disappoved: {totaldisapproved}
							</div>
						</Grid>
						<Grid
							item
							xs={4}
							sx={{ textAlign: "center", verticalAlign: "top" }}>
							<ExportReportBtn getData={getCsvData} />
						</Grid>
					</Grid>
					<Box sx={{ width: "100%", height: "80vh", marginTop: 3 }}>
						<DataGrid
							rows={data}
							columns={columns}
							pagination
							rowsPerPageOptions={[20, 50, 100]}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							initialState={{
								pagination: {
									pageSize: pageSize,
								},
							}}
							components={{ Toolbar: QuickSearchToolbar }}
							componentsProps={{
								toolbar: {
									value: searchText,
									onChange: (event) => {
										requestSearch(event.target.value);
										handleFilter(event.target.value, null);
									},
									clearSearch: () => requestSearch(""),
									selectOnChange: (e) => {
										setSelectedStage(e.target.value);
										handleFilter(null, e.target.value);
									},
									handleClearFilters: () => {
										setSelectedStage("All");
										requestSearch("");
										setFilterDate({ m: "", y: "" });
										setData(backUpData);
									},
									onChangeMonths: (monthValue) => {
										handleFilter(null, null, monthValue);
									},
									filterDate: filterDate,
									monthfilter: monthfilter,
									selectedStage,
								},
								pagination: { classes: null },
							}}
						/>
					</Box>
				</Paper>
			</Container>
		</>
	);
}
