import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getData } from "../../Actions/Actions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import ExportReportBtn from "../Commons/ExportReportBtn";
const mdTheme = createTheme();

const columns = [
  {
    field: "id",
    headerName: "Edit",
    width: 50,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/edituser?id=${params.value}`,
          state: { id: params.value },
        }}
        reloadDocument>
        <EditIcon style={{ fontSize: 25 }} color="primary" />
      </Link>
    ),
    sortable: false,
  },

  {
    headerName: "Manage",
    width: 80,
    renderCell: (params) => {
      console.log(params.row);
      return (
        <Link
          to={{
            pathname: `/asignpositionsavailable?id=${params.row.id}`,
            state: { id: params.row.id },
          }}
          style={params.row.rol === "EXTERNAL" ? {} : { display: "none" }}
          reloadDocument>
          <SettingsIcon style={{ fontSize: 25 }} color="primary" />
        </Link>
      );
    },
    sortable: false,
  },

  {
    field: "firstName",
    headerName: "Name",
    width: 150,
    editable: true,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 150,
    editable: true,
  },
  {
    field: "company",
    headerName: "Company",
    width: 250,
  },

  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
  {
    field: "rol",
    headerName: "Rol",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
];

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default function Users() {
  const [loading, setloading] = useState(false);
  const [pageSize, setPageSize] = React.useState(50);
  const [searchText, setSearchText] = React.useState("");
  const [data, setdata] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadResults = async () => {
      setloading(true);
      const resultado = await getData("Users");
      setdata(resultado);
      console.log(resultado);
      setloading(false);
    };

    loadResults();
  }, []);

  const requestSearch = async (searchValue) => {
    if (searchValue === "") {
      setSearchText(searchValue);

      setloading(true);
      const resultado = await getData();
      setdata(resultado);
      setloading(false);
    } else {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = data.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
      setdata(filteredRows);
    }
  };

  const getCsvData = () => {
    const headers = [];
    const csvData = [];
    columns.forEach((item) => {
      if (item.headerName === "Edit" || item.headerName === "Manage") return;
      headers.push(item.headerName);
    });
    data.forEach((item) => {
      csvData.push({
        firstName: item.firstName,
        lastName: item.lastName,
        company: item.company,
        email: item.email,
        rol: item.rol,
        status: item.status,
      });
    });
    return [headers, csvData];
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ mt: 10, width: "100%", px: 5, pb: 3 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              height: "90vh",
            }}>
            <Title>Users</Title>
            <Grid container>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{ marginBottom: 2 }}>
                <ExportReportBtn getData={getCsvData} />
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/createuser");
                  }}
                  sx={{ ml: 2 }}
                  startIcon={<AddCircleOutlineRoundedIcon />}>
                  ADD NEW USER
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={data}
              columns={columns}
              sx={{
                boxShadow: 2,
              }}
              loading={loading}
              pagination
              rowsPerPageOptions={[20, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              initialState={{
                pagination: {
                  pageSize: pageSize,
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => {
                    requestSearch(event.target.value);
                  },
                  clearSearch: () => requestSearch(""),
                },
                pagination: { classes: null },
              }}
            />
          </Paper>
        </Grid>
      </Box>
    </Box>
  );
}
