import React, { useState, useEffect, useContext } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getData } from "../../Actions/Actions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../Commons/ErrorBoundary";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GlobalContext } from "../../context/GlobalContext";

const columns = [
  {
    field: "id",
    headerName: "Acciones",
    width: 100,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/pendingupdate?id=${params.value}`,
          state: { id: params.value },
        }}
        reloadDocument>
        <VisibilityIcon style={{ fontSize: 25 }} color="primary" />
      </Link>
    ),
    sortable: false,
  },

  {
    field: "pmssaid",
    headerName: "PMSSA ID",
    width: 80,
    editable: true,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    width: 300,
    editable: true,
  },
  {
    field: "createDate",
    headerName: "createDate",
    width: 250,
    renderCell: (params) => (
      <Typography>
        {" "}
        {moment(params.row.createDate.toDate()).format("LL")}
      </Typography>
    ),
  },

  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: true,
    renderCell: (params) => <StatusComponent params={params} />,
  },
];

const getStatusStyle = (status) => {
  let baseStyle = {
    width: 15,
    height: 15,
    mr: 1,
    borderRadius: "50%",
  };
  if (status === "Updated") baseStyle.backgroundColor = "green";
  if (status === "pending") baseStyle.backgroundColor = "orange";
  if (status === "Finished") baseStyle.backgroundColor = "gray";
  return baseStyle;
};

const StatusComponent = ({ params }) => {
  let value = params.row.status;
  return (
    <>
      <Box sx={getStatusStyle(value)} />
      <Typography variant="body1" color="initial">
        {value}
      </Typography>
    </>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function Companies() {
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);
  const [showFinished, setShowFinished] = useState(false);
  const { updateInfo } = useContext(GlobalContext);

  useEffect(() => {
    const filteredData = updateInfo.filter((item) => {
      return item.status !== "Finished";
    });
    console.log(filteredData);
    setData(filteredData);
  }, [updateInfo]);

  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              height: "80vh",
            }}>
            <Title>UPDATE STAFF DATA</Title>
            <Grid container>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{ marginBottom: 2 }}>
                <Typography sx={{ mr: 1 }}>
                  Show finished staff updates
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      checked={showFinished}
                      onChange={() => setShowFinished(!showFinished)}
                    />
                  }
                />
              </Grid>
            </Grid>
            <ErrorBoundary>
              {updateInfo && (
                <DataGrid
                  rows={showFinished ? updateInfo : data}
                  columns={columns}
                  autoPageSize={false}
                  pageSize={20}
                  sx={{
                    boxShadow: 2,
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  loading={loading}
                />
              )}
            </ErrorBoundary>
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
}
