import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {
  getPersonById,
  loadVesselType,
  loadDepartments,
  loadPositions,
  updateDataPersonal,
  vesselTypeDetail,
  typeofdocument,
  loadCertificates,
  uploadDocumentToFirebase,
  addRecord,
  getFirebaseAttachments,
  getFirebaseAttachmentsExternal,
  getRecordById,
  updateRecord,
  infoUpdateRequest,
  getCountryCodes,
} from "../../Actions/Actions";
import { v4 as uuidv4 } from "uuid";
import _, { isEmpty, isObject, isEqual, isArray, transform } from "lodash";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { contrylistdata } from "../Commons/CountryList";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExperienceOnBoard from "./ExperienceOnBoard";
import ExperienceOnShore from "./ExperienceOnShore";
import LicBookSTCW from "./LicBookSTCW";
import OtherTrainings from "./OtherTrainings";
import COCTable from "./CocTable";
import AddCOC from "../DataViewComponents/AddCOC";
import AddNewExperienceOnBoard from "../DataViewComponents/AddNewExperienceOnBoard";
import AddNewExperienceOnShore from "../DataViewComponents/AddNewExperienceOnShore";
import AttachmentTableExternal from "../DataViewComponents/AttachmentTableExternal";
import DocumentsTab from "./DocumentsTab";
import AddDocumnets from "../DataViewComponents/AddDocuments";
import AddTrainings from "../DataViewComponents/AddTrainings";
import AddOtherTrainings from "../DataViewComponents/AddOtherTrainings";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AddAttachmentExternal from "../DataViewComponents/AddAttachmentExternal";
import LoadingProgress from "../Commons/LoadingProgress";
import logo from "../../assets/logo.jpg";
import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";

/***********DECLARACIÓN DE OBJETOS**************/
const emptycoc = {
  contry: "PANAMA",
  issueatplace: "",
  no: "",
  issuedate: null,
  validdate: null,
};

const otherattachment = {
  date: new Date(),
  description: "",
  donwloadkey: "",
  id: "",
  idpersonal: "",
  name: "",
  reference: "",
  source: "FIREBASE",
  type: "OTHER DOCUMENTS",
  url: "",
  attachmentType: "",
};

const emptydoc = {
  doctype: "",
  contry: "",
  issueatplace: "",
  no: "",
  issuedate: null,
  validdate: null,
};

const emptyexperience = {
  dateon: null,
  dateoff: null,
  companyname: "",
  vesselname: "",
  imo: "",
  gthp: "",
  typevessel: "",
  "Rank/position": "",
};

const emptycertificate = {
  description: "",
  contry: "",
  number: "",
  issuedate: null,
  expireddate: null,
};
/*****FIN DECLARACIÓN DE OBJETOS*************/

/***************INICIO********************/
export default function UpdatePersonalInfo({ isview }) {
  let QUERY = window.location.search;
  const [data, setdata] = useState({});
  const [value, setValue] = React.useState(0);
  const [valuemain, setValuemain] = React.useState(0);
  const [contry, setcontry] = useState("");
  const [vessel, vessellist] = useState([]);
  const [deparmentlist, setdepartmentlist] = useState([]);
  const [positionslist, setpositionlist] = useState([]);
  const [loading, setloading] = useState(false);
  const [showcocpanel, setshowcocpanel] = useState(false);
  const [newcoc, setnewcoc] = useState(emptycoc);
  const [cocloading, setcocloading] = useState(false);
  const [vesseltypedetails, setvesseltypedetails] = useState([]);
  const [listtypeofdocument, setlisttypeofdocument] = useState([]);
  const [showexperienceboard, setshowexperienceboard] = useState(false);
  const [newexperienceboard, setnewexperienceboard] = useState(emptyexperience);
  const [experienceboardloading, setexperienceboardloading] = useState(false);
  const [showexperienceshore, setshowexperienceshore] = useState(false);
  const [newexperienceshore, setnewexperienceshore] = useState(emptyexperience);
  const [experienceshoreloading, setexperienceshoreloading] = useState(false);
  const [showdocpanel, setshowdocpanel] = useState(false);
  const [newdoc, setnewdoc] = useState(emptydoc);
  const [docloading, setdocloading] = useState(false);
  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);
  const [certificatelist, setcertificatelist] = useState([]);
  const [showcertificates, setshowcertificates] = useState(false);
  const [showothercert, setshowothercert] = useState(false);
  const [newcertificate, setnewcertificate] = useState(emptycertificate);
  const [newothercertificate, setnewothercertificate] =
    useState(emptycertificate);
  const [loadingcert, setloadingcert] = useState(false);
  const [attachmentlist, setatachmentlist] = useState([]);
  const [isedit, setisedit] = useState(false);
  const [backup, setbackup] = useState({});
  const [loadingprogress, setloadingprogress] = useState(false);
  const [embarcations, setembarcations] = useState([]);
  const [completerecod, setcompleterecord] = useState({});
  const [isSent, setIsSent] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [changes, setChanges] = useState({});
  const [dbData, setDbData] = useState([]);
  const [countryCodes, setCountryCodes] = useState("");
  const [showattachments, setshowattachments] = useState(false);
  const [newattachments, setnewattachments] = useState(otherattachment);
  const [attachmentTableData, setAttachmentTableData] =
    useState(attachmentlist);

  const navigate = useNavigate();

  useEffect(() => {
    setAttachmentTableData(attachmentlist);
  }, [attachmentlist]);

  useEffect(() => {
    const loadData = async () => {
      loadDataPerson();
    };
    loadData();
  }, []);

  function Newdifference(origObj, newObj) {
    function changes(newObj, origObj) {
      let arrayIndexCounter = 0;
      return transform(newObj, function (result, value, key) {
        if (
          value &&
          !isObject(value) &&
          !isEqual(JSON.stringify(value), JSON.stringify(origObj[key]))
        ) {
          let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
          result[resultKey] =
            isObject(value) && isObject(origObj[key])
              ? changes(value, origObj[key])
              : value;
        }
      });
    }
    return changes(newObj, origObj);
  }

  /* const checkfileExist = (docType, obj) => {
    if (docType === "COC") {
      let cocArray = JSON.parse(dbData[0].COC);
      const isExist = cocArray.some((item) =>
        Object.entries(obj).every(([key, value]) => item[key] === value)
      );
      return isExist;
    }
  }; */

  const isNewData = (value) => {
    return value in changes;
  };

  const loadDataPerson = async () => {
    let id = QUERY.replace("?id=", "");
    setloadingprogress(true);
    // const resultado = await getPersonById(id);
    const persona1 = await getRecordById("UpdateRequests", id);
    const barquitos = await loadVesselType();
    const departments = await loadDepartments();
    const positions = await loadPositions();
    const vesseldetails = await vesselTypeDetail();
    const typedocs = await typeofdocument();
    const country_codes = await getCountryCodes();
    const certificates = await loadCertificates();
    let firebaseattachment = [];
    if (isview === 1) {
      if (persona1.status === "Updated" || persona1.status === "Finished") {
        setIsRequestSent(true);
        return;
      }
    }
    if (isview !== 1) {
      const origPersona = await getPersonById(persona1.pmssaid);
      const dataDiff = Newdifference(origPersona[0], persona1.data);
      setDbData(origPersona);
      setChanges(dataDiff);
    }
    const resultado = persona1.data;
    console.log("resultado es lo que viene");
    console.log(resultado);

    firebaseattachment = await getFirebaseAttachmentsExternal(
      "Attachment",
      persona1.pmssaid
    );

    setatachmentlist(firebaseattachment);
    setcompleterecord(persona1);
    setcertificatelist(certificates);
    setlisttypeofdocument(typedocs);
    setvesseltypedetails(vesseldetails);
    setpositionlist(positions);
    setCountryCodes(country_codes);
    setdepartmentlist(departments);
    setdata(resultado);
    vessellist(barquitos);
    setloadingprogress(false);
  };

  const uploadFileServer = async (type, description, newType) => {
    if (file) {
      let url = await uploadDocumentToFirebase(file);
      const upload = {
        id: uuidv4(),
        name: file.name,
        description: description,
        type: "DOCUMENTS",
        date: moment(new Date()).format("lll"),
        downloadkey: "",
        reference: "",
        source: "FIREBASE",
        url: url,
        idpersonal: data["PMSSA ID NO"],
        attachmentType: newType,
        external: true,
      };
      showToast("File was added succesfull.", 1);
      setnewattachments(otherattachment);

      setAttachmentTableData([...attachmentTableData, upload]);
    }
  };

  const cleanFile = () => {
    if (file) {
      setFile("");
    }
  };

  const deleteRecord = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Type of Document"] === row[["Type of Document"]] &&
        item["Country of Issue"] === row["Country of Issue"] &&
        item["Issued at (Place)"] === row["Issued at (Place)"] &&
        item["No."] === row["No."] &&
        item["Date of Issue"] === row["Date of Issue"] &&
        item["Valid Until"] === row["Valid Until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });
    let datos = {
      Id: QUERY.replace("?id=", ""),
      type_document: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteCOC = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Country of issue"] === row[["Country of issue"]] &&
        item["Issue at (place)"] === row["Issue at (place)"] &&
        item["No."] === row["No."] &&
        item["Date of issue"] === row["Date of issue"] &&
        item["Date of Issue"] === row["Date of Issue"] &&
        item["Valid until"] === row["Valid until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      COC: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteLicBook = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Description of cert / course"] ===
          row[["Description of cert / course"]] &&
        item["Country of issue"] === row["Country of issue"] &&
        item["Number"] === row["Number"] &&
        item["Date of issue"] === row["Date of issue"] &&
        item["Date of expiry"] === row["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      lic_sbook_stcw_certificates: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const deleteOther = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Description of cert / course"] ===
          row[["Description of cert / course"]] &&
        item["Country of issue"] === row["Country of issue"] &&
        item["Number"] === row["Number"] &&
        item["Date of issue"] === row["Date of issue"] &&
        item["Date of expiry"] === row["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      other_training: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  /**EDIT NEW DOCUMENT*/

  const editNewDocument = (row) => {
    const emptydoc = {
      doctype: row["Type of Document"],
      contry: row["Country of Issue"],
      issueatplace: row["Issued at (Place)"],
      no: row["No."],
      issuedate: row["Date of Issue"],
      validdate: row["Valid Until"],
    };

    setbackup(row);
    setisedit(true);
    setnewdoc(emptydoc);
    setshowdocpanel(true);
  };

  const saveDocument = async () => {
    //ELIMINAR EL DOCUMENTO ANTERIOR DEL ARRAY.

    let newarray = [];
    let datos = JSON.parse(data.type_document);

    datos.map((item) => {
      if (
        item["Type of Document"] === backup[["Type of Document"]] &&
        item["Country of Issue"] === backup["Country of Issue"] &&
        item["Issued at (Place)"] === backup["Issued at (Place)"] &&
        item["No."] === backup["No."] &&
        item["Date of Issue"] === backup["Date of Issue"] &&
        item["Valid Until"] === backup["Valid Until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });
    //addnew
    //newadday.push()

    if (isEmpty(newdoc.doctype)) {
      showToast("Please select a Document Type", 3);
    } else {
      setdocloading(true);

      let insertdata = {
        "Type of Document": newdoc.doctype,
        "Country of Issue": newdoc.contry,
        "No.": newdoc.no,
        "Date of Issue":
          newdoc.issuedate === null
            ? ""
            : moment(newdoc.issuedate).format("YYYY/MM/DD"),
        "Issued at (Place)": newdoc.issueatplace,
        "Valid Until":
          newdoc.validdate === null
            ? ""
            : moment(newdoc.validdate).format("YYYY/MM/DD"),
      };
      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        type_document: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      setdocloading(false);
      await loadDataPerson();
      setnewdoc(emptydoc);
      handleDocClose();
      setFile("");
      setbackup({});
      setisedit(false);
      setshowdocpanel(false);
      showToast("Update Sucessful", 1);
    }
  };

  const editCOC = (row) => {
    const emptyCOC = {
      contry: row["Country of issue"],
      issueatplace: row["Issue at (place)"],
      no: row["No."],
      issuedate: row["Date of issue"],
      validdate: row["Valid until"],
    };

    setbackup(row);
    setisedit(true);
    setnewcoc(emptyCOC);
    setshowcocpanel(true);
  };

  const saveCOC = async () => {
    let newarray = [];
    let datos = JSON.parse(data.COC);
    setcocloading(true);
    datos.map((item) => {
      if (
        item["Country of issue"] === backup["Country of issue"] &&
        item["Issue at (place)"] === backup["Issue at (place)"] &&
        item["No."] === backup["No."] &&
        item["Date of issue"] === backup["Date of issue"] &&
        item["Valid until"] === backup["Valid until"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let insertdata = {
      "Country of issue": newcoc.contry,
      "No.": newcoc.no,
      "Date of issue":
        newcoc.issuedate === null
          ? ""
          : moment(newcoc.issuedate).format("YYYY/MM/DD"),
      "Issue at (place)": newcoc.issueatplace,
      "Valid until":
        newcoc.validdate === null
          ? ""
          : moment(newcoc.validdate).format("YYYY/MM/DD"),
    };

    newarray.push(insertdata);

    let datos1 = {
      Id: QUERY.replace("?id=", ""),
      COC: newarray,
    };
    const respuesta = await updateDataPersonal(datos1);
    console.log(respuesta);

    setcocloading(false);
    await loadDataPerson();
    setnewcoc(emptycoc);
    handleCOCClose();
    setbackup({});
    setisedit(false);
    showToast("COC Updated", 1);
  };

  const editexperienceonboard = (row) => {
    const emptyexperience = {
      dateon: row["Date on"],
      dateoff: row["Date off"],
      companyname: row["Company name"],
      vesselname: row["Vessel name"],
      imo: row["IMO #"],
      gthp: row["GT / HP"],
      typevessel: row["Type of vessel"],
      "Rank/position": row["Rank/position"],
    };

    setbackup(row);
    setisedit(true);
    setnewexperienceboard(emptyexperience);
    setshowexperienceboard(true);
  };

  const saveExperienceOnBoard = async () => {
    let newarray = [];
    let datos = JSON.parse(data.experience_onboard);
    datos.map((item) => {
      if (
        item["Date on"] === backup["Date on"] &&
        item["Date off"] === backup["Date off"] &&
        item["Company name"] === backup["Company name"] &&
        item["Vessel name"] === backup["Vessel name"] &&
        item["IMO #"] === backup["IMO #"] &&
        item["GT / HP"] === backup["GT / HP"] &&
        item["Type of vessel"] === backup["Type of vessel"] &&
        item["Rank/position"] === backup["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newexperienceboard.companyname)) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceboardloading(true);

      const insertdata = {
        "Date on":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceboard.companyname,
        "Vessel name": newexperienceboard.vesselname,
        "IMO #": newexperienceboard.imo,
        "GT / HP": newexperienceboard.gthp,
        "Type of vessel": newexperienceboard.typevessel,
        "Rank/position": newexperienceboard["Rank/position"],
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onboard: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      await loadDataPerson();
      setexperienceboardloading(false);
      setnewexperienceboard(emptyexperience);
      handleExperienceBoardClose();
      setbackup({});
      setisedit(false);
      showToast("Experience On Board was Updated Sucessful", 1);
    }
  };

  const editexperienceonshore = (row) => {
    const emptyexperience = {
      dateon: row["Date on"],
      dateoff: row["Date off"],
      companyname: row["Company name"],
      vesselname: row["Vessel name"],
      imo: row["IMO #"],
      gthp: row["GT / HP"],
      typevessel: row["Type of vessel"],
      "Rank/position": row["Rank/position"],
    };

    setbackup(row);
    setisedit(true);
    setnewexperienceshore(emptyexperience);
    setshowexperienceshore(true);
  };

  const saveExperienceOnShore = async () => {
    let newarray = [];
    let datos = JSON.parse(data.experience_onshore);
    datos.map((item) => {
      if (
        item["Date on"] === backup["Date on"] &&
        item["Date off"] === backup["Date off"] &&
        item["Company name"] === backup["Company name"] &&
        item["Vessel name"] === backup["Vessel name"] &&
        item["IMO #"] === backup["IMO #"] &&
        item["GT / HP"] === backup["GT / HP"] &&
        item["Type of vessel"] === backup["Type of vessel"] &&
        item["Rank/position"] === backup["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newexperienceshore.companyname)) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceshoreloading(true);

      const insertdata = {
        "Date on":
          newexperienceshore.dateon === null
            ? ""
            : moment(newexperienceshore.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceshore.dateon === null
            ? ""
            : moment(newexperienceshore.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceshore.companyname,
        "Vessel name": newexperienceshore.vesselname,
        "IMO #": newexperienceshore.imo,
        "GT / HP": newexperienceshore.gthp,
        "Type of vessel": newexperienceshore.typevessel,
        "Rank/position": newexperienceshore["Rank/position"],
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        experience_onshore: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      await loadDataPerson();
      setexperienceshoreloading(false);
      setnewexperienceshore(emptyexperience);
      handleExperienceshoreClose();
      setbackup({});
      setisedit(false);
      showToast("Updated Sucessful", 1);
    }
  };

  const editnewLicBook = (row) => {
    const emptybook = {
      description: row["Description of cert / course"],
      contry: row["Country of issue"],
      number: row["Number"],
      issuedate: row["Date of Issue"],
      expireddate: row["Date of expiry"],
    };

    setbackup(row);
    setisedit(true);
    setnewcertificate(emptybook);
    setshowcertificates(true);
  };

  const handleOpenAttachments = () => {
    setshowattachments(true);
  };

  const saveLicBook = async () => {
    let newarray = [];
    let datos = JSON.parse(data.lic_sbook_stcw_certificates);

    console.log("certificado", newcertificate);

    datos.map((item) => {
      if (
        item["Description of cert / course"] ===
          backup["Description of cert / course"] &&
        item["Country of issue"] === backup["Country of issue"] &&
        item["Number"] === backup["Number"] &&
        item["Date of issue"] === backup["Date of issue"] &&
        item["Date of expiry"] === backup["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newcertificate.description)) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newcertificate.description,
        "Country of issue": newcertificate.contry,
        Number: newcertificate.number,
        "Date of issue":
          newcertificate.issuedate === null
            ? ""
            : moment(newcertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newcertificate.expireddate === null
            ? ""
            : moment(newcertificate.expireddate).format("YYYY/MM/DD"),
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        lic_sbook_stcw_certificates: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      console.log(respuesta);
      //await uploadFileServer("LIC BOOK STCW", newcertificate.description);

      await loadDataPerson();
      setnewcertificate(emptycertificate);
      handleCertificatesClose();
      setloadingcert(false);
      setbackup({});
      setisedit(false);
      showToast("Update Sucesfull", 1);
    }
  };

  const editothertrainings = (row) => {
    const emptybook = {
      description: row["Description of cert / course"],
      contry: row["Country of issue"],
      number: row["Number"],
      issuedate: row["Date of Issue"],
      expireddate: row["Date of expiry"],
    };

    setbackup(row);
    setisedit(true);
    setnewothercertificate(emptybook);
    setshowothercert(true);
  };

  const saveOtherCertificate = async () => {
    let newarray = [];
    let datos = JSON.parse(data.other_training);

    datos.map((item) => {
      if (
        item["Description of cert / course"] ===
          backup["Description of cert / course"] &&
        item["Country of issue"] === backup["Country of issue"] &&
        item["Number"] === backup["Number"] &&
        item["Date of issue"] === backup["Date of issue"] &&
        item["Date of expiry"] === backup["Date of expiry"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    if (isEmpty(newothercertificate.description)) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newothercertificate.description,
        "Country of issue": newothercertificate.contry,
        Number: newothercertificate.number,
        "Date of issue":
          newothercertificate.issuedate === null
            ? ""
            : moment(newothercertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newothercertificate.expireddate === null
            ? ""
            : moment(newothercertificate.expireddate).format("YYYY/MM/DD"),
      };

      newarray.push(insertdata);

      let datos = {
        Id: QUERY.replace("?id=", ""),
        other_training: newarray,
      };

      const respuesta = await updateDataPersonal(datos);
      // await uploadFileServer(
      // 	"OTHER TRAININGS",
      // 	newothercertificate.description
      // );
      console.log(respuesta);
      await loadDataPerson();
      setnewothercertificate(emptycertificate);
      handleOtherCertClose();
      setloadingcert(false);
      setbackup({});
      setisedit(false);
      showToast("Update Sucesfull", 1);
    }
  };

  /*EDIT NEW DOCUMENT*/
  /****************CERTIFICATES******************/
  const handleCertificatesOpen = () => setshowcertificates(true);
  const handleCertificatesClose = () => {
    setshowcertificates(false);
    cleanFile();
  };

  const deleteExperienceOnBoard = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Date on"] === row[["Date on"]] &&
        item["Date off"] === row["Date off"] &&
        item["Company name"] === row["Company name"] &&
        item["Vessel name"] === row["Vessel name"] &&
        item["IMO #"] === row["IMO #"] &&
        item["GT / HP"] === row["GT / HP"] &&
        item["Type of vessel"] === row["Type of vessel"] &&
        item["Rank/position"] === row["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      experience_onboard: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };
  const deteleExperienceOnShore = async (data, row) => {
    let newarray = [];

    data.map((item) => {
      if (
        item["Date on"] === row[["Date on"]] &&
        item["Date off"] === row["Date off"] &&
        item["Company name"] === row["Company name"] &&
        item["Vessel name"] === row["Vessel name"] &&
        item["IMO #"] === row["IMO #"] &&
        item["GT / HP"] === row["GT / HP"] &&
        item["Type of vessel"] === row["Type of vessel"] &&
        item["Rank/position"] === row["Rank/position"]
      ) {
        console.log("ignorar");
      } else {
        newarray.push(item);
      }
    });

    let datos = {
      Id: QUERY.replace("?id=", ""),
      experience_onshore: newarray,
    };
    const respuesta = await updateDataPersonal(datos);
    await loadDataPerson();
  };

  const handleChangeCertSelect = (e) => {
    setnewcertificate({ ...newcertificate, description: e.target.value });
  };

  const handleChangeCertIssueDate = (e) => {
    setnewcertificate({ ...newcertificate, issuedate: e });
  };

  const handleChangeCertExpiredDate = (e) => {
    setnewcertificate({ ...newcertificate, expireddate: e });
  };

  const handleCountryCert = (e) => {
    setnewcertificate({ ...newcertificate, contry: e.target.value });
  };

  const onchangeCert = (event) => {
    setnewcertificate({
      ...newcertificate,
      [event.target.name]: event.target.value,
    });
  };

  const addNewCertificate = async () => {
    if (isEmpty(newcertificate.description)) {
      showToast("Please Choose a Certificate", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newcertificate.description,
        "Country of issue": newcertificate.contry,
        Number: newcertificate.number,
        "Date of issue":
          newcertificate.issuedate === null
            ? ""
            : moment(newcertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newcertificate.expireddate === null
            ? ""
            : moment(newcertificate.expireddate).format("YYYY/MM/DD"),
      };

      let docdatos = isEmpty(data.lic_sbook_stcw_certificates)
        ? []
        : JSON.parse(data.lic_sbook_stcw_certificates);
      docdatos.push(insertdata);

      let datos = {
        ...data,
        lic_sbook_stcw_certificates: JSON.stringify(docdatos),
      };

      const post = {
        ...completerecod,
        data: datos,
        updatedDate: new Date(),
      };

      const respuesta = await updateRecord(
        "UpdateRequests",
        QUERY.replace("?id=", ""),
        post
      );
      console.log("DATOS", respuesta);
      //await uploadFileServer("LIC BOOK STCW", newcertificate.description);

      await loadDataPerson();
      setnewcertificate(emptycertificate);
      handleCertificatesClose();
      showToast("Certificate Added Sucessful", 1);
      setloadingcert(false);
    }
  };

  /*****************FIN CERTIFICATES************/

  /*****************OTHER CERTIFICATES*********/
  const handleOtherCertOpen = () => setshowothercert(true);
  const handleOtherCertClose = () => {
    setshowothercert(false);
    cleanFile();
  };

  const handleChangeOtherCertIssueDate = (e) => {
    setnewothercertificate({ ...newothercertificate, issuedate: e });
  };

  const handleChangeOtherCertExpiredDate = (e) => {
    setnewothercertificate({ ...newothercertificate, expireddate: e });
  };

  const handleCountryOtherCert = (e) => {
    setnewothercertificate({ ...newothercertificate, contry: e.target.value });
  };

  const onchangeOtherCert = (event) => {
    setnewothercertificate({
      ...newothercertificate,
      [event.target.name]: event.target.value,
    });
  };

  const addNewOtherCertificate = async () => {
    if (isEmpty(newothercertificate.description)) {
      showToast("Please add a Certificate description", 3);
    } else {
      setloadingcert(true);

      let insertdata = {
        "Description of cert / course": newothercertificate.description,
        "Country of issue": newothercertificate.contry,
        Number: newothercertificate.number,
        "Date of issue":
          newothercertificate.issuedate === null
            ? ""
            : moment(newothercertificate.issuedate).format("YYYY/MM/DD"),
        "Date of expiry":
          newothercertificate.expireddate === null
            ? ""
            : moment(newothercertificate.expireddate).format("YYYY/MM/DD"),
      };

      let docdatos = isEmpty(data.other_training)
        ? []
        : JSON.parse(data.other_training);
      docdatos.push(insertdata);

      let datos = {
        ...data,
        other_training: JSON.stringify(docdatos),
      };

      const post = {
        ...completerecod,
        data: datos,
        updatedDate: new Date(),
      };

      const respuesta = await updateRecord(
        "UpdateRequests",
        QUERY.replace("?id=", ""),
        post
      );
      console.log("DATOS", respuesta);

      await loadDataPerson();
      setnewothercertificate(emptycertificate);
      handleOtherCertClose();
      setloadingcert(false);
      showToast("Certificate Added Sucessful", 1);
    }
  };

  /**++++++++++++++++FIN OTHER CERTIFICATES****+*/

  /****************DOCUMENTS*********************/
  const handleDocsOpen = () => {
    setshowdocpanel(true);
  };
  const handleDocClose = () => {
    setshowdocpanel(false);
    cleanFile();
  };

  const handleChangeDocIssueDate = (e) => {
    setnewdoc({ ...newdoc, issuedate: e });
  };

  const handleChangeDocValidDate = (e) => {
    setnewdoc({ ...newdoc, validdate: e });
  };

  const handleChangeContrySelect = (e) => {
    setnewdoc({ ...newdoc, contry: e.target.value });
  };

  const handleChangeIssueDate = (e) => {
    setnewcoc({ ...newcoc, issuedate: e });
  };

  const handleChangeValidDate = (e) => {
    setnewcoc({ ...newcoc, validdate: e });
  };
  const handleDocChangeSelect = (e) => {
    setnewdoc({ ...newdoc, doctype: e.target.value });
  };

  const handleChangeSelect = (e) => {
    setnewcoc({ ...newcoc, contry: e.target.value });
  };

  const addNewDoc = async () => {
    if (isEmpty(newdoc.doctype)) {
      showToast("Please select a Document Type", 3);
    } else {
      setdocloading(true);
      setloadingprogress(true);

      let insertdata = {
        "Type of Document": newdoc.doctype,
        "Country of Issue": newdoc.contry,
        "No.": newdoc.no,
        "Date of Issue":
          newdoc.issuedate === null
            ? ""
            : moment(newdoc.issuedate).format("YYYY/MM/DD"),
        "Issued at (Place)": newdoc.issueatplace,
        "Valid Until":
          newdoc.validdate === null
            ? ""
            : moment(newdoc.validdate).format("YYYY/MM/DD"),
      };
      let docdatos = isEmpty(data.type_document)
        ? []
        : JSON.parse(data.type_document);
      docdatos.push(insertdata);

      let datos = {
        ...data,
        type_document: JSON.stringify(docdatos),
      };

      //   const respuesta = await updateDataPersonal(datos);

      const post = {
        ...completerecod,
        data: datos,
        updatedDate: new Date(),
      };

      const respuesta = await updateRecord(
        "UpdateRequests",
        QUERY.replace("?id=", ""),
        post
      );

      console.log("RESPUESTA ADD");
      console.log(respuesta);
      await loadDataPerson();
      handleDocClose();
      setdocloading(false);
      setnewdoc(emptydoc);
      setloadingprogress(false);
      showToast("Document Added Sucessful", 1);
    }
  };

  /***************END DOCUMENTS*****************/

  /****************EXPERIENCE ON BOARD***********/
  const onchangeExpBoard = (event) => {
    setnewexperienceboard({
      ...newexperienceboard,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeDateon = (e) => {
    setnewexperienceboard({ ...newexperienceboard, dateon: e });
  };

  const handleChangeDateoff = (e) => {
    setnewexperienceboard({ ...newexperienceboard, dateoff: e });
  };
  const handleChangeSelectExperience = (e) => {
    setnewexperienceboard({
      ...newexperienceboard,
      typevessel: e.target.value,
    });
  };
  const handleExperienceBoardClose = () => {
    setshowexperienceboard(false);
    cleanFile();
  };

  const handleExperienceBoardOpen = () => {
    setshowexperienceboard(true);
  };

  const addNewExpOnBoard = async () => {
    if (isEmpty(newexperienceboard.companyname)) {
      showToast("Please enter a company Name", 3);
    } else {
      setexperienceboardloading(true);

      const insertdata = {
        "Date on":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceboard.dateon === null
            ? ""
            : moment(newexperienceboard.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceboard.companyname,
        "Vessel name": newexperienceboard.vesselname,
        "IMO #": newexperienceboard.imo,
        "GT / HP": newexperienceboard.gthp,
        "Type of vessel": newexperienceboard.typevessel,
        "Rank/position": newexperienceboard["Rank/position"],
      };
      let experienceonboard = isEmpty(data.experience_onboard)
        ? []
        : JSON.parse(data.experience_onboard);
      experienceonboard.push(insertdata);

      let datos = {
        ...data,
        experience_onboard: JSON.stringify(experienceonboard),
      };

      const post = {
        ...completerecod,
        data: datos,
        updatedDate: new Date(),
      };

      const respuesta = await updateRecord(
        "UpdateRequests",
        QUERY.replace("?id=", ""),
        post
      );
      console.log("Response", respuesta);
      await loadDataPerson();
      setexperienceboardloading(false);
      setnewexperienceboard(emptyexperience);
      handleExperienceBoardClose();
      showToast("Experience On Board was Added Sucessful", 1);
    }
  };

  /*************FIN EXPERIENCE ON BOARD*****************/

  /****************EXPERIENCE ON SHORE******************/

  /******************FIN EXPERIENCE ON SHORE*********/

  const onchangeExpShore = (event) => {
    setnewexperienceshore({
      ...newexperienceshore,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeDateonShore = (e) => {
    setnewexperienceshore({ ...newexperienceshore, dateon: e });
  };

  const handleChangeDateoffShore = (e) => {
    setnewexperienceshore({ ...newexperienceshore, dateoff: e });
  };
  const handleChangeSelectShore = (e) => {
    setnewexperienceshore({
      ...newexperienceshore,
      typevessel: e.target.value,
    });
  };
  const handleExperienceshoreClose = () => {
    setshowexperienceshore(false);
    cleanFile();
  };

  const handleExperienceshoreOpen = () => {
    setshowexperienceshore(true);
  };

  const addNewExpOnshore = async () => {
    if (isEmpty(newexperienceshore.companyname)) {
      showToast("Please add a Company Name", 3);
    } else {
      setexperienceshoreloading(true);

      const insertdata = {
        "Date on":
          newexperienceshore.dateon === null
            ? ""
            : moment(newexperienceshore.dateon).format("YYYY/MM/DD"),
        "Date off":
          newexperienceshore.dateon === null
            ? ""
            : moment(newexperienceshore.dateoff).format("YYYY/MM/DD"),
        "Company name": newexperienceshore.companyname,
        "Vessel name": newexperienceshore.vesselname,
        "IMO #": newexperienceshore.imo,
        "GT / HP": newexperienceshore.gthp,
        "Type of vessel": newexperienceshore.typevessel,
        "Rank/position": newexperienceshore["Rank/position"],
      };
      let experienceonshore = isEmpty(data.experience_onshore)
        ? []
        : JSON.parse(data.experience_onshore);
      experienceonshore.push(insertdata);

      let datos = {
        ...data,
        experience_onshore: JSON.stringify(experienceonshore),
      };

      const post = {
        ...completerecod,
        data: datos,
        updatedDate: new Date(),
      };

      const respuesta = await updateRecord(
        "UpdateRequests",
        QUERY.replace("?id=", ""),
        post
      );
      console.log("Response", respuesta);
      await loadDataPerson();
      setexperienceshoreloading(false);
      setnewexperienceshore(emptyexperience);
      handleExperienceshoreClose();
      showToast("Experience On Shore was Added Sucessful", 1);
    }
  };

  /*******************COC******************************/

  const handleCOCClose = () => {
    setshowcocpanel(false);
    cleanFile();
  };

  const handleCOCOpen = () => {
    setshowcocpanel(true);
  };

  const onchangeCOC = (event) => {
    setnewcoc({ ...newcoc, [event.target.name]: event.target.value });
  };

  const onchangeDoc = (event) => {
    setnewdoc({
      ...newdoc,
      [event.target.name]: event.target.value,
    });
  };

  const addNewCoc = async () => {
    setcocloading(true);

    let insertdata = {
      "Country of issue": newcoc.contry,
      "No.": newcoc.no,
      "Date of issue":
        newcoc.issuedate === null
          ? ""
          : moment(newcoc.issuedate).format("YYYY/MM/DD"),
      "Issue at (place)": newcoc.issueatplace,
      "Valid until":
        newcoc.validdate === null
          ? ""
          : moment(newcoc.validdate).format("YYYY/MM/DD"),
    };
    let cocdatos = isEmpty(data.COC) ? [] : JSON.parse(data.COC);
    cocdatos.push(insertdata);

    let datos = {
      ...data,
      COC: JSON.stringify(cocdatos),
    };

    const post = {
      ...completerecod,
      data: datos,
      updatedDate: new Date(),
    };

    const respuesta = await updateRecord(
      "UpdateRequests",
      QUERY.replace("?id=", ""),
      post
    );
    console.log("DATOS", datos);
    //const respuesta = await infoUpdateRequest(id, datos, "UpdateRequests");
    //const respuesta = await updateDataPersonal(datos);
    console.log(respuesta);
    await loadDataPerson();
    setcocloading(false);
    setnewcoc(emptycoc);
    handleCOCClose();
    showToast("COC Document Added Sucessful", 1);
  };

  /*****************FIN COC***************************/

  /*****************USER DATA*************************/

  const handlePlaceOfBirth = (e) => {
    setdata({ ...data, place_of_birth: e.target.value });
  };

  const changeVesselType = (e) => {
    setdata({ ...data, category_vessel: e.target.value });
  };

  const changeCompany = (e) => {
    setdata({ ...data, company: e.target.value });
  };

  const changeDepartment = (e) => {
    setdata({ ...data, department: e.target.value });
  };

  const changePositions = (e) => {
    setdata({ ...data, position_applying: e.target.value });
  };

  const handleChange = (event) => {
    setdata({ ...data, [event.target.name]: event.target.value });
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTabsmain = (event, newValue) => {
    setValuemain(newValue);
  };

  const handleDateBirth = (value) => {
    setdata({ ...data, date_of_birth: new Date(value) });
  };

  const updateData = async () => {
    setloading(true);
    let id = QUERY.replace("?id=", "");
    let datos = data;
    delete datos.id;
    datos.date_of_birth = moment(datos.date_of_birth).format("YYYY/MM/DD");
    //AQUÍ HAGO QUE SE ACTUALICE LA SOLICITUD
    //updateRecord
    const post = {
      ...completerecod,
      data: datos,
      updatedDate: new Date(),
    };

    const respuesta = await updateRecord("UpdateRequests", id, post);

    showToast("Record has been updated successful!", 1);
    await loadDataPerson();
    setloading(false);
  };

  const confirmUpdate = async () => {
    setloading(true);
    let id = QUERY.replace("?id=", "");
    let datos = data;
    console.log("DATOS", datos);
    delete datos.id;
    const post = {
      ...completerecod,
      data: datos,
      status: "Finished",
      updatedDate: new Date(),
    };
    const respuestaFlokzu = await updateDataPersonal(data);
    const respuesta = await updateRecord("UpdateRequests", id, post);

    showToast("Record has been updated successful!", 1);
    await loadDataPerson();
    setloading(false);
    navigate(`/updatepersonalinfo?id=${data.Id}`);
  };

  /**************FIN USER DATA************************************/

  /****************TOOLS*****************************************/
  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };
  const handleUpload = async () => {
    if (!file) {
      alert("Please upload an image first!");
    } else {
      await uploadDocumentToFirebase(file);
    }

    // const storageRef = ref(storage, `/files/${file.name}`);

    // // progress can be paused and resumed. It also exposes progress updates.
    // // Receives the storage reference and the file to upload.
    // const uploadTask = uploadBytesResumable(storageRef, file);

    // uploadTask.on(
    //   "state_changed",
    //   (snapshot) => {
    //     const percent = Math.round(
    //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //     );

    //     // update progress
    //     setPercent(percent);
    //   },
    //   (err) => console.log(err),
    //   () => {
    //     // download url
    //     getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //       console.log("DATOS DEL ARCHIVO");
    //       console.log(url);
    //     });
    //   }
    // );
  };

  function handleFileChange(event) {
    setFile(event.target.files[0]);
  }

  const handleCloseAttachments = () => {
    setshowattachments(false);
  };

  const onchangeAttachments = (event) => {
    setnewattachments({
      ...newattachments,
      [event.target.name]: event.target.value,
    });
  };

  const addNewAttachments = async () => {
    if (!newattachments.description === "") {
      showToast("Please enter a description", 2);
    } else if (!file) {
      showToast("Please Select a File", 2);
    } else {
      //ingresar documento
      setloadingcert(true);
      await uploadFileServer(
        "DOCUMENTS",
        newattachments.description,
        "APPLICATION PROCESS"
      );

      const datafile = getFirebaseAttachments("Attachment");

      //window.location.reload(true);
      setloadingcert(false);
      handleCloseAttachments();
    }
  };

  /*******************FIN TOOLS**********************/

  const SelectPlaceOfBirth = ({ place_of_birth }) => {
    return (
      <FormControl fullWidth>
        <Select
          disabled={true}
          labelId="placeofbirthselect"
          id="placeofbirthselect"
          value={_.get(data, "place_of_birth", "").toUpperCase()}
          onChange={handlePlaceOfBirth}
          name="place_of_birth"
          fullWidth
          variant="standard">
          {contrylistdata.map((item) => (
            <MenuItem value={item.name.toUpperCase()} key={item.name}>
              {item.name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const VesselType = () => {
    return (
      <FormControl fullWidth>
        <Select
          disabled={true}
          labelId="merchanselect"
          id="merchanselect"
          value={
            data.category_vessel === ""
              ? "Merchant vessel"
              : data.category_vessel
          }
          label="Category Vessel"
          onChange={changeVesselType}
          fullWidth
          variant="standard">
          {vessel.map((item) => (
            <MenuItem value={item.Name} key={item.Id}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const sendUpdateInfo = async () => {
    //1. Alertar de que se generará un correo y un link.

    const updaterequest = {
      createDate: new Date(),
      data: data,
      status: "pending",
      pmssaid: data.id,
      fullname: data.fullname,
    };

    const resultado = await addRecord("UpdateRequests", updaterequest);
    console.log(resultado);
  };

  const updateRequestInfo = async () => {
    let id = QUERY.replace("?id=", "");
    const updaterequest = {
      data: data,
      status: "Updated",
      pmssaid: data.id,
      fullname: data.fullname,
    };
    infoUpdateRequest(id, updaterequest, "UpdateRequests");
    showToast("Record has been updated successful!", 1);
    setIsSent(true);
    setIsRequestSent(true);
  };

  const DepartmentSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="departmentselect"
          id="departmentselect"
          value={_.get(data, "department", "").toUpperCase()}
          label="Department"
          disabled={isview === 1}
          onChange={changeDepartment}
          variant="standard">
          {deparmentlist.map((item) => (
            <MenuItem value={item.department_name.toUpperCase()} key={item.Id}>
              {item.department_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const PositionSelect = () => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="positionselect"
          id="positionselect"
          value={_.get(data, "position_applying", "").toUpperCase()}
          label="Position Applying"
          disabled={isview === 1}
          onChange={changePositions}
          variant="standard">
          {positionslist.map((item) => (
            <MenuItem value={item.position_name.toUpperCase()} key={item.Id}>
              {item.position_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const changeCountryCode = (e) => {
    const value = e.target.value;
    const countryObj = countryCodes.filter((item) => {
      return item.country_code_name === value;
    });
    setdata({ ...data, countrycode: countryObj[0].Id });
  };

  const CountryCodeSelect = () => {
    if (!countryCodes) return;
    const countryIndex = Number(data.countrycode) - 1;
    const value = countryCodes[countryIndex];
    return (
      <FormControl fullWidth>
        <Select
          value={value ? value.country_code_name : ""}
          label="Position Applying"
          onChange={(e) => changeCountryCode(e)}
          variant="standard">
          {countryCodes.map((item, index) => {
            return (
              <MenuItem key={index} value={item.country_code_name}>
                {item.country_code_name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      {console.log("values", isview, isRequestSent)}
      <CssBaseline />
      {isview === 1 && isRequestSent ? (
        <Container maxWidth="lg" sx={{ mt: 5, mb: 4 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <CardMedia
                component="img"
                style={{ height: "100px", objectFit: "contain" }}
                image={logo}
              />
              <AssignmentTurnedInIcon
                sx={{ color: "green", width: 150, height: 150, mt: 25 }}
              />
              <Typography
                variant="h5"
                color="initial"
                sx={{ textTransform: "capitalize" }}>
                THANK YOU FOR UPDATING YOUR DETAILS
                {/* EL EQUIPO DE LOGISTIC INTERNATIONAL SERVICES CORP. ESTARA VALIDANDO LA INFORMACION SUMINISTRADA. */}
              </Typography>
              <Typography
                variant="h6"
                color="initial"
                sx={{ textTransform: "capitalize" }}>
                THE LOGISTIC INTERNATIONAL SERVICES TEAM WILL BE VALIDATING THE
                INFORMATION PROVIDED.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <Container maxWidth="lg" sx={{ mt: 5, mb: 4 }}>
            <Grid container>
              {isview === 1 ? (
                <Grid
                  item
                  xs={12}
                  sx={{ alingSelf: "center", textAlign: "center" }}>
                  <CardMedia
                    component="img"
                    style={{ height: "100px", objectFit: "contain" }}
                    image={logo}
                  />
                </Grid>
              ) : (
                <Grid sx={{ mt: 5 }}></Grid>
              )}
            </Grid>
            <Grid>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "row",
                }}>
                <Grid
                  justifyContent={"start"}
                  alignItems={"center"}
                  container
                  spacing={1}>
                  {isview !== 1 ? (
                    <>
                      <Grid item xs={3} sx={{}}>
                        <Button
                          variant="contained"
                          sx={{ mr: 2 }}
                          onClick={confirmUpdate}
                          startIcon={<SaveAsRoundedIcon />}
                          size={"medium"}>
                          {!loading ? (
                            "CONFIRM UPDATE"
                          ) : (
                            <CircularProgress color="inherit" />
                          )}
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={2} sx={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          sx={{ mr: 2 }}
                          onClick={updateRequestInfo}
                          startIcon={<SaveAsRoundedIcon />}
                          size={"medium"}
                          disabled={isSent}>
                          {!loading ? (
                            "SEND UPDATE"
                          ) : (
                            <CircularProgress color="inherit" />
                          )}
                        </Button>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={2} sx={{}}></Grid>
                  <Grid item xs={2} sx={{}}></Grid>

                  <Grid
                    item
                    xs={2}
                    sx={{
                      textAlign: "left",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    {/* <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  startIcon={<BadgeIcon />}
                  size={"medium"}
                  color="success"
                >
                  {data.status}
                </Button> */}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Tabs
                  value={valuemain}
                  onChange={handleChangeTabsmain}
                  aria-label="basic tabs"
                  variant="standard">
                  <Tab label="PERSONAL DATA" {...a11yProps(0)} />
                  {/* <Tab label="EMBARKATIONS" {...a11yProps(1)} /> */}
                  <Tab label="ATTACHMENTS" {...a11yProps(1)} />
                </Tabs>

                <TabPanel value={valuemain} index={0}>
                  <Grid>
                    <Grid
                      container
                      sx={{
                        justifyContent: "center",
                        alignItem: "center",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}>
                      <Grid item xs={2}>
                        <Typography
                          variant="subtitle2"
                          sx={isNewData("fullname") ? { color: "blue" } : {}}>
                          Full Name:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <TextField
                          required
                          id="fullname"
                          label=""
                          name="fullname"
                          disabled={isview === 1}
                          autoComplete="fullname"
                          value={data.fullname}
                          onChange={handleChange}
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={isNewData("sex") ? { color: "blue" } : {}}>
                          Gender:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="sex"
                            value={_.get(data, "sex", "M")}
                            onChange={handleChange}>
                            <FormControlLabel
                              value="F"
                              disabled={isview === 1}
                              control={<Radio />}
                              label="F"
                            />
                            <FormControlLabel
                              value="M"
                              disabled={isview === 1}
                              control={<Radio />}
                              label="M"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={
                            isNewData("date_of_birth") ? { color: "blue" } : {}
                          }>
                          Date of Birth:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <MobileDatePicker
                          label=""
                          inputFormat="MM/dd/yyyy"
                          value={data.date_of_birth}
                          disabled={isview === 1}
                          onChange={handleDateBirth}
                          name="date_of_birth"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="subtitle2"
                          sx={
                            isNewData("place_of_birth") ? { color: "blue" } : {}
                          }>
                          Nationality:
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <SelectPlaceOfBirth
                          place_of_birth={data.place_of_birth}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={
                            isNewData("application_date")
                              ? { color: "blue" }
                              : {}
                          }>
                          Application Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{}}>
                        <Typography variant="subtitle2">
                          {data.application_date}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={
                            isNewData("category_vessel")
                              ? { color: "blue" }
                              : {}
                          }>
                          Category Vessel:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <VesselType />
                      </Grid>
                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={isNewData("company") ? { color: "blue" } : {}}>
                          Company:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <TextField
                          disabled={true}
                          id="company"
                          label=""
                          name="company"
                          autoComplete="company"
                          value={data.company}
                          onChange={handleChange}
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={isNewData("department") ? { color: "blue" } : {}}>
                          Department:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <DepartmentSelect />
                      </Grid>
                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={isNewData("email") ? { color: "blue" } : {}}>
                          Email:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <TextField
                          required
                          id="email"
                          label=""
                          name="email"
                          autoComplete="email"
                          value={data.email}
                          onChange={handleChange}
                          variant="standard"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={
                            isNewData("position_applying")
                              ? { color: "blue" }
                              : {}
                          }>
                          Position:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <PositionSelect />
                      </Grid>

                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={isNewData("phone") ? { color: "blue" } : {}}>
                          Phone:{" "}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          pr: 2,
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center",
                        }}>
                        <Box sx={{ width: "40%" }}>
                          <CountryCodeSelect />
                        </Box>
                        <TextField
                          required
                          id="phone"
                          label=""
                          name="phone"
                          autoComplete="phone"
                          value={data.phone}
                          onChange={handleChange}
                          variant="standard"
                          fullWidth
                          sx={{ width: "60%" }}
                        />
                      </Grid>

                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={isNewData("whatsapp") ? { color: "blue" } : {}}>
                          Whatsapp:{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <TextField
                          required
                          id="whatsapp"
                          label=""
                          name="whatsapp"
                          autoComplete="whatsapp"
                          value={data.whatsapp}
                          onChange={handleChange}
                          variant="standard"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={
                            isNewData("customeraddress")
                              ? { color: "blue" }
                              : {}
                          }>
                          Complete Address:
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <TextField
                          required
                          id="customeraddress"
                          label=""
                          name="customeraddress"
                          autoComplete="whatsapp"
                          value={data.customeraddress}
                          onChange={handleChange}
                          variant="standard"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={2} sx={{}}>
                        <Typography
                          variant="subtitle2"
                          sx={
                            isNewData("nearly_airport") ? { color: "blue" } : {}
                          }>
                          Nearly Airport:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ pr: 2 }}>
                        <TextField
                          required
                          id="nearly_airtport"
                          label=""
                          name="nearly_airport"
                          autoComplete="nearly_airport"
                          value={data.nearly_airport}
                          onChange={handleChange}
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "left" }}>
                        <Typography variant="subtitle2">PMSSA #</Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ textAlign: "left" }}>
                        <Title> {data["PMSSA ID NO"]}</Title>
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  </Grid>

                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChangeTabs}
                        aria-label="basic tabs"
                        variant="standard">
                        <Tab label="DOCUMENTS" {...a11yProps(0)} />
                        <Tab label="COC" {...a11yProps(1)} />
                        <Tab label="EXPERIENCE ON BOARD" {...a11yProps(2)} />
                        <Tab label="EXPERIENCE ON SHORE" {...a11yProps(3)} />
                        <Tab label="LIC BOOK STCW" {...a11yProps(4)} />
                        <Tab label="OTHER TRAININGS" {...a11yProps(5)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <DocumentsTab
                        data={data.type_document}
                        addNew={handleDocsOpen}
                        deleterecord={deleteRecord}
                        edit={editNewDocument}
                        isview={isview}
                        checkForNewFiles={true}
                        dbData={dbData}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <COCTable
                        data={data.COC}
                        addNew={handleCOCOpen}
                        deleterecord={deleteCOC}
                        edit={editCOC}
                        isview={isview}
                        checkForNewFiles={true}
                        dbData={dbData}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <ExperienceOnBoard
                        data={data.experience_onboard}
                        addNew={handleExperienceBoardOpen}
                        deleterecord={deleteExperienceOnBoard}
                        edit={editexperienceonboard}
                        isview={isview}
                        checkForNewFiles={true}
                        dbData={dbData}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <ExperienceOnShore
                        data={data.experience_onshore}
                        addNew={handleExperienceshoreOpen}
                        deleterecord={deteleExperienceOnShore}
                        edit={editexperienceonshore}
                        isview={isview}
                        checkForNewFiles={true}
                        dbData={dbData}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <LicBookSTCW
                        data={data.lic_sbook_stcw_certificates}
                        addNew={handleCertificatesOpen}
                        deleterecord={deleteLicBook}
                        edit={editnewLicBook}
                        isview={isview}
                        checkForNewFiles={true}
                        dbData={dbData}
                      />
                    </TabPanel>

                    <TabPanel value={value} index={5}>
                      <OtherTrainings
                        data={data.other_training}
                        addNew={handleOtherCertOpen}
                        deleterecord={deleteOther}
                        edit={editothertrainings}
                        isview={isview}
                        checkForNewFiles={true}
                        dbData={dbData}
                      />
                    </TabPanel>
                  </Box>
                </TabPanel>
                {/* <TabPanel value={valuemain} index={1}>
              <Embarcations data={embarcations} />
            </TabPanel> */}
                <TabPanel value={valuemain} index={1}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="right"
                    alignItems="center"
                    sx={{ marginBottom: 2 }}>
                    <Button variant="contained" onClick={handleOpenAttachments}>
                      ADD NEW ATTACHMENT +
                    </Button>
                  </Grid>

                  <AttachmentTableExternal
                    data={attachmentlist}
                    datos={attachmentTableData}
                    setDatos={setAttachmentTableData}
                    marine={data}
                    embarcations={[]}
                  />
                  {/* <AttachmentTable data={attachmentlist} /> */}
                </TabPanel>
              </Paper>
            </Grid>
          </Container>

          <AddCOC
            open={showcocpanel}
            handleOpen={handleCOCOpen}
            handleClose={handleCOCClose}
            contrylistdata={contrylistdata}
            data={newcoc}
            onChange={onchangeCOC}
            handleChangeSelect={handleChangeSelect}
            contry={contry}
            handleChangeIssueDate={handleChangeIssueDate}
            handleChangeValidDate={handleChangeValidDate}
            addnew={addNewCoc}
            loading={cocloading}
            setloading={setcocloading}
            handleUpload={handleUpload}
            isedit={isedit}
            edit={saveCOC}
          />
          <AddNewExperienceOnBoard
            open={showexperienceboard}
            handleOpen={handleExperienceBoardOpen}
            handleClose={handleExperienceBoardClose}
            vesseltypedetails={vesseltypedetails}
            data={newexperienceboard}
            onChange={onchangeExpBoard}
            handleChangeSelect={handleChangeSelectExperience}
            handleChangeDateoff={handleChangeDateoff}
            handleChangeDateon={handleChangeDateon}
            addnew={addNewExpOnBoard}
            loading={experienceboardloading}
            isedit={isedit}
            edit={saveExperienceOnBoard}
          />

          <AddNewExperienceOnShore
            open={showexperienceshore}
            handleOpen={handleExperienceshoreOpen}
            handleClose={handleExperienceshoreClose}
            vesseltypedetails={vesseltypedetails}
            data={newexperienceshore}
            onChange={onchangeExpShore}
            handleChangeSelect={handleChangeSelectShore}
            handleChangeDateoff={handleChangeDateoffShore}
            handleChangeDateon={handleChangeDateonShore}
            addnew={addNewExpOnshore}
            loading={experienceshoreloading}
            isedit={isedit}
            edit={saveExperienceOnShore}
          />

          <AddDocumnets
            open={showdocpanel}
            handleOpen={handleDocsOpen}
            handleClose={handleDocClose}
            listtypeofdocument={listtypeofdocument}
            contrylistdata={contrylistdata}
            data={newdoc}
            onChange={onchangeDoc}
            handleChangeSelect={handleChangeContrySelect}
            handleChangeDoctypeSelect={handleDocChangeSelect}
            handleChangeIssueDate={handleChangeDocIssueDate}
            handleChangeValidDate={handleChangeDocValidDate}
            addnew={addNewDoc}
            loading={docloading}
            handleFileChange={handleFileChange}
            handleUpload={handleUpload}
            percent={percent}
            isedit={isedit}
            edit={saveDocument}
          />

          <AddTrainings
            open={showcertificates}
            handleOpen={handleCertificatesOpen}
            handleClose={handleCertificatesClose}
            list={certificatelist}
            data={newcertificate}
            handleChangeCertSelect={handleChangeCertSelect}
            handleChangeSelect={handleCountryCert}
            contrylistdata={contrylistdata}
            onChange={onchangeCert}
            handleChangeCertIssueDate={handleChangeCertIssueDate}
            handleChangeCertExpiredDate={handleChangeCertExpiredDate}
            handleFileChange={handleFileChange}
            handleUpload={handleUpload}
            percent={percent}
            loading={loadingcert}
            addnew={addNewCertificate}
            isedit={isedit}
            edit={saveLicBook}
          />

          <AddOtherTrainings
            open={showothercert}
            handleOpen={handleOtherCertOpen}
            handleClose={handleOtherCertClose}
            data={newothercertificate}
            handleChangeSelect={handleCountryOtherCert}
            contrylistdata={contrylistdata}
            onChange={onchangeOtherCert}
            handleChangeCertIssueDate={handleChangeOtherCertIssueDate}
            handleChangeCertExpiredDate={handleChangeOtherCertExpiredDate}
            handleFileChange={handleFileChange}
            handleUpload={handleUpload}
            percent={percent}
            loading={loadingcert}
            addnew={addNewOtherCertificate}
            isedit={isedit}
            edit={saveOtherCertificate}
          />

          <AddAttachmentExternal
            open={showattachments}
            handleClose={handleCloseAttachments}
            data={newattachments}
            onChange={onchangeAttachments}
            addnew={addNewAttachments}
            loading={loadingcert}
            handleFileChange={handleFileChange}
            isedit={false}
            attachmentlist={attachmentlist}
            marine={data}
            embarcations={embarcations}
          />

          <LoadingProgress loading={loadingprogress} />
          <ToastContainer />
        </>
      )}
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
