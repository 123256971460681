import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { TEMPLATE } from "../../emails/Emails";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import { size } from "lodash";
import Paper from "@mui/material/Paper";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import {
  addRecord,
  getCurrentUser,
  sendEmail,
  userExists,
  getDatabyId,
  updateRecord,
  getRecordById,
} from "../../Actions/Actions";
const theme = createTheme();

const userdata = {
  email: "",
  status: true,
  company: "",
  contact: "",
  address: "",
};

export default function EditCompany() {
  const [company, setcompany] = useState("PMSSA");
  const [state, setstate] = useState(userdata);
  const [rol, setrol] = useState("EXTERNAL");
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [companylist, setcompanylist] = useState([]);
  let QUERY = window.location.search;

  useEffect(() => {
    const loadData = async () => {
      let id = QUERY.replace("?id=", "");
      console.log(id);
      const resultadouser = await getRecordById("Companies", id);
      console.log(resultadouser);
      setstate(resultadouser);
    };

    loadData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const onChange = (event) => {
    setstate({ ...state, [event.target.name]: event.target.value });
  };

  const editCompany = async () => {
    let id = QUERY.replace("?id=", "");
    console.log(state);
    setLoading(true);

    if (!state.company) {
      showToast("Please Introduce a Company Name", 2);
    } else if (state.email && !validaremail(state.email)) {
      showToast("Please Introduce a Valid Email", 2);
    } else {
      /*   const firebaseuser = await createUserAndPassword(
        state.email,
        state.password,
        state.firstName + " " + state.lastName
      );*/

      const data = {
        ...state,
      };
      console.log("DATA");
      console.log(data);
      const addresult = await updateRecord("Companies", id, data);
      /* const emailcreate = TEMPLATE.filter((item) => item.id === "NEW_USER");
     let body = emailcreate[0].body.replace("$user$", state.email);
      body = body.replace("$password$", state.password);

      sendEmail(body, emailcreate[0].subject, state.email); 
     setstate(userdata);*/
      showToast("Company was update sucesfull", 1);
      navigate("/companies");
    }
    setLoading(false);
  };

  const validaremail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      return false;
    } else {
      return true;
    }
  };

  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Container component="main" maxWidth="md" style={{ marginTop: 20 }}>
          <Paper>
            <Box
              sx={{
                marginTop: 8,
                padding: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container>
                <ArrowBackIosNewRoundedIcon
                  autoReverse={true}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </Grid>
              <Typography component="h1" variant="h5">
                Edit User
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="given-name"
                      name="company"
                      required
                      fullWidth
                      id="company"
                      label="Company"
                      autoFocus
                      value={state.company}
                      onChange={(e) => onChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="contact"
                      label="Contact Name"
                      type="text"
                      id="contact"
                      value={state.contact}
                      onChange={(e) => onChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="email"
                      label="Contact Email"
                      name="email"
                      autoComplete="email"
                      value={state.email}
                      onChange={(e) => onChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="address"
                      label="Address"
                      type="text"
                      id="address"
                      value={state.address}
                      onChange={(e) => onChange(e)}
                      multiline={true}
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    Status
                    <Switch
                      checked={state.status}
                      onChange={() => {
                        setstate({ ...state, status: !state.status });
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={editCompany}
                  disabled={loading}
                  startIcon={<SaveAsRoundedIcon />}
                >
                  {!loading ? (
                    "Edit Company"
                  ) : (
                    <CircularProgress color="secondary" />
                  )}
                </Button>
              </Box>
            </Box>
            <ToastContainer />
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
