import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Title from "../Commons/Title";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export default function ExperienceOnShore({
  data,
  addNew,
  deleterecord,
  edit,
  isview,
  disableAdd,
  dbData,
  checkForNewFiles,
}) {
  let datos = isEmpty(data) ? [] : JSON.parse(data);

  console.log("data", data);
  console.log("dbData", dbData);

  const checkfileExist = (obj) => {
    if (isview !== 1) {
      if (dbData[0].experience_onshore === "") return false;
      let documentArray = JSON.parse(dbData[0].experience_onshore);
      const isExist = documentArray.some((item) =>
        Object.entries(obj).every(([key, value]) => item[key] === value)
      );
      return isExist;
    }
    return true;
  };

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{ marginBottom: 2 }}>
          {disableAdd ? (
            ""
          ) : (
            <>
              <Button variant="contained" onClick={addNew}>
                ADD +
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date on</TableCell>
            <TableCell>Date off</TableCell>
            <TableCell>Company name</TableCell>
            <TableCell>Contact person telephone</TableCell>
            <TableCell>Duties or responsibilities </TableCell>
            <TableCell>Rank/position</TableCell>
            <TableCell>Reason for leaving</TableCell>
            {isview !== 1 && (
              <>
                <TableCell>Edit.</TableCell>

                <TableCell>Del.</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {datos
            ?.sort((a, b) => new Date(a["Date on"]) - new Date(b["Date on"]))
            .map((row) => (
              <TableRow key={Math.random()}>
                {checkForNewFiles ? (
                  <>
                    {checkfileExist(row) ? (
                      <>
                        <TableCell>{row["Date on"]}</TableCell>
                        <TableCell>{row["Date off"]}</TableCell>
                        <TableCell>{row["Company name"]}</TableCell>
                        <TableCell>{row["Contact person telephone"]}</TableCell>
                        <TableCell>
                          {row["Duties or responsibilities"]}
                        </TableCell>
                        <TableCell>
                          {row["Rank/position"]
                            ? row["Rank/position"]
                            : row["Rank/ position"]}
                        </TableCell>
                        <TableCell>{row["Reason for leaving"]}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Date on"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Date off"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Company name"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Contact person telephone"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Duties or responsibilities"]}
                        </TableCell>
                        <TableCell>
                          {row["Rank/position"]
                            ? row["Rank/position"]
                            : row["Rank/ position"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Reason for leaving"]}
                        </TableCell>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TableCell>{row["Date on"]}</TableCell>
                    <TableCell>{row["Date off"]}</TableCell>
                    <TableCell>{row["Company name"]}</TableCell>
                    <TableCell>{row["Contact person telephone"]}</TableCell>
                    <TableCell>{row["Duties or responsibilities"]}</TableCell>
                    <TableCell>
                      {row["Rank/position"]
                        ? row["Rank/position"]
                        : row["Rank/ position"]}
                    </TableCell>
                    <TableCell>{row["Reason for leaving"]}</TableCell>
                  </>
                )}
                {isview !== 1 && (
                  <>
                    <TableCell>
                      <ModeEditIcon
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          edit(row);
                        }}></ModeEditIcon>
                    </TableCell>
                    <TableCell>
                      <DeleteIcon
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let resp = window.confirm(
                            "Do you want delete this document?"
                          );
                          if (resp) {
                            deleterecord(datos, row);
                          }
                        }}></DeleteIcon>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}
