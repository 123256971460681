import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { experimentalStyled as styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { size } from "lodash";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",

  color: theme.palette.text.secondary,
}));

export default function AddAttachments({
  open,
  handleClose,
  data,
  onChange,
  addnew,
  loading,
  handleFileChange,
  isedit,
  edit,
  attachmentlist,
  embarcations,
  marine,
}) {
  const [selectOptions, setSelectOptions] = React.useState([]);
  const [selectValue, setSelectValue] = React.useState("");

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  const addSelectOptions = () => {
    let baseOptions = ["APPLICATION PROCESS", "HIRING PROCESS"];
    if (embarcations.length <= 3) {
      setSelectOptions([...baseOptions, "VESSEL 1", "VESSEL 2", "VESSEL 3"]);
      return;
    }
    if (size(embarcations) > 0) {
      embarcations.forEach((item, index) => {
        baseOptions.push(`VESSEL ${index + 1}`);
      });
    }
    setSelectOptions(baseOptions);
  };

  useEffect(() => {
    if (!marine.idproceso) {
      addSelectOptions();
      return;
    }
    let uniques = [];
    attachmentlist.forEach((item) => {
      if (uniques.includes(item.attachmentType)) return;
      uniques.push(item.attachmentType);
    });
    setSelectOptions(uniques);
  }, [attachmentlist]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Paper
          sx={{
            width: 0.5,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            paddingBottom: 5,
          }}>
          <Grid>
            <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
              <CloseOutlinedIcon onClick={() => handleClose()} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              <Title>{data.id}</Title>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              {isedit ? (
                <Title>EDIT DOCUMENT</Title>
              ) : (
                <Title>ADD ATTACHMENTS</Title>
              )}
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItem: "center",
                marginRight: "auto",
                marginLeft: "auto",
                pr: 5,
                pl: 5,
              }}>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Description : </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="description"
                  required
                  id="description"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data.description}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Attachment Type : </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="attachmentType"
                    variant="standard"
                    label="TYPE FILTER"
                    onChange={onChange}
                    value={data.attachmentType}>
                    {selectOptions.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <div>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    onChange={handleFileChange}
                  />
                </div>
              </Grid>
              <Grid item xs={6} sx={{ mt: 5, textAlign: "center" }}>
                {isedit ? (
                  <Button variant="outlined" sx={{ mr: 5 }} onClick={edit}>
                    {!loading ? "UPDATE" : <CircularProgress color="inherit" />}
                  </Button>
                ) : (
                  <Button variant="outlined" sx={{ mr: 5 }} onClick={addnew}>
                    {!loading ? (
                      "ADD NEW ATTACHMENT"
                    ) : (
                      <CircularProgress color="inherit" />
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}
