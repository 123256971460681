import React, { useState, useEffect } from "react";
import Title from "./Commons/Title";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {
  getGapPool,
  getUserByEmail,
  getPositionsByUsers,
  getCurrentUser,
  sendEmail,
  addRecord,
  getSkills,
  deleteRecord,
  deleteRecordByID,
  loadCertificates,
} from "../Actions/Actions";
import ClearIcon from "@mui/icons-material/Clear";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { forEach, isEmpty, size } from "lodash";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AlertDialog from "./Commons/Dialog";
import { TEMPLATE } from "../emails/Emails";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ModalPerson from "./ModalPerson/index";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme } from "@mui/material/styles";
import {
  BlobProvider,
  Document,
  Page,
  PDFViewer,
  Text,
} from "@react-pdf/renderer";
import PdfDocument from "./PdfDocument";
import { IconButton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import _ from "lodash";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const OrderStaff = (props) => {
  const [data, setdata] = useState([]);
  const [person, setperson] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [showviewer, setshowviewer] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [downloadFiles, setDownloadFiles] = useState(false);
  const [fecha, setfecha] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [firebaseid, setfirebaseid] = useState("");
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [certData, setCertData] = useState([]);

  const handleModal = () => {
    setShowPdf(!showPdf);
  };

  const handleChangeDate = (newValue) => {
    setfecha(newValue);
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
  };

  const columns = [
    {
      field: "Id",
      headerName: "View CV",
      width: 80,
      renderCell: (params) => (
        <PersonIcon
          style={{ fontSize: 25 }}
          color="primary"
          onClick={() => settingData(params.row)}
        />
      ),
      sortable: false,
    },
    {
      field: "selected",
      headerName: "Select",
      width: 80,
      renderCell: (params) => (
        <Checkbox
          {...label}
          checked={params.row.selected}
          onClick={() => {
            updatepanel(params);
          }}
        />
      ),
      sortable: false,
    },
    {
      field: "id",
      headerName: "#",
      width: 20,
      hide: true,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 300,
    },

    {
      field: "department",
      headerName: "Department",
      width: 150,
      editable: true,
    },

    {
      field: "position_applying",
      headerName: "Position",
      width: 200,
      editable: true,
    },
    {
      field: "sex",
      headerName: "Gender",
      width: 100,
      editable: true,
    },
  ];

  const settingData = (params) => {
    setperson(params);
    handleModal();
  };

  const loadResults = async () => {
    setloading(true);
    let ids = [];
    let totalpersonal = [];

    //1. Obtener GapPool
    const resultado = await getGapPool();

    const certsData = await loadCertificates();

    const gappool = resultado.filter(
      (item) => _.get(item, "status", "").toUpperCase() === "GAP POOL ST/BY"
    ); // SOLO MUESTRA GAP POOL Y DESPUÉS SOLO MOSTRARÁ LOS QUE ESTÁN SELECCIONADOS.
    //2. Obtener usuario para buscarlo

    //3. Obtener posiciones del usuario
    let userid = await obtenerUser();
    setfirebaseid(userid);
    let positions = await getPositionsByUsers("PositionsByUsers", userid);

    if (size(positions) > 0) {
      positions = positions[0].positions; //validar cuando positions está en blanco
    } else {
      navigate("/noaccess");
      positions = [];
    }
    //4. Recorrer posiciones del gappol.
    positions.forEach((item) => {
      gappool.forEach((record) => {
        record.fullname = record.fullname
          ? record.fullname.toUpperCase()
          : record.fullname;
        if (ids.includes(record.Id)) return;

        if (
          compareStrings(record.department, item.department) &&
          compareStrings(record.position_applying, item.position_name)
        ) {
          record.selected = false;
          totalpersonal.push(record);
          ids.push(record.Id);
        }
      });
    });
    setCertData(certsData);
    setdata(totalpersonal);
    setloading(false);
  };

  useEffect(() => {
    loadResults();
  }, []);

  function compareStrings(string1, string2) {
    if (!isEmpty(string1) && !isEmpty(string2)) {
      if (string1.trim().toUpperCase() === string2.trim().toUpperCase()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const obtenerUser = async () => {
    let userid = "";
    const user = JSON.parse(sessionStorage.getItem("user"));
    const user2 = await getUserByEmail(user.email);
    userid = user2[0].id; //id de la tabla de usuario
    return userid;
  };

  const deleteAccess = async () => {
    if (firebaseid) {
      await deleteRecord("PositionsByUsers", firebaseid);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const showDowViewer = () => {
    setshowviewer(true);
  };

  const updatepanel = async (params) => {
    let id = params.row.id;
    let newarray = [];
    data.forEach((item) => {
      if (item.id === id) {
        item.selected = !item.selected;
      }
      newarray.push(item);
    });
    setSelectedUsers(
      newarray.filter((item) => {
        return item.selected === true;
      })
    );
    setdata(newarray);
  };

  const completeRequest = async () => {
    const user = getCurrentUser();
    let details = [];
    data.forEach((item) => {
      if (item.selected === true) {
        item.interviewtestdate = null;
        item.passed = false;
        item.iscompleted = false;
        item.accepted = null;
        item.completeddate = null;
        details.push(item);
      }
    });
    if (size(details) === 0) {
      showToast("You must select a person", 3);
    } else if (fecha === null) {
      showToast("Please select an estimate boarding Date", 3);
    } else {
      const myrequest = {
        id: new Date().getTime(),
        createAt: new Date(),
        request_details: details,
        vesselType: "Crucero",
        bordingDate: fecha,
        userEmail: user.email,
        userId: user.uid,
        status: "reviewing",
        userName: user.displayName,
      };
      const resultado = await addRecord("Request", myrequest);
      await deleteRecordByID("PositionsByUsers", firebaseid);

      // EMAIL FOR COMPANY MAKING THE REQUEST
      const emailcreate_company = TEMPLATE.filter(
        (item) => item.id === "NEW_REQUEST_COMPANY"
      );
      let body = emailcreate_company[0].body.replace(
        "$company_name",
        user.displayName
      );
      sendEmail(body, emailcreate_company[0].subject, user.email);

      // EMAIL FOR STAFF TO CHECK QUE REQUEST
      const emailcreate_staff = TEMPLATE.filter(
        (item) => item.id === "NEW_REQUEST_STAFF"
      );
      let body_2 = emailcreate_staff[0].body.replace(
        "$company_name",
        user.displayName
      );
      sendEmail(body_2, "SOLICITUD DE STAFF", "info@mp-ip.edu.pa");
      showToast(
        "Request completed succesfull, you can review your request in My Request Page",
        1
      );
      sendEmail("Ha recibido una nueva solicitud de respuesta de");
      navigate("/requests");
    }
    setOpenDialog(false);
  };

  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };

  const handleDownloadAllPdf = (url) => {
    try {
      let link = document.createElement("a");
      // create a blobURI pointing to our Blob
      link.href = url;
      link.download = "Staff.pdf";
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    } catch (error) {
      console.log(error);
    }
  };

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const requestSearch = async (searchValue) => {
    if (searchValue === "") {
      setSearchText(searchValue);
      setIsLoading(true);
      await loadResults();
      setIsLoading(false);
    } else {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = data.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
      setdata(filteredRows);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* TESTING DOWNLOAD DOCUMENTS */}
      {downloadFiles === true && (
        <BlobProvider
          document={
            <Document>
              {selectedUsers.map((item, index) => {
                return (
                  <PdfDocument key={index} person={item} certData={certData} />
                );
              })}
            </Document>
          }>
          {({ blob, url, loading, error }) => {
            if (url === null) return;
            handleDownloadAllPdf(url);
            setDownloadFiles(false);
          }}
        </BlobProvider>
      )}
      {/* TESTING DOWNLOAD DOCUMENTS */}
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        {showPdf ? (
          <>
            <PDFViewer
              style={{
                width: "90%",
                height: "100vh",
                zIndex: 9999999,
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
              }}>
              <Document>
                <PdfDocument person={person} certData={certData} />
              </Document>
            </PDFViewer>
            <Button
              sx={{
                position: "fixed",
                top: 0,
                right: 15,
                color: "white",
                zIndex: 9999999,
                fontSize: 30,
              }}
              onClick={() => setShowPdf(!showPdf)}>
              X
            </Button>
            <div
              style={{
                width: "100%",
                height: "100%",
                zIndex: 999999,
                left: 0,
                top: 0,
                position: "fixed",
                backgroundColor: "black",
                opacity: 0.5,
              }}></div>
          </>
        ) : (
          ""
        )}

        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginBottom: 2,
              }}>
              <Title>ORDER STAFF</Title>
              <Title>
                #SELECTED{" "}
                {size(data) > 0
                  ? size(data.filter((item) => item.selected === true))
                  : 0}
              </Title>
              <MobileDatePicker
                label="Boarding date"
                inputFormat="MM/dd/yyyy"
                value={fecha}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button
                variant="contained"
                startIcon={<AssignmentIcon />}
                size={"medium"}
                onClick={() => {
                  setOpenDialog(true);
                }}>
                CONFIRM
              </Button>
              <Button
                variant="contained"
                disabled={selectedUsers.length === 0}
                startIcon={<AssignmentIcon />}
                size={"medium"}
                onClick={() => {
                  setDownloadFiles(true);
                }}>
                DOWNLOAD {selectedUsers.length}{" "}
                {selectedUsers.length === 1 ? "FILE" : "FILES"}
              </Button>
            </Box>

            <DataGrid
              rows={data}
              columns={columns}
              sx={{
                boxShadow: 2,
              }}
              height={"100vh"}
              loading={loading}
              pageSize={50}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => {
                    requestSearch(event.target.value);
                  },
                  clearSearch: () => requestSearch(""),
                },
                pagination: { classes: null },
              }}
            />
          </Paper>
        </Grid>

        <AlertDialog
          open={openDialog}
          handleClose={handleCloseModal}
          completeRequest={completeRequest}
          title={"Confirm"}
          description="Are you sure to send request?"
        />
        <ToastContainer />
      </Container>
    </Box>
  );
};

const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default OrderStaff;
