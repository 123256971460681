import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { experimentalStyled as styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",

  color: theme.palette.text.secondary,
}));

export default function AddNewExperienceOnBoard({
  open,
  handleClose,
  data,
  onChange,
  vesseltypedetails,
  handleChangeSelect,
  handleChangeDateoff,
  handleChangeDateon,
  addnew,
  loading,
  isedit,
  edit,
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Paper
          sx={{
            width: 0.5,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            paddingBottom: 5,
          }}>
          <Grid>
            <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
              <CloseOutlinedIcon onClick={() => handleClose()} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              <Title>{data.id}</Title>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              {isedit ? (
                <Title>UPDATE</Title>
              ) : (
                <Title>ADD NEW EXPERIENCE ON SHORE</Title>
              )}
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItem: "center",
                marginRight: "auto",
                marginLeft: "auto",
                pr: 5,
                pl: 5,
              }}>
              <Grid
                item
                xs={4}
                sx={{ alignItems: "center", justifyContent: "center" }}>
                <Typography variant="subtitle2">Company Name: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="Company name"
                  required
                  id="companyname"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data["Company name"]}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  Contact person telephone:
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="Contact person telephone"
                  required
                  id="vesselname"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data["Contact person telephone"]}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  Duties or responsibilities
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="Duties or responsibilities"
                  required
                  id="imo"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data["Duties or responsibilities"]}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Rank/position </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="Rank/position"
                  required
                  id="gthp"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={
                    data["Rank/position"]
                      ? data["Rank/position"]
                      : data["Rank/ position"]
                  }
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Reason for leaving </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="Reason for leaving"
                  required
                  id="gthp"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data["Reason for leaving"]}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Date on: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <MobileDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={data["Date on"]}
                  onChange={handleChangeDateon}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Date off: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <MobileDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={data["Date off"]}
                  onChange={handleChangeDateoff}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6} sx={{ mt: 5, textAlign: "center" }}>
                {isedit ? (
                  <Button variant="outlined" sx={{ mr: 5 }} onClick={edit}>
                    {!loading ? "UPDATE" : <CircularProgress color="inherit" />}
                  </Button>
                ) : (
                  <Button variant="outlined" sx={{ mr: 5 }} onClick={addnew}>
                    {!loading ? (
                      "ADD NEW EXPERIENCE ON SHORE"
                    ) : (
                      <CircularProgress color="inherit" />
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}
