import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { loadPositions, getGapPool } from "../../Actions/Actions";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ModalPositionsChoose from "../ModalPositionsChoose";
import { ToastContainer, toast } from "react-toastify";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
import {
  addRecord,
  getPositionsByUsers,
  updateRecord,
  deleteRecord,
} from "../../Actions/Actions";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Checkbox } from "@mui/material";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export const AssignPositionsAvailables = (props) => {
  const [data, setdata] = useState([]);
  const [storedData, setStoredData] = useState([]);
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [loading, setloading] = useState(false);
  const [choose, setchoose] = useState([]);
  const [showposition, setshowposition] = useState(false);
  const [firebaseid, setfirebaseid] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const [searchText, setSearchText] = useState("");
  let QUERY = window.location.search;
  let id = QUERY.replace("?id=", "");
  const navigate = useNavigate();

  const handleSelectChange = (id) => {
    let selections = [...selectedBoxes];
    if (selectedBoxes.includes(id)) {
      const index = selections.indexOf(id);
      selections.splice(index, 1);
      console.log("x", selections);
      setSelectedBoxes(selections);
      return;
    }
    setSelectedBoxes([...selectedBoxes, id]);
  };

  const columns = [
    {
      headerName: "Select",
      width: 20,
      renderCell: (params) => (
        <>
          <Checkbox
            checked={selectedBoxes.includes(params.row.id)}
            onChange={() => handleSelectChange(params.row.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </>
      ),
    },
    {
      field: "id",
      headerName: "#",
      width: 20,
      hide: true,
    },
    {
      field: "department",
      headerName: "Department",
      width: 300,
    },

    {
      field: "position_name",
      headerName: "Position Name",
      width: 200,
    },
  ];

  useEffect(() => {
    const loadResults = async () => {
      await loadData();
    };

    loadResults();
  }, []);

  const loadData = async () => {
    setloading(true);
    let rowselected = [];
    let chooseselected = [];
    let positions = await loadPositions();
    const loadfb = await getPositionsByUsers("PositionsByUsers", id);
    let gappool = await getGapPool();
    gappool = gappool.filter((item) => item.status === "GAP POOL ST/BY");

    loadfb.forEach((item) => {
      if (item) {
        console.log(item);
        setfirebaseid(item.id);
        item.positions.forEach((items) => {
          console.log(items.id);
          rowselected.push(items.id);
          chooseselected.push(items);
        });
      }
    });
    setchoose(chooseselected);
    let departmentsavaiblegappol = [];
    let positionavaiblegappol = [];
    gappool.forEach((item) => {
      if (item.department) {
        departmentsavaiblegappol.push(item.department.toUpperCase());
      }

      if (item.position_applying) {
        positionavaiblegappol.push(item.position_applying.toUpperCase());
      }
    });
    departmentsavaiblegappol = deleteDuplicate(departmentsavaiblegappol);
    positionavaiblegappol = deleteDuplicate(positionavaiblegappol);
    positions = positions.filter((obj) =>
      ifExists(departmentsavaiblegappol, obj.department.toUpperCase())
    );
    positions = positions.filter((obj) =>
      ifExists(positionavaiblegappol, obj.position_name.toUpperCase())
    );
    console.log("POSITIONS:", positions);
    positions.forEach((item) => {
      item.id = item.Id;
      item.isSelected = false;
    });
    setdata(positions);
    setStoredData(positions);
    setdata(positions);
    setloading(false);
  };

  const handleOpen = () => {
    let choseData = storedData.filter((item) => {
      return selectedBoxes.includes(item.id);
    });
    setchoose(choseData);
    setshowposition(true);
  };
  const handleClose = () => {
    setshowposition(false);
  };

  const deleteAccess = async () => {
    if (id) {
      setloading(true);
      await deleteRecord("PositionsByUsers", firebaseid);
      await loadData();
      setfirebaseid("");
      setloading(false);
      window.location.reload();
    } else {
      showToast("No items selected or saved before", 2);
    }
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const addPositions = async () => {
    setloading(true);
    if (choose.length > 0) {
      const positionsavailables = {
        iduser: id,
        positions: choose,
      };

      console.log("firebase", firebaseid);
      if (firebaseid) {
        await updateRecord("PositionsByUsers", firebaseid, positionsavailables);
        handleClose();
        showToast("Permission was Updated Succesful", 1);
      } else {
        await addRecord("PositionsByUsers", positionsavailables);
        handleClose();
        showToast("Permission was added Succesful", 1);
      }
    } else {
      showToast("Please Select a Posiiton", 3);
    }

    //mostrar toad si está vacio
    //mostrar toad si se hizo bien.
    setloading(false);
  };

  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };

  const requestSearch = async (searchValue) => {
    if (searchValue === "") {
      setSearchText(searchValue);
      setdata(storedData);
    } else {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = data.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
      setdata(filteredRows);
    }
  };

  const deleteDuplicate = (array) => {
    const uniqueArr = [...new Set(array)];
    return uniqueArr;
  };

  const ifExists = (array, data) => {
    let response = false;
    array.forEach((item) => {
      if (item === data) {
        response = true;
      }
    });
    return response;
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}>
            {/* Poner botón atrás */}

            <Grid container>
              <Grid container sx={{ mb: 2 }}>
                <Grid item xs={3}>
                  <ArrowBackIosIcon onClick={() => navigate(-1)} />
                </Grid>
                <Grid item xs={3}>
                  <Title>Positions By Users</Title>
                </Grid>

                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    startIcon={<AssignmentIcon />}
                    size={"medium"}
                    onClick={() => handleOpen()}>
                    PREVIEW AND CONFIRM
                  </Button>
                </Grid>

                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    startIcon={<AssignmentIcon />}
                    size={"medium"}
                    onClick={() => deleteAccess()}
                    sx={{ backgroundColor: "#D32F2F" }}>
                    DELETE ACCESS
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <DataGrid
              rows={data}
              columns={columns}
              sx={{
                boxShadow: 2,
              }}
              loading={loading}
              /* checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel} */
              rowsPerPageOptions={[20, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              initialState={{
                pagination: {
                  pageSize: pageSize,
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => {
                    requestSearch(event.target.value);
                  },
                  clearSearch: () => requestSearch(""),
                },
                pagination: { classes: null },
              }}
            />
          </Paper>
        </Grid>
      </Container>

      <ModalPositionsChoose
        open={showposition}
        handleOpen={handleOpen}
        handleClose={handleClose}
        data={choose}
        setchoose={setchoose}
        addStaffToRequest={addPositions}
      />
      <ToastContainer />
    </Box>
  );
};

export default AssignPositionsAvailables;
