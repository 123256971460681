import React, { useState, useEffect } from "react";

import Title from "../Commons/Title";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import AlertDialog from "../Commons/Dialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingProgress from "../Commons/LoadingProgress";
import {
	showpersonal,
	getRecordById,
	updateArray,
	updateRecord,
	createNewHiring,
	userExists,
} from "../../Actions/Actions";
import { DataGrid } from "@mui/x-data-grid";
import _, { size, isEmpty } from "lodash";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams, useNavigate } from "react-router-dom";
import ModalPersonal from "../ModalPersonal";
import ModalPerson from "../ModalPerson";
import ErrorBoundary from "../Commons/ErrorBoundary";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DirectionsBoatRoundedIcon from "@mui/icons-material/DirectionsBoatRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import TodayIcon from "@mui/icons-material/Today";
const mdTheme = createTheme();

export const RequestDetails = (props) => {
	const [state, setstate] = useState({});
	const [open, setOpen] = React.useState(false);

	const [idgap, setid] = useState();
	const [data, setdata] = useState([]);
	const [choose, setchoose] = useState([]);
	const [staffdata, setstaffdata] = useState([]);
	const [viewperson, setviewperson] = useState(false);
	const [person, setperson] = useState(false);
	const [dialog, setdiaglog] = useState(false);
	const [rol, setrol] = useState("");
	const [loading, setloading] = useState(false);
	const [columns, setcolumns] = useState([]);
	const [datetotal, setdatetotal] = useState(moment());
	const navigate = useNavigate();

	const getActionsBtns = (record, params) => {
		let { accepted } = params.row;

		if (accepted === null) {
			return (
				<Grid container spacing={2} key={Math.random()}>
					<Grid item>
						<Button
							variant="contained"
							color="success"
							onClick={() => acceptHiring(record, params)}
							size="small">
							Accept
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							onClick={() => {
								refuse(record, params);
							}}
							size="small"
							color="error">
							Refuse
						</Button>
					</Grid>
				</Grid>
			);
		} else if (accepted === true) {
			return (
				<>
					<CheckCircleIcon sx={{ backgroundColor: "green", color: "#fff" }} />
					{/* {params.row.completeddate(
            <Typography>
              {" " + moment(params.row.completeddate.toDate()).format("ll")}
            </Typography>
          )} */}
				</>
			);
		} else if (accepted === false) {
			return (
				<>
					<CancelIcon sx={{ backgroundColor: "red", color: "#fff" }} />
					{/* <Typography>
            {" " + moment(params.row.completeddate.toDate()).format("ll")}
          </Typography> */}
				</>
			);
		}
	};

	const returnColumns = (record, rolload) => {
		console.log(record);
		console.log(record.status);
		console.log("EXTERNAL " + rolload);
		if (record.status === "reviewing" && rolload === "PMSSA") {
			return [
				{
					field: "Id",
					headerName: "Id",
					width: 10,
				},
				{
					field: "fullname",
					headerName: "Name",
					width: 280,
				},

				{
					field: "department",
					headerName: "Department",
					width: 100,
				},
				{
					field: "position_applying",
					headerName: "Position",
					width: 120,
				},

				{
					field: "sex",
					headerName: "Gender",
					width: 80,
				},
				{
					field: "row",
					headerName: "InterView /Marlin Test",
					width: 250,
					renderCell: (params) => getDeleteButton(record, params),
				},
				{
					field: "iscompleted",
					headerName: "Set Intv./M.Test Date",
					width: 200,
					renderCell: (params) => getButtons(record, params),
				},
			];
		} else if (record.status === "reviewing" && rolload === "EXTERNAL") {
			return [
				{
					field: "Id",
					headerName: "Id",
					width: 10,
				},
				{
					field: "fullname",
					headerName: "Name",
					width: 280,
				},

				{
					field: "department",
					headerName: "Department",
					width: 100,
				},
				{
					field: "position_applying",
					headerName: "Position",
					width: 120,
				},

				{
					field: "sex",
					headerName: "Gender",
					width: 80,
				},
				{
					field: "row",
					headerName: "InterView /Marlin Test",
					width: 300,
					renderCell: (params) => (
						<Typography sx={{ fontWeight: "bold", color: "green" }}>
							PMSSA IS WORKING ON ...
						</Typography>
					),
				},
			];
		} else if (record.status === "pending" && rolload === "PMSSA") {
			return [
				{
					field: "Id",
					headerName: "Id",
					width: 10,
				},
				{
					field: "fullname",
					headerName: "Name",
					width: 280,
				},

				{
					field: "department",
					headerName: "Department",
					width: 100,
				},
				{
					field: "position_applying",
					headerName: "Position",
					width: 120,
				},

				{
					field: "sex",
					headerName: "Gender",
					width: 80,
				},
				{
					field: "interviewtestdate",
					headerName: "InterView /Marlin Test",
					width: 180,
					renderCell: (params) => (
						<Typography sx={{ fontWeight: "bold", color: "green" }}>
							{!isEmpty(params.row.interviewtestdate)
								? moment(params.row.interviewtestdate).format("LLL")
								: "data.params.interviewtestdate"}
						</Typography>
					),
				},
				{
					field: "iscompleted",
					headerName: "Request Status",
					width: 300,
					renderCell: (params) => (
						<Typography sx={{ fontWeight: "bold", color: "#FFA000" }}>
							WAITING FOR APPROVAL...
						</Typography>
					),
				},
			];
		} else if (record.status === "pending" && rolload === "EXTERNAL") {
			return [
				{
					field: "Id",
					headerName: "Id",
					width: 10,
				},
				{
					field: "fullname",
					headerName: "Name",
					width: 280,
				},

				{
					field: "department",
					headerName: "Department",
					width: 100,
				},
				{
					field: "position_applying",
					headerName: "Position",
					width: 120,
				},

				{
					field: "sex",
					headerName: "Gender",
					width: 80,
				},
				{
					field: "interviewtestdate",
					headerName: "InterView /Marlin Test",
					width: 180,
					renderCell: (params) => (
						<>
							{console.log(
								"this date",
								moment(params.row.interviewtestdate).format("LLL")
							)}
							<Typography sx={{ fontWeight: "bold", color: "green" }}>
								{!isEmpty(params.row.interviewtestdate)
									? moment(params.row.interviewtestdate).format("LLL")
									: data.params.interviewtestdate}
							</Typography>
						</>
					),
				},
				state.status !== "reviewing" && {
					field: "accept",
					headerName: "Accept",
					width: 200,
					renderCell: (params) => getActionsBtns(record, params),
				},
			];
		} else if (record.status === "completed") {
			return [
				{
					field: "Id",
					headerName: "Id",
					width: 10,
				},
				{
					field: "fullname",
					headerName: "Name",
					width: 280,
				},

				{
					field: "department",
					headerName: "Department",
					width: 100,
				},
				{
					field: "position_applying",
					headerName: "Position",
					width: 120,
				},

				{
					field: "sex",
					headerName: "Gender",
					width: 60,
				},
				{
					field: "interviewtestdate",
					headerName: "InterView /Marlin Test",
					width: 220,
					renderCell: (params) => (
						<>
							<Typography sx={{ fontWeight: "bold", color: "green" }}>
								{!isEmpty(params.row.interviewtestdate)
									? moment(params.row.interviewtestdate).format("LLL")
									: data.params.interviewtestdate}
							</Typography>
						</>
					),
				},
				{
					field: "accept",
					headerName: "Accept",
					width: 200,
					renderCell: (params) => getActionsBtns(record, params),
				},
			];
		}
	};

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleOpenPerson = (params) => {
		setperson(params);
		setviewperson(true);
	};
	const handleClosePerson = () => setviewperson(false);

	const handleOpenDialog = () => setdiaglog(true);
	const handleCloseDialog = () => setdiaglog(false);

	let QUERY = window.location.search;
	useEffect(() => {
		const loadResults = async () => {
			await loadData();
		};

		loadResults();
	}, []);

	const getDeleteButton = (record, data) => {
		return (
			<MobileDateTimePicker
				value={
					!isEmpty(data.row.interviewtestdate)
						? moment(data.row.interviewtestdate).format("LLL")
						: data.row.interviewtestdate
				}
				onChange={(e) => {
					handleChangeDate(record, data, e);
				}}
				renderInput={(params) => (
					<>
						<TextField {...params} variant="outlined" size="small" />
					</>
				)}
				minDate={new Date()}
				sx={{ m: 2 }}
			/>
		);
	};

	const handleChangeDate = (record, row, value) => {
		let { id } = row.row;
		let newarray = [];
		console.log(state);
		record.request_details.map((item) => {
			if (item.id === id) {
				item.interviewtestdate = value;
			}
			newarray.push(item);
		});

		let obj = { ...state, request_details: newarray };
		setstate(obj);
	};

	const viewButton = (params) => {
		return (
			<AccountCircle
				style={{ fontSize: 25 }}
				color="primary"
				onClick={() => handleOpenPerson(params.row)}
			/>
		);
	};

	const deleteItem = async (params) => {
		console.log("GET PARAMS");
		console.log(params);
		let QUERY = window.location.search;
		let id = QUERY.replace("?id=", "");
		let data = state.staff;
		data = data.filter((item) => item.id !== params.id);
		await updateRecord("Request", id, {
			staff: data,
		});

		await loadData();
	};

	const showToast = (message, type) => {
		if (type === 1) {
			toast.success(message, { position: "top-center" });
		} else if (type === 2) {
			toast.warn(message, { position: "top-center" });
		} else if (type === 3) {
			toast.error(message, { position: "top-center" });
		}
	};

	const getButtons = (record, params) => {
		return (
			<Grid container spacing={2}>
				<Grid item>
					<Button
						variant="contained"
						color="success"
						onClick={() => asignDate(record, params)}
						size="small"
						startIcon={<TodayIcon />}>
						Set Date
					</Button>
				</Grid>
			</Grid>
		);
	};

	const asignDate = async (record, params) => {
		setloading(true);
		console.log(state);
		let idparams = QUERY.replace("?id=", "");
		let { id, interviewtestdate } = params.row; //validar si la entrevista est´en blanco

		if (interviewtestdate) {
			let newarray = [];
			record.request_details.map((item) => {
				console.log("date", moment(interviewtestdate).format("LLL"));
				if (item.id === id) {
					item.iscompleted = true;
					item.interviewtestdate = moment(interviewtestdate).format("LLL");
				}
				newarray.push(item);
			});

			console.log(newarray);

			await updateRecord("Request", idparams, {
				request_details: newarray,
			});
			await loadData();
			setloading(false);
		} else {
			showToast("Please select a Date", 3);
			setloading(false);
		}
	};

	const asignDateAll = async (record) => {
		setloading(true);
		console.log(state);
		let idparams = QUERY.replace("?id=", "");

		if (datetotal) {
			let newarray = [];
			record.request_details.map((item) => {
				console.log("date", moment(datetotal).format("LLL"));

				item.iscompleted = true;
				item.interviewtestdate = moment(datetotal).format("LLL");

				newarray.push(item);
			});

			//console.log(newarray);

			await updateRecord("Request", idparams, {
				request_details: newarray,
			});
			await loadData();
			setloading(false);
		} else {
			showToast("Please select a Date", 3);
			setloading(false);
		}
	};

	const assignAll = async () => {
		setloading(true);

		if (size(state.request_details) > 0) {
			console.log(state.request_details);
			console.log(datetotal);

			await asignDateAll(state);
		} else {
			toast.error("Empty Data");
		}
		setloading(false);
	};

	const acceptHiring = async (record, params) => {
		setloading(true);
		let idparams = QUERY.replace("?id=", "");
		let { id } = params.row;

		let newarray = [];
		record.request_details.map((item) => {
			if (item.id === id) {
				item.accepted = true;
				item.completeddate = new Date();
			}
			newarray.push(item);
		});

		await updateRecord("Request", idparams, {
			request_details: newarray,
		});
		await loadData();
		setloading(false);

		await createNewHiring(params.id);
		showToast(params.row.fullname + " has started the hiring process", 1);
	};

	const refuse = async (record, params) => {
		setloading(true);
		let { id } = params.row;
		let idparams = QUERY.replace("?id=", "");
		console.log("refuse");
		console.log(id);
		let newarray = [];
		record.request_details.map((item) => {
			if (item.id === id) {
				item.accepted = false;
				item.completeddate = new Date();
			}
			newarray.push(item);
		});

		await updateRecord("Request", idparams, {
			request_details: newarray,
		});
		await loadData();
		setloading(false);
		// await createNewHiring(params.id);
		showToast(params.row.fullname + " has been Declined", 3);
	};

	const getRol = async () => {
		const account = sessionStorage.getItem("user");
		const user = JSON.parse(account);
		const data = await userExists(user.email);

		setrol(_.get(data[0], "rol", ""));
		return _.get(data[0], "rol", "");
	};

	const loadData = async () => {
		let id = QUERY.replace("?id=", "");
		let datacompleta = {};
		const resultado = await getRecordById("Request", id);
		let response = [resultado];
		response.map((item) => {
			datacompleta = item;
			let amount = 0;
			datacompleta.createAt = moment(datacompleta.createAt.toDate()).format(
				"ll"
			);
			datacompleta.bordingDate = moment(
				datacompleta.bordingDate.toDate()
			).format("ll");

			let c = 0;
			datacompleta.request_details.map((amountdetail) => {
				c++;
			});

			datacompleta.amount = c;
		});
		let rolload = await getRol();
		setstate(datacompleta);
		let datacolums = returnColumns(datacompleta, rolload);
		setcolumns(datacolums);
	};

	const showDialogPerson = async (idsend, row, key) => {
		handleOpen();
		setid(idsend);
		const datos = await showpersonal();
		let respuesta = [];

		console.log("ID SEND");
		console.log(idsend);
		console.log("KEY");
		console.log(key);

		console.log("SHOW PERSONAL");
		console.log(datos);

		datos.map((item) => {
			if (
				_.get(item, "department", "").trim().toUpperCase() +
					_.get(item, "position_applying", "").trim().toUpperCase() ===
				key
			) {
				let datito = item;
				datito.id = item.Id;
				datito.check = respuesta.push(datito);
			}
		});

		setdata(respuesta);
	};

	const addStaffToRequest = async () => {
		let id = QUERY.replace("?id=", "");
		const data = choose;

		const updateselected = state.request_details;
		console.log(state.request_details);
		console.log(idgap);
		updateselected.map(async (item) => {
			if (item.id === idgap) {
				let datito = item;
				datito.selected = size(choose);
			}
		});
		await updateRecord("Request", id, {
			request_details: state.request_details,
		});
		await updateArray("Request", id, data);
		await loadData();
		handleClose();
	};

	const completeRequest = async () => {
		let id = QUERY.replace("?id=", "");
		handleCloseDialog();
		let process = true;
		if (state.status === "reviewing" && rol === "PMSSA") {
			state.request_details.map((item) => {
				if (isEmpty(item.interviewtestdate)) {
					process = false;
					showToast("Please set Interview Date to " + item.fullname, 2);
				}
			});
			if (process) {
				await updateRecord("Request", id, {
					status: "pending",
				});
				showToast("Request has been update successfully", 1);
			}
		} else if (state.status === "pending" && rol === "EXTERNAL") {
			state.request_details.map((item) => {
				console.log("ACCEPTED " + item.accepted);
				console.log(isEmpty(item.accepted));
				if (item.accepted === null) {
					process = false;
				}
			});

			if (process) {
				await updateRecord("Request", id, {
					status: "completed",
				});
				showToast("Request has been completed successfully", 1);
			} else {
				showToast("Please Accept or Refuse All Records ", 2);
			}
		} else if (state.status === "reviewing" && rol === "EXTERNAL") {
			showToast(
				"Please wait until PMSSA TEAM set Interview/Marlin Test Date",
				2
			);
		}
		redirect();
	};

	const redirect = () => {
		setTimeout(() => {
			navigate("/requests");
		}, 3000);
	};

	return (
		<ErrorBoundary>
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
					<Grid item xs={12} md={8} lg={9}>
						<Paper
							sx={{
								p: 2,
								display: "flex",
								flexDirection: "column",
							}}>
							<Grid container>
								<Grid item xs={2}>
									<ArrowBackIosNewRoundedIcon
										autoReverse={true}
										onClick={() => {
											navigate(-1);
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									<Title>Request Details</Title>
								</Grid>
								<Grid item xs={4}>
									<Button startIcon={<DirectionsBoatRoundedIcon />}>
										{state && state.vesselType}
									</Button>
								</Grid>
								<Grid>
									{state && (
										<Button
											startIcon={
												<AssignmentIcon
													sx={{
														color:
															state.status === "completed"
																? "green"
																: "#FFA000",
													}}
												/>
											}
											sx={{ fontWeight: "bold" }}>
											{state && state.status}
										</Button>
									)}
								</Grid>
							</Grid>

							<Grid container>
								<Grid item xs={3} sx={{ mb: 2 }}>
									<Typography>Boarding Date</Typography>

									<TextField
										autoComplete="given-name"
										name="firstName"
										required
										id="firstName"
										value={state.bordingDate}
										disabled
									/>
								</Grid>
								<Grid item xs={3}>
									<Typography>Created At</Typography>

									<TextField
										name="CreateAt"
										required
										id="firstName"
										value={state.createAt}
										disabled
									/>
								</Grid>
								<Grid item xs={3}>
									<Typography>Created By</Typography>

									<TextField
										name="Amount"
										required
										id="firstName"
										value={state.userEmail}
										disabled
									/>
								</Grid>
								<Grid item xs={3}>
									<Typography>Total Staff</Typography>

									<TextField
										name="firstName"
										required
										id="firstName"
										autoFocus
										disabled
										value={state.amount}
									/>
								</Grid>
							</Grid>

							<Grid container>
								<Grid sx={{ mt: 2 }} item xs={12}>
									{state.request_details && (
										<>
											<Title>Staff Chosen</Title>

											{state.status === "reviewing" && rol === "PMSSA" && (
												<Grid
													container
													justifyContent={"center"}
													alignContent={"center"}
													sx={{ mb: 2 }}>
													<Grid item sx={{ mr: 2 }}>
														<Typography sx={{ fontWeight: "bold" }}>
															Assign Date to All
														</Typography>
													</Grid>
													<Grid item>
														<MobileDateTimePicker
															value={datetotal}
															onChange={(e) => {
																//	handleChangeDate(record, data, e);
																setdatetotal(e);
															}}
															renderInput={(params) => (
																<>
																	<TextField
																		{...params}
																		variant="outlined"
																		size="small"
																	/>
																</>
															)}
															minDate={new Date()}
															sx={{ m: 2 }}
														/>
													</Grid>
													<Grid item sx={{ ml: 2 }}>
														<Button
															variant="contained"
															onClick={() => {
																assignAll();
															}}>
															Assign All
														</Button>
													</Grid>
												</Grid>
											)}

											<DataGrid
												rows={state.request_details}
												columns={columns}
												autoPageSize={true}
												sx={{
													boxShadow: 2,
													height: "60vh",
												}}
											/>
										</>
									)}
								</Grid>

								<Grid
									item
									xs={12}
									justifyContent="right"
									alignItems="center"
									sx={{ marginBottom: 2, mt: 2 }}>
									{state.status !== "completed" && (
										<Button
											variant="contained"
											onClick={() => {
												handleOpenDialog();
											}}>
											Complete Request
										</Button>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Container>
			</Box>

			<ModalPersonal
				open={open}
				handleOpen={handleOpen}
				handleClose={handleClose}
				data={data}
				setchoose={setchoose}
				addStaffToRequest={addStaffToRequest}
			/>
			<ModalPerson
				open={viewperson}
				handleOpen={handleOpenPerson}
				handleClose={handleClosePerson}
				data={person}
			/>

			<AlertDialog
				open={dialog}
				handleClose={handleCloseDialog}
				completeRequest={completeRequest}
				title={"Complete Staff Request"}
				description="Are you sure to complete staff request?"
			/>
			<LoadingProgress loading={loading} />
			<ToastContainer />
		</ErrorBoundary>
	);
};

export default RequestDetails;
