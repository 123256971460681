import { async } from "@firebase/util";
import { Title } from "@mui/icons-material";
import { Box, Container, Paper, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getComplaints,
  getGapPool,
  requestpersonalstatus,
} from "../../Actions/Actions";
import LoadingProgress from "../Commons/LoadingProgress";
import Chart from "react-apexcharts";
import { DataGrid } from "@mui/x-data-grid";
import PersonIcon from "@mui/icons-material/Person";
import { BlobProvider, Document, PDFViewer } from "@react-pdf/renderer";
import ComplaintPdf from "../ComplaintPdf";

export default function Complaints() {
  const [downloadFiles, setDownloadFiles] = useState(false);
  const [complaintsData, setComplaintsData] = useState([]);
  const [selectedUserPdf, setSelectedUserPdf] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [barChar, setBarChar] = useState(null);

  const getAllData = async () => {
    setIsLoading(true);
    const response = await getComplaints();
    if (!response.length) {
      setIsLoading(false);
      return;
    }
    response.forEach((item, index) => {
      item.id = index;
    });
    setComplaintsData(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const columns = [
    {
      field: "Id",
      headerName: "Download",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{ width: 100, textAlign: "center" }}
          onClick={() => {
            setSelectedUserPdf(params.row);
            setDownloadFiles(true);
          }}>
          <PersonIcon style={{ fontSize: 25 }} color="primary" />
        </Box>
      ),
      sortable: false,
    },
    {
      field: "fullname",
      headerName: "Full name",
      width: 250,
      editable: false,
    },
    {
      field: "complaint",
      headerName: "Complaint",
      sortable: false,
      width: 400,
    },
    {
      field: "company",
      headerName: "Company",
      sortable: false,
      width: 200,
    },
    {
      field: "vessel",
      headerName: "Vessel name",
      sortable: false,
      width: 150,
    },
    {
      field: "date",
      headerName: "Date",
      sortable: false,
      width: 150,
    },
    {
      field: "Idproceso",
      headerName: "Id flokzu",
      sortable: false,
      width: 150,
    },
  ];

  const handleDownloadAllPdf = (url) => {
    try {
      let link = document.createElement("a");
      // create a blobURI pointing to our Blob
      console.log("link", "numero de veces");
      link.href = url;
      link.download = "Staff.pdf";
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 1000);
    } catch (error) {
      console.log("Error:", error);
    }
    setDownloadFiles(false);
  };

  const PdfDownlaod = () => {
    return (
      <BlobProvider
        document={
          <Document>
            <ComplaintPdf user={selectedUserPdf} />
          </Document>
        }>
        {({ blob, url, loading, error }) => {
          if (url === null) return;
          handleDownloadAllPdf(url);
        }}
      </BlobProvider>
    );
  };

  const x = "eeeeeeeeeeeeeeeeeeeeee";

  return (
    <>
      {downloadFiles ? <PdfDownlaod /> : null}
      {/* <PDFViewer
        style={{
          width: "90%",
          height: "100vh",
          zIndex: 9999999,
          position: "fixed",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
        }}>
        <Document>
          <ComplaintPdf user={selectedUserPdf} />
        </Document>
      </PDFViewer> */}
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}>
          <Typography variant="h5" color="primary">
            Complaints amount
          </Typography>
          <Typography component="p" variant="h4">
            #{complaintsData.length} Complaint
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            {moment().format("MMM Do YY")}
          </Typography>
        </Paper>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}>
          {/* NEW CHART HERE */}
          {complaintsData && (
            <Box sx={{ p: 3 }}>
              <Typography variant="h5" color="primary">
                Complaints report
              </Typography>
              <Box sx={{ width: "100%", height: "70vh" }}>
                <DataGrid
                  rows={complaintsData}
                  columns={columns}
                  pageSize={10}
                />
              </Box>
            </Box>
          )}
        </Paper>
        <LoadingProgress loading={isLoading} />
      </Container>
    </>
  );
}
