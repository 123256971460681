import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Title from "../Commons/Title";
import { experimentalStyled as styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import _, { size, isEmpty } from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import DocumentsTab from "../DBComponets/DocumentsTab";
import COCTable from "../DBComponets//CocTable";
import ExperienceOnBoard from "../DBComponets/ExperienceOnBoard";
import ExperienceOnShore from "../DBComponets/ExperienceOnShore";
import LicBookSTCW from "../DBComponets/LicBookSTCW";
import OtherTrainings from "../DBComponets/OtherTrainings";
import Button from "@mui/material/Button";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",

  color: theme.palette.text.secondary,
}));

export default function ModalPerson({ open, handleOpen, handleClose, data }) {
  const [value, setValue] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Paper
          sx={{
            width: 0.9,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            paddingBottom: 10,
            paddingRight: 10,
            paddingLeft: 10,
            paddingTop: 10,
          }}>
          <Grid container alignContent={"center"} alignItems={"center"}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{ alignSelf: "center" }}>
                CLOSE
              </Button>
            </Grid>
          </Grid>

          <Grid sx={{ mt: 2, mb: 10 }}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItem: "center",
                marginRight: "auto",
                marginLeft: "auto",
              }}>
              <Grid item xs={2}>
                <Typography variant="subtitle2">Full Name: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <TextField
                  aria-readonly
                  required
                  id="fullname"
                  label=""
                  name="fullname"
                  autoComplete="fullname"
                  value={data.fullname}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Gender: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="sex"
                    value={_.get(data, "sex", "M")}>
                    <FormControlLabel value="F" control={<Radio />} label="F" />
                    <FormControlLabel value="M" control={<Radio />} label="M" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Date of Birth: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <MobileDatePicker
                  label=""
                  inputFormat="MM/dd/yyyy"
                  value={data.date_of_birth}
                  name="date_of_birth"
                  renderInput={(params) => (
                    <TextField
                      aria-readonly
                      {...params}
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2">Nationality: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <Typography> {data.place_of_birth} </Typography>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Application Date:</Typography>
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">
                  {data.application_date}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Category Vessel: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <Typography>{data.category_vessel}</Typography>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Company: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <Typography>{data.company}</Typography>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Department: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <Typography>{data.department}</Typography>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Email: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <TextField
                  aria-readonly
                  required
                  id="email"
                  label=""
                  name="email"
                  autoComplete="email"
                  value={data.email}
                  variant="standard"
                  fullWidth
                />
              </Grid>

              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Position: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <Typography>{data.position_applying} </Typography>
              </Grid>

              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Phone: </Typography>
              </Grid>

              <Grid item xs={4} sx={{ pr: 2 }}>
                <TextField
                  aria-readonly
                  required
                  id="phone"
                  label=""
                  name="phone"
                  autoComplete="phone"
                  value={data.phone}
                  variant="standard"
                  fullWidth
                />
              </Grid>

              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Whatsapp: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <TextField
                  aria-readonly
                  required
                  id="whatsapp"
                  label=""
                  name="whatsapp"
                  autoComplete="whatsapp"
                  value={data.whatsapp}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Nearly Airport: </Typography>
              </Grid>
              <Grid item xs={4} sx={{ pr: 2 }}>
                <TextField
                  aria-readonly
                  required
                  id="nearly_airtport"
                  label=""
                  name="nearly_airport"
                  autoComplete="nearly_airport"
                  value={data.nearly_airport}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">PMSSA #</Typography>
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Title> {data.id}</Title>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Commences Date: </Typography>
              </Grid>
              <Grid item xs={2} sx={{ pr: 2 }}>
                <Typography variant="subtitle2">
                  {data.contractcommencesdate}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">Ends Date: </Typography>
              </Grid>
              <Grid item xs={2} sx={{ pr: 2 }}>
                <Typography variant="subtitle2">
                  {" "}
                  {data.contractendsdate}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <Typography variant="subtitle2">
                  Company Hiring Code :
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{}}>
                <TextField
                  readonly
                  required
                  id="hiringcompanycode"
                  label=""
                  name="hiringcompanycode"
                  autoComplete="hiringcompanycode"
                  value={data.hiringcompanycode}
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                aria-label="basic tabs"
                variant="standard">
                <Tab label="DOCUMENTS" {...a11yProps(0)} />
                <Tab label="COC" {...a11yProps(1)} />
                <Tab label="EXPERIENCE ON BOARD" {...a11yProps(2)} />
                <Tab label="EXPERIENCE ON SHORE" {...a11yProps(3)} />
                <Tab label="LIC BOOK STCW" {...a11yProps(4)} />
                <Tab label="OTHER TRAININGS" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <DocumentsTab
                data={data.type_document}
                isview={1}
                disableAdd={true}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <COCTable data={data.COC} isview={1} disableAdd={true} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ExperienceOnBoard
                data={data.experience_onboard}
                isview={1}
                disableAdd={true}
              />
            </TabPanel>

            <TabPanel value={value} index={3}>
              <ExperienceOnShore
                data={data.experience_onshore}
                isview={1}
                disableAdd={true}
              />
            </TabPanel>

            <TabPanel value={value} index={4}>
              <LicBookSTCW
                data={data.lic_sbook_stcw_certificates}
                isview={1}
                disableAdd={true}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <OtherTrainings
                data={data.other_training}
                isview={1}
                disableAdd={true}
              />
            </TabPanel>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
