import { Text, View } from "@react-pdf/renderer";

const ExpOnShore = ({ styles, person, expOnShore }) => {
  const experiencesColumn = [
    {
      name: "Date on",
      width: "10%",
    },
    {
      name: "Date Off",
      width: "10%",
    },
    {
      name: "Company Name /ship-owner",
      width: "20%",
    },
    {
      name: "Duties or responsibilities",
      width: "20%",
    },
    {
      name: "Rank/position",
      width: "10%",
    },
    {
      name: "Reason for leaving",
      width: "10%",
    },
    {
      name: "Contact person telephone",
      width: "20%",
    },
  ];
  return (
    <View>
      <View style={styles.firstTextContainer}>
        <Text style={{ fontSize: 10 }}>Professional Experience Onshore:</Text>
      </View>
      <View style={{ borderBottom: 0.5 }}>
        <View style={[styles.row, { borderRight: 0.5 }]}>
          {experiencesColumn.map((item, index) => {
            return (
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: item.width },
                ]}
                key={index}>
                {item.name}
              </Text>
            );
          })}
        </View>
        {person.experience_onshore === "" ? (
          <>
            <View style={styles.row}>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "20%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "20%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "20%" },
                ]}></Text>
            </View>
          </>
        ) : (
          <>
            {expOnShore.map((item, index) => {
              return (
                <View style={styles.row} key={index}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["Date on"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["Date off"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "20%" },
                    ]}>
                    {item["Company name"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "20%" },
                    ]}>
                    {item["Duties or responsibilities"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["Rank/position"]
                      ? item["Rank/position"]
                      : item["Rank/ position"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["Reason for leaving"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "20%", borderRight: 0.5 },
                    ]}>
                    {item["Contact person telephone"]}
                  </Text>
                </View>
              );
            })}
          </>
        )}
      </View>
    </View>
  );
};

export default ExpOnShore;
