import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getData, getGapPool, getRecordById } from "../../Actions/Actions";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import View from "@mui/icons-material/ViewAgenda";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Checkbox, Divider, Typography } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { storage } from "../../Firebase.js/Firebase";
import FileSaver, { saveAs } from "file-saver";
import { stringify } from "uuid";

export const SkillsTable = ({
  data,
  position,
  questions,
  setQuestions,
  skillRecord,
  disabledBoxes,
}) => {
  const handleCheckBox = (event, section, question) => {
    let newItem = { ...questions[section].questions[question] };
    let newQuestions = [...questions];
    newItem.isTrue = event.target.checked;
    newQuestions[section].questions[question] = newItem;
    setQuestions(newQuestions);
  };

  const bosunSkill = [
    {
      skill:
        "Mark the follow skills/ responsibilities/ learning experience / achievements if you have knowledge, competence, and experience about:",
      questions: [
        {
          question:
            "Skills professional sailor, responsible, reliable, proactive and well organized with good managerial and organizational skills on the maintenance and conservation of the vessel´s decks and superstructures.",
          isTrue: false,
        },
        {
          question:
            "Have you carried out the task on board related to the able boded seaman (Abs) and ordinary Seaman (Oss) for work assignment?",
          isTrue: false,
        },
        {
          question:
            "Do you consider yourself to have the leadership qualities necessary to manage a multicultural crew?",
          isTrue: false,
        },
        {
          question: "Have you performed operations relevant to port cargo?",
          isTrue: false,
        },
        {
          question:
            "Do you have Knowledge of inventory management and ensure that there are sufficient inventories of all supplies and tools used by the deck department?",
          isTrue: false,
        },
        {
          question:
            "Do you have control of the operations carried out in the docking and undocking port have been supervised, including mooring ropes and/or anchor chains are properly fastened?",
          isTrue: false,
        },
      ],
    },
    {
      skill:
        "Do you have full knowledge of all day-to-day deck operations related to maintenance and deck procedures including?",
      questions: [
        {
          question: "•	Anchor windlass, chains, and anchors",
          isTrue: false,
        },
        {
          question: "•	Mooring winches, ropes, and springs",
          isTrue: false,
        },
        {
          question: "•	Cranes, derricks and associated auxiliary equipment",
          isTrue: false,
        },
        {
          question: "•	Lifeboats, davits and associated auxiliary equipment.",
          isTrue: false,
        },
        {
          question:
            "Supervises crew to ensure that all deck-planned maintenance is carried out within the specified time periods given by deck Maintenance officer.",
          isTrue: false,
        },
        {
          question:
            "Responsible for ensuring that all working routines and procedures associated with entering and working in confined spaces are strictly adhered to.",
          isTrue: false,
        },
        {
          question:
            "Have you have knowledge on how to report and fill out accident/incident reports?",
          isTrue: false,
        },
      ],
    },
    {
      skill:
        "Do you always followed all working routines and procedures associated with safety & environmental procedures?",
      questions: [
        {
          question:
            "Do you understand of the Company Safety & environmental protection quality management program and the responsibilities in the safety organization according to the emergency evacuation plan, as well as station bill?",
          isTrue: false,
        },
        {
          question:
            "Have you collaborated in carrying out firefighting drills on board the ship?",
          isTrue: false,
        },
      ],
    },
    {
      skill:
        "Do you have Knowledge of the responsibility that the crew in charge properly use the appropriate protective equipment?",
      questions: [
        {
          question:
            "Have you ensured that crew is using at all times the proper safety and protection equipment in the daily deck operations and the critical working areas are appropriately and adequately isolated?",
          isTrue: false,
        },
        {
          question:
            "Do you have knowledge of the care for the hazardous material and chemicals and all areas where these area in use within the deck department?",
          isTrue: false,
        },
        {
          question:
            "Have you known of the proper handling and storage of chemicals and hazards? (Including the correct storage of the paint)",
          isTrue: false,
        },
      ],
    },
    {
      skill: "Exceptional quality of work with outstanding results",
      questions: [
        {
          question: "Time management",
          isTrue: false,
        },
        {
          question: "Team worker",
          isTrue: false,
        },
        {
          question: "Good leader",
          isTrue: false,
        },
        {
          question: "Honest and hardworking",
          isTrue: false,
        },
        {
          question: "Can work effectively on team or independently",
          isTrue: false,
        },
        {
          question: "Neat and well organized",
          isTrue: false,
        },
        {
          question:
            "Respect and good treatment towards to my other colleagues.",
          isTrue: false,
        },
        {
          question: "Have your ever been nominated employee of the month",
          isTrue: false,
        },
        {
          question: "Can effectively perform with less or without supervision",
          isTrue: false,
        },
      ],
    },
  ];
  const cookSkill = [
    {
      skill:
        "Mark the follow skills /responsibilities / leaning experience / achievements if you have knowledge, competence, and experience about:",
      questions: [
        {
          question:
            "Do you have experience in the management and supervision of the kitchen personnel?",
          isTrue: false,
        },
        {
          question:
            "Are you able to work with multicultural teams and be able to assign tasks to them in the kitchen?",
          isTrue: false,
        },
        {
          question:
            "Can You maintain order, galley cleaning schedule established and discipline for the crew in charge, after each service, exercising proper methods to minimize equipment damages?",
          isTrue: false,
        },
        {
          question:
            "Are you aware that everything related to overtime must be coordinated with the captain, who is responsible for its authorization?",
          isTrue: false,
        },
        {
          question:
            "Do you know how the reports that you must present to the captain are carried out, related to the catering department to determine product availability and current food cost?",
          isTrue: false,
        },
        {
          question:
            "Do you know that the cook position in no way states or implies that these are the only duties to be performed by the shipboard employee occupying this position shipboard employees will be required to perform any other job-related duties assigned by master?",
          isTrue: false,
        },
        {
          question:
            "Do you known know about the established quality standards and company policies in order to supervise galley staff? ",
          isTrue: false,
        },
        {
          question:
            "Do you have experience keeping the galley, pantries, mess rooms and provision rooms clean and prepared for rough weather?",
          isTrue: false,
        },
      ],
    },
    {
      skill: "PRACTICAL KITCHEN KNOWLEDGE",
      questions: [
        {
          question:
            "Understands and follows a recipe, has knowledge of nutrition, raw materials, preparation techniques, and implements these skills in the planning of menus",
          isTrue: false,
        },
        {
          question:
            "Understand the importance of the daily/weekly menus and organize and prepare these menus",
          isTrue: false,
        },
        {
          question:
            "Understand the interaction between meals and the pace of daily work on board and its importance for the practical aspects of food and snack service",
          isTrue: false,
        },
        {
          question:
            "Do you have experience in planning and preparing orders for provisions keeping in mind food allowance?",
          isTrue: false,
        },
      ],
    },
    {
      skill: "FOOD HYGIENE AND PERSONAL",
      questions: [
        {
          question:
            "Maintains good personal hygiene, understands the importance of frequent hand washing, and how to perform it thoroughly, what kind of clothes to bring in the kitchen, and factors that put the health of others",
          isTrue: false,
        },
        {
          question:
            "Do you know of how to break the chain of foodborne diseases?",
          isTrue: false,
        },
        {
          question:
            "Understand the importance and knowledge of how to clean and disinfect kitchens, dining rooms and pantries, and why it is important to do so",
          isTrue: false,
        },
        {
          question:
            "Do you have knowledge of how to handle refrigerated and frozen products?",
          isTrue: false,
        },
        {
          question:
            "Organizes and applies appropriate working methods that allow the correct flow of products, as well as cleaning at all times, avoiding cross-contamination of food",
          isTrue: false,
        },
        {
          question:
            "Maintains adequate control to ensure the safety of food when refrigerating leftovers, especially the importance of putting date in food for reuse",
          isTrue: false,
        },
        {
          question:
            "Are you aware of the causes of allergies, how to prevent skin infections, and how to prevent skin allergies derived from food consumption, as well as how to identify the symptoms?",
          isTrue: false,
        },
        {
          question:
            "Know the importance of keeping proper maintenance control for drinking water equipment in order to prevent the proliferation of bacteria in the system",
          isTrue: false,
        },
      ],
    },
    {
      skill: "STORAGE OF FOOD",
      questions: [
        {
          question:
            "Do you have knowledge of how to store very perishable foods, perishables, and durations?",
          isTrue: false,
        },
        {
          question:
            "Procedure for store food safely and safely, especially once the container is opened.",
          isTrue: false,
        },
        {
          question:
            "Ensure the handling of food products at the time of delivery and how to place them on the shelves (first come, first out system) is carried according to procedures",
          isTrue: false,
        },
      ],
    },
    {
      skill: "MANAGEMENT OF RESERVATIONS",
      questions: [
        {
          question:
            "Are you aware that the food products to be bought, according to the composition of the menu plan, is according to the use of raw materials and the financial established?",
          isTrue: false,
        },
        {
          question:
            "Do you have experience in prepare and submit a monthly stock of provisions and a galley consumables equipment inventory?",
          isTrue: false,
        },
        {
          question:
            "Do you have ability to calculate figures and amounts such as discounts, interest, commissions, tips, proportions, percentages?",
          isTrue: false,
        },
        {
          question:
            "Can you maintain an adequate control of the quantity of provisions according to the number of crew members and the duration and nature of the voyages?",
          isTrue: false,
        },
        {
          question:
            "Do you Know the importance of food supply, and maintains control through systematization and periodic reviews to monitor the quantity and quality of food?",
          isTrue: false,
        },
        {
          question:
            "Are you able to draw up a menu plan for an extended period?",
          isTrue: false,
        },
        {
          question: "It supplies enough water to drink and prepare food.",
          isTrue: false,
        },
      ],
    },
    {
      skill: "ENVIRONMENTAL PROTECTION",
      questions: [
        {
          question:
            "Experience in how to manage the waste on board the ship in accordance with the provisions of Annex V of the MARPOL Convention, on waste management plans, kitchen waste should be handled and stored separately from provisions for food, raw materials and drinking water.",
          isTrue: false,
        },
      ],
    },
    {
      skill: "SAFETY AND HEALTH OF FONDA SERVICE",
      questions: [
        {
          question:
            "maintain the security in the storage of the provisions of the strong surge, while avoiding subjecting the body to an unnecessary physical effort",
          isTrue: false,
        },
        {
          question:
            "Are you familiar with the IGS Code and safety management systems?",
          isTrue: false,
        },
        {
          question:
            "Are you capable of providing first aid, especially specific kitchen area accidents, such as fires, machine-related accidents, cuts, scalds, caustic burns and crush injuries?",
          isTrue: false,
        },
        {
          question:
            "Do you know of how to deal with fires in kitchens, and the use of firefighting equipment?",
          isTrue: false,
        },
      ],
    },
  ];
  const fitterSkill = [
    {
      skill:
        "Mark the follow skills / responsibilities / learning experience / achievements if you have knowledge, competence, and experience about:",
      questions: [
        {
          question:
            "Have you contributed, supported, and performed all machinery space watches always following company´s procedures, rules, and regulations as fitter?",
          isTrue: false,
        },
        {
          question:
            "Do you have knowledge and experience in equipment maintenance?",
          isTrue: false,
        },
        {
          question:
            "Do you have welding certificate by one of the following LR, ABS, DNV?",
          isTrue: false,
        },
        {
          question:
            "Do you have knowledge and experience in welding, including TIG and MIG?",
          isTrue: false,
        },
        {
          question:
            "Do you have knowledge in the handling and storage of the equipment used on board?",
          isTrue: false,
        },
        {
          question:
            "Have you worked under the supervision of various departments, for example deck and engine department?",
          isTrue: false,
        },
        {
          question:
            "Are you aware that all overtime performed must be authorized and reported to the officer in charge, depending on the area and nature of the work performed?",
          isTrue: false,
        },
      ],
    },
    {
      skill:
        "Do you always followed all working routines and procedures associated with safety & environmental procedures?",
      questions: [
        {
          question:
            "Have you worked with supervisors and subordinates to understand and comply with the company´s environmental policies and be committed to safeguarding the environment?",
          isTrue: false,
        },
        {
          question:
            "Are you aware of the mechanisms to inform your superior about any situation on board that puts the environment at risk or environmental system does not function properly onboard?",
          isTrue: false,
        },
        {
          question:
            "Are you aware that it is your responsibility to keep the equipment and tools in good working order?",
          isTrue: false,
        },
      ],
    },
    {
      skill:
        "I have procedures of the supported and carried out in all engine-planned maintenance including:",
      questions: [
        {
          question: "Do you have Knowledge in overhauling of pumps?",
          isTrue: false,
        },
        {
          question: "Do you have Knowledge in operating lathe machine?",
          isTrue: false,
        },
        {
          question:
            "Do you have Knowledge in carrying out must steelwork, including stainless steel? ",
          isTrue: false,
        },
        {
          question: "Do you have Knowledge in Pipe installation and fitting?",
          isTrue: false,
        },
        {
          question:
            "Do you have experience in assist with overhauling of diesel engines and auxiliary systems?",
          isTrue: false,
        },
        {
          question:
            "Do you have experience to taking all reading on main engine and generators, and auxiliary equipment?",
          isTrue: false,
        },
        {
          question:
            "Do you always follow up all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE?",
          isTrue: false,
        },
      ],
    },
    {
      skill: "Exceptional quality of work with outstanding results:",
      questions: [
        {
          question: "Time management",
          isTrue: false,
        },
        {
          question: "Team Worker",
          isTrue: false,
        },
        {
          question: "Honest and hardworking",
          isTrue: false,
        },
        {
          question: "Can work effectively on team or independently",
          isTrue: false,
        },
        {
          question: "Neat and well Organized",
          isTrue: false,
        },
        {
          question: "Can effectively perform with less or without supervision",
          isTrue: false,
        },
        {
          question:
            "Respect and good treatment towards to my other colleagues.",
          isTrue: false,
        },
        {
          question: "Have your ever been nominated employee of the month?",
          isTrue: false,
        },
      ],
    },
  ];
  const messmanSkill = [
    {
      skill: "SKILLS / RESPONSIBILITIES / LEARNING EXPERIENCE / ACHIEVEMENTS",
      questions: [
        {
          question: "Hard worked",
          isTrue: false,
        },
        {
          question:
            "Well Organized and effective support skills, being able to take the initiative with jobs at hand. Proper cleaning techniques and chemical handling",
          isTrue: false,
        },
        {
          question:
            "Ability to work positively and cooperatively in a diverse team environment to meet the entire housekeeping operation.",
          isTrue: false,
        },
        {
          question:
            "Demonstrated aptitude and monitors at all times company´s OPP procedures for sanitation and cleanliness. ",
          isTrue: false,
        },
        {
          question:
            "Always in compliance with the company´s environmental policies and be committed to safeguarding the environment and performed all related duties and worn the proper PPE as required at all times.",
          isTrue: false,
        },
        {
          question:
            "Active worker and responsible Seaman able to adjust to a variety of activities such as: cleaning and sanitizing cabins, uploading and downloading provision, manipulate laundry equipment, handle cleaning machines, such as: Scrubbing machine, suction machine, shampooing machine, steaming machine, dealing with chemicals, doing the fogging, delivering food in quarantine areas, etc. ",
          isTrue: false,
        },
        {
          question:
            "So friendly, open minded, organized and effective support skills, being able to take the initiative with jobs at hand. Proper cleaning techniques and chemical handling. ",
          isTrue: false,
        },
        {
          question:
            "Ability to work every day cooperatively by using too much common sense in a multicultural environment to meet the entire housekeeping operation.",
          isTrue: false,
        },
        {
          question:
            "Demonstrated aptitude and monitors at all times company´s OPP procedures for sanitation and cleanliness.",
          isTrue: false,
        },
      ],
    },
  ];
  const oilerWiperSkill = [
    {
      skill: "For: Oiler",
      questions: [],
    },
    {
      skill:
        "Mark the follow skills / responsibilities / learning experience / achievements if you have knowledge, competence, and experience about:",
      questions: [
        {
          question:
            "I have contributed, supported, and performed all machinery space watches always following company´s procedures, rules, and regulations as motorman / oiler",
          isTrue: false,
        },
        {
          question:
            "I was also responsible for the inspection, operation, and testing, as required, of all machinery and equipment under may responsibility also assisting engineers during the machinery maintenance schedule",
          isTrue: false,
        },
        {
          question: "Hard working team player and quick to learn individual",
          isTrue: false,
        },
        {
          question:
            "I was also responsible for helping the EWO or Engineer advised regarding the status of the vessel and assigned equipment, always in compliance with the company´s environmental policies and be committed to safeguarding the environmental.",
          isTrue: false,
        },
      ],
    },
    {
      skill: "For: Wiper",
      questions: [
        {
          question: "Highly motivated and keen to learn from superiors",
          isTrue: false,
        },
        {
          question:
            "As wiper always keeping all the machinery spaces clean, tidy, and sanitized at all times.",
          isTrue: false,
        },
        {
          question:
            "Also participating in maintenance work, under supervision. Washing and rinse the floors, in daily basis, and painted at all times",
          isTrue: false,
        },
        {
          question:
            "In the event something went wrong it was reported immediately to the EOW. Ensures that relevant signs are posted, and advance notice was given when maintenance, repair, or cleaning operations",
          isTrue: false,
        },
        {
          question:
            "Performed al related duties and worn the proper PPE as required at all times",
          isTrue: false,
        },
      ],
    },
    {
      skill:
        "I have procedures of the supported and carried out in all engine-planned maintenance including:",
      questions: [
        {
          question: "Main engine",
          isTrue: false,
        },
        {
          question: "Purifiers room",
          isTrue: false,
        },
        {
          question: "Auxiliary Engine and Generators",
          isTrue: false,
        },
        {
          question: "I.G. Fans",
          isTrue: false,
        },
        {
          question: "Central Coolers",
          isTrue: false,
        },
        {
          question: "Main Engine Air Coolers",
          isTrue: false,
        },
        {
          question: "Painting & Cleaning Engine room and tanks",
          isTrue: false,
        },
        {
          question:
            "Assisting on transferring and sounding fuel / oil / sludge",
          isTrue: false,
        },
        {
          question: "Assisting over hauling pumps, valves, and others.",
          isTrue: false,
        },
        {
          question: "Engine inventory and storing",
          isTrue: false,
        },
        {
          question: "Loading and unloading",
          isTrue: false,
        },
        {
          question:
            "Taking all reading on main engine and generators, and auxiliary equipment",
          isTrue: false,
        },
        {
          question:
            "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
          isTrue: false,
        },
      ],
    },
    {
      skill: "Exceptional quality of work with outstanding results:",
      questions: [
        {
          question: "Time management",
          isTrue: false,
        },
        {
          question: "Team Worker",
          isTrue: false,
        },
        {
          question: "Good Leader",
          isTrue: false,
        },
        {
          question: "Honest and hardworking",
          isTrue: false,
        },
        {
          question: "Can work effectively on team or independently",
          isTrue: false,
        },
        {
          question: "Neat and well Organized",
          isTrue: false,
        },
        {
          question: "Can effectively perform with less or without supervision",
          isTrue: false,
        },
        {
          question:
            "Respect and good treatment towards to my other colleagues.",
          isTrue: false,
        },
        {
          question: "Have your ever been nominated employee of the month?",
          isTrue: false,
        },
      ],
    },
  ];
  const abOsSkill = [
    {
      skill:
        "Mark the follow skills/ responsibilities/ learning experience / achievements if you have knowledge, competence, and experience about:",
      questions: [
        {
          question:
            "Skill professional sailor, responsible, reliable, proactive and well organized with good managerial and organizational skills on the maintenance and conservation of the vessel´s decks and superstructures.",
          isTrue: false,
        },
        {
          question: "Perform operations relevant to port cargo.",
          isTrue: false,
        },
        {
          question: "Performed a safe lookout. ",
          isTrue: false,
        },
        {
          question:
            "Stood wheel duty when required and followed helm orders from the master, watchkeeping officer or the pilot",
          isTrue: false,
        },
        {
          question:
            "Stood duties at port by the gangway following company´s security policy.",
          isTrue: false,
        },
      ],
    },
    {
      skill:
        "I have procedures of the supported and carried out in all deck-planned maintenance including",
      questions: [
        {
          question: "Anchor windlass, chains, and anchors",
          isTrue: false,
        },
        {
          question: "Mooring winches, ropes, and springs",
          isTrue: false,
        },
        {
          question: "Cranes, derricks and associated auxiliary equipment",
          isTrue: false,
        },
        {
          question: "Lifeboats, davits and associated auxiliary equipment",
          isTrue: false,
        },
        {
          question: "Cleaning and maintaining other lifesaving appliances",
          isTrue: false,
        },
        {
          question:
            "Outer deck railings, wires, superstructures, deck hull, fire lockers, life raft stations, paint locker, hazmat, and chemicals.",
          isTrue: false,
        },
      ],
    },
    {
      skill:
        "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
      questions: [
        {
          question: "Deck Maintenance",
          isTrue: false,
        },
        {
          question: "Cut surface engrave ",
          isTrue: false,
        },
        {
          question: "Loading and unloading",
          isTrue: false,
        },
        {
          question: "Painting",
          isTrue: false,
        },
        {
          question: "Fast Rescue boats Handling",
          isTrue: false,
        },
        {
          question: "Survival Craft Boat handling",
          isTrue: false,
        },
        {
          question: "Deck Inventory / Storing",
          isTrue: false,
        },
      ],
    },
    {
      skill: "Exceptional quality of work with outstanding results",
      questions: [
        {
          question: "Time management",
          isTrue: false,
        },
        {
          question: "Team worker",
          isTrue: false,
        },
        {
          question: "Good leader",
          isTrue: false,
        },
        {
          question: "Honest and hardworking",
          isTrue: false,
        },
        {
          question: "Can work effectively on team or independently",
          isTrue: false,
        },
        {
          question: "Neat and well organized",
          isTrue: false,
        },
        {
          question:
            "Respect and good treatment towards to my other colleagues.",
          isTrue: false,
        },
        {
          question: "Have your ever been nominated employee of the month",
          isTrue: false,
        },
        {
          question: "Can effectively perform with less or without supervision",
          isTrue: false,
        },
      ],
    },
  ];

  useEffect(() => {
    if (questions.length !== 0 && disabledBoxes === true) {
      return;
    }
    if (data.position_applying === "BOSUN") {
      setQuestions(bosunSkill);
    }
    if (data.position_applying === "COOK") {
      setQuestions(cookSkill);
    }
    if (data.position_applying === "FITTER") {
      setQuestions(fitterSkill);
    }
    if (data.position_applying === "MESSMAN") {
      setQuestions(messmanSkill);
    }
    if (
      data.position_applying === "OILER" ||
      data.position_applying === "WIPER"
    ) {
      setQuestions(oilerWiperSkill);
    }
    if (data.position_applying === "AB" || data.position_applying === "OS") {
      setQuestions(abOsSkill);
    }
  }, []);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        px: 2,
      }}>
      {questions.map((section, index) => {
        return (
          <Box key={index}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "lightgray",
                py: 2,
                pl: 2,
              }}>
              <Typography sx={{ width: "90%", fontWeight: "bold" }}>
                {section.skill}
              </Typography>
              <Typography sx={{ width: "10%", fontWeight: "bold" }}>
                YES/NO
              </Typography>
            </Box>
            <Divider />
            {section.questions.map((item, i) => {
              return (
                <Box key={i}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      py: 1,
                    }}>
                    <Typography sx={{ width: "90%" }}>
                      {item.question || item.questions}
                    </Typography>
                    <Box sx={{ width: "10%" }}>
                      <Checkbox
                        checked={item.isTrue}
                        /*  disabled={disabledBoxes} */
                        onChange={(event) => handleCheckBox(event, index, i)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Paper>
  );
};

export default SkillsTable;
