import { InsertEmoticon } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import { borderRight } from "@mui/system";
import { Image, Text, View, StyleSheet, Page, Svg } from "@react-pdf/renderer";
import { useState } from "react";
import { useEffect } from "react";
import { getRecordById, getSkills, loadCertificates } from "../Actions/Actions";
import img from "../assets/logo.jpg";
import BasicInfo from "./Commons/PdfForms/BasicInfo";
import Certificates from "./Commons/PdfForms/Certificates";
import CocDocuments from "./Commons/PdfForms/CocDocuments";
import ExpOnBoard from "./Commons/PdfForms/ExpOnBoard";
import ExpOnShore from "./Commons/PdfForms/ExpOnShore";
import {
  AB_POSITIONS,
  BOSUN_POSITIONS,
  COOK_POSITIONS,
  COURSES_LIST,
  DOCUMUMENT_LIST,
  FITTER_POSITIONS,
  MESSMAN_POSITIONS,
  OILER_POSITIONS,
} from "../constants";
import moment from "moment";

const PdfDocument = ({ person, certData }) => {
  const [names, setNames] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [documents, setdocuments] = useState([]);
  const [COC, setCOC] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [otherTraining, setOtherTraining] = useState([]);
  const [expOnShore, setExpOnShore] = useState([]);
  const [expOnBoard, setExpOnBoard] = useState([]);
  const [skillTable, setSkillTable] = useState([]);
  const [sosContacts, setSosContacts] = useState([]);
  const [idProcessAnswers, setIdProcessAnswers] = useState([]);
  const [idProcessQuestions, setIdProcessQuestions] = useState([]);

  const skillArray = [
    "AB",
    "OS",
    "BOSUN",
    "COOK",
    "FITTER",
    "MESSMAN",
    "OILER",
    "WIPER",
    "Able Seaman",
    "Ordinary Seaman",
    "Apprentice Ordinary Seaman",
    "UTILITY COOK",
    "Motorman / Oiler",
    "Apprentice Wiper",
    "Galley steward",
    "Mechanics",
    "Welder",
    "Deck officers",
    "Deck cadet",
    "Engine Officers",
    "Engine Cadet",
    "Electro technical Officer III/6",
    "Electro-technical Rating III/7",
    "Electro-Technical Cadet",
  ];

  const bosunSkill = [
    "Skills professional sailor, responsible, reliable, proactive and well organized with good managerial and organizational skills on the maintenance and conservation of the vessel´s decks and superstructures.",
    "Have you carried out the task on board related to the able boded seaman (Abs) and ordinary Seaman (Oss) for work assignment?",
    "Do you consider yourself to have the leadership qualities necessary to manage a multicultural crew?",
    "Have you performed operations relevant to port cargo?",
    "Do you have Knowledge of inventory management and ensure that there are sufficient inventories of all supplies and tools used by the deck department?",
    "Do you have control of the operations carried out in the docking and undocking port have been supervised, including mooring ropes and/or anchor chains are properly fastened?",
    "•	Anchor windlass, chains, and anchors",
    "•	Mooring winches, ropes, and springs",
    "•	Cranes, derricks and associated auxiliary equipment",
    "•	Lifeboats, davits and associated auxiliary equipment.",
    "Supervises crew to ensure that all deck-planned maintenance is carried out within the specified time periods given by deck Maintenance officer.",
    "Responsible for ensuring that all working routines and procedures associated with entering and working in confined spaces are strictly adhered to.",
    "Have you have knowledge on how to report and fill out accident/incident reports?",
    "Do you understand of the Company Safety & environmental protection quality management program and the responsibilities in the safety organization according to the emergency evacuation plan, as well as station bill?",
    "Have you collaborated in carrying out firefighting drills on board the ship?",
    "Have you ensured that crew is using at all times the proper safety and protection equipment in the daily deck operations and the critical working areas are appropriately and adequately isolated?",
    "Do you have knowledge of the care for the hazardous material and chemicals and all areas where these area in use within the deck department?",
    "Have you known of the proper handling and storage of chemicals and hazards? (Including the correct storage of the paint)",
    "Time management",
    "Team worker",
    "Good leader",
    "Honest and hardworking",
    "Can work effectively on team or independently",
    "Neat and well organized",
    "Can effectively perform with less or without supervision",
    "Respect and good treatment towards to my other colleagues.",
    "Have your ever been nominated employee of the month",
  ];
  const cookSkill = [
    "Do you have experience in the management and supervision of the kitchen personnel?",
    "Are you able to work with multicultural teams and be able to assign tasks to them in the kitchen?",
    "Can You maintain order, galley cleaning schedule established and discipline for the crew in charge, after each service, exercising proper methods to minimize equipment damages?",
    "Are you aware that everything related to overtime must be coordinated with the captain, who is responsible for its authorization?",
    "Do you know how the reports that you must present to the captain are carried out, related to the catering department to determine product availability and current food cost?",
    "Do you know that the cook position in no way states or implies that these are the only duties to be performed by the shipboard employee occupying this position shipboard employees will be required to perform any other job-related duties assigned by master?",
    "Do you known know about the established quality standards and company policies in order to supervise galley staff? ",
    "Do you have experience keeping the galley, pantries, mess rooms and provision rooms clean and prepared for rough weather?",
    "Understands and follows a recipe, has knowledge of nutrition, raw materials, preparation techniques, and implements these skills in the planning of menus",
    "Understand the importance of the daily/weekly menus and organize and prepare these menus",
    "Understand the interaction between meals and the pace of daily work on board and its importance for the practical aspects of food and snack service",
    "Do you have experience in planning and preparing orders for provisions keeping in mind food allowance?",
    "Maintains good personal hygiene, understands the importance of frequent hand washing, and how to perform it thoroughly, what kind of clothes to bring in the kitchen, and factors that put the health of others",
    "Do you know of how to break the chain of foodborne diseases?",
    "Understand the importance and knowledge of how to clean and disinfect kitchens, dining rooms and pantries, and why it is important to do so",
    "Do you have knowledge of how to handle refrigerated and frozen products?",
    "Organizes and applies appropriate working methods that allow the correct flow of products, as well as cleaning at all times, avoiding cross-contamination of food",
    "Maintains adequate control to ensure the safety of food when refrigerating leftovers, especially the importance of putting date in food for reuse",
    "Are you aware of the causes of allergies, how to prevent skin infections, and how to prevent skin allergies derived from food consumption, as well as how to identify the symptoms?",
    "Know the importance of keeping proper maintenance control for drinking water equipment in order to prevent the proliferation of bacteria in the system",
    "Do you have knowledge of how to store very perishable foods, perishables, and durations?",
    "Procedure for store food safely and safely, especially once the container is opened.",
    "Ensure the handling of food products at the time of delivery and how to place them on the shelves (first come, first out system) is carried according to procedures",
    "Are you aware that the food products to be bought, according to the composition of the menu plan, is according to the use of raw materials and the financial established?",
    "Do you have experience in prepare and submit a monthly stock of provisions and a galley consumables equipment inventory?",
    "Do you have ability to calculate figures and amounts such as discounts, interest, commissions, tips, proportions, percentages?",
    "Can you maintain an adequate control of the quantity of provisions according to the number of crew members and the duration and nature of the voyages?",
    "Do you Know the importance of food supply, and maintains control through systematization and periodic reviews to monitor the quantity and quality of food?",
    "Are you able to draw up a menu plan for an extended period?",
    "It supplies enough water to drink and prepare food.",
    "Experience in how to manage the waste on board the ship in accordance with the provisions of Annex V of the MARPOL Convention, on waste management plans, kitchen waste should be handled and stored separately from provisions for food, raw materials and drinking water.",
    "maintain the security in the storage of the provisions of the strong surge, while avoiding subjecting the body to an unnecessary physical effort",
    "Are you familiar with the IGS Code and safety management systems?",
    "Are you capable of providing first aid, especially specific kitchen area accidents, such as fires, machine-related accidents, cuts, scalds, caustic burns and crush injuries?",
    "Do you know of how to deal with fires in kitchens, and the use of firefighting equipment?",
  ];
  const fitterSkill = [
    "Have you contributed, supported, and performed all machinery space watches always following company´s procedures, rules, and regulations as fitter?",
    "Do you have knowledge and experience in equipment maintenance?",
    "Do you have welding certificate by one of the following LR, ABS, DNV?",
    "Do you have knowledge and experience in welding, including TIG and MIG?",
    "Do you have knowledge in the handling and storage of the equipment used on board?",
    "Have you worked under the supervision of various departments, for example deck and engine department?",
    "Are you aware that all overtime performed must be authorized and reported to the officer in charge, depending on the area and nature of the work performed?",
    "Have you worked with supervisors and subordinates to understand and comply with the company´s environmental policies and be committed to safeguarding the environment?",
    "Are you aware of the mechanisms to inform your superior about any situation on board that puts the environment at risk or environmental system does not function properly onboard?",
    "Are you aware that it is your responsibility to keep the equipment and tools in good working order?",
    "Do you have Knowledge in overhauling of pumps?",
    "Do you have Knowledge in operating lathe machine?",
    "Do you have Knowledge in carrying out must steelwork, including stainless steel? ",
    "Do you have Knowledge in Pipe installation and fitting?",
    "Do you have experience in assist with overhauling of diesel engines and auxiliary systems?",
    "Do you have experience to taking all reading on main engine and generators, and auxiliary equipment?",
    "Do you always follow up all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE?",
    "Time management",
    "Team Worker",
    "Good Leader",
    "Honest and hardworking",
    "Can work effectively on team or independently",
    "Neat and well Organized",
    "Can effectively perform with less or without supervision",
    "Respect and good treatment towards to my other colleagues.",
    "Have your ever been nominated employee of the month?",
  ];
  const messmanSkill = [
    "Hard worked",
    "Well Organized and effective support skills, being able to take the initiative with jobs at hand. Proper cleaning techniques and chemical handling",
    "Ability to work positively and cooperatively in a diverse team environment to meet the entire housekeeping operation.",
    "Demonstrated aptitude and monitors at all times company´s OPP procedures for sanitation and cleanliness. ",
    "Always in compliance with the company´s environmental policies and be committed to safeguarding the environment and performed all related duties and worn the proper PPE as required at all times.",
    "Active worker and responsible Seaman able to adjust to a variety of activities such as: cleaning and sanitizing cabins, uploading and downloading provision, manipulate laundry equipment, handle cleaning machines, such as: Scrubbing machine, suction machine, shampooing machine, steaming machine, dealing with chemicals, doing the fogging, delivering food in quarantine areas, etc. ",
    "So friendly, open minded, organized and effective support skills, being able to take the initiative with jobs at hand. Proper cleaning techniques and chemical handling. ",
    "Ability to work every day cooperatively by using too much common sense in a multicultural environment to meet the entire housekeeping operation.",
  ];
  const oiler = [
    "I have contributed, supported, and performed all machinery space watches always following company´s procedures, rules, and regulations as motorman / oiler",
    "I was also responsible for the inspection, operation, and testing, as required, of all machinery and equipment under may responsibility also assisting engineers during the machinery maintenance schedule",
    "Hard working team player and quick to learn individual",
    "I was also responsible for helping the EWO or Engineer advised regarding the status of the vessel and assigned equipment, always in compliance with the company´s environmental policies and be committed to safeguarding the environmental.",
    "Main engine",
    "Purifiers room",
    "Auxiliary Engine and Generators",
    "I.G. Fans",
    "Central Coolers",
    "Main Engine Air Coolers",
    "Painting & Cleaning Engine room and tanks",
    "Assisting on transferring and sounding fuel / oil / sludge",
    "Assisting over hauling pumps, valves, and others.",
    "Engine inventory and storing",
    "Taking all reading on main engine and generators, and auxiliary equipment",
    "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
    "Loading and unloading",
    "Time management",
    "Team Worker",
    "Good Leader",
    "Honest and hardworking",
    "Can work effectively on team or independently",
    "Neat and well Organized",
    "Can effectively perform with less or without supervision",
    "Respect and good treatment towards to my other colleagues.",
    "Have your ever been nominated employee of the month?",
  ];
  const wiper = [
    "Highly motivated and keen to learn from superiors",
    "As wiper always keeping all the machinery spaces clean, tidy, and sanitized at all times.",
    "Also participating in maintenance work, under supervision. Washing and rinse the floors, in daily basis, and painted at all times",
    "In the event something went wrong it was reported immediately to the EOW. Ensures that relevant signs are posted, and advance notice was given when maintenance, repair, or cleaning operations",
    "Performed al related duties and worn the proper PPE as required at all times",
    "Main engine",
    "Purifiers room",
    "Auxiliary Engine and Generators",
    "I.G. Fans",
    "Central Coolers",
    "Main Engine Air Coolers",
    "Painting & Cleaning Engine room and tanks",
    "Assisting on transferring and sounding fuel / oil / sludge",
    "Assisting over hauling pumps, valves, and others.",
    "Engine inventory and storing",
    "Taking all reading on main engine and generators, and auxiliary equipment",
    "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
    "Loading and unloading",
    "Time management",
    "Team Worker",
    "Good Leader",
    "Honest and hardworking",
    "Can work effectively on team or independently",
    "Neat and well Organized",
    "Can effectively perform with less or without supervision",
    "Respect and good treatment towards to my other colleagues.",
    "Have your ever been nominated employee of the month?",
  ];
  const abOsSkill = [
    "Skill professional sailor, responsible, reliable, proactive and well organized with good managerial and organizational skills on the maintenance and conservation of the vessel´s decks and superstructures.",
    "Perform operations relevant to port cargo.",
    "Performed a safe lookout. ",
    "Stood wheel duty when required and followed helm orders from the master, watchkeeping officer or the pilot",
    "Stood duties at port by the gangway following company´s security policy.",
    "Anchor windlass, chains, and anchors",
    "Mooring winches, ropes, and springs",
    "Cranes, derricks and associated auxiliary equipment",
    "Lifeboats, davits and associated auxiliary equipment",
    "Cleaning and maintaining other lifesaving appliances",
    "Outer deck railings, wires, superstructures, deck hull, fire lockers, life raft stations, paint locker, hazmat, and chemicals.",
    "Deck Maintenance",
    "Cut surface engrave 		",
    "Loading and unloading		",
    "Painting",
    "Fast Rescue boats Handling		",
    "Survival Craft Boat handling		",
    "Deck Inventory / Storing",
    "Time management",
    "Team worker",
    "Good leader",
    "Honest and hardworking",
    "Can work effectively on team or independently",
    "Neat and well organized",
    "Can effectively perform with less or without supervision",
    "Respect and good treatment towards to my other colleagues.",
    "Have your ever been nominated employee of the month",
  ];

  const getSkillFromIdProcess = async (idprocessdata) => {
    let filteredQuestions = [];
    if (person.idproceso !== "") {
      //TO DO MAKE THE PROCESS ID QUESTIONSttt
      idprocessdata.fields.forEach((item) => {
        if (
          Object.keys(item).some(function (k) {
            return ~k.indexOf("Answer");
          })
        ) {
          filteredQuestions.push(item);
        }
      });
    }
    if (
      AB_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let ABOS_1 = filteredQuestions.slice(0, 18);
      let ABOS_2 = filteredQuestions.slice(118, 140);
      setIdProcessAnswers([...ABOS_1, ...ABOS_2]);
      setIdProcessQuestions(abOsSkill);
    }
    if (
      BOSUN_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let BOSUN_1 = filteredQuestions.slice(18, 36);
      let BOSUN_2 = filteredQuestions.slice(118, 140);
      setIdProcessAnswers([...BOSUN_1, ...BOSUN_2]);
      setIdProcessQuestions(bosunSkill);
    }
    if (
      COOK_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      console.log("entering");
      let COOK = filteredQuestions.slice(36, 80);
      console.log("filteredQuestions", filteredQuestions);
      setIdProcessAnswers(COOK);
      setIdProcessQuestions(cookSkill);
    }
    if (
      MESSMAN_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let MESSMAN = filteredQuestions.slice(88, 105);
      setIdProcessAnswers(MESSMAN);
      setIdProcessQuestions(messmanSkill);
    }
    if (
      FITTER_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let FITTER_1 = filteredQuestions.slice(71, 88);
      let FITTER_2 = filteredQuestions.slice(118, 140);
      setIdProcessAnswers([...FITTER_1, ...FITTER_2]);
      setIdProcessQuestions(fitterSkill);
    }
    if (
      OILER_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let OILER_1 = filteredQuestions.slice(96, 100);
      let OILER_2 = filteredQuestions.slice(105, 127);
      setIdProcessAnswers([...OILER_1, ...OILER_2]);
      setIdProcessQuestions(oiler);
    }
    if (person.position_applying === "WIPER") {
      let WIPER_1 = filteredQuestions.slice(100, 127);
      setIdProcessAnswers(WIPER_1);
      setIdProcessQuestions(wiper);
    }
  };

  useEffect(() => {
    const getSkillRecord = async () => {
      if (person.idproceso !== "") {
        const idProcessData = await getSkills(person.idproceso);
        getSkillFromIdProcess(idProcessData);
        return;
      }
      await getRecordById("Skills", person.Id.toString()).then((res) => {
        if (Array.isArray(res.skills)) {
          setSkillTable(res.skills);
        }
      });
    };
    getSkillRecord();
    if (person.emergencycontact !== "") {
      setSosContacts(JSON.parse(person.emergencycontact));
    }
    let [firstName, secName, secLastName, firstLastName] =
      person.fullname.split(" ");
    setNames([firstName, secName, secLastName, firstLastName]);
    if (person.type_document !== "") {
      let localDocs = JSON.parse(person.type_document);
      DOCUMUMENT_LIST.forEach((document) => {
        let exists = localDocs.some(
          (obj) =>
            obj["Type of Document"]?.toUpperCase() === document.toUpperCase()
        );
        if (!exists) {
          localDocs.push({ "Type of Document": document });
        }
      });

      /* localDocs.forEach((item) => {
        if (!DOCUMUMENT_LIST.includes(item["Type of Document"])) {
          localDocs.push({ "Type of Document": item["Type of Document"] });
        }
      }); */
      setdocuments(localDocs);
    } else {
      const empty_doc_list = DOCUMUMENT_LIST.map((item) => {
        return { "Type of Document": item };
      });
      setdocuments(empty_doc_list);
    }
    if (person.COC !== "") {
      setCOC(JSON.parse(person.COC));
    }
    if (person.lic_sbook_stcw_certificates !== "") {
      const filteredArray = certData.filter((obj) => {
        const keys = Object.keys(obj).map((item) => {
          return item.toUpperCase();
        });
        if (person.position_applying.toUpperCase().includes("OFFICER")) {
          return obj["officer"] === "1";
        } else if (person.position_applying.toUpperCase().includes("COOK")) {
          return obj["cook"] === "1";
        } else if (!keys.includes(person.position_applying.toUpperCase())) {
          return obj["hotelstaff"] === "1";
        } else {
          return Object.keys(obj).some((key) => {
            const value = obj[key];
            return (
              typeof value === "string" &&
              key.toUpperCase() === person.position_applying.toUpperCase() &&
              value === "1"
            );
          });
        }
      });

      const courseNames = filteredArray.map((item) =>
        item.curse_name?.toUpperCase()
      );

      console.log("filteredArray", courseNames);
      const localCourses = JSON.parse(person.lic_sbook_stcw_certificates);

      courseNames.forEach((course) => {
        let exists = localCourses.some(
          (obj) =>
            obj["Description of cert / course"]?.toUpperCase() ===
            course.toUpperCase()
        );
        if (!exists) {
          localCourses.push({ "Description of cert / course": course });
        }
      });
      setCertificates(localCourses);
    }
    if (person.other_training !== "") {
      setOtherTraining(JSON.parse(person.other_training));
    }
    if (person.experience_onshore !== "") {
      setExpOnShore(JSON.parse(person.experience_onshore));
    }
    if (person.experience_onboard !== "") {
      setExpOnBoard(JSON.parse(person.experience_onboard));
    }
    fixCoCs();
  }, [person]);

  const fixCoCs = () => {
    switch (person.position_applying) {
      case "AB" || "OS":
        setCodigo("F-PMSSA-01-C");
        fixCocFunction(["COC II/4", "COC II/5"]);
        break;
      case "BOSUN":
        setCodigo("F-PMSSA-01-D");
        fixCocFunction(["COC II/5"]);
        break;
      case "COOK":
        setCodigo("F-PMSSA-01-B");
        fixCocFunction(["COC"]);
        break;
      case "MESSMAN":
        setCodigo("F-PMSSA-01-E");
        fixCocFunction(["COC II/4", "COC VI/1"]);
        break;
      case "FITTER":
        setCodigo("F-PMSSA-01-F");
        fixCocFunction(["COC III/5", "COC VI/6-2"]);
        break;
      case "OILER" || "WIPER":
        setCodigo("F-PMSSA-01-G");
        fixCocFunction(["COC III/4", "COC III/5"]);
        break;
      default:
        setCodigo("F-PMSSA-01");
    }
  };

  const fixCocFunction = (abskills) => {
    const coc = JSON.parse(person.COC);
    const updatedCOC = [...coc];

    abskills.forEach((skill) => {
      const foundItem = coc.find((item) => item["COC DOC."] === skill);
      if (!foundItem) {
        updatedCOC.push({ "COC DOC.": skill });
      }
    });
    setCOC(updatedCOC);
  };

  const lightGrey = "#e9e9e9";

  return (
    <Page
      size="A4"
      style={{
        padding: 40,
        paddingBottom: 90,
        flexDirection: "column",
        display: "block",
      }}>
      <View style={styles.mainContainer}>
        {/* TOP SECTION */}
        <View style={styles.topSectionContainer}>
          <Image src={img} style={{ width: 100 }} />
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Text style={{ fontSize: 16 }}>
              LOGISTIC INTERNATIONAL SERVICES
            </Text>
            <Text style={{ marginTop: 10, fontSize: 14 }}>
              SEAFARER APPLICATION FORM
            </Text>
          </View>
          <View style={styles.icon}>
            {person.user_selfie ? (
              <Image
                style={{
                  objectFit: "cover",
                  objectPosition: "center center",
                  width: "100%",
                  height: "100%",
                  borderWidth: 0.5,
                  borderColor: "black",
                }}
                src={person.user_selfie}
                alt="User selfie"
              />
            ) : (
              <>
                <Text style={{ fontSize: 10 }}>
                  PHOTO IN COLOR AND IN PROFESSIONAL DRESS. NO CASUAL PHOTOS
                </Text>
              </>
            )}
          </View>
        </View>
        {/* TEXT BEFORE THE TABLE */}
        <View>
          <View style={styles.firstTextContainer}>
            <Text style={{ fontSize: 10 }}>1. Personal information</Text>
            <Text style={{ fontSize: 10 }}>
              Position Applying for Rank: {person.position_applying}
            </Text>
          </View>
        </View>
        {/* TABLE ITSELF  */}
        <BasicInfo names={names} person={person} />
        {/* ## DOCUMENTS/COC TABLE ##eeee */}
        {/*  <View style={[styles.row, { marginTop: 20 }]}>
            <Text
              style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
              Last wages
            </Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "30%" },
              ]}></Text>
            <Text
              style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
              Required Wages
            </Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "30%", borderRight: 0.5 },
              ]}></Text>
          </View> */}
        {/* EMERGENCY CONTACTS */}
        <View style={styles.row}>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              { width: "100%", borderRight: 0.5 },
            ]}>
            Emergency Contact / Next of kin
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={[styles.cell, styles.normalTableText, { width: "40%" }]}>
            Complete Name
          </Text>
          <Text style={[styles.cell, styles.normalTableText, { width: "10%" }]}>
            Relationship
          </Text>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              { width: "30%", borderRight: 0.5 },
            ]}>
            Address
          </Text>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              { width: "20%", borderRight: 0.5 },
            ]}>
            Telephone Number / Mobile
          </Text>
        </View>
        {sosContacts.length > 0 ? (
          <>
            <View style={{ borderBottom: 0.5 }}>
              {sosContacts.map((row) => {
                return (
                  <View style={[styles.row, { borderRight: 0.5 }]}>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "40%" },
                      ]}>
                      {row.Name} {row["Middle name"]} {row.Surnames}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "10%" },
                      ]}>
                      {row.Relationship}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "30%" },
                      ]}>
                      {row.Address}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "20%" },
                      ]}>
                      {row.Phone}
                    </Text>
                  </View>
                );
              })}
            </View>
          </>
        ) : (
          <>
            <View style={[styles.row, { borderBottom: 0.5 }]}>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "50%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "20%" },
                ]}></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "30%", borderRight: 0.5 },
                ]}></Text>
            </View>
          </>
        )}
      </View>
      {person.position_applying.includes("CADET") ||
      person.position_applying.includes("OFFICER") ? (
        <>
          <View style={{ marginVertical: 10, borderBottom: 0.5 }}>
            <View style={styles.row}>
              <Text
                style={[styles.cell, styles.normalTableText, { width: "10%" }]}>
                COC
              </Text>
              <Text
                style={[styles.cell, styles.normalTableText, { width: "15%" }]}>
                Grade of license
              </Text>
              <Text
                style={[styles.cell, styles.normalTableText, { width: "15%" }]}>
                Country of issue
              </Text>
              <Text
                style={[styles.cell, styles.normalTableText, { width: "10%" }]}>
                No.
              </Text>
              <Text
                style={[styles.cell, styles.normalTableText, { width: "10%" }]}>
                Date of issue
              </Text>
              <Text
                style={[styles.cell, styles.normalTableText, { width: "20%" }]}>
                Issue at (place)
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "20%", borderRight: 0.5 },
                ]}>
                Valid until
              </Text>
            </View>
            {COC.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["COC DOC."]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%" },
                    ]}>
                    {item["Grade of license (if apply)"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%" },
                    ]}>
                    {item["Country of issue"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["No."]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%" },
                    ]}>
                    {item["Date of issue"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "20%" },
                    ]}>
                    {item["Issue at (place)"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "20%", borderRight: 0.5 },
                    ]}>
                    {item["Valid until"]}
                  </Text>
                </View>
              );
            })}
          </View>
        </>
      ) : null}

      <View style={styles.mainContainer}>
        {/* ### DOCUMENTS/COC TABEL ###tt */}
        <CocDocuments
          person={person}
          documents={documents}
          COC={COC}
          lightGrey={lightGrey}
          hideCoC={
            person.position_applying.includes("CADET") ||
            person.position_applying.includes("OFFICER")
          }
        />
        {/* ### 2. Professional Experience Onboard: ### */}
        <View style={{ width: "100%" }}>
          <ExpOnBoard styles={styles} person={person} expOnBoard={expOnBoard} />
          {/* ### 2. Professional Experience SHORE: #### */}
          <ExpOnShore styles={styles} person={person} expOnShore={expOnShore} />
        </View>
      </View>
      <View style={styles.mainContainer}>
        {/* ### CERTIFICATES TABLE ####ttt */}
        <Certificates
          styles={styles}
          person={person}
          certificates={certificates}
          otherTraining={otherTraining}
          lightGrey={lightGrey}
        />
      </View>
      {skillArray
        .map((item) => item.toUpperCase())
        .includes(person.position_applying.toUpperCase()) ? (
        <>
          {person.idproceso === "" ? (
            <>
              {skillTable.length > 0 ? (
                <View style={styles.mainContainer}>
                  <Text
                    style={[
                      styles.normalTableText,
                      {
                        textAlign: "left",
                      },
                    ]}>
                    4. SKILLS / RESPONSIBILITIES / LEARNING EXPERIENCE /
                    ACHIEVEMENTS
                  </Text>
                  <View>
                    <View style={{ borderBottom: 0.5 }}>
                      {skillTable.map((section, index) => {
                        return (
                          <View key={index}>
                            <View
                              style={[
                                styles.row,
                                { backgroundColor: lightGrey },
                              ]}>
                              <Text
                                style={[
                                  styles.cell,
                                  styles.normalTableText,
                                  {
                                    width: "80%",
                                    textAlign: "left",
                                    paddingLeft: 7,
                                  },
                                ]}>
                                {section.skill}
                              </Text>
                              <Text
                                style={[
                                  styles.cell,
                                  styles.normalTableText,
                                  { width: "10%" },
                                ]}>
                                Yes
                              </Text>
                              <Text
                                style={[
                                  styles.cell,
                                  styles.normalTableText,
                                  { width: "10%", borderRight: 0.5 },
                                ]}>
                                No
                              </Text>
                            </View>
                            {/* TABLE BEGINS */}
                            {section.questions.map((row, i) => {
                              return (
                                <View style={[styles.row]} key={i}>
                                  <Text
                                    style={[
                                      styles.cell,
                                      styles.normalTableText,
                                      {
                                        width: "80%",
                                        textAlign: "left",
                                        paddingLeft: 7,
                                        paddingBottom: 5,
                                      },
                                    ]}>
                                    {row.question}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.cell,
                                      styles.normalTableText,
                                      { width: "10%" },
                                    ]}>
                                    {row.isTrue ? "YES" : ""}
                                  </Text>
                                  <Text
                                    style={[
                                      styles.cell,
                                      styles.normalTableText,
                                      { width: "10%", borderRight: 0.5 },
                                    ]}>
                                    {row.isTrue ? "" : "NO"}
                                  </Text>
                                </View>
                              );
                            })}
                          </View>
                        );
                      })}
                    </View>
                  </View>
                  {/* TABLE BEGINS  tttttttt */}
                </View>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <View style={[styles.mainContainer, { marginTop: 10 }]}>
                <Text
                  style={[
                    styles.normalTableText,
                    {
                      textAlign: "left",
                      marginBottom: 2,
                    },
                  ]}>
                  4. SKILLS / RESPONSIBILITIES / LEARNING EXPERIENCE /
                  ACHIEVEMENTS
                </Text>
                <View>
                  <View style={{ borderBottom: 0.5 }}>
                    {idProcessQuestions.map((item, index) => {
                      return (
                        <View
                          key={index}
                          style={[
                            styles.row,
                            { width: "100%", fontSize: 10, my: 4 },
                          ]}>
                          <View style={{ width: "80%", borderLeft: 1 }}>
                            <Text
                              style={{
                                paddingVertical: 4,
                                paddingHorizontal: 6,
                              }}>
                              {item}
                            </Text>
                          </View>
                          {idProcessAnswers.length > 0 ? (
                            <>
                              <View
                                style={{
                                  width: "20%",
                                  justifyContent: "space-evenly",
                                  borderRight: 0.5,
                                  borderLeft: 0.5,
                                }}>
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}>
                                  {Object.values(idProcessAnswers[index])[0] ===
                                  "true"
                                    ? "YES"
                                    : "NO"}
                                </Text>
                              </View>
                            </>
                          ) : (
                            <></>
                          )}
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            </>
          )}
        </>
      ) : null}
      <View style={styles.pageNumber} fixed>
        <Text style={{ fontSize: 8 }}>{codigo}</Text>
        <Text style={{ fontSize: 8 }}>Revisiòn: 00</Text>
        <Text style={{ fontSize: 8 }}>Date: May 17, 2022</Text>
        <Text
          style={{ fontSize: 8 }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  mainContainer: {},
  topSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  firstTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  icon: {
    width: 70,
    height: 90,
    borderWidth: 0.5,
    borderColor: "black",
  },
  row: {
    borderTop: 0.5,
    borderColor: "black",
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderLeft: 0.5,
    borderColor: "black",
    textAlign: "center",
    minHeight: 20,
    paddingHorizontal: 2,
  },
  normalTableText: {
    fontSize: 10,
    paddingTop: 5,
  },
  verticalCell: {
    display: "flex",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 35,
    left: 0,
    right: 30,
    textAlign: "right",
    color: "grey",
  },
});

export default PdfDocument;
