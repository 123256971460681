import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";

const MonthSelector = ({ handleMonthSelect }) => {
  const [month, setMonth] = useState();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "Show all",
  ];
  const handleChange = (e) => {
    setMonth(e.target.value);
    handleMonthSelect(e.target.value);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Select month</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={month}
        label="Select month"
        onChange={handleChange}>
        {months.map((item, index) => {
          return (
            <MenuItem key={index} value={index}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MonthSelector;
