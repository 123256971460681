import React, { useEffect, useState } from "react";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import moment from "moment";
import {
  getGapPool,
  loadCertificates,
  requestpersonalstatus,
} from "../../Actions/Actions";
import LoadingProgress from "../Commons/LoadingProgress";
import Chart from "react-apexcharts";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { size } from "lodash";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import { Document, PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "../PdfDocument";
import ExportReportBtn from "../Commons/ExportReportBtn";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default function Seaferers() {
  const [staffData, setStaffData] = useState([]);
  const [staffAmount, setStaffAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [datachartstatus, setdatachartstatus] = useState([]);
  const [barChar, setBarChar] = useState(null);
  const [pageSize, setPageSize] = React.useState(50);
  const [searchText, setSearchText] = React.useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [person, setperson] = useState([]);
  const [certsData, setCertsData] = useState([]);

  const getAllData = async () => {
    setIsLoading(true);
    let resultadofinal = [];
    const userdata = JSON.parse(sessionStorage.getItem("userdata"));
    let { rol, company } = userdata;
    let response = await getGapPool();

    const certs = await loadCertificates();

    let graphstatus = await requestpersonalstatus();
    if (!response.length) return;
    //FILTRA DATOS POR COMPAÑIA PARA EL ROL DE AUDITORÍA
    if (rol === "AUDIT") {
      response = response.filter(
        (item) =>
          item.company.trim().toUpperCase() === company.trim().toUpperCase()
      );

      let groupcompany = groupBy(response, "status");
      let claves = Object.keys(groupcompany);

      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        resultadofinal.push({ id: clave, total: size(groupcompany[clave]) });
      }

      graphstatus = resultadofinal;
    }
    setCertsData(certs);
    setStaffData(response);
    setStaffAmount(size(response));
    setdatachartstatus(graphstatus);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (!datachartstatus) return;

    const state = {
      options: {
        chart: {
          id: "apexchart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Total",
          data: [],
        },
      ],
    };
    datachartstatus.forEach((item) => {
      state.options.xaxis.categories.push(item.id);
      state.series[0].data.push(item.total);
    });
    setBarChar(state);
  }, [datachartstatus]);

  const columns = [
    {
      field: "Id",
      headerName: "View CV",
      width: 80,
      renderCell: (params) => (
        <PersonIcon
          style={{ fontSize: 25 }}
          color="primary"
          onClick={() => {
            setperson(params.row);
            setShowPdf(true);
          }}
        />
      ),
      sortable: false,
    },
    {
      field: "fullname",
      headerName: "Full name",
      width: 300,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      width: 201,
    },
    {
      field: "position_applying",
      headerName: "Position",
      sortable: true,
      width: 150,
    },
    {
      field: "department",
      headerName: "Department",
      sortable: true,
      width: 150,
    },
    {
      field: "company",
      headerName: "Company",
      sortable: true,
      width: 150,
    },
  ];

  let groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const requestSearch = async (searchValue) => {
    if (searchValue === "") {
      setSearchText(searchValue);
      setIsLoading(true);
      await getAllData();
      setIsLoading(false);
    } else {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = staffData.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
      setStaffData(filteredRows);
    }
  };

  const getCsvData = () => {
    const csvData = [];
    const headers = [
      "Full name",
      "Status",
      "Position",
      "Department",
      "Company",
    ];
    staffData.forEach((item) => {
      csvData.push({
        fullname: item.fullname,
        status: item.status,
        position_applying: item.position_applying,
        department: item.department,
        company: item.company,
      });
    });
    return [headers, csvData];
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        {showPdf ? (
          <>
            <PDFViewer
              style={{
                width: "90%",
                height: "100vh",
                zIndex: 99999999,
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
              }}>
              <Document>
                <PdfDocument person={person} certData={certsData} />
              </Document>
            </PDFViewer>
            <Button
              sx={{
                position: "fixed",
                top: 0,
                right: 15,
                color: "white",
                zIndex: 9999999,
                fontSize: 30,
              }}
              onClick={() => setShowPdf(!showPdf)}>
              X
            </Button>
            <div
              style={{
                width: "100%",
                height: "100%",
                zIndex: 999999,
                left: 0,
                top: 0,
                position: "fixed",
                backgroundColor: "black",
                opacity: 0.5,
              }}
            />
          </>
        ) : null}
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}>
          <Typography variant="h5" color="primary">
            TOTAL STAFF
          </Typography>
          <Typography component="p" variant="h4">
            #{staffAmount} Staff
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            {moment().format("MMM Do YY")}
          </Typography>
        </Paper>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}>
          {/* NEW CHART HEREttttttttttttttttttttt */}
          <Typography variant="h5" color="primary">
            STAFF STATUS
          </Typography>
          <Box sx={{ p: 3, mt: 3 }}>
            {barChar && (
              <Chart
                options={barChar.options}
                series={barChar.series}
                type="bar"
                width={"100%"}
                height={320}
              />
            )}
          </Box>
        </Paper>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}>
          {/* NEW CHART HERE */}
          {staffData && (
            <Box sx={{ p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}>
                <Typography variant="h5" color="primary">
                  STAFF LIST
                </Typography>
                <ExportReportBtn getData={getCsvData} />
              </Box>
              <Box sx={{ height: "70vh" }}>
                <DataGrid
                  rows={staffData}
                  columns={columns}
                  sx={{
                    boxShadow: 2,
                  }}
                  loading={isLoading}
                  pagination
                  rowsPerPageOptions={[20, 50, 100]}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  initialState={{
                    pagination: {
                      pageSize: pageSize,
                    },
                  }}
                  components={{ Toolbar: QuickSearchToolbar }}
                  componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => {
                        requestSearch(event.target.value);
                      },
                      clearSearch: () => requestSearch(""),
                    },
                    pagination: { classes: null },
                  }}
                />
              </Box>
            </Box>
          )}
        </Paper>
        <LoadingProgress loading={isLoading} />
      </Container>
    </>
  );
}
