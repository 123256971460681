import { collection, onSnapshot, query } from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { createContext } from "react";
import { fixingAb, getData } from "../Actions/Actions";
import { db } from "../Firebase.js/Firebase";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [staffRequest, setStaffRequest] = useState();
  const [updateInfo, setUpdateInfo] = useState();

  useEffect(() => {
    const loadResults = async () => {
      const citiesRef = query(collection(db, "Request"));
      onSnapshot(citiesRef, (snapshot) => {
        const result = [];
        snapshot.docs.forEach((doc) => {
          let info = doc.data();
          info.id = doc.id;
          result.push(info);
        });

        let respuesta = [];

        result.length > 0 && result.sort(compare);
        result.map((item) => {
          let dato = item;
          let amount = 0;
          dato.createAt = moment(dato.createAt.toDate()).format("lll");
          dato.bordingDate = dato.bordingDate
            ? moment(dato.bordingDate.toDate()).format("ll")
            : dato.bordingDate;

          dato.request_details.map((amountdetail) => {
            amount = Number(amount) + Number(amountdetail.requested);
          });
          dato.amount = amount;

          respuesta.push(dato);
        });
        setStaffRequest(respuesta);
      });
    };
    loadResults();
  }, []);

  useEffect(() => {
    // FIXING AB DELETE THIS PLEASE
    //fixingAb();
    // FIXING AB DELETE THIS PLEASE
    const loadResults = async () => {
      const citiesRef = query(collection(db, "UpdateRequests"));
      onSnapshot(citiesRef, (snapshot) => {
        const result = [];
        snapshot.docs.forEach((doc) => {
          let info = doc.data();
          info.id = doc.id;
          result.push(info);
        });
        setUpdateInfo(result);
      });
    };

    loadResults();
  }, []);

  function compare(a, b) {
    if (a.createAt > b.createAt) {
      return -1;
    }
    if (a.createAt < b.createAt) {
      return 1;
    }
    return 0;
  }

  return (
    <GlobalContext.Provider value={{ staffRequest, updateInfo }}>
      {children}
    </GlobalContext.Provider>
  );
};
