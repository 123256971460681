import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Title from "../Commons/Title";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import TableContainer from "@mui/material/TableContainer";

export default function LicBookSTCW({
  data,
  addNew,
  deleterecord,
  edit,
  isview,
  disableAdd,
  dbData,
  checkForNewFiles,
}) {
  let datos = isEmpty(data) ? [] : JSON.parse(data);

  const checkfileExist = (obj) => {
    if (isview !== 1) {
      if (dbData[0].lic_sbook_stcw_certificates === "") return false;
      let documentArray = JSON.parse(dbData[0].lic_sbook_stcw_certificates);
      const isExist = documentArray.some((item) =>
        Object.entries(obj).every(([key, value]) => item[key] === value)
      );
      return isExist;
    }
    return true;
  };

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{ marginBottom: 2 }}>
          {disableAdd ? (
            ""
          ) : (
            <>
              <Button variant="contained" onClick={addNew}>
                ADD +
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <TableContainer sx={{ maxHeight: 200 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Description of cert / course</TableCell>
              <TableCell>Country of issue</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Date of issue</TableCell>
              <TableCell>Date of expiry</TableCell>
              {isview !== 1 && (
                <>
                  <TableCell>Edit.</TableCell>
                  <TableCell>Del.</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {datos.map((row) => (
              <TableRow key={Math.random()}>
                {checkForNewFiles ? (
                  <>
                    {checkfileExist(row) ? (
                      <>
                        <TableCell>
                          {row["Description of cert / course"]}
                        </TableCell>
                        <TableCell>{row["Country of issue"]}</TableCell>
                        <TableCell>{row["Number"]}</TableCell>
                        <TableCell>{row["Date of issue"]}</TableCell>
                        <TableCell>{row["Date of expiry"]}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Description of cert / course"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Country of issue"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Number"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Date of issue"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Date of expiry"]}
                        </TableCell>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TableCell>{row["Description of cert / course"]}</TableCell>
                    <TableCell>{row["Country of issue"]}</TableCell>
                    <TableCell>{row["Number"]}</TableCell>
                    <TableCell>{row["Date of issue"]}</TableCell>
                    <TableCell>{row["Date of expiry"]}</TableCell>
                  </>
                )}

                {isview !== 1 && (
                  <>
                    <TableCell>
                      <ModeEditIcon
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          edit(row);
                        }}></ModeEditIcon>
                    </TableCell>
                    <TableCell>
                      <DeleteIcon
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let resp = window.confirm(
                            "Do you want delete this document?"
                          );
                          if (resp) {
                            deleterecord(datos, row);
                          }
                        }}></DeleteIcon>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
